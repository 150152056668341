import React, { useEffect, useState } from "react";
import "./UniversityStructure.css";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiSquareRemove } from "react-icons/ci";

const BulimXona = () => {

    const [maqsad, setMaqsad] = useState();
  const [text, setText] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Yangi Fakultet yaratildi.") {
      toast.success(text);
    } else if (text === "Fakultet o'zgartirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };

  const getYunalish = () => {
    axios
      .get(BaseUrl + "/api/admin/bulimxona", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.xonalar);
        setMaqsad(res.data.xonalar);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    getYunalish();
  }, []);

  const deleteYunalish = (id) => {
    axios
      .delete(BaseUrl + `/api/admin/bulimxona/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          getYunalish();
          setText(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 13;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(maqsad && maqsad.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(maqsad && maqsad.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, maqsad]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % maqsad.length;
    setItemOffset(newOffset);
  };

  return (
    <div>
      <ToastContainer />
      <h2 className="mt-4">Bo'lim xonasi</h2>
      <hr />
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Link to="/Supermain/bulim hona add">
          <div className="add-kafedra">Xona qo'shish</div>
        </Link>
      </div>
      <div className="tableScroll">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Bolim</th>
              <th scope="col">Nomi</th>
              <th scope="col">Izoh</th>
              <th scope="col">Holati</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{item.id}</th>
                  <td>{item.bulim.nomi}</td>
                  <td>{item.nomi}</td>
                  <td>{item.izoh}</td>
                  <td>
                    {item.active == 0 ? (
                      <div className="chek2">
                        <CiSquareRemove style={{ color: "white" }} />
                      </div>
                    ) : (
                      <div className="chek">
                        <HiOutlineCheckCircle style={{ color: "white" }} />
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="main_edite-box">
                      <div className="edite_show_box">
                        <div
                          onClick={() =>
                            navigate(`/Supermain/bulim hona add/${item.id}`)
                          }
                          className="edite"
                        >
                          <AiOutlineEdit />
                        </div>
                        <div
                          onClick={() => deleteYunalish(item.id)}
                          className="delete"
                        >
                          <MdOutlineDelete />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default BulimXona;
