import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Select, Radio } from "antd";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import TextArea from "antd/es/input/TextArea";

const AddToplam = () => {

    const [nomi, setNomi] = useState("")
    const [anotatsiya, setAnotatsiya] = useState("")
    const [country_id, setCountryId] = useState("")
    const [city_id, setCity_id] = useState("")
    const [ilmiy_nashr_turi_id, setIlmiy_nashr_turi_id] = useState("")
    const [resurs_tili_id, setResurs_tili_id] = useState("")
    const [fan_sohasi_id, setFan_sohasi_id] = useState("")
    const [yunalishi_id, setYunalishi_id] = useState("")
    const [yili, setYili] = useState("")
    const [sahifa_soni, setSahifa_soni] = useState("")
    const [invertar_raqam, setInvertar_raqam] = useState("")
    const [muqova_jpg, setMuqova_jpg] = useState("")
    const [elektron_pdf, setElektron_pdf] = useState("")
    const [izoh, setIzoh] = useState("")
    const [user_id, setUser_id] = useState("")
    const [mualliflar, setMualliflar] = useState("")
    const [kalitSuzlar, setKalitSuzlar] = useState("")
    const [text, setText] = useState(false)
    const { id } = useParams()
    const [active, setActive] = useState("")

    const [ilmiyNashr, setIlmiyNahr] = useState({
        nomi: "",
        anotatsiya: "",
        resurs_turi: "",
        resurs_tili_id: "",
        fan_sohasi_id: "",
        country_id: "",
        fan_yunalishi_id: "",
        country_id: "",
        nashr_yili: "",
        kirish_huquqi: "",
        sahifalar_soni: "",
        doi_kodi: "",
        muassasa_nomi: "",
        user_id: sessionStorage.getItem("userId"),
        muqova_jpg: "",
        fayl_pdf:"",
        izoh: "",
        issn:"",
        mualliflar: [],
        // bosh_muharrir:""
    })
    let formData = new FormData();
    for (const key in ilmiyNashr) {
        if (ilmiyNashr.hasOwnProperty(key)) {
            const value = ilmiyNashr[key];
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    const subObj = value[i];
                    for (const subKey in subObj) {
                        if (subObj.hasOwnProperty(subKey)) {
                            const subValue = subObj[subKey];
                            const subKeyWithIndex = `${key}[${i}][${subKey}]`;
                            formData.append(subKeyWithIndex, subValue);
                        }
                    }
                }
            } else {
                formData.append(key, value);
            }
        }
    }
    const davlat = useSelector(state => state.store.davlat)
    const text_type = useSelector(state => state.store.text_type)
    const book_language = useSelector(state => state.store.book_language)
    const science_field = useSelector(state => state.store.science_field)
    const [getShaharId, setGetShaharId] = useState([])
    const [getYunalish, setGetYunalish] = useState([])

    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") { } else if (text === "Yangi Ilmiy nashr to'plami yaratildi.") {
            toast.success(text)
        }
        else if (text === "Fakultet o'zgartirildi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };
    const addFakultet = () => {
        if (id) {
            axios.put(BaseUrl + "/api/admin/fakultet/edit/" + id, { nomi, izoh, active }, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                }
            }).catch(err => {
                console.log(err.response.data.errors);
                setText(err.response.data.errors)
            })
        }
        else {
            axios.post(BaseUrl + "/api/kutubxonachi/ilmiytuplam", formData, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    // setNomi("")
                    // setIzoh("")
                }
            }).catch(err => {
                // console.log(country_id);
                console.log(err.response);
                setText(err.response.data.errors)
            })
        }
    }
    const getFakultet = () => {
        axios.get(BaseUrl + "/api/admin/fakultet", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            res.data.fakultetlar.forEach((item, index) => {
                if (item.id == id) {
                    setNomi(item.nomi);
                    setIzoh(item.izoh);
                    // setLastName(item.lastname);
                    // setFathername(item.fathername);
                    // console.log(item);
                    // console.log(res.data)
                }
            })
            // dispatch(BulimAction(res.data.bulimlar))

        }).catch(err => {
            console.log(err);
        })
    }
    const getShaharID = () => {
        axios.get(BaseUrl + "/api/admin/city/" + ilmiyNashr.country_id, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data.cities);
            setGetShaharId(res.data.cities)
        }).catch(err => {
            console.log(err);
        })
    }
    const getYunalishId = () => {
        axios.get(BaseUrl + "/api/kutubxonachi/kitobyunalishi/" + ilmiyNashr.fan_sohasi_id, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data.kitobYunalishlari);
            setGetYunalish(res.data.kitobYunalishlari)
        }).catch(err => {
            console.log(err);
        })
    }
    useEffect(() => {
        getShaharID()
    }, [ilmiyNashr.country_id])
    useEffect(() => {
        getYunalishId()
    }, [ilmiyNashr.fan_sohasi_id])
    useEffect(() => {
        getFakultet()
    }, [])
    const { Option } = Select;
    const [muallifArr, setMuallifArr] = useState([])
    const [kalitSozArr, setKalitSozArr] = useState([])
    const handleChange = (value) => {
        setMuallifArr(value && value.map((it) => ({ nomi: it })));
    };
    const handleChange2 = (value) => {
        setKalitSozArr(value && value.map((it) => ({ nomi: it })));
    };
    useEffect(() => {
        setIlmiyNahr({ ...ilmiyNashr, mualliflar: muallifArr });
    }, [muallifArr]);
    useEffect(() => {
        setIlmiyNahr({ ...ilmiyNashr, kalitSuzlar: kalitSozArr });
    }, [kalitSozArr]);
    return (
        <div style={{ width: "100%" }}>
            <ToastContainer />
            <h2 className="mt-4">{id ? "Ma'qola va tezislar to'plamlari o'zgartirish" : "Ma'qola va tezislar to'plamlari yaratish"}</h2>
            <hr />
            <div style={{ width: "100%", padding: "10px 50px", display: "flex", justifyContent: "space-between" }} className="main_box">
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        <div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Nomini kiriting</label></div>
                                <input onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, nomi: e.target.value })} value={ilmiyNashr.nomi} placeholder="Nomi..." className="form-control" type="text" />
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Annotatsiyasini kiriting</label></div>
                                <TextArea onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, anotatsiya: e.target.value })} value={ilmiyNashr.anotatsiya} placeholder="Annotatsiyasini..." className="form-control" type="text" />
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Resurs turini tanlng</label></div>
                                <select className="form-select" onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, resurs_turi: e.target.value })} name="" id="">
                                    <option value="">Tanlang</option>
                                    <option value="Ilmiy maqola">Ilmiy maqola</option>
                                    <option value="Ilmiy tezis">Ilmiy tezis</option>
                                </select>
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Resurs tilini tanlang</label></div>
                                <select className="form-select" onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, resurs_tili_id: e.target.value })} name="" id="">
                                    <option value="">Tanlng</option>
                                    {
                                        book_language && book_language.map((item, index) => (
                                            <>
                                                <option key={index} value={item.id}>
                                                    {item.nomi}
                                                </option>
                                            </>
                                        ))
                                    }
                                </select>
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Fan sohasini tanlang</label></div>
                                <select onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, fan_sohasi_id: e.target.value })} value={ilmiyNashr.fan_sohasi_id} className="form-select" name="" id="">
                                    <option>
                                        Tanlang
                                    </option>
                                    {
                                        science_field && science_field.map((item, index) => (
                                            <>
                                                <option key={index} value={item.id}>
                                                    {item.nomi}
                                                </option>
                                            </>
                                        ))
                                    }
                                </select>
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Davlatni tanlang</label></div>
                                <select onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, country_id: e.target.value })} className="form-select" name="" id="">
                                    <option value="">
                                        Tanlang
                                    </option>
                                    {
                                        davlat && davlat.map((item, index) => (
                                            <>
                                                <option key={index} value={item.id}>
                                                    {item.nomi}
                                                </option>
                                            </>
                                        ))
                                    }
                                </select>
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Nashr yilini kiriting</label></div>
                                <input onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, nashr_yili: e.target.value })} value={ilmiyNashr.nashr_yili} placeholder="Nashr yilini kiriting ..." className="form-control" type="text" />
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">ISSN kiriting</label></div>
                                <input onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, issn: e.target.value })} value={ilmiyNashr.issn} placeholder="Nashr yilini kiriting ..." className="form-control" type="text" />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        <div>
                            <div><label className="mt-3" htmlFor="">Kirish huquqini tanlang</label></div>
                            <select className="form-select" onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, kirish_huquqi: e.target.value })} name="" id="">
                                <option value="">Tanlang</option>
                                <option value="1">Ochiq</option>
                                <option value="2">Qisman ochiq</option>
                                <option value="3">Yopiq</option>
                            </select>
                        </div>
                        <div>
                            <div><label className="mt-3" htmlFor="">Sahifalar sonini kiriting</label></div>
                            <input onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, sahifalar_soni: e.target.value })} value={ilmiyNashr.sahifalar_soni} placeholder="Sahifalar sonini kiriting ..." className="form-control" type="text" />
                        </div>
                        <div>
                            <div><label className="mt-3" htmlFor="">DOI kodini kiriting</label></div>
                            <input onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, doi_kodi: e.target.value })} value={ilmiyNashr.doi_kodi} placeholder="DOI kodini kiriting ..." className="form-control" type="text" />
                        </div>
                        <div>
                            <div><label className="mt-3" htmlFor="">Muassasa nomi</label></div>
                            <input onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, muassasa_nomi: e.target.value })} value={ilmiyNashr.muassasa_nomi} placeholder="Muassasa nomi ..." className="form-control" type="text" />
                        </div>
                        <div>
                            <div><label className="mt-3" htmlFor="">Bosh muharrir</label></div>
                            <input onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, bosh_muharrir: e.target.value })} value={ilmiyNashr.bosh_muharrir} placeholder="Bosh muharrir ..." className="form-control" type="text" />
                        </div>
                        <div>
                            <div><label className="mt-3" htmlFor="">Muqova rasmini kiriting</label></div>
                            <input onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, muqova_jpg: e.target.files[0] })} placeholder="Muqova jpg farmatda yuklang" className="form-control" type="file" />
                        </div>
                        <div>
                            <div><label className="mt-3" htmlFor="">Fayilni kiriting</label></div>
                            <input onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, fayl_pdf: e.target.files[0] })} placeholder="Fayilni kiriting" className="form-control" type="file" />
                        </div>
                        <div>
                            <div><label className="mt-3" htmlFor="">Tahririyat a'zolarini kiriting</label></div>
                            <Select
                                placeholder="Kimga"
                                mode="tags"
                                style={{ width: "100%" }}
                                tokenSeparators={[","]}
                                onChange={handleChange}
                            >
                                {/* {userName.map((item, index) => ( */}
                                <Option></Option>
                                {/* ))} */}
                            </Select>
                        </div>
                        {
                            id ? <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <select style={{ width: "200px", height: "40px" }} className="form-select mt-3" onChange={(e) => setIlmiyNahr({ ...ilmiyNashr, active: e.target.value })} name="" id="">
                                    <option value="">tanlang</option>
                                    <option value="1">Faol</option>
                                    
                                    <option value="0">Faol emas</option>
                                </select>
                                <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                    <button onClick={addFakultet} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                                </div>
                            </div> : <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                <button onClick={addFakultet} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                            </div>
                        }

                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddToplam