import React, { useEffect, useState } from "react";
import "./UniversityStructure.css"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { MdOutlineDelete } from "react-icons/md"
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { KafedraAction, KutubxonaActions, YunalishAction } from "../../../redux/actions/Action";
import ReactPaginate from "react-paginate"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CiSquareRemove } from "react-icons/ci"    


const UniversityKutubxona = () =>{

    const dispatch = useDispatch()
    const kutubxona = useSelector(state => state.store.kutubxona)
    const [text, setText] = useState(false)
    const navigate = useNavigate()


    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === ""){} 
        else if (text === "Yangi Fakultet yaratildi."){
            toast.success(text)
        }
        else if (text === "Fakultet o'zgartirildi."){
            toast.success(text)
        }
        else{
            toast.error(text)
        }
        setText("")
    };

    const getKutubxona = () => {
        axios.get(BaseUrl + "/api/admin/kutubxonabulim", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data.kutubxonaBulim);
            // setFakultet(res.data.fakultetlar)
            dispatch(KutubxonaActions(res.data.kutubxonaBulim))
        }).catch(err => {
            console.log(err.response);
        })
    }

    useEffect(() => {
        getKutubxona()
    }, [])

    const deleteKutubxona = (id) =>{
        axios.delete(BaseUrl+`/api/admin/kutubxonabulim/${id}`,{
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res.data);
            if (res.status == 200) {
                getKutubxona()
                setText(res.data.message)
            }
        }).catch(err=>{
            console.log(err);
        })
    }



    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 13;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(Loading items from ${itemOffset} to ${endOffset});
        setCurrentItems(kutubxona && kutubxona.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(kutubxona.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, kutubxona]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % kutubxona.length;
        setItemOffset(newOffset);
    };

    return(
        <div>
             <ToastContainer/> 
        <h2 className="mt-4">Kutubxona</h2>
        <hr />
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <Link to="/Supermain/kutubxona/add">
                <div className="add-kafedra">Qo'shish</div>
            </Link>
        </div>
        <div className="tableScroll">
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">№</th>
                    <th scope="col">Ma'sul shaxs</th>
                    <th scope="col">Nomi</th>
                    <th scope="col">Izoh</th>
                    <th scope="col">Faolmi</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {
                    currentItems && currentItems.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{item.id}</th>
                            <td>{item.masul_shaxs}</td>
                            <td>{item.nomi}</td>
                            <td>{item.izoh}</td>
                            <td>{
                                item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                    :
                                    <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                }</td>
                            <td>
                                <div className="main_edite-box">
                                    <div className="edite_show_box">
                                        {/* <div className="show_data"><AiOutlineEye /></div> */}
                                        <div onClick={() => navigate(`/Supermain/kutubxona/add/${item.id}`)} className="edite"><AiOutlineEdit /></div>
                                        <div onClick={()=>deleteKutubxona(item.id)} className="delete"><MdOutlineDelete /></div>
                                    </div>
                                    {/* <div className="allDelete">Ma'lumotlar bazasidan o'chirish</div> */}
                                </div>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        </div>
        <div className="my-pagination">
            <ReactPaginate
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeLinkClassName="active"
            />
        </div>
    </div>
)
    
}

export default UniversityKutubxona;