import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EyeOutlined } from "@ant-design/icons";
import useScanDetection from "use-scan-detection";

const Royhat = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [maqsad, setMaqsad] = useState();
  const [text, setText] = useState(false);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Bu foydalanuvchi tashrif buyurgan") {
      toast.error(text);
    } else {
      toast.success(text);
    }
    setText("");
  };
  ///api/kutubxonachi/qatnov  /api/kutubxonachi/qatnovlar
  const getYunalish = (page = 1) => {
    axios
      .get(
        BaseUrl +
        `/api/kutubxonachi/qatnovlar?page=${page}&search=${searchTerm}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data.users.data);
        setPageCount(res.data.users.last_page);
        setUsers(res.data.users.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    getYunalish();
  }, [text, searchTerm]);

  const userId = sessionStorage.getItem("userId");

  const [userID, setUserId] = useState('')
  const [userID2, setUserId2] = useState('')


  useScanDetection({
    onComplete: setUserId2,
    minLength: 3,
  })




  const QabulQilish = () => {
    if (userID) {
      axios.post(BaseUrl + `/api/kutubxonachi/qatnov`, { userId, user_id: userID, }, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => {
        console.log("resp -", res.data);
        // if (res.status == 200) {
        //     getYunalish()
        setText(res.data.message);
        // setUserId('')
        // console.log("resp - ", res);
        // }
      })
        .catch((err) => {
          // console.log(err.response);
          setText(err.response.data.errors)
        });
    }
  };

  let  testId = '' 

  const QabulQilish2 = () => {
    if (userID2.length == 3) {
       testId = userID2.slice(2)
    }
    else if (userID2.length == 4) {
       testId = userID2.slice(2)
    }
    else if (userID2.length == 5) {
       testId = userID2.slice(2)
    }
    else if (userID2.length == 6) {
       testId = userID2.slice(2)
    }
    else {
       testId = userID2.slice(2, -9)
    }
    console.log('testId', testId);
    if (testId) {
      console.log(testId);
      axios.post(BaseUrl + `/api/kutubxonachi/qatnov`, { userId, user_id: testId, }, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => {
        console.log("resp -", res.data);
        // if (res.status == 200) {
        //     getYunalish()
        setText(res.data.message);
        // setUserId('')
        // console.log("resp - ", res);
        // }
      })
        .catch((err) => {
          console.log(err.response.data.errors);
          setText(err.response.data.errors)
        });
    }
  };

  useEffect(() => {
    QabulQilish()
  }, [userID])

  useEffect(() => {
    QabulQilish2()
    // console.log('userID2',userID2);
  }, [userID2])

  const itemsPerPage = 13;
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(users && users.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, users]);

  const handlePageClick = (event) => {
    getYunalish(event.selected + 1);
  };

  return (
    <div>
      <ToastContainer />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="mt-4">Ro'yxatlar</h2>
        <div className="for-search">
          <div className="for-search2">
            <img
              style={{
                width: "30px",
                position: "absolute",
                margin: "5px 0 0 5px",
              }}
              src="/images/search.png"
              alt=""
            />
          </div>
          <input
            placeholder="Ism familiya bo'yicha qidiruv"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            className="form-control search_input"
            type="search"
          />
        </div>
      </div>
      <hr />
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        {/* <Link to="/Supermain/maqsad/add">
                <div className="add-kafedra">Maqsad qo'shish</div>
            </Link> */}
      </div>
      <div className="tableScroll">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Ismi Familiyasi</th>
              <th scope="col">Foydalanuvchi Toifasi</th>
              <th scope="col">fakulteti</th>
              <th scope="col">Yo'nalishi</th>
              <th scope="col">Kursi</th>
              <th scope="col">Holati</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{item.id}</th>
                  <td>
                    {item.firstname} {item.lastname}
                  </td>
                  <td>{item.lavozim.nomi}</td>
                  <td>{item.fakultet.nomi}</td>
                  <td>{item.yunalish.nomi}</td>
                  <td>{item.kursi}</td>
                  <td>
                    {item.isNew == true ? (
                      <div
                        disabled
                        onClick={() => setUserId(item?.id)}
                        className="edite"
                      >
                        Keldi
                      </div>
                    ) : (
                      <div style={{
                        display: "flex"
                      }}>
                        <div
                          disabled
                          style={{
                            width: "60px",
                            backgroundColor: "gray",
                            padding: "2px",
                            borderRadius: "4px",
                            color: "white",
                            textAlign: "center",
                            opacity: ".4",
                          }}
                        >
                          Kelgan
                        </div>
                        <div
                          onClick={() =>
                            navigate(`/adminMain/royhat2/detail/${item.id}`)
                          }
                          className="edite"
                        >
                          <EyeOutlined />
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default Royhat;
