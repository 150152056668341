import React, { useState, useEffect } from "react";
import "../Auth/Auth.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { BaseUrl } from "../BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const [bulim, setBulimData] = useState([])
  const [fakultet, setFakultetData] = useState([])
  const [uquvyili, setEducationYear] = useState([])

  const user_id = sessionStorage.getItem("userId");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassworWisable] = useState("");
  const [email, setEmail] = useState("");
  const [rasm, setRasm] = useState("");
  const [tel_nomer, setTelNomer] = useState("");
  const [telegram_link, setTelegram] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [middlename, setMiddleName] = useState("");
  const [date_birth, setDateBirth] = useState("");
  const [jinsi, setJinsi] = useState("");
  const [manzili, setManzili] = useState("");
  const [jshshir, setJshshir] = useState("");
  const [passport, setPassport] = useState("");
  const [passport_pd, setPassportPd] = useState("");
  const [f_turi_id, setTuri] = useState("");
  const [bulim_id, setBulim] = useState("");
  const [lavozim_id, setLavozim] = useState("");
  const [fakultet_id, setFakultet] = useState("");
  const [kafedra_id, setKafedra] = useState("");
  const [yunalish_id, setYunalish] = useState("");
  const [guruh_id, setGuruh] = useState("");
  const [uquv_yili_id, setUquvYili] = useState("");
  const [role, setRole] = useState("");
  const [active, setActive] = useState("");
  const [kursi, setKursi] = useState("");
  const [hemis_id, setHemisId] = useState("");

  let formData = new FormData();

  formData.append("firstname", firstname);
  formData.append("lastname", lastname);
  formData.append("login", login);
  formData.append("passport", passport);
  formData.append("password", password);
  formData.append("passport_pdf", passport_pd);
  formData.append("password_confirmation", password_confirmation);
  formData.append("email", email);
  formData.append("tel_nomer", tel_nomer);
  formData.append("telegram_link", telegram_link);
  formData.append("middlename", middlename);
  formData.append("date_birth", date_birth);
  formData.append("jinsi", jinsi);
  formData.append("manzili", manzili);
  formData.append("jshshir", jshshir);
  formData.append("bulim_id", bulim_id);
  formData.append("fakultet_id", fakultet_id);
  formData.append("kafedra_id", kafedra_id);
  formData.append("guruh_id", guruh_id);
  formData.append("yunalish_id", yunalish_id);
  formData.append("lavozim_id", lavozim_id);
  formData.append("uquv_yili_id", uquv_yili_id);
  formData.append("role", role);
  formData.append("active", active);
  formData.append("rasm", rasm);
  formData.append("f_turi_id", f_turi_id);
  formData.append("kursi", kursi);
  formData.append("user_id", user_id);
  formData.append("hemis_id", hemis_id);

  const [text, setText] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Yangi foydalanuvchi yaratildi.") {
      toast.success(text);
    } else if (text === "Foydalanuvchi bazadan o'zgartirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };

  const [kafedraIdData, setKafedraIdData] = useState([]);
  const [yunalishIdData, setYunalishIdData] = useState([]);
  const [guruhIdSData, setGuruhIdData] = useState([]);
  const [lavozimlar, setLavozimlar] = useState([]);

  const getUserId = async () => {
    await axios
      .get(BaseUrl + "/api/kutubxonachi/users/show/" + id, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.user);
        setLogin(res.data.user.login);
        setEmail(res.data.user.email);
        setPassword(res.data.user.password_visible);
        setTelNomer(res.data.user.tel_nomer);
        setTelegram(res.data.user.telegram_link);
        setFirstName(res.data.user.firstname);
        setLastName(res.data.user.lastname);
        setMiddleName(res.data.user.middlename);
        setDateBirth(res.data.user.date_birth);
        setJinsi(res.data.user.jinsi);
        setManzili(res.data.user.manzili);
        setJshshir(res.data.user.jshshir);
        setPassport(res.data.user.passport);
        setTuri(res.data.user.f_turi_id);
        setBulim(res.data.user.bulim_id);
        setLavozim(res.data.user.lavozim_id);
        setFakultet(res.data.user.fakultet_id);
        setKafedra(res.data.user.kafedra_id);
        setYunalish(res.data.user.yunalish_id);
        setGuruh(res.data.user.guruh_id);
        setUquvYili(res.data.user.uquv_yili_id);
        setRole(res.data.user.role);
        setActive(res.data.user.active);
        setKursi(res.data.user.kursi);
        setPassworWisable(res.data.user.password_visible);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postUser = async () => {
    if (sessionStorage.getItem("role") == 1) {
      await axios
        .post(BaseUrl + "/api/kutubxonachi/users", formData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setText(res.data.message);
          }
        })
        .catch((err) => {
          setText(err.response.data.errors);
        });
    } else {
      await axios
        .post(BaseUrl + "/api/admin/users", formData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            setText(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setText(err.response.data.errors);
        });
    }
  };

  const editUser = async () => {
    formData.forEach((value, key) => {
      if (value == "null" || value.trim() == "") formData.delete(key);
    });

    if (sessionStorage.getItem("role") == 1) {
      await axios
        .post(BaseUrl + "/api/kutubxonachi/users/edit/" + id, formData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            _method: "PUT",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            setText(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setText(err.response.data.errors);
        });
    } else {
      await axios
        .post(BaseUrl + "/api/admin/users/edit/" + id, formData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            _method: "PUT",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            setText(res.data.message);
          }
        })
        .catch((err) => {
          setText(err.response.data.errors);
        });
    }
  };

  useEffect(() => {
    getUserId();
  }, [id]);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/lavozim/" + f_turi_id, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLavozimlar(res.data.lavozimlar);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [f_turi_id]);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/kafedra/" + fakultet_id, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setKafedraIdData(res.data.kafedralar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fakultet_id]);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/yunalish/" + fakultet_id, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setYunalishIdData(res.data.yunalishlar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [kafedra_id]);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/guruh/" + yunalish_id, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setGuruhIdData(res.data.guruhlar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [yunalish_id]);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/uquvyili", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setEducationYear(res.data.uquvYillari);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/bulim", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setBulimData(res.data.bulimlar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/fakultet", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setFakultetData(res.data.fakultetlar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      <h2 className="mt-4">Ro'yxatga olish</h2>
      <hr />
      <div className="for_main_img">
        <div
          className="form_data d-flex"
          style={{ justifyContent: "space-around" }}
        >
          <div style={{ width: "40%" }}>
            <div className="mb-3 mt-3">
              <label htmlFor="text" className="form-label">
                Ismi:
              </label>
              <div>
                <input
                  defaultValue={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Ismi"
                  name="firstName"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Familiyasi:
              </label>
              <div>
                <input
                  defaultValue={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Familiyasi"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Otasini ismi:
              </label>
              <div>
                <input
                  defaultValue={middlename}
                  onChange={(e) => setMiddleName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Otasini ismi"
                  name="pswd"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Tug'ilgan sana:
              </label>
              <div>
                <input
                  defaultValue={date_birth}
                  onChange={(e) => setDateBirth(e.target.value)}
                  type="date"
                  className="form-control"
                  placeholder="Tug'ilgan sana"
                  name="pswd"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Telefon no'mer:
              </label>
              <div>
                <input
                  defaultValue={tel_nomer}
                  onChange={(e) => setTelNomer(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Telefon no'mer"
                  name="pswd"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Telegram:
              </label>
              <div>
                <input
                  defaultValue={telegram_link}
                  onChange={(e) => setTelegram(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Telegram"
                  name="pswd"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Jinsi:
              </label>
              <div>
                <select
                  defaultValue={jinsi}
                  onChange={(e) => setJinsi(e.target.value)}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="">Tanlang</option>
                  <option value="Erkak">Erkak</option>
                  <option value="Ayol">Ayol</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Manzil:
              </label>
              <div>
                <input
                  defaultValue={manzili}
                  onChange={(e) => setManzili(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Manzil"
                  name="pswd"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                J.SH.SH.I.R:
              </label>
              <div>
                <input
                  defaultValue={jshshir}
                  onChange={(e) => setJshshir(e.target.value)}
                  type="text"
                  maxLength="14"
                  className="form-control"
                  placeholder="JSHSHIR (Faqat raqam kirgizing)"
                  name="pswd"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Pasprot: (AC 0000000)
              </label>
              <div>
                <input
                  defaultValue={passport}
                  onChange={(e) => setPassport(e.target.value)}
                  maxLength="9"
                  type="text"
                  className="form-control"
                  placeholder="Pasprot"
                  name="pswd"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Pasprot PDF:
              </label>
              <div>
                <input
                  onChange={(e) => setPassportPd(e.target.files[0])}
                  type="file"
                  className="form-control"
                  placeholder="Pasprot PDF"
                  name="pswd"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Foydalanuvchi turi:
              </label>
              <div>
                <select
                  defaultValue={f_turi_id}
                  onChange={(e) => setTuri(e.target.value)}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="">Tanlang</option>
                  <option value="1">Xodim</option>
                  <option value="2">Talaba</option>
                  <option value="3">Mehmon</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Foydalanuvchi toifasi:
              </label>
              <div>
                <select
                  defaultValue={lavozim_id}
                  onChange={(e) => setLavozim(e.target.value)}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="">Tanlang</option>
                  {lavozimlar &&
                    lavozimlar.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div style={{ width: "40%" }}>
            <div className="mb-3 mt-3">
              <label htmlFor="text" className="form-label">
                {" "}
                Hemis id
              </label>
              <div>
                <input
                  defaultValue={hemis_id}
                  onChange={(e) => setHemisId(e.target.value)}
                  maxLength="14"
                  type="text"
                  className="form-control"
                  placeholder="Hemis id"
                  name="text"
                />
              </div>
            </div>
            <div className="mb-3 mt-3">
              <label htmlFor="text" className="form-label">
                {id ? "Login o'zgartirilmaydi: " : "Login:"}
              </label>
              <div>
              {id ? (
                  <input
                    defaultValue={login}
                    disabled
                    onChange={(e) => setLogin(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Login"
                    name="text"
                  />
                ) : (
                  <input
                    defaultValue={login}
                    onChange={(e) => setLogin(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Login"
                    name="text"
                  />
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                {id ? "Parol: " : "Parol:"}
              </label>
              <div>
                {id ? (
                  <input
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="text"
                    disabled
                    className="form-control"
                    placeholder="Parol"
                    name="pswd"
                  />
                ) : (
                  <input
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Parol"
                    name="pswd"
                  />
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                {id ? "Parol tasdig'i: " : "Parol tasdig'i:"}
              </label>
              <div>
                {id ? (
                  <input
                    defaultValue={password_confirmation}
                    onChange={(e) => setPassworWisable(e.target.value)}
                    type="text"
                    disabled
                    className="form-control"
                    placeholder="Parol tasdig'i"
                    name="pswd"
                  />
                ) : (
                  <input
                    defaultValue={password_confirmation}
                    onChange={(e) => setPassworWisable(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Parol tasdig'i"
                    name="pswd"
                  />
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Foydalanuvchining rasmi:
              </label>
              <div>
                <input
                  onChange={(e) => setRasm(e.target.files[0])}
                  type="file"
                  className="form-control"
                  placeholder="Foydalanuvchining rasmi"
                  name="pswd"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Kursi:
              </label>
              <div>
                <select
                  defaultValue={kursi}
                  className="form-select"
                  onChange={(e) => setKursi(e.target.value)}
                  name=""
                  id=""
                >
                  <option>Tanlang</option>
                  <option value="1-kurs">1-kurs</option>
                  <option value="2-kurs">2-kurs</option>
                  <option value="3-kurs">3-kurs</option>
                  <option value="4-kurs">4-kurs</option>
                  <option value="5-kurs">5-kurs</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Bo'limi:
              </label>
              <div>
                <select
                  defaultValue={bulim_id}
                  onChange={(e) => setBulim(e.target.value)}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="">Tanlang</option>
                  {bulim &&
                    bulim.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Fakultet:
              </label>
              <div>
                <select
                  defaultValue={fakultet_id}
                  onChange={(e) => setFakultet(e.target.value)}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="">Tanlang</option>
                  {fakultet &&
                    fakultet.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Kafedra:
              </label>
              <div>
                <select
                  defaultValue={kafedra_id}
                  onChange={(e) => setKafedra(e.target.value)}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="">Tanlang</option>
                  {kafedraIdData &&
                    kafedraIdData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Yo'nalishi:
              </label>
              <div>
                <select
                  defaultValue={yunalish_id}
                  onChange={(e) => setYunalish(e.target.value)}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="">Tanlang</option>
                  {yunalishIdData &&
                    yunalishIdData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Guruhi:
              </label>
              <div>
                <select
                  defaultValue={guruh_id}
                  onChange={(e) => setGuruh(e.target.value)}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="">Tanlang</option>
                  {guruhIdSData &&
                    guruhIdSData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                O'quv yili:
              </label>
              <div>
                <select
                  defaultValue={uquv_yili_id}
                  className="form-select"
                  onChange={(e) => setUquvYili(e.target.value)}
                  name=""
                  id=""
                >
                  <option>Tanlang</option>
                  {uquvyili &&
                    uquvyili.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {sessionStorage.getItem("role") == 1 ? (
              ""
            ) : (
              <div className="mb-3">
                <label htmlFor="pwd" className="form-label">
                  Role:
                </label>
                <div>
                  <select
                    defaultValue={role}
                    className="form-select"
                    onChange={(e) => setRole(e.target.value)}
                    name=""
                    id=""
                  >
                    <option>Tanlang</option>
                    <option value="1">Kutubxonachi</option>
                    <option value="2">Kitobxon</option>
                  </select>
                </div>
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="pwd" className="form-label">
                Holati:
              </label>
              <div>
                <select
                  defaultValue={active}
                  className="form-select"
                  onChange={(e) => setActive(e.target.value)}
                  name=""
                  id=""
                >
                  <option value="">Tanlang</option>
                  <option value="1">Faol</option>
                  <option value="0">Faol emas</option>
                </select>
              </div>
            </div>
            <div
              className="mt-4"
              style={{ display: "flex", justifyContent: "end" }}
            >
              {id ? (
                <button
                  onClick={editUser}
                  className="btn btn-primary d-flex justify-content-end mt-4 mb-5"
                >
                  O'zgartirish
                </button>
              ) : (
                <button
                  onClick={postUser}
                  className="btn btn-primary d-flex justify-content-end mt-4 mb-5"
                >
                  Qo'shish
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
