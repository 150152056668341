import React from "react";

const ElektronAxborot = () => {
  return (
    <div className="container py-3">
      <h3 className="text-center">
        ELEKTRON AXBOROT-KUTUBXONA RESURSLARI BO‘LIMI
      </h3>

      <ul>
        <li>Elektron resurslar orqali foydalanuvchilarga xizmat ko‘rsatish;</li>
        <li>
          Elektron resurslar orqali foydalanuvchilarga xizmat ko‘rsatish
          monitoringini olib boorish;
        </li>
        <li>ARMda elektron kataloglar yaratishda ishtirok etish;</li>
        <li>ARMda ma’lumotlar bazasini boyitishni ta’minlash;</li>
        <li>ARM va ushbu bo‘lim ish rejasiga muvofiq tadbirlar o‘tkazish;</li>
        <li>
          Elektron resurslar orqali aholiga xizmat ko‘rsatishni tashkil etish;
        </li>
        <li>Yangi nashrlar to‘g‘risida axborot berish.</li>
      </ul>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/electronAxborot1.jpg")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
        Axborot-resurs markazida kitobxonlarga elektron axborot kutubxona
        resurslari bilan xizmat ko’rsatish va masofaviy ta’limda talabalarni
        o‘quv va ilmiy adabiyotlar bilan ta’minlash maqsadida ARMning
        <a href="http://kutubxona.adu.uz" target="_blank">
          <span style={{color:"blue"}}> http://kutubxona.adu.uz</span>
        </a>{" "}
        “Elektron kutubxona” saxifasi tashkil etilgan. Elektron kutubxona
        bazasiga jami 10142 nomda elektron resurslar joylashtirilgan bo’lib
        tizimdan foydalanuvchialar soni 17604 nafarni tashkil qiladi.
      </p>

      <div className="row mt-2">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/electronAxborot2.jpg")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
        Shuningdek professor-o‘qituvchi va talabalar bilan mobil qurulmalari
        orqali tezkor axborot almashinuv jarayonini ta’minlash uchun telegram
        tarmog‘ida{" "}
        <a href="https://t.me/aduarmbot" target="_blank">
          <span style={{color:"blue"}}> https://t.me/aduarmbot</span>
        </a>{" "}
        interaktiv kutubxonasi xizmat ko’rsatadi.
      </p>

      <div className="row mt-2">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/electronAxborot3.jpg")}
            alt=""
          />
        </div>
      </div>

      <p className="m-0 mt-2">
        Bundan tashqari{" "}
        <a href="https://aliyev.uz" target="_blank">
          <span style={{color:"blue"}}> https://aliyev.uz</span>
        </a>{" "}
        elektron bazasida o‘quv dasturida kafedralar kesimida berilgan 4150
        nomdagi asosiy va qo‘shimcha adabiyotlarning elektron nusxasi yuklangan.{" "}
      </p>

      <div className="row mt-2">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/electronAxborot4.jpg")}
            alt=""
          />
        </div>
      </div>

      <p className="m-0 mt-2">
        Elektron axborot resurslari bo‘limida muntazam bosma nashrlar scanner
        qilinib Word va PDF shaklga o‘tkazili ARMning elektron resurslar
        bazasiga joylashtiriladi. Elektron o‘quv zallaridagi mavjud o‘quv
        adabiyotlari, kafedralar tomonidan topshirilgan o‘quv uslubiy majmualar
        foydalanuvchilarning fleshka va smartfonlariga yuklab olish imkoniyati
        yaratilgan.
      </p>

      <div className="row mt-2">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/electronAxborot5.jpg")}
            alt=""
          />
        </div>
      </div>

      <p className="m-0 mt-2">
      O‘zbekiston Respublikasi oliy ta’lim va ilmiy tadqiqot muassasalarining   <a href="https://unilibrary.uz" target="_blank">
          <span style={{color:"blue"}}> https://unilibrary.uz</span>
        </a> yagona elektron kutubxona axborot tizimida ham ARM elektron kutubxona bazasi yaratilgan bo‘lib xozirgi kungacha 10431 nomdagi o‘quv va ilmiy adabiyotlar to‘liq joylashtirilgan.  
      
      </p>
    </div>
  );
};

export default ElektronAxborot;
