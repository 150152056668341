import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { BaseUrl } from "../../BaseUrl";
import { useParams } from "react-router-dom";

const AddBulimXona = () => {


    const [bulim, setBulim] = useState()
    const [bulim_id, setBulim_id] = useState()
    const [nomi, setNomi] = useState("")
    const [izoh, setIzoh] = useState("")
    const [text, setText] = useState(false)
    const { id } = useParams()
    const [active, setActive] = useState("")

    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") { } else if (text === "Axborot-kutubxona resurslari bilan xizmat ko‘rsatish bo‘limi (Fond bo'limi) bo'limiga yangi qism biriktirildi.") {
            toast.success(text)
        }
        else if (text === "Yo'nalish bazadan o'zgartirildi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };


    const addFakultet = () => {
        if (id) {
            axios.put(BaseUrl + "/api/admin/bulimxona/edit/" + id, { nomi, izoh, active }, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                }
            }).catch(err => {
                console.log(err.response.data.errors);
                setText(err.response.data.errors)
            })
        }
        else {
            axios.post(BaseUrl + "/api/admin/bulimxona", { nomi, izoh, bulim_id}, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(bulim_id);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                }
            }).catch(err => {
                console.log(bulim_id);
                console.log(err.response.data.errors);
                setText(err.response.data.errors)
            })
        }
    }

    const getYunalish = () => {
        axios.get(BaseUrl + "/api/admin/bulimxona", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            res.data.xonalar.forEach((item, index) => {
                if (item.id == id) {
                    setNomi(item.nomi);
                    setIzoh(item.izoh);
                }
            })
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getYunalish()
    }, [])

    const getKutubxona = () => {
        axios.get(BaseUrl + "/api/admin/kutubxonabulim", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            setBulim(res.data.kutubxonaBulim)
        }).catch(err => {
            console.log(err.response);
        })
    }

    useEffect(() => {
        getKutubxona()
    }, [])


    return (
        <div style={{ width: "100%" }}>
            <ToastContainer />
            <h2 className="mt-4">Bo'lim xona yaratish</h2>
            <hr />
            <div style={{ width: "100%", padding: "10px 50px", display: "flex", justifyContent: "space-between" }} className="main_box">
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        <div>
                        <div>
                            <div><label className="mt-3" htmlFor="">Bo'limni tanlang</label></div>
                            <select onChange={(e)=>setBulim_id(e.target.value)} name="" id="" className="form-select">
                                <option value="">Tanlang</option>
                                {
                                    bulim && bulim.map((item,index)=>(
                                        <option key={index} value={item.id}>{item.nomi}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div>
                            <div><label className="mt-3" htmlFor="">Nomini kiriting</label></div>
                            <input onChange={(e) => setNomi(e.target.value)} value={nomi} placeholder="Nomi..." className="form-control" type="text" />
                        </div>
                            
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                    <div>
                                <div><label className="mt-3" htmlFor="">Izoh kiriting</label></div>
                                <input onChange={(e) => setIzoh(e.target.value)} value={izoh} placeholder="Izoh..." className="form-control" type="text" />
                            </div>
                        <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                            {id ? <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <select style={{ width: "200px", height: "40px" }} className="form-select mt-3" onChange={(e) => setActive(e.target.value)} name="" id="">
                                    <option value="">tanlang</option>
                                    <option value="1">Faol</option>
                                    <option value="0">Faol emas</option>
                                </select>
                                <button onClick={addFakultet} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                            </div>
                                :
                                <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                    <button onClick={addFakultet} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBulimXona;