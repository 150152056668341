import "./Header.css";
import { Link, Routes, Route } from "react-router-dom";
import { MdLogin } from "react-icons/md";
import Home from "../../userPages/home/Home";
// import Articles from './userPages/articles/Articles';
// import Books from './userPages/books/Books';

function Header() {
  return (
    <>
      <div className="header ">
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 shadow-sm">
          <div className="container">
            <Link to="/" className="navbar-brand fw-bold fs-3">
              <img  src={require("../../assets/armlogo.png")} alt="" />
              <span style={{marginLeft:"10px"}}>AK.ADU.UZ</span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto ul">
                <li className="nav-item dropDawn">
                  <Link to="" className="nav-link header_link header_link  ">
                    Kutubxona tuzilmasi
                  </Link>
                  <ul className="list-group innerMenu">
                    <li class="list-group-item">
                      <Link to={"/ilmiy_uslubiy"} className="innerLink">
                        Ilmiy-uslubiy va axborot-ma’lumotlar (davriy nashrlar)
                        bo‘limi
                      </Link>{" "}
                    </li>
                    <li class="list-group-item">
                      <Link to={"/butlash_kataloglashtrish"} className="innerLink">
                        Axborot-kutubxona resurslarini butlash,
                        kataloglashtirish va tizimlashtirish bo‘limi
                      </Link>{" "}
                    </li>
                    <li  class="list-group-item">
                      <Link to={"/axborot_kutubxona_xizmat"} className="innerLink">
                        Axborot-kutubxona resurslari bilan xizmat ko‘rsatish
                        bo‘limi
                      </Link>{" "}
                    </li>
                    <li class="list-group-item">
                      <Link to={"/elektron_axborot"} className="innerLink">
                        Elektron axborot resurslari bo‘limi
                      </Link>{" "}
                    </li>
                    <li class="list-group-item">
                      <Link to={"/xorijiy_axborot"} className="innerLink">
                        Xorijiy axborot-kutubxona resurslari bilan ishlash
                        bo‘limi
                      </Link>{" "}
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropDawn">
                  <Link to="" className="nav-link header_link">
                    Kutubxona xizmatlari
                  </Link>
                  <ul className="list-group innerMenu">
                    <li class="list-group-item">
                      <Link to="/traditional_service" className="innerLink">
                        An'anaviy xizmat
                      </Link>
                    </li>
                    <li class="list-group-item">
                      <Link to="/remote_service" className="innerLink">
                        Masofaviy xizmat
                      </Link>
                    </li>
                    <li class="list-group-item">
                      <Link to="/material_technical_base" className="innerLink">Moddiy texnik bazasi</Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropDawn">
                  <Link to="" className="nav-link header_link">
                    Kutubxona katalogi
                  </Link>
                  <ul className="list-group innerMenu">
                    <li class="list-group-item">
                      <Link to={"/electronic_catalog"} className="innerLink">ARM elektron katalogi</Link>
                    </li>
                    
                  </ul>
                </li>

                <li className="nav-item dropDawn">
                  <Link to="" className="nav-link header_link">
                    Me'yoriy hujjatlar
                  </Link>
                  <ul className="list-group innerMenu">
                    <li class="list-group-item">
                      <a href={require("../file/Axboroty_kutubxona.pdf")} target="_blank" className="innerLink">"Axborot-kutubxona faoliyati to'g'risida"gi Qonun</a>{" "}
                    </li>
                    <li class="list-group-item">
                      <Link to="/nizom" className="innerLink">Nizom</Link>{" "}
                    </li>
                  
                  </ul>
                </li>
              </ul>
              <div>
                {/* <button className='btn btn-outline-dark'>O'zbekcha</button> */}
                {
                  sessionStorage.getItem("token") != null && sessionStorage.getItem("role") == 2 ?
                    <Link to="/profile">
                      <button type="button" className="btn btn-dark ms-auto p-1">
                        <MdLogin /> Shaxsiy kabinetga kirish
                      </button>
                    </Link>

                    :
                    <Link onClick={()=>sessionStorage.setItem("href", window.location.href)} to="/auth">
                      <button type="button" className="btn btn-dark ms-auto">
                        <MdLogin /> Kirish
                      </button>
                    </Link>
                }
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* <Home /> */}
    </>
  );
}

export default Header;
