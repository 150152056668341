import "react-multi-carousel/lib/styles.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../../Super_admin/BaseUrl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./TavsiyaEtilgan.css";

const TavsiyaEtilgan = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [tavsiyaData, setTavsiyaData] = useState([]);
  const navgate = useNavigate();
  const getNews = () => {
    axios
      .get(BaseUrl + "/api/public/kitobtavsiyalar")
      .then((res) => {
        console.log("kitobTavsiyalar", res.data.kitobTavsiyalar);
        setTavsiyaData(res.data.kitobTavsiyalar);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getNews();
  }, []);

  return (
    <div className="row row_main">
      <Carousel
        autoPlay={true}
        infinite
        autoPlaySpeed={2000}
        responsive={responsive}
      >
        {tavsiyaData &&
          tavsiyaData.map((item, index) => {
            return (
              <div
                className="card_tavsiya"
                onClick={() => navgate(`/electronic_catalog/show/${item.id}`)}
              >
                <img
                  className="w-100"
                  src={BaseUrl + "/storage/" + item.muqova_jpg}
                  alt=""
                />
                <div className="card_tavsiya_content">
                  <h2>{item.nomi}</h2>
                </div>
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};

export default TavsiyaEtilgan;
