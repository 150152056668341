/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux";
import { CiSquareRemove } from "react-icons/ci"
import axios from "axios";
import { MdOutlineDelete } from "react-icons/md"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { AdminUserListAction } from "../../redux/actions/Action";
import ReactPaginate from "react-paginate"
import useScanDetection from "use-scan-detection";




const AdminStudentList = () => {
    const navigate = useNavigate()
    const [text, setText] = useState(false);
    const [userCount, setUserCount] = useState(15)
    const [pageCount, setPageCount] = useState();
    const [studentsData, setStudentsData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = userCount;

    const [fakultet, setFakultet] = useState("")
    const [yunalish, setYunalish] = useState("")
    const [lavozim, setLavozim] = useState("")
    const [kursi, setKursi] = useState("")
    const [fakultetList, setFakultetList] = useState()
    const [yunalishList, setYunalishList] = useState()
    const [talimShakli, setTalimShakli] = useState()


    const getFakultet = () => {
        axios.get(`${BaseUrl}/api/admin/fakultet`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.fakultetlar);
            setFakultetList(res.data.fakultetlar)
        }).catch(err => {
            console.log(err);
        })
    }

    const getYunalish = () => {
        axios.get(`${BaseUrl}/api/admin/yunalish/${fakultet}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.yunalishlar);
            setYunalishList(res.data.yunalishlar)
        }).catch(err => {
            console.log(err);
        })
    }
    const getTalimShakli = () => {
        axios.get(`${BaseUrl}/api/admin/lavozim/2`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.lavozimlar);
            setTalimShakli(res.data.lavozimlar)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getFakultet()
        getTalimShakli()
    }, [])
    useEffect(() => {
        getYunalish()
    }, [fakultet])

    
  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Bu foydalanuvchi tashrif buyurgan") {
      toast.error(text);
    } else {
      toast.success(text);
    }
    setText("");
  };

    const getTalabaList = (page = 1) => {
        axios(BaseUrl + `/api/kutubxonachi/users/talabalar?paginator=${userCount}&page=${page}&search=${searchTerm}&fakultet=${fakultet}&yunalish=${yunalish}&lavozim=${lavozim}&kursi=${kursi}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data);
            sessionStorage.setItem("userDataCount", res.data.users.total)
            setPageCount(res.data.users.last_page)
            setStudentsData(res.data.users.data)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getTalabaList()
    }, [userCount, searchTerm,fakultet,yunalish,kursi,lavozim])

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(studentsData && studentsData.slice(itemOffset, endOffset));
    }, [itemOffset, itemsPerPage, studentsData]);


    const handlePageClick = (event) => {
        getTalabaList(event.selected + 1)
    };

    const userId = sessionStorage.getItem("userId");
    const [userID2, setUserId2] = useState('')
  
    useScanDetection({
      onComplete: setUserId2,
      minLength: 3,
    })
    
  let  testId = '' 

  const QabulQilish2 = () => {
    if (userID2.length == 3) {
       testId = userID2.slice(2)
    }
    else if (userID2.length == 4) {
       testId = userID2.slice(2)
    }
    else if (userID2.length == 5) {
       testId = userID2.slice(2)
    }
    else if (userID2.length == 6) {
       testId = userID2.slice(2)
    }
    else {
       testId = userID2.slice(2, -9)
    }
    console.log('testId', testId);
    if (testId) {
      console.log(testId);
      axios.post(BaseUrl + `/api/kutubxonachi/qatnov`, { userId, user_id: testId, }, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => {
        console.log("resp -", res.data);
        // if (res.status == 200) {
        //     getYunalish()
        setText(res.data.message);
        // setUserId('')
        // console.log("resp - ", res);
        // }
      })
        .catch((err) => {
          console.log(err.response.data.errors);
          setText(err.response.data.errors)
        });
    }
  };


  useEffect(() => {
    QabulQilish2()
    // console.log('userID2',userID2);
  }, [userID2])

    return (
        <div>
            <ToastContainer />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="mt-4">Talabalar ro'yxati</h2>
                <div className="for-search">
                    <div className="for-search2">
                        <img style={{ width: "30px", position: "absolute", margin: "5px 0 0 5px" }} src="/images/search.png" alt="" />
                    </div>
                    <input onChange={event => { setSearchTerm(event.target.value) }} className="form-control search_input" type="search" />
                </div>
            </div>
            <hr />
            <div className="mb-3" style={{ width: "100%", display: "flex", justifyContent: "center",position:"relative" }}>
                <div className="d-flex">
                    <div style={{ marginLeft: "30px" }}>
                        <select className="form-select" name="" id="" onChange={(e) => setFakultet(e.target.value)}>
                            <option disabled selected value="">Fakultetni tanlang</option>
                            <option value="">Umumiy qidirish</option>
                            {
                                fakultetList?.map((item, index) => (
                                    <option value={item.id}>{item.nomi}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <select onChange={(e) => setYunalish(e.target.value)} className="form-select" name="" id="">
                            <option disabled selected value="">Yo'nalishini tanlang</option>
                            <option value="">Umumiy qidirish</option>
                            {
                                yunalishList?.map((item, index) => (
                                    <option value={item.id}>{item.nomi}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <select onChange={(e) => setLavozim(e.target.value)} className="form-select" name="" id="">
                            <option disabled selected value="">Talim shakli</option>
                            <option value="">Umumiy qidirish</option>
                            {
                                talimShakli?.map((item, index) => (
                                    <option value={item.id}>{item.nomi}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <select onChange={(e) => setKursi(e.target.value)} className="form-select" name="" id="">
                            <option disabled selected >Kursini tanlang</option>
                            <option value="">Umumiy qidirish</option>
                            <option value="1-Kurs">1-Kurs</option>
                            <option value="2-Kurs">2-Kurs</option>
                            <option value="3-Kurs">3-Kurs</option>
                            <option value="4-Kurs">4-Kurs</option>
                            <option value="5-Kurs">5-Kurs</option>
                        </select>
                    </div>
                </div>
            </div>
            <select style={{}} onChange={(e) => setUserCount(+e.target.value)} name="" id="">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Link to={sessionStorage.getItem("role") == 0 ? "/Supermain/AdminStudentList/add" : "/adminMain/AdminStudentList/add"}>
                    <div className="add-kafedra"> Qo'shish</div>
                </Link>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Ism familiya</th>
                        <th scope="col">Login</th>
                        <th scope="col">Parol</th>
                        <th scope="col">Ta'lim shakli</th>
                        <th scope="col">Fakultet</th>
                        <th scope="col">Kursi</th>
                        <th scope="col">Yo'nalishi</th>
                        <th scope="col">Holati</th>
                        <th style={{ textAlign: "center" }}>Amallar</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        currentItems && currentItems.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{item?.id}</th>
                                    <td>{item?.firstname + " "} {item?.lastname}</td>
                                    <td>{item?.login}</td>
                                    <td>{item?.password_visible}</td>
                                    <td>{item?.lavozim?.nomi}</td>
                                    <td>{item?.fakultet?.nomi}</td>
                                    <td>{item?.kursi}</td>
                                    <td>{item?.yunalish?.nomi}</td>
                                    <td>
                                        {
                                            item?.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                                :
                                                <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                        }
                                    </td>
                                    <td>
                                        <div className="main_edite-box">
                                            <div className="edite_show_box">
                                                <div onClick={() => navigate('/adminMain/AdminStudentList/show/' + item.id)} className="show_data"><AiOutlineEye /></div>
                                                <div onClick={() => navigate('/adminMain/AdminStudentList/add/' + item.id)} className="edite"><AiOutlineEdit /></div>
                                                <div className="delete"><MdOutlineDelete /></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }





                    {/* {
                        studentsData && studentsData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{item && item.id}</th>
                                    <td>{item.firstname + " "} {item.lastname}</td>
                                    <td>{item.login}</td>
                                    <td>{item.password_visible}</td>
                                    <td>{item.lavozim.nomi}</td>
                                    <td>{item.fakultet.nomi}</td>
                                    <td>{item.yunalish.nomi}</td>
                                    <td>
                                        {
                                            item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                                :
                                                <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                        }
                                    </td>
                                    <td>
                                        <div className="main_edite-box">
                                            <div className="edite_show_box">
                                                <div onClick={() => navigate('#')} className="show_data"><AiOutlineEye /></div>
                                                <div onClick={() => navigate('#')} className="edite"><AiOutlineEdit /></div>
                                                <div onClick={() => deleteLUser('#')} className="delete"><MdOutlineDelete /></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    } */}
                </tbody>
            </table>
            <b>Jami {sessionStorage.getItem("userDataCount")} ta ma'lumotdan {currentItems.length} ko'rsatilmoqda</b>
            <div className="my-pagination">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
        </div>
    )
}

export default AdminStudentList;