import axios from "axios";
import React, { useState, useEffect } from "react";
import { BaseUrl } from "../../BaseUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const AddUniversityShahar = () => {

    const [nomi, setNomi] = useState("")
    const [country_id, setCountryId] = useState("")
    const [izoh, setIzoh] = useState("")
    const [text, setText] = useState(false)
    const { id } = useParams()
    const [active, setActive] = useState("")
    const davlat = useSelector(state =>state.store.davlat)

    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") { } else if (text === "Yangi Fakultet yaratildi.") {
            toast.success(text)
        }
        else if (text === "Fakultet o'zgartirildi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };


    const addFakultet = () => {
        if (id) {
            axios.put(BaseUrl + "/api/admin/fakultet/edit/" + id, { nomi, izoh, active }, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                }
            }).catch(err => {
                console.log(err.response.data.errors);
                setText(err.response.data.errors)
            })
        }
        else {
            axios.post(BaseUrl + "/api/admin/city", { country_id,nomi, izoh }, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(country_id);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                }
            }).catch(err => {
                console.log(country_id);
                console.log(err.response.data.errors);
                setText(err.response.data.errors)
            })
        }
    }


    const getFakultet = () => {
        axios.get(BaseUrl + "/api/admin/fakultet", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            res.data.fakultetlar.forEach((item, index) => {
                if (item.id == id) {
                    setNomi(item.nomi);
                    setIzoh(item.izoh);
                    // setLastName(item.lastname);
                    // setFathername(item.fathername);
                    // console.log(item);
                    // console.log(res.data)
                }
            })
            // dispatch(BulimAction(res.data.bulimlar))

        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getFakultet()
    }, [])


    return (
        <div style={{ width: "100%" }}>
            <ToastContainer />
            <h2 className="mt-4">{id ? "Shahar o'zgartirish" : "Shahar yaratish"}</h2>
            <hr />
            <div style={{ width: "100%", padding: "10px 50px", display: "flex", justifyContent: "space-between" }} className="main_box">
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        <div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Davlatni tanlang</label></div>
                                <select onChange={(e) => setCountryId(e.target.value)} value={country_id} className="form-select" name="" id="">
                                    {
                                        davlat && davlat.map((item, index)=>(
                                            <>
                                            <option>
                                               Tanlang
                                            </option>
                                            <option value={item.id}>
                                                {item.nomi}
                                            </option></>
                                        ))
                                    }
                                </select>
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Nomini kiriting</label></div>
                                <input onChange={(e) => setNomi(e.target.value)} value={nomi} placeholder="Nomi..." className="form-control" type="text" />
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Izoh kiriting</label></div>
                                <input onChange={(e) => setIzoh(e.target.value)} value={izoh} placeholder="Izoh..." className="form-control" type="text" />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        {
                            id ? <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <select style={{ width: "200px", height: "40px" }} className="form-select mt-3" onChange={(e) => setActive(e.target.value)} name="" id="">
                                    <option value="">tanlang</option>
                                    <option value="1">Faol</option>
                                    <option value="0">Faol emas</option>
                                </select>
                                <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                    <button onClick={addFakultet} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                                </div>
                            </div> : <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                <button onClick={addFakultet} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUniversityShahar