import React from "react";
import ".//..//..//../User.css";

const Nizom = () => {
  return (
    <div className="container py-3">
      <h3 className="text-center mt-4">Nizom</h3>
      <div
        className="accordion accordion-flush mt-2"
        id="accordionFlushExample"
      >
        <div className="accordion-item accordionItem">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed accardionBtn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Umumiy qoidalar:
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body accordionBody">
              <div className="text-bg-light p-2 shadow">
                <p className="m-0 p-1">
                  1. Andijon davlat universitetining Axborot-resurs markazi
                  (keyingi o' rinlarda Axborot-resurs markazi deb ataladi)
                  (O'zbekiston Respublikasi oliy ta' lim tizimida ta'lim va
                  ilm-fanni rivojlantirish manfaatlari yo'lida axborot-kutubxona
                  faoliyatini tashkil etuvchi hamda amalga oshiruvchi
                  axborot-kutubxona muassasasi hisoblanadi.
                </p>
                <p className="m-0 p-1">
                  2. Axborot-resurs markazi universitetning tarkibiy bo'linmasi
                  sifatida universitet rahbariyatiga bo'ysunadi.
                </p>
                <p className="m-0 p-1">
                  3. Axborot-resurs markazi o'z faoliyatida (Ozbekiston
                  Respublikasi Konstitutsiyasiga, qonunlariga, O'zbekiston
                  Respublikasi Oliy Maj lisi palatalarining qarorlariga,
                  O'zbekiston Respublikasi Prezidentining farmonlari, qarorlari
                  va farmoyishlariga, O'zbekiston Respublikasi Vazirlar
                  Mahkamasining qarorlari va farmoyishlariga, Oliy va o'rta
                  maxsus ta'lim vazirligining buyruqlari va Hay'at qarorlariga,
                  Universitet Kuzatuv kengashining qarorlariga, shuningdek,
                  universitetning ustavi va universitet rahbariyatining
                  buyruqlari va farmoyishlariga, ichki tartib qoidalari hamda
                  ushbu Nizomga amal qiladi.
                </p>
                <p className="m-0 p-1">
                  4. Axborot-kutubxona fondidan foydalanish tartibi, asosiy
                  xizmatlar ro'yxati, ularni ko'rsatish shartlari,
                  foydalanuvchilarning huquqlari va javobgarligi Axborot-resurs
                  markazidan foydalanish qoidalarida belgilanadi. Foydalanish
                  qoidalari universitet tomonidan mustaqil ishlab chiqiladi.
                  Universitet Axborot-resurs markazining axborot-kutubxona fondi
                  davlat mulki hisoblanadi.
                </p>
                <p className="m-0 p-1">
                  5. Universitetning Axborot-resurs markaziga umumiy,
                  tashkiliy-metodik rahbarlik qilish, ularning ishlarini
                  muvofiqlashtirish Oliy va o'rta maxsus ta'lim vazirligi
                  huzuridagi Oliy ta'limni rivojlantirish tadqiqotlari va ilg'or
                  texnologiyalarni tatbiq etish markazi tomonidan amalga
                  oshiriladi. Andijon davlat universitetining Axborot-resurs
                  markazi universitet tasarrufidagi akademik litseylar
                  kutubxonalariga umumiy, tashkiliy metodik rahbarlik qiladi,
                  ularning faoliyatini muvofiqlashtiradi hamda tasarrufidagi
                  texnikumlarga metodik yordam ko'rsatadi.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item accordionItem">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed accardionBtn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              Axborot-resurs markazining asosiy vazifalari
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body accordionBody">
              <div className="text-bg-light p-2 shadow">
                <p className="m-0 p-1">
                  6. Quyidagilar Axborot-resurs markazining asosiy vazifalari
                  hisoblanadi: zamonaviy axborot-kommunikasiya
                  texnologiyalaridan foydalangan holda axborot olishning manbai,
                  shakl va metodlarini tanlash imkoniyatini ta'minlash tamoyili
                  asosida talaba-o'quvchilar, doktorantlar, mustaqil
                  izlanuvchilar, ilmiy xodimlar, professor-o’qituvchilar,
                  shuningdek, aholining intellektual, bilim, ma'naviy-axloqiy va
                  madaniy ehtiyojlarini har tomonlama qondirish uchun qulay
                  shart-sharoitlar va keng axborot resurslari bazasini yaratish;
                  axborot-kutubxona faoliyati sohasida davlat siyosatini amalga
                  oshirishda qatnashish, talaba-o’quvchilar, doktorantlar,
                  mustaqil izlanuvchilar, ilmiy xodimlar,
                  professor-o'qituvchilar, shuningdek, aholiga axborot-kutubxona
                  xizmati ko'rsatish darajasini doimiy takomillashtirish va
                  oshirishni hisobga olgan holda Axborot-resurs markazini yanada
                  rivojlantirishning asosiy yo'nalishlari bo'yicha takliflar va
                  tavsiyalar ishlab chiqish; axborot-kutubxona faoliyatini
                  amalga oshirish doirasida, shu jumladan axborot-kutubxona
                  resurslarini almashish va o'zaro foydalanish borasida turli
                  tashkilot va idoralar bilan hamkorlikni ta'minlash;
                  talaba-o'quvchilar, doktorantlar, mustaqil izlanuvchilar,
                  ilmiy xodimlar, professor-o'qituvchilar, shuningdek, aholiga
                  axborot-kutubxona xizmati ko'rsatish shart-sharoitlarini,
                  shakl va metodlarini takomillashtirish, maxsus dasturiy
                  komplekslarni joriy etish, elektron axborot-ma'lumot
                  xizmatlarini yaratish, zamonaviy kompyuter vositalaridan va
                  ma'lumotlar uzatish texnologiyalaridan foydalanish asosida
                  axborot-kutubxona faoliyati jarayonlarining
                  avtomatlashtirilishini ta'minlash; foydalanuvchilarga
                  Axborot-resurs markazining axborot-kutubxona resurslaridan
                  foydalanishni ta'minlaydigan elektron xizmat ko'rsatish,
                  xorijiy kutubxonalar resurslari va ilmiy-texnik ma'lumotlar
                  bazalariga ulangan holda markazning yig'ma elektron katalogini
                  va elektron axborot-kutubxona fondini shakllantirish hamda
                  yuritish; axborot-kutubxona fondini milliy va chet el
                  nashrlari bilan tizimli to'ldirib borish, nodir va alohida
                  qimmatli nashrlarning ehtiyotlab saqlash, qo'lyozma kitoblar
                  va nodir nashrlarni konservatsiya va restavratsiya qilishning
                  zamonaviy metodlarini joriy etish, ularni ishonchli saqlashni
                  ta'minlaydigan maxsus rejim sharoitlarida asrashni tashkil
                  etish; yuqori malakali mutaxassislarni tanlov asosida tanlab
                  olish, Axborot-resurs markazi kadrlarini qayta tayyorlash va
                  ularning malakasini oshirish, kadrlar salohiyatini doimiy
                  takomillashtirish
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item accordionItem">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed accardionBtn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              Axborot-resurs markazining asosiy funksiyalari
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body accordionBody">
              <div className="text-bg-light p-2 shadow">
                <p className="m-0 p-1">
                  7. Axborot-resurs markazi o'ziga yuklangan vazifalarga muvofiq
                  quyidagi funksiyalarni amalga oshiradi: axborot-kutubxona
                  faoliyatini takomillashtirish masalalari bo'yicha dasturlar,
                  ”Yo'l xarita”lari, normativ-huquqiy hujjatlar loyihalarini
                  ishlab chiqish va amalga oshirishda qatnashadi; Axborot-resurs
                  markaziga yangi axborot-kutubxona texnologiyalarini joriy
                  etadi, axborot-kutubxona faoliyati jarayonlarining
                  avtomatlashtirilishini ta'minlash bo'yicha takliflar ishlab
                  chiqadi va ishlarni muvofiqlashtiradi; Axborot-resurs
                  markazining nashriyotlar, kitob sotish tashkilotlari va
                  ommaviy axborot vositalari bilan o'zaro hamkorligini
                  yaxshilash choratadbirlarini ta'minlaydi;
                  axborot-kommunikasiya texnologiyalarini keng qo'llagan holda
                  foydalanuvchilarning barcha toifalariga axborot-kutubxona
                  xizmatlari ko'rsatadi, foydalanuvchilar tanlashiga ko'ra
                  axborot-kutubxona xizmati ko'rsatish sharoitlari, shakllari va
                  metodlarini ta'minlaydi; foydalanuvchilarga Axborot-resurs
                  markazining axborot-kutubxona resurslaridan foydalanish,
                  qidirish, shuningdek, yetakchi xorijiy axborot resurslarining
                  ma'lumotlar bazasiga ulanishni ta'minlaydigan elektron
                  vositasida masofadan xizmat ko'rsatish bo'yicha xizmatlar
                  ko'rsatadi; foydalanuvchilarga qulaylik yaratish uchun
                  qo'shimcha xizmat turlari, shu jumladan pulli xizmatlar,
                  shuningdek, axborot resurslarini keng qo'llashda qidirish,
                  maslahat berish, materiallarni tarjima qilish, skanerlash,
                  bosib chiqarish kabi xizmatlar ko'rsatadi, pulli xizmatlar
                  ro'yxati va preyskurantlari qonunchilikda belgilangan tartibda
                  universitet rahbariyati tomonidan tasdiqlanadi; jismoniy
                  imkoniyatlari cheklangan kishilar uchun ixtisoslashtirilgan
                  axborotkutubxona xizmatlari ko ' rsatadi; Axborot-resurs
                  markazining yig'ma elektron katalogini hamda axborotkutubxona
                  resurslari elektron katalogini shakllantiradi va yuritadi;
                  Axborot-resurs markazining elektron kutubxonasini
                  shakllantiradi va yuritadi; bosma nashrlarni, audio va video
                  materiallarni hamda boshqa axborot resurslarini sotib olish,
                  obuna bo'lish, almashtirish, bepul olish yo'li bilan
                  axborotkutubxona fondini shakllantiradi; axborot-kutubxona
                  fondini hisobga olish, ishlash, joylashtirish, saqlash va
                  undan foydalanishni belgilangan tartibda yuritadi;
                  axborot-kutubxona fondi saqlanishini, elektron resurslarning
                  axborot xavfsizligini ta'minlash ishlarini amalga oshiradi;
                  zamonaviy metodlar va texnologiyalar asosida nodir va alohida
                  qimmatga ega bo'lgan nashrlarni saqlash, restavratsiya va
                  konservatsiya qilish ishlarini amalga oshiradi; bosma
                  nashrlar, adabiyot va san'at asarlari, qo'lyozma va grafik
                  hujjatlar, kitoblar-manuskriptlar va nodir nashrlarning
                  elektron fondini shakllantiradi; xorijiy axborot-resurs
                  markazlari, nashriyotlar, axborot markazlari bilan ilg'or ish
                  tajribasini o'rganish va joriy etish bo'yicha xalqaro
                  kutubxonalararo axborot almashuvlarni rivojlantirish, xalqaro
                  kutubxona anjumanlari va tashkilotlarida qatnashish bo'yicha
                  xalqaro hamkorlikni rivojlantirishga doir tavsiyalar ishlab
                  chiqadi; xodimlarini qayta tayyorlash va ularning malakasini
                  oshirish, shu jumladan xorijiy kutubxonalarda tajriba
                  orttirishlarni tashkil etish bo'yicha uzluksiz ish tizimini
                  amalga oshiradi.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item accordionItem">
          <h2 className="accordion-header" id="flush-headingFour">
            <button
              className="accordion-button collapsed accardionBtn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              Axborot-resurs markazining huquqlari va javobgarligi
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body accordionBody">
              <div className="text-bg-light p-2 shadow">
                <p className="m-0 p-1">
                  8. Axborot-resurs markazi quyidagi huquqlarga ega:
                  ”Axborot-kutubxona faoliyati to'g'risida”gi O'zbekiston
                  Respublikasi Qonuniga va ushbu Nizomga muvofiq O'z
                  axborot-kutubxona faoliyatining mazmuni va aniq shakllarini
                  belgilash; oliy ta'lim muassasasi bo'limlari, fakultetlari,
                  kafedralari bilan o'quv, ilmiymetodik va ilmiy-tadqiqot
                  masalalari bo'yicha ishlab chiqiladigan qoidalar, nizomlar,
                  yo'riqnomalar, o'quv dasturlari va boshqa normativ hujjatlarni
                  kelishish; tasdiqlangan xizmatlar ro'yxati va narxlar
                  preyskurantlariga muvofiq axborot-kommunikatsiya
                  texnologiyalari asosida pulli axborot-kutubxona xizmatlari
                  ko'rsatish; axborot-kutubxona fondlarini va axborot-kutubxona
                  resurslarini saqlash, ulardan foydalanishning alohida
                  shart-sharoitlarini belgilash; foydalanuvchilar tomonidan
                  Axborot-resurs markaziga yetkazilgan zararni qonunchilikka va
                  Axborot-resurs markazidan foydalanish qoidalariga muvofiq
                  qoplash turlari va miqdorlarini belgilash; "Axborot-kutubxona
                  resurslarini axborot-kutubxona muassasalarining fondlari
                  hisobidan chiqarish tartibi to'g'risidagi nizom”ga muvofiq
                  axborotkutubxona fondlaridan, ularning to'liqligi va butligiga
                  xilof yetkazmasdan axborotkutubxona resurslarining ortiqcha
                  soni va nusxalarini hisobdan chiqarish; o 'z vakolatlari
                  doirasida mahalliy va xalqaro axborot-kutubxona muassasalari
                  hamda tashkilotlari bilan aloqalarni amalga oshirish;
                  xodimlarni zamonaviy axborot texnologiyalaridan professional
                  foydalanishi, chet tilni bilishi, foydalanuvchilarga xizmat
                  ko'rsatishning samarali shakllarini joriy etganligi uchun
                  moddiy rag'batlantirish bo'yicha oliy ta'lim muassasasi
                  rahbariyatiga takliflar kiritish. Axborot-resurs markazi o'z
                  vakolatlari doirasida, o'ziga yuklangan vazifalar va
                  funksiyalardan kelib chiquvchi boshqa huquqlarga ham ega.
                </p>
                <p className="m-0 p-1">
                  9. Axborot-resurs markazi quyidagilar uchun javob beradi:
                  Axborot-resurs markazida saqlanayotgan axborot-kutubxona
                  resurslarining saqlanishini, to'liqligini va butligini
                  ta'minlash; foydalanuvchilarga o'z vaqtida va sifatli
                  axborot-kutubxona xizmati ko'rsatilishini ta'minlash;
                  Axborot-resurs markazining yig 'ma elektron katalogini va
                  axborot-kutubxona resurslari elektron katalogini
                  shakllantirish; Axborot-resurs markazining elektron
                  kutubxonasining yagona elektron axborot-kutubxona fondini
                  shakllantirish.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item accordionItem">
          <h2 className="accordion-header" id="flush-headingFife">
            <button
              className="accordion-button collapsed accardionBtn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFife"
              aria-expanded="false"
              aria-controls="flush-collapseFife"
            >
              Axborot-resurs markazini boshqarish
            </button>
          </h2>
          <div
            id="flush-collapseFife"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingFife"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body accordionBody">
              <div className="text-bg-light p-2 shadow">
                <p className="m-0 p-1">
                  10. Universitet Axborot-resurs markazi faoliyatiga belgilangan
                  tartibda universitet rektori tomonidan lavozimga
                  tayinlanadigan va lavozimdan ozod qilinadigan direktor
                  rahbarlik qiladi. Axborot-resurs markazi xodimlari ham oliy
                  ta'lim muassasasi rektori tomonidan ishga qabul qilinadi va
                  ozod qilinadi.
                </p>
                <p className="m-0 p-1">
                  11 . Direktor: Axborot-resurs markaziga yuklangan
                  vazifalarning bajarilishi uchun shaxsan javob beradi;
                  Axborot-resurs markazi xodimlarini belgilangan tartibda
                  lavozimga tayinlash va lavozimdan ozod qilish bo'yicha
                  universitet rahbariyatiga takliflar tayyorlaydi; qonunchilikka
                  muvofiq Axborot-resurs markazi xodimlarini rag'batlantirish
                  chora-tadbirlarini ko'radi va ularga nisbatan intizomiy jazo
                  choralarini qo'llash bo'yicha universitet rahbariyatiga
                  takliflar tayyorlaydi; belgilangan tartibda Axborot-resurs
                  markazining mol-mulkini tasarruf etadi, markazning
                  axborot-kutubxona resurslaridan samarali foydalanilishini
                  ta'minlaydi; Axborot-resurs markazining moddiy-texnika
                  bazasini saqlash va rivojlantirish, markaz foydalanuvchilari
                  va xodimlari uchun zarur shart-sharoitlar yaratish
                  chora-tadbirlarini belgilangan tartibda amalga oshiradi,
                  axborot-kutubxona fondlari, shu jumladan nodir va alohida
                  qimmatga ega nashrlar to'plamlari, shuningdek, binolar,
                  inshootlar, asbob-anjomlar, markazning boshqa mol-mulki
                  saqlanishi hamda ulardan zarur darajada foydalanilishi uchun
                  javob beradi.
                </p>
                <p className="m-0 p-1">
                  12. Axborot-resurs markazi rahbar-xodimlarining xizmat
                  vazifalari lavozim yo'riqnomalarida belgilanadi.
                  Axborot-resurs markazi boshqaruv va xizmat ko'rsatish
                  xodimlari soni universitetning namunaviy shtatlari
                  birliklarida belgilanadi hamda shunga muvofiq haq to'lanadi.
                  Bundan tashqari, qonunchilikka muvofiq Axborot-resurs markazi
                  rahbar-xodimlari oliy ta'lim muassasasi rahbariyati tomonidan
                  rag'batlantirilishi yoki intizomiy jazo choralari
                  qo'llanilishi mumkin.
                </p>
                <p className="m-0 p-1">
                  13. Axborot-resurs markazi huzurida maslahatlashuv organi
                  sifatida boshqa Axborot-resurs markazlari, axborot-kutubxona
                  muassasalari, kutubxonalar, universitetning ilmiy va o'quv
                  bo'linmalari bilan hamkorlikda ishlash uchun Axborot-resurs
                  markazi Kengashi tashkil etiladi. Kengash tarkibi oliy ta'lim
                  muassasasi rahbariyati tomonidan tasdiqlanadi.
                </p>
                <p className="m-0 p-1">
                  14. Axborot-resurs markazi o'z faoliyati to'g'risida
                  belgilangan tartibda hujjatlarni yuritadi hamda hisobotlarni,
                  ish rejalarini va boshqa axborotlami taqdim etadi,
                </p>
                <p className="m-0 p-1">
                  15. Universitet rahbariyati Axborot-resurs markazini amaldagi
                  me'yorlarga muvofiq axborot-kutubxona xizmatini ko'rsatish
                  bo'yicha zarur xizmat va ishlab chiqarish xonalari,
                  kompyuterlar hamda nusxa ko'chirish-ko'paytirish, tezkor
                  skaner texnikasi, aloqa kanallari va boshqa texnikalar bilan
                  ta'minlaydi. Axborot-resurs markazi ta'minoti uchun xarajatlar
                  universitetning umumiy xarajatlar smetasida nazarda tutiladi.
                  Axborot-resurs markazi o'zining subhisob raqamiga ega bo'lishi
                  mumkin, unga markaz tomonidan pulli qo'shimcha xizmatlar ko
                  'rsatish hisobiga jalb etiladigan mablag'lar kiritiladi.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item accordionItem">
          <h2 className="accordion-header" id="flush-headingSex">
            <button
              className="accordion-button collapsed accardionBtn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSex"
              aria-expanded="false"
              aria-controls="flush-collapseSex"
            >
              Axborot-resurs markazini boshqarish
            </button>
          </h2>
          <div
            id="flush-collapseSex"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingSex"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body accordionBody">
              <div className="text-bg-light p-2 shadow">
                <p className="m-0 p-1">
                  10. Universitet Axborot-resurs markazi faoliyatiga belgilangan
                  tartibda universitet rektori tomonidan lavozimga
                  tayinlanadigan va lavozimdan ozod qilinadigan direktor
                  rahbarlik qiladi. Axborot-resurs markazi xodimlari ham oliy
                  ta'lim muassasasi rektori tomonidan ishga qabul qilinadi va
                  ozod qilinadi.
                </p>
                <p className="m-0 p-1">
                  11 . Direktor: Axborot-resurs markaziga yuklangan
                  vazifalarning bajarilishi uchun shaxsan javob beradi;
                  Axborot-resurs markazi xodimlarini belgilangan tartibda
                  lavozimga tayinlash va lavozimdan ozod qilish bo'yicha
                  universitet rahbariyatiga takliflar tayyorlaydi; qonunchilikka
                  muvofiq Axborot-resurs markazi xodimlarini rag'batlantirish
                  chora-tadbirlarini ko'radi va ularga nisbatan intizomiy jazo
                  choralarini qo'llash bo'yicha universitet rahbariyatiga
                  takliflar tayyorlaydi; belgilangan tartibda Axborot-resurs
                  markazining mol-mulkini tasarruf etadi, markazning
                  axborot-kutubxona resurslaridan samarali foydalanilishini
                  ta'minlaydi; Axborot-resurs markazining moddiy-texnika
                  bazasini saqlash va rivojlantirish, markaz foydalanuvchilari
                  va xodimlari uchun zarur shart-sharoitlar yaratish
                  chora-tadbirlarini belgilangan tartibda amalga oshiradi,
                  axborot-kutubxona fondlari, shu jumladan nodir va alohida
                  qimmatga ega nashrlar to'plamlari, shuningdek, binolar,
                  inshootlar, asbob-anjomlar, markazning boshqa mol-mulki
                  saqlanishi hamda ulardan zarur darajada foydalanilishi uchun
                  javob beradi.
                </p>
                <p className="m-0 p-1">
                  12. Axborot-resurs markazi rahbar-xodimlarining xizmat
                  vazifalari lavozim yo'riqnomalarida belgilanadi.
                  Axborot-resurs markazi boshqaruv va xizmat ko'rsatish
                  xodimlari soni universitetning namunaviy shtatlari
                  birliklarida belgilanadi hamda shunga muvofiq haq to'lanadi.
                  Bundan tashqari, qonunchilikka muvofiq Axborot-resurs markazi
                  rahbar-xodimlari oliy ta'lim muassasasi rahbariyati tomonidan
                  rag'batlantirilishi yoki intizomiy jazo choralari
                  qo'llanilishi mumkin.
                </p>
                <p className="m-0 p-1">
                  13. Axborot-resurs markazi huzurida maslahatlashuv organi
                  sifatida boshqa Axborot-resurs markazlari, axborot-kutubxona
                  muassasalari, kutubxonalar, universitetning ilmiy va o'quv
                  bo'linmalari bilan hamkorlikda ishlash uchun Axborot-resurs
                  markazi Kengashi tashkil etiladi. Kengash tarkibi oliy ta'lim
                  muassasasi rahbariyati tomonidan tasdiqlanadi.
                </p>
                <p className="m-0 p-1">
                  14. Axborot-resurs markazi o'z faoliyati to'g'risida
                  belgilangan tartibda hujjatlarni yuritadi hamda hisobotlarni,
                  ish rejalarini va boshqa axborotlami taqdim etadi,
                </p>
                <p className="m-0 p-1">
                  15. Universitet rahbariyati Axborot-resurs markazini amaldagi
                  me'yorlarga muvofiq axborot-kutubxona xizmatini ko'rsatish
                  bo'yicha zarur xizmat va ishlab chiqarish xonalari,
                  kompyuterlar hamda nusxa ko'chirish-ko'paytirish, tezkor
                  skaner texnikasi, aloqa kanallari va boshqa texnikalar bilan
                  ta'minlaydi. Axborot-resurs markazi ta'minoti uchun xarajatlar
                  universitetning umumiy xarajatlar smetasida nazarda tutiladi.
                  Axborot-resurs markazi o'zining subhisob raqamiga ega bo'lishi
                  mumkin, unga markaz tomonidan pulli qo'shimcha xizmatlar ko
                  'rsatish hisobiga jalb etiladigan mablag'lar kiritiladi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nizom;
