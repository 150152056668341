import React, { useState, useEffect } from "react";
import { Button, Input, Modal, Form } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseUrl } from "../../../Super_admin/BaseUrl";
const BronModal = (props) => {

    const { modal2Open, setModal2Open, inventar_id } = props
    const [text, setText] = useState('')

    const navigate = useNavigate()

    const { id } = useParams()

    const notify = () => {
        if (text === "" ) {} else if (text ==="Kitob band qilish so'rovi yuborildi.") {
            toast.success(text)
        }
        else{
            toast.error(text)
        }
        setText("")
    };

    useEffect(()=>{
        notify()
    },[text])

    const data = {
        user_id: sessionStorage.getItem("userId"),
        kitob_id: id,
        inventar_id: inventar_id,
    }

    const [bron, setBron] = useState()
    

    // console.log({ ...data, muddati: bron });

    const postBron = async () => {
        await axios.post(BaseUrl + `/api/user/kitobbron`, { ...data, muddati: bron }, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(async (res) => {
            console.log(res.data.message);
            setBron("")
            setText(res.data.message)
        }).catch(err => {
            setBron("")
            setText(err.response.data.errors)
        })
       
    }


    return (
        <Modal
            title="Haqiqatdan ham kitobni band qilmoqchimisiz ? "
            open={modal2Open}
            onOk={() => setModal2Open(false)}
            onCancel={() => setModal2Open(false)}>
            <ToastContainer />
            <p>Kitobni kutubxonadan olib ketishingiz uchun muddatni belgilang</p>
            <select className="form-select" onChange={(e) => setBron(e.target.value)} value={bron} name="" id="">
                <option>Tanlang</option>
                <option value="1">1 kun</option>
                <option value="2">2 kun</option>
                <option value="3">3 kun</option>
                <option value="4">4 kun</option>
                <option value="5">5 kun</option>
            </select>
            <p className="btnn_foot">
                <button
                    onClick={() => {
                        setModal2Open(false);
                    }}
                    style={{ marginTop: "20px" }}
                    type="submit"
                    className="btn btn-danger d-flex justify-content-end">
                    Bekor qilish
                </button>
                {
                    sessionStorage.getItem("token") ?
                        <button
                            onClick={() => { { postBron() } }}
                            style={{ marginTop: "20px" }}
                            type="submit"
                            className="btn btn-primary d-flex justify-content-end">
                            Tasdiqlash
                        </button>
                        :
                        <button
                            onClick={() => { sessionStorage.setItem("href", window.location.href); navigate("/auth"); }}
                            style={{ marginTop: "20px" }}
                            type="submit"
                            className="btn btn-primary d-flex justify-content-end">
                            Tasdiqlash
                        </button>
                }
            </p>
        </Modal>
    )
}

export default BronModal;