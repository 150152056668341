import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";


const AddUniversityKafedra = () => {

    const [fakultet, setFakultet] = useState([])

    const [fakultet_id, setFakultet_id] = useState("")
    const [nomi, setNomi] = useState("")
    const [izoh, setIzoh] = useState("")
    const { id } = useParams()
    const [active, setActive] = useState("")
    const [text, setText] = useState(false)

  
    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === ""){} else if (text === "Yangi kafedra yaratildi."){
            toast.success(text)
        }
        else if (text === "Kafedra o'zgartirildi."){
            toast.success(text)
        }
        else{
            toast.error(text)
        }
        setText("")
    };

    console.log(fakultet_id,nomi,izoh);

    const getFaculyty = () => {
        axios.get(BaseUrl + "/api/admin/fakultet", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.fakultetlar);
            setFakultet(res.data.fakultetlar)            
        }).catch(err => {
            console.log(err);
        })
    }

    const addKafedra = () =>{
       if (id) {
        axios.put(BaseUrl+"/api/admin/kafedra/edit/"+id,{fakultet_id,nomi,izoh,active},{
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res);
            if (res.status == 200) {
                setText(res.data.message)
                // setFakultet_id("")
                setNomi("")
                setIzoh("")
            }
        }).catch(err=>{
            console.log(err);
        })
       }
       else{
        axios.post(BaseUrl+"/api/admin/kafedra",{fakultet_id,nomi,izoh},{
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res);
            if (res.status == 200) {
                setText(res.data.message)
                // setFakultet_id("")
                setNomi("")
                setIzoh("")
            }
        }).catch(err=>{
            console.log(err);
        })
       }
    }
    
    const getKafedra = () => {
        axios.get(BaseUrl + "/api/admin/kafedra", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data.kafedralar);
            // setFakultet(res.data.fakultetlar)
            // dispatch(KafedraAction(res.data.kafedralar))
            res.data.kafedralar.forEach((item, index) => {
                if (item.id == id) {
                    setNomi(item.nomi);
                    setIzoh(item.izoh);
                    // setLastName(item.lastname);
                    // setFathername(item.fathername);
                    // console.log(item);
                    // console.log(res.data)
                }})
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(()=>{
        getFaculyty()
        getKafedra()
    },[])

    return (
        <div style={{ width: "100%" }}>
            <ToastContainer/>
            <h2 className="mt-4">Kafedra yaratish</h2>
            <hr />
            <div style={{ width: "100%", padding: "10px 50px", display: "flex", justifyContent: "space-between" }} className="main_box">
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        <div>
                            <div><label htmlFor="">Fakultetni tanlang</label></div>
                            <select onChange={(e)=>setFakultet_id(e.target.value)} className="form-select" name="" id="">
                                <option value="" >tanlang</option>
                                {
                                    fakultet && fakultet.map((item,index)=>(
                                        <option key={index} value={item.id} >{item.nomi}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div>
                            <div><label className="mt-3" htmlFor="">Nomini kiriting</label></div>
                            <input onChange={(e)=>setNomi(e.target.value)} value={nomi} placeholder="Nomi..." className="form-control" type="text" />
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        <div>
                            <div><label className="mt-3" htmlFor="">Izoh yozing</label></div>
                            <input onChange={(e)=>setIzoh(e.target.value)} value={izoh} placeholder="Izoh..." className="form-control" type="text" />
                        </div>
                        {
                            id ? <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <select style={{ width: "200px", height: "40px" }} className="form-select mt-3" onChange={(e) => setActive(e.target.value)} name="" id="">
                                    <option value="">tanlang</option>
                                    <option value="1">Faol</option>
                                    <option value="0">Faol emas</option>
                                </select>
                                <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                    <button onClick={addKafedra} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                                </div>
                            </div> : <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                <button onClick={addKafedra} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUniversityKafedra