import React from "react";
import { BaseUrl } from "../../../../../Super_admin/BaseUrl";

const KitobUqish = () =>{
    return(
        <>
        <h1>Ruxsat berilgan kitob</h1>
        <hr />
        <div style={{width:"100%",height:"80vh"}}>
        <object width={"100%"} height={"100%"} data={BaseUrl+`/storage/${sessionStorage.getItem("kitob")}`+"#toolbar=0"} frameborder="0"></object>
        </div>
        </>
    )
}

export default KitobUqish