import { useState } from 'react';
import './ChangeProfile.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseUrl } from '../../../Super_admin/BaseUrl';



function ChangeProfile() {  

    

    const [password, setPassword] = useState({ old_password: '', password: '', password_confirmation: '' });

    const handleInput = (event) => {
        setPassword({ ...password, [event.target.name]: event.target.value });
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        axios.post(BaseUrl + "/api/user/change-password/" + sessionStorage.getItem("userId"), password, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            toast.info(res.data.message);
        }).catch(err => {
            console.log(err);
        })

    }

    return (
        <div className='container my-5'>
            <form className='row' onSubmit={handleSubmit}>
                <div className='col-md-4 mb-4'>
                    <label htmlFor="inputname">eski parol</label>
                    <input type="text" className="form-control" placeholder="Eski parolingizni kiriting" name="old_password" value={password.old_password} onChange={handleInput} />
                </div>
                <div className='col-md-4 mb-4'>
                    <label htmlFor="inputname">yangi parol</label>
                    <input type="text" className="form-control" placeholder="Yangi parol kiriting" name="password" value={password.password} onChange={handleInput} />
                </div>
                <div className='col-md-4 mb-4'>
                    <label htmlFor="inputname">qayta kiritish</label>
                    <input type="text" className="form-control" placeholder="Parolni qayta kiriting" name="password_confirmation" value={password.password_confirmation} onChange={handleInput} />
                </div>
                <button className="btn btn-dark w-100">Yuborish</button>
                <ToastContainer />
            </form>
        </div>
    )
}

export default ChangeProfile