import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../../../Super_admin/BaseUrl";

const BibliografikYozuv = () => {
  const [bibliografikYozuv, setBibliografikYozuv] = useState("");

  const { id } = useParams();

    const getBibliografikYozuv = () => {
        axios
          .get(BaseUrl + `/api/kutubxonachi/kitoblar/show/${id}`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            // console.log(res.data);
            setBibliografikYozuv(res.data.bibliografikYozuv);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      
      useEffect(() => {
        getBibliografikYozuv();
      }, []);

  return (
      <ul class="list-group mb-3">
        <li class="list-group-item list-group-item-dark">
          <b>BibliografikYozuv:</b>
        </li>
        <li
          class="list-group-item list-group-item-light"
          dangerouslySetInnerHTML={{
            __html: bibliografikYozuv && bibliografikYozuv,
          }}>
          {/* {bibliografikYozuv &&  bibliografikYozuv} */}
        </li>
      </ul>
  );
};

export default BibliografikYozuv;
