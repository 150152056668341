import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import DetailBooks from "../../../DetailBooks/DetailBooks";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import axios from "axios";
import { BaseUrl } from "../../../../../../Super_admin/BaseUrl";

const Inventar = () => {
  const [kutubxonabulimTable, setkutubxonabulimTable] = useState([]);
  const [inventarTable, setInventarTable] = useState([]);
  const [editId, setEditId] = useState("");
  const [izoh, setIzoh] = useState("");
  const [text, setText] = useState("");
  const [inventar_raqam, setInventar_raqam] = useState("");
  const [active, setActive] = useState("");
  const [connectId, setConnectId] = useState("");

  const { id } = useParams();

console.log("connect", connectId);

  const getkitobbulimTable = () => {
    axios
      .get(
        BaseUrl +
          `/api/kutubxonachi/kitobbulim/index/${sessionStorage.getItem(
            "showId"
          )}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setkutubxonabulimTable(res.data.kitobBulimlar);
        res.data.kitobBulimlar.map((item)=>{
          if(item.id == id){
            return(
              setConnectId(item.ID)
            )
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // INVENTAR
//  console.log("active",active);
  const notify = () => {
    if (text === "") {
    } else if (text === "Yangi Kitob inventar yaratildi.") {
      toast.success(text);
    } else if (text === "Kitob inventar bazadan o'chirildi.") {
      toast.success(text);
    } else if (text === "Kitob inventar bazadan o'zgartirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };

  useEffect(() => {
    notify();
  }, [text]);

  const getInventarTable = () => {
    axios
      .get(BaseUrl + `/api/kutubxonachi/kitobinventar/connect/${connectId}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setInventarTable(res.data.kitobInventarlar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addInventar = () => {
    if (editId) {
      axios
        .put(
          BaseUrl + "/api/kutubxonachi/kitobinventar/edit/" + editId,
          {
            kitob_id: sessionStorage.getItem("showId"),
            bulim_id: id,
            inventar_raqam,
            user_id: sessionStorage.getItem("userId"),
            izoh,
            active,
          },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            setText(res.data.message);
            setEditId("");
            setInventar_raqam("");
            getInventarTable();
          }
        })
        .catch((err) => {
          console.log(err.response.data.errors);
          setText(err.response.data.errors);
        });
    } else {
      axios
        .post(
          BaseUrl + "/api/kutubxonachi/kitobinventar",
          {
            kitob_id: sessionStorage.getItem("showId"),
            bulim_id: id,
            inventar_raqam,
            user_id: sessionStorage.getItem("userId"),
            izoh,
          },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            setText(res.data.message);
            setInventar_raqam("");
            getInventarTable();
          }
        })
        .catch((err) => {
          console.log(err.response.data.errors);
          setText(err.response.data.errors);
        });
    }
  };

  useEffect(() => {
    getkitobbulimTable();
    getInventarTable();
  }, [connectId]);

  const deleteItem = (id) => {
    axios
      .delete(BaseUrl + `/api/kutubxonachi/kitobinventar/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.message);
        if (res.status == 200) {
          getInventarTable();
          setText(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="navBar">
          <p>Inventar Raqami</p>
          <ToastContainer />
          <Link to={`/adminMain/books_collection/detail/${sessionStorage.getItem("showId")}`}>
            <div className="add-kafedra">Orqaga</div>
          </Link>
        </div>
        <hr />

        <div className="card shadow p-3">
          <DetailBooks />
          <hr />
          <div class="table-responsive">
            <table class="table align-middle table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Faolmi?</th>
                  <th scope="col">Bo‘limlar</th>
                  <th scope="col">Mas'ul shaxs</th>
                  <th scope="col">Nusxalar soni</th>
                  <th scope="col">Kutubxonada bormi?</th>
                  <th scope="col">Elektron formatda bormi?</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {kutubxonabulimTable &&
                  kutubxonabulimTable.map((item, index) => {
                    if (id == item.id) {
                        return (
                        <tr key={index}>
                          <td>{item.ID}</td>
                          <td>
                            {item.active === 0 ? (
                              <div className="chek2">
                                <CiSquareRemove style={{ color: "white" }} />
                              </div>
                            ) : (
                              <div className="chek">
                                <HiOutlineCheckCircle
                                  style={{ color: "white" }}
                                />
                              </div>
                            )}
                          </td>
                          <td>{item.nomi}</td>
                          <td>{item.masul_shaxs}</td>
                          <td>0</td>
                          <td>
                            {item.active === 0 ? (
                              <div className="chek2">
                                <CiSquareRemove style={{ color: "white" }} />
                              </div>
                            ) : (
                              <div className="chek">
                                <HiOutlineCheckCircle
                                  style={{ color: "white" }}
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {item.active === 0 ? (
                              <div className="chek2">
                                <CiSquareRemove style={{ color: "white" }} />
                              </div>
                            ) : (
                              <div className="chek">
                                <HiOutlineCheckCircle
                                  style={{ color: "white" }}
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>

          <div className="card p-2">
            <div className="col-md-4 col-sm-12">
              <label className="mt-3" htmlFor="">
                Inventar Raqami
              </label>
              <input
                onChange={(e) => setInventar_raqam(e.target.value)}
                value={inventar_raqam}
                className="form-control"
                name=""
                id=""
                type="text"
              />

              {editId && (
                <>
                  <div>
                    <label className="mt-3" htmlFor="">
                      Faoligi
                    </label>
                    <select
                      className="form-select "
                      onChange={(e) => setActive(e.target.value)}
                      name=""
                      id="">
                      <option value="1">tanlang</option>
                      <option value="1">Faol</option>
                      <option value="0">Faol emas</option>
                    </select>
                  </div>

                  <div>
                    <label className="mt-3" htmlFor="">
                      Izoh kiriting
                    </label>
                  </div>
                  <input
                    onChange={(e) => setIzoh(e.target.value)}
                    value={izoh}
                    placeholder="Izoh..."
                    className="form-control"
                    type="text"
                  />
                </>
              )}
              <span className="d-flex flex-row-reverse ">
                <button className="btn btn-primary mt-3" onClick={addInventar}>
                  {editId ? "O'zgartrish" : "Yaratish"}
                </button>
              </span>
            </div>

            <div class="table-responsive">
              <hr />
              <table class="table align-middle table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Faolmi?</th>
                    <th scope="col">Inventar raqami</th>
                    <th scope="col">Shtrixli kod</th>
                    <th scope="col">Kim tomonidan yaratilgan</th>
                    <th scope="col">Kim tomonidan yangilangan</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {inventarTable &&
                    inventarTable.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>
                            {item.active === 0 ? (
                              <div className="chek2">
                                <CiSquareRemove style={{ color: "white" }} />
                              </div>
                            ) : (
                              <div className="chek">
                                <HiOutlineCheckCircle
                                  style={{ color: "white" }}
                                />
                              </div>
                            )}
                          </td>
                          <td>{item.inventar_raqam}</td>
                          <td>{item.shtrix_code}</td>
                          <td>{item.createdBy}</td>
                          <td>{item.updatedBy}</td>
                          <td>
                            <div className="main_edite-box">
                              <div className="edite_show_box">
                                <div
                                  className="edite"
                                  onClick={(e) => {
                                    setEditId(item.id);
                                    setInventar_raqam(item.inventar_raqam);
                                  }}>
                                  <AiOutlineEdit />{" "}
                                </div>
                                <div
                                  className="delete"
                                  onClick={() => deleteItem(item.id)}>
                                  <MdOutlineDelete />{" "}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inventar;
