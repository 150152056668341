import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { CiSquareRemove } from "react-icons/ci";
import { Book_directionAction } from "../../../redux/actions/Action";

const Book_direction = () => {
    const [text, setText] = useState(false);

    const dispatch = useDispatch();
    const book_direction = useSelector((state) => state.store.book_direction);
    // console.log(science_field);
    const navgate = useNavigate();

  
    useEffect(() => {
      notify();
    }, [text]);
  
    const notify = () => {
      if (text === "") {
      } else if (text === "Kitob yo'nalishi bazadan o'chirildi.") {
        toast.success(text);
      } else {
        toast.error(text);
      }
      setText("");
    };
    // get
    const getBook_direction = () => {
      axios
        .get(BaseUrl + "/api/kutubxonachi/kitobyunalishi", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res.data);
          dispatch(Book_directionAction(res.data.kitobYunalishlari));
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    useEffect(() => {
        getBook_direction();
    }, []);
  
    const deleteBook_direction = (id) => {
      axios
        .delete(BaseUrl + `/api/kutubxonachi/kitobyunalishi/${id}`, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res.data.message);
          if (res.status == 200) {
            getBook_direction();
            setText(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 13;
  
    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(
        book_direction && book_direction.slice(itemOffset, endOffset)
      );
      setPageCount(Math.ceil(book_direction.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, book_direction]);
  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % book_direction.length;
      setItemOffset(newOffset);
    };
    return (
      <div>
        <div className="navBar">
          <p>Kitob yo'nalishi</p>
          <ToastContainer />
          <Link to={sessionStorage.getItem("userId") == 1 ? "/Supermain/book_direction/add" : "/adminMain/book_direction/add"}>
            <div className="add-kafedra">Yaratmoq</div>
          </Link>
        </div>
        <hr />
        <table class="table">
          <thead>
            <tr>
              <th scope="col">No</th> 
              <th scope="col">Nomi</th>
              <th scope="col">Fan sohasi </th>
              <th scope="col">Faolmi?</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((item, index) => (
                <tr>
                  <td scope="row">{item.id}</td>
                  <td scope="row">{item.nomi}</td>
                  <td scope="row">{item.fan_sohasi.nomi}</td>
                  <td>
                    {item.active === 0 ? (
                      <div className="chek2">
                        <CiSquareRemove style={{ color: "white" }} />
                      </div>
                    ) : (
                      <div className="chek">
                        <HiOutlineCheckCircle style={{ color: "white" }} />
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="main_edite-box">
                      <div className="edite_show_box">
                        <div
                          className="edite"
                          onClick={() =>
                            navgate(`/adminMain/book_direction/add/${item.id}`)
                          }
                        >
                          <AiOutlineEdit />
                        </div>
                        <div
                          className="delete"
                          onClick={() => deleteBook_direction(item.id)}
                        >
                          <MdOutlineDelete />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="my-pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
          />
        </div>
      </div>
    );
}

export default Book_direction