import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { BsDownload } from "react-icons/bs";

import { MdLogin } from "react-icons/md";
import { spaceChildren } from "antd/es/button";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import ElectronModal from "../../userComponents/HeaderMenuPage/Electronic_catalog/Electronic_catalogShow/ElectronModal";
import BronModal from "./BronModal";

const BookDeatail = () => {
    const [catalogshow, setCatlogshow] = useState([]);
    const [invertar, setInvertar] = useState([]);
    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const { id } = useParams();
    const [invertarr, setInvertarr] = useState("")
    const navigate = useNavigate();

    const [ruxsat, setRuxsat] = useState('')
    const getNews = () => {
        axios
            .get(BaseUrl + "/api/user/kitoblar/show/" + id, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            })
            .then((res) => {
                console.log(res.data);
                setRuxsat(res.data.kitob.surovlar)
                setCatlogshow(res.data.kitob);
                setInvertar(res.data.inventarlar)
                sessionStorage.setItem("kitobId", res.data.kitob.id)
                verifyUserPermission()
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getNews();
    }, [modal2Open]);

    // antd modal


    // verify user permission:

    const [userPermission, setUserPermission] = useState("");

    const verifyUserPermission = () => {
        axios.get(`https://akapi.andalofai.uz/api/user/kitobbron/check/${sessionStorage.getItem('kitobId')}?user_id=${sessionStorage.getItem('userId')}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            setUserPermission(res?.data?.ruxsat)
            console.log(res.data.ruxsat);
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(() => {
        verifyUserPermission()
    }, [])

    return (
        <div className="container mt-5 mb-5">
            <div className="row w-100">
                <p>{catalogshow && catalogshow.nomi}</p>
                <div className="col-md-4 col-sm-12 ">
                    <img
                        className="w-100 mb-3"
                        src={BaseUrl + "/storage/" + catalogshow.muqova_jpg}
                        alt="gfsdgsdf"
                    />
                    {catalogshow?.kirish_huquqi == 1 ? (
                        <a
                            href={BaseUrl + "/storage/" + catalogshow?.kitob_pdf}
                            target={"_blank"}>
                            <span className="download text-center w-100 border d-inline-block">
                                <BsDownload /> Adabiyotni yuklab olish
                            </span>
                        </a>
                    ) : catalogshow?.kirish_huquqi == 2 ? (
                        sessionStorage.getItem("token") ? (
                            <a
                                href={BaseUrl + "/storage/" + catalogshow?.kitob_pdf}
                                target={"_blank"}>
                                <span className="download text-center w-100 border d-inline-block">
                                    <BsDownload /> Adabiyotni yuklab olish
                                </span>
                            </a>
                        ) : (
                            <span
                                className="download text-center w-100 border d-inline-block"
                                onClick={() => {
                                    sessionStorage.setItem("href", window.location.href);
                                    navigate("/auth");
                                }}>
                                <BsDownload /> Kitobni yuklab olish uchun tizimga kiring!!
                            </span>
                        )
                    ) : catalogshow?.kirish_huquqi == 3 ? (
                        <span
                            onClick={() => { ruxsat == sessionStorage.getItem("userId") ? setModal1Open(false) : setModal1Open(true) }}
                            className="download text-center w-100 border d-inline-block">
                            <BsDownload className="fs-4" /> {ruxsat == sessionStorage.getItem("userId") ? <span onClick={() => { navigate(`/profile/readbook`); sessionStorage.setItem("kitob", catalogshow.kitob_pdf) }}>Adaboyotni o'qish</span> : "Adabiyotni o'qish so'rovini yuborish"}
                        </span>
                    ) : (
                        ""
                    )}
                </div>
                <div className="col-md-8 col-sm-12 py-2">
                    <p className="m-0">
                        <span className="text-muted">Mualliflar: </span>
                        <span className="title_katalog">
                            {catalogshow?.mualliflar?.map((item, index) => {
                                return <span key={index}>{item.nomi}</span>;
                            })}
                        </span>
                    </p>
                    <p className="m-0">
                        <span className="text-muted">Nashriyot: </span>
                        <span className="title_katalog">{catalogshow?.nashriyot_nomi}</span>
                    </p>
                    <p className="m-0">
                        <span className="text-muted">Resursni qo'shgan muassasa: </span>
                        <span className="title_katalog">
                            {catalogshow?.created_by?.lastname}
                        </span>
                    </p>
                    <div className="catalog_show_main">
                        <span class="catalog_span">
                            <samp className="text-muted">Resurs statusi</samp>
                            {catalogshow?.kirish_huquqi == 1
                                ? "Ochiq"
                                : catalogshow?.kirish_huquqi == 2
                                    ? "Qisman ochiq"
                                    : "Yopiq"}
                        </span>
                        <span class="catalog_span ">
                            <samp className="text-muted">Resurs turi:</samp>
                            {catalogshow?.kitob_turi?.nomi}
                        </span>
                        <span class="catalog_span">
                            <samp className="text-muted">Resurs tili:</samp>
                            {catalogshow?.kitob_tili?.nomi}
                        </span>
                        <span class="catalog_span">
                            <samp className="text-muted">Davlat:</samp>
                            {catalogshow?.country?.nomi}
                        </span>
                        <span class="catalog_span">
                            <samp className="text-muted">Nashr yili:</samp>
                            {catalogshow?.nash_yili}
                        </span>
                        <span class="catalog_span">
                            <samp className="text-muted">Resurs sohasi:</samp>
                            {catalogshow?.fan_sohasi?.nomi}
                        </span>
                        <span class="catalog_span">
                            <samp className="text-muted">Sahifa soni:</samp>
                            {catalogshow?.betlar_soni}
                        </span>
                        <span class="catalog_span">
                            <samp className="text-muted">Resurs ruxsati:</samp>
                            {catalogshow?.kimga_muljallangan?.nomi}
                        </span>
                    </div>
                </div>
                <div className="my-4">
                    {
                        userPermission === false ? <h4 className="text-center">Ushbu kitob sizning qayt varaqangizda ro'yxatga olingan</h4>
                            : <h4 className="text-center">Kitobni kutubxonada oldindan band qilish uchun tanlang</h4>
                    }
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Invertar raqam</th>
                                <th>Buyurtma</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invertar?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>{item?.inventar_raqam}</td>
                                        <td>
                                            {
                                                item?.berilgan_kitoblar?.length !== 0 ? <button className="btn btn-warning" disabled>O'qilmoqda</button> : item?.bron_kitoblar?.length !== 0 ? <button className="btn btn-secondary" disabled>Band qilingan</button>
                                                    : userPermission === false ? <button className="btn btn-primary" disabled>Tanlash</button> : <button onClick={() => { setModal2Open(true); setInvertarr(item.id) }} className="btn btn-primary">Tanlash</button>
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <td></td>
                    </table>
                </div>
            </div>
            {/* <iframe src={BaseUrl + "/storage/" + catalogshow?.kitob_pdf} frameborder="0"></iframe> */}
            {/* onClick={() =>sessionStorage.setItem("href", window.location.href.slice(21)) } */}
            <ElectronModal setModal1Open={setModal1Open} modal1Open={modal1Open} kitobId={catalogshow.id} />
            <BronModal setModal2Open={setModal2Open} modal2Open={modal2Open} inventar_id={invertarr} />
            {/* {console.log(invertarr)} */}
        </div>
    );
};

export default BookDeatail;
