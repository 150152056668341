/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { BookCatalogAction } from "../../redux/actions/Action";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";

const BookCatalog = () => {
  const [text, setText] = useState(false);

  const [userCount, setUserCount] = useState(15);
  const [pageCount, setPageCount] = useState();
  const [usersData, setUsersData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = userCount;

  const navgate = useNavigate();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Kitob turi bazadan o'chirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };
  const getAllUsers = (page = 1) => {
    axios
      .get(
        BaseUrl +
          `/api/kutubxonachi/kitoblarkatalogi?paginator=${userCount}&page=${page}&search=${searchTerm}`,
        {
          headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
        }
      )
      .then((res) => {
        sessionStorage.setItem("userDataCount", res.data.kaloglar.total);
        setPageCount(res.data.kaloglar.last_page);
        setUsersData(res.data.kaloglar.data);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [userCount, searchTerm]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(usersData && usersData.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, usersData]);

  const handlePageClick = (event) => {
    getAllUsers(event.selected + 1);
  };
 
  return (
    <div>
      <div className="navBar">
        <p>Kitob katalogi</p>
      </div>
      <hr />
      <div className="d-flex justify-content-between">
        <select
          onChange={(e) => setUserCount(+e.target.value)}
          className="form-select getbooks-option w-auto">
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>

          <div className="for-search">
            <div className="for-search2">
              <img
                style={{
                  width: "30px",
                  position: "absolute",
                  margin: "5px 0 0 5px",
                }}
                src="/images/search.png"
                alt=""
              />
            </div>
            <input
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              className="form-control search_input"
              type="search"
            />
          </div>
      </div>
      <ToastContainer />
      <hr />
      <table class="table">
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">Muqova tasviri</th>
            <th scope="col">Bibliografik yozuv</th>
            <th scope="col">Jami Soni</th>
            <th scope="col">mavjud Soni</th>
            <th scope="col">BBK</th>
            <th scope="col">Annotatsiyasi </th>
            <th scope="col">Pdf</th>
            <th scope="col">Ko'rish</th>
          </tr>
        </thead>
        <tbody>
          {
            currentItems.map((item, index) => (
              <tr key={index}>
                {console.log(item)}
                <th scope="row">{item.id}</th>
                <td>
                  <a
                    href={BaseUrl + "/storage/" + item.kitob_pdf}
                    target={"_blank"}>
                    <img
                      width={100}
                      height={130}
                      src={BaseUrl + "/storage/" + item.muqova_jpg}
                      alt="muqova yoq"
                    />
                  </a>
                </td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: item.bibliografikYozuv && item.bibliografikYozuv,
                  }}></td>
                <td>{item.umumiySoni}</td>
                <td>{item.soni}</td>
                <td>{item.bbk}</td>
                <td style={{ width: "100px" }}>
                  {item.anotatsiya.slice(0, 80) + "..."}
                </td>
                <td>{item.kitob_pdf  ? "Bor" : "Yo'q"}</td>
                <td>
                  <div className="main_edite-box d-flex">
                    <div className="edite_show_box">
                      <div
                        className="show_data"
                        onClick={() =>
                          navgate(`/adminMain/book_catalog/show/${item.id}`)
                        }>
                        <AiOutlineEye />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <b>Jami {sessionStorage.getItem("userDataCount")} ta ma'lumotdan {currentItems.length} ko'rsatilmoqda</b>

      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default BookCatalog;
