import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Super_admin/Auth/Login";
import Main from "./Super_admin/main/Main";
import AdminMain from "./admin/adminMain";
import Register from "./Super_admin/Auth/RegisterAdmin";
import { AllFunctionActions } from "./AllFunctionActions";
import User from "../src/user/User";
import Profile from "./user/userProfile/Profile";
import StudentRegister from "./Super_admin/Auth/StudentRegister";
import ForgotPassword from "./Super_admin/Auth/ForgotPassword";

const App = () => {
  return (
    <>
      <AllFunctionActions />
      <Routes>
        <Route exact path="/*" element={<User />} />
        <Route path="/profile/*" element={<Profile />} />
        <Route path="/auth" element={<Login />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route path="/register-student" element={<StudentRegister />} />
        <Route path="/register" element={<Register />} />
        <Route path="/Supermain/*" element={<Main />} />
        <Route path="/adminMain/*" element={<AdminMain />} />
        
      </Routes>
    </>
  );
};
export default App;
