import React from "react";
import "./Remote_service.css"

const Remote_service = () => {
  return (
    <div className="container py-3">
      <h3 className="text-center">Elektron kutubxona axborot tizimlaridan foydalanish</h3>
      <p className="m-0 mt-2">
        O'zbekiston Respublikasi oliy ta'lim va ilmiy tadqiqot muassasalarining
        yagona elektron kutubxona axborot tizimiga axborot-resurs markazi umumiy
        fondida mavjud jami 40427 nomdagi o’quv va ilmiy adabiyotlar, maqola va
        jurnallar, desertatsiya, monografiyalar joylashtirilib umumiy elektron
        bazasi yaratilgan. Shuningdek kutubxona elektron xizmat ko’rsatish
        bo’limi tomonidan tashkil etilgan <b><a className="webLink" href="http://kutubxona.adu.uz">http://kutubxona.adu.uz</a></b> elektron
        platformasi va “Hemis” dasturi orqali ro‘yxatga olish, kutubxonaga
        a’zolik va qarzdorlikni monitoring qilish, kitoblarning bibliografik
        yozuvlari va to‘liq matnini shakllantirish, kitobxonlarga masofaviy
        xizmat ko‘rsatish kabi vazifalar amalga oshiriladi. Platformaga jami
        19669 nafar professor-o‘qituvchi va talabalar ro‘yxatga olingan bo‘lib
        o‘quv rejada fanlar kesimida belgilangan barcha asosiy va qo‘shimcha
        o‘quv adabiyotlarning 25851 nomdagi elektron shakli joylashtirilgan.
        Bundan tashqari kitobxonlarning kata qismi telegram messenjeridan
        foydalanishlarini inobatga olib masofadan elektron xizmat ko’rsatish
        tizimlarini o’z ichiga olgan https://t.me/aduarmbot mobil ilovasi
        yaratilgan bo’lib, ilova orqali universitet professor-o’qituvchi va
        talabalari tegishli ta’lim yo’nalishlari bo’yicha taqsimlargan asosiy va
        qo’shimcha adabiyotlar jamlangan virtual kutubxonadan keng
        foydalanishadi. Ilova orqali o’quv va ilmiy adabiyotlar, audio va video
        darslar bilan tanishish, yuklab olish va buyurtma qilish kabi ko’plab
        avtomatlashrilgan funksiyalardan foydalanishadi.
      </p>
      <p className="m-0 mt-2">
        O'zbekiston Respublikasi oliy ta'lim va ilmiy tadqiqot muassasalarining
        yagona elektron kutubxona axborot tizimiga axborot-resurs markazi umumiy
        fondida mavjud jami 40427 nomdagi o’quv va ilmiy adabiyotlar, maqola va
        jurnallar, desertatsiya, monografiyalar joylashtirilib umumiy elektron
        bazasi yaratilgan. Shuningdek kutubxona elektron xizmat ko’rsatish
        bo’limi tomonidan tashkil etilgan <b><a className="webLink" href="http://kutubxona.adu.uz" target={"_blank"}>http://kutubxona.adu.uz</a></b>  elektron
        platformasi va “Hemis” dasturi orqali ro‘yxatga olish, kutubxonaga
        a’zolik va qarzdorlikni monitoring qilish, kitoblarning bibliografik
        yozuvlari va to‘liq matnini shakllantirish, kitobxonlarga masofaviy
        xizmat ko‘rsatish kabi vazifalar amalga oshiriladi. Platformaga jami
        19669 nafar professor-o‘qituvchi va talabalar ro‘yxatga olingan bo‘lib
        o‘quv rejada fanlar kesimida belgilangan barcha asosiy va qo‘shimcha
        o‘quv adabiyotlarning 25851 nomdagi elektron shakli joylashtirilgan.
        Bundan tashqari kitobxonlarning kata qismi telegram messenjeridan
        foydalanishlarini inobatga olib masofadan elektron xizmat ko’rsatish
        tizimlarini o’z ichiga olgan <b><a className="webLink" href="https://t.me/aduarmbot" target={"_blank"}>https://t.me/aduarmbot</a></b>  mobil ilovasi
        yaratilgan bo’lib, ilova orqali universitet professor-o’qituvchi va
        talabalari tegishli ta’lim yo’nalishlari bo’yicha taqsimlargan asosiy va
        qo’shimcha adabiyotlar jamlangan virtual kutubxonadan keng
        foydalanishadi. Ilova orqali o’quv va ilmiy adabiyotlar, audio va video
        darslar bilan tanishish, yuklab olish va buyurtma qilish kabi ko’plab
        avtomatlashrilgan funksiyalardan foydalanishadi.
      </p>
      <p className="m-0 mt-2">
        Universitet www.adu.uz saytiga O‘zbekiston Respublikasi Milliy
        kutubxonasining <b><a className="webLink" href="https://www.natlib.uz/" target={"_blank"}> https://www.natlib.uz/</a></b> elektron sayitiga
        integrallashtirilagan bo‘lib, milliy kutubxona elektron bazasidan
        foydalanishlariga imkoniyat yaratilgan.
      </p>
      <p className="m-0 mt-2">
        ARM ning xorijiy axborot kutubxona resurslari va universitet ilmiy
        bo‘limi bilan hamkorlikda <b>Springer Nature, Web of Science,</b>
        jurnallari shuningdek{" "}
        <b>
          Proquest, EBSCOhost, Academic Search Premier, Education Research
          Complete, MasterFile Premier, Science & Technology Collection
        </b>{" "}
        kabi jami 12 ta xorijiy ilmiy ta’limiy to‘liq matnli ma’lumotlar
        bazalaridan foydalanish yo‘lga qo‘yilgan. Ushbu bazalardan jami 15685
        nomdagi elektron resurslar (monografiya, jurnal, dissertatsiya,
        avtoreferat, maqola va tezislar) yuklab olinib umumiy elektron bazasi
        shakllantirilgan
      </p>
    </div>
  );
};

export default Remote_service;
