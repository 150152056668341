import axios from "axios";
import React, { useState, useEffect } from "react";
import { BaseUrl } from "../../BaseUrl";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const AddUniversityGroup = () =>{


    const yunalish = useSelector(state => state.store.yunalish)
    const [yunalish_id, setYunalish] = useState("")
    const [nomi, setNomi] = useState("")
    const [izoh, setIzoh] = useState("")
    const [active,setActive] = useState("")
    const [text, setText] = useState(false)
    const {id} = useParams()

  
    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === ""){} else if (text === "Yangi guruh bazaga yozildi."){
            toast.success(text)
        }
        else if (text === "Bazadagi guruh o'zgartirildi."){
            toast.success(text)
        }
        else{
            toast.error(text)
        }
        setText("")
    };


    const addYunalish = () => {
        if (id) {
            axios.put(BaseUrl + "/api/admin/guruh/edit/"+id, { nomi, izoh, yunalish_id ,active}, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                }
            }).catch(err => {
                console.log(err.response.data.errors);
                setText(err.response.data.errors)
            })
        }
        else{
            axios.post(BaseUrl + "/api/admin/guruh", { nomi, izoh, yunalish_id }, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                }
            }).catch(err => {
                console.log(err.response.data.errors);
                setText(err.response.data.errors)
            })
        }
    }


    const getYunalish = () => {
        axios.get(BaseUrl + "/api/admin/guruh", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data);
            res.data.guruhlar.forEach((item, index) => {
                if (item.id == id) {
                    setNomi(item.nomi);
                    setIzoh(item.izoh);
                    // setLastName(item.lastname);
                    // setFathername(item.fathername);
                    // console.log(item);
                    // console.log(res.data)
                }})
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getYunalish()
    }, [])



    return(
        <div style={{ width: "100%" }}>
        <ToastContainer/>
       <h2 className="mt-4">Guruh yaratish</h2>
       <hr />
       <div style={{ width: "100%", padding: "10px 50px", display: "flex", justifyContent: "space-between" }} className="main_box">
           <div style={{ width: "40%" }} className="first">
               <div style={{ width: "100%" }} className="main-add">
                   <div>
                   <div>
                           <div><label className="mt-3" htmlFor="">Yonalishini kiriting</label></div>
                          <select onChange={(e)=>setYunalish(e.target.value)} className="form-select" name="" id="">
                          <option>Tanlang</option>
                            {yunalish && yunalish.map((item,index)=>(
                                <option key={index} value={item.id}>{item.nomi}</option>
                            ))}
                          </select>
                       </div>
                       <div>
                           <div><label className="mt-3" htmlFor="">Nomini kiriting</label></div>
                           <input onChange={(e) => setNomi(e.target.value)} value={nomi} placeholder="Nomi..." className="form-control" type="text" />
                       </div>
                       <div>
                           <div><label className="mt-3" htmlFor="">Izoh kiriting</label></div>
                           <input onChange={(e) => setIzoh(e.target.value)} value={izoh} placeholder="Izoh..." className="form-control" type="text" />
                       </div>
                   </div>
               </div>
           </div>
           <div style={{ width: "40%" }} className="first">
               <div style={{ width: "100%" }} className="main-add">
               {
                            id ? <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <select style={{ width: "200px", height: "40px" }} className="form-select mt-3" onChange={(e) => setActive(e.target.value)} name="" id="">
                                    <option value="">tanlang</option>
                                    <option value="1">Faol</option>
                                    <option value="0">Faol emas</option>
                                </select>
                                <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                    <button onClick={addYunalish} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                                </div>
                            </div> : <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                <button onClick={addYunalish} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                            </div>
                        }
               </div>
           </div>
       </div>
   </div>
    )
}

export default AddUniversityGroup;