import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Books_collectionAction } from "../../../redux/actions/Action";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { BaseUrl } from "../../../Super_admin/BaseUrl";

const Buyurtma = () => {
  const [text, setText] = useState(false);
  const [switchs, setSwitchs] = useState();

  const dispatch = useDispatch();

  const navgate = useNavigate();

  const [userCount, setUserCount] = useState(15);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [books, setBooks] = useState([]);

  const getDate = (page = 1) => {
    axios
      .get(
        BaseUrl +
          `/api/user/kitobbron/${sessionStorage.getItem(
            "userId"
          )}?paginator=${userCount}&page=${page}&search=${searchTerm}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        // console.log(res.data.kitobBronlar);
        sessionStorage.setItem("userDataCount", res.data.kitobBronlar.total);
        setPageCount(res.data.kitobBronlar.last_page);
        setUsers(res.data.kitobBronlar.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDate();
  }, [userCount, searchTerm]);
  // console.log(users);

  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = userCount;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(users && users.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, users]);

  const handlePageClick = (event) => {
    getDate(event.selected + 1);
  };

  const user_id = sessionStorage.getItem("userId");

  useEffect(() => {
    getDate();
  }, []);
  return (
    <div>
      <div className="navBar">
        <p>Mening buyurtmalarim</p>
        <ToastContainer />
      </div>
      <hr />
      <div class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Muqova tasviri </th>
              <th scope="col">Kitob nomi</th>
              <th scope="col">Invertar raqam</th>
              <th scope="col">Status</th>
              <th scope="col">Kim tomonidan tasdiqlangan </th>
              {/* <th scope="col">Tasdiqlangan sana</th> */}
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((item) => {
                // console.log("item", item);
                return (
                  <tr>
                    <td>{item.id}</td>
                    <td>
                      <img
                        width={100}
                        height={80}
                        src={BaseUrl + "/storage/" + item.kitob.muqova_jpg}
                        alt="Kitob rasmi yo'q"
                      />
                    </td>
                    <td>{item.kitob.nomi}</td>
                    <td>{item.inventar.inventar_raqam}</td>
                    <td>
                      {item.izoh ? (
                       <button type="button" class="btn btn-success">{item.izoh}</button>

                      ) : (
                        <button type="button" class="btn btn-warning">
                          Ko'rib chiqilmoqda
                        </button>
                      )}
                    </td>
                    <td>
                      {item.kutubxonachi?.lastname}
                      {item.kutubxonachi?.firstname}
                    </td>
                    {/* <td>{new Date(item.created_at).toLocaleString()} </td> */}
                    <td>
                      <div className="main_edite-box ">
                        <div className="edite_show_box">
                          <div
                            className="show_data"
                            onClick={() => {
                              navgate(`/profile/kitoblar/show/${item.kitob.id}`);
                              sessionStorage.setItem("showId", item.kitob.id);
                            }}>
                            <AiOutlineEye />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default Buyurtma;
