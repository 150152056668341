import './Footer.css';
import { FiPhoneCall } from 'react-icons/fi';
import { MdAlternateEmail } from 'react-icons/md';
import { GoLocation } from 'react-icons/go';


function Footer() {
    return (
        <div className="footer p-3">
            <footer className="py-3 mt-4">
                <div className="row">
                    <div className="col-sm-12">
                        <h5 className="footer-value">ANDIJON DAVLAT UNIVERSITETI AXBOROT-RESURS MARKAZI</h5>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer