import React from "react";

const AkKatalog = () => {
  return (
    <div className="container py-3">
      <h3 className="text-center">
        Axborot-kutubxona resurslarini butlash, kataloglashtirish va
        tizimlashtirish bo‘limi
      </h3>
      <p className="m-0 mt-3">
        <b>
          2011-yil 13-aprelda qabul qilingan O‘zbekiston Respublikasining
          “Axborot-kutubxona faoliyati to‘g‘risida”gi qonunida ko‘rsatilgan
          vazifalarni amalga oshiradi.
        </b>
      </p>
      <p className="m-0 mt-2">Bo‘limning asosiy vazifasi:</p>
      <ul>
        <li>
          Axborot texnologiyalari asosida AK (avtomatlashtirilgan kutubxona)
          dasturida elektron kutubxona katalogini shakllantirish uchun
          adabiyotlarga bibliografik tavsif berish;
        </li>
        <li>
          Kutubxonaga yangi qabul qilingan adabiyotlarning shartnoma asosida AK
          tizimida ro‘yxatga olish, inventar raqamlarini qayd etish;
        </li>
        <li>Summar hisob-kitobini yuritish;</li>
        <li>
          Axborot texnologiyalari asosida yaroqsiz holga kelgan,
          foydalanuvchilar tomonidan yo‘qotilgan kitoblarni ARM fondidan
          chiqarish va o‘rniga qabul qilingan kitoblarni ro‘yxatga olish;
        </li>
        <li>
          Axborot texnologiyalari asosida yaroqsiz holga kelgan,
          foydalanuvchilar tomonidan yo‘qotilgan kitoblarni ARM fondidan
          chiqarish va o‘rniga qabul qilingan kitoblarni ro‘yxatga olish;
        </li>
        <li>
          Yil davomida ARMga keltirilgan yangi adabiyotlar haqida hisobotlar
          tayyorlash, fakultetlararo targ‘ib etish va taqsimlash.
        </li>
      </ul>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid"
            src={require("..//../assets/butlash1.jpg")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
        Bo‘limga yangi kelgan adabiyotlar hujjat asosida qabul qilinib, ilmiy
        ishlov, shtamp bosilib, inventar raqami beriladi va inventar kitobiga
        yozilib boriladi. Kitobga maxsus konvert yopishtirilib unda qaytarish
        muddati formulyari qayd etish varaqasi joylashtiriladi. Yangi kelgan
        kitoblar bo‘limlarga ajratilib, KBK shifri hamda narxlari qo‘yiladi va 4
        nusxada kartochkalar yozilib, yig‘ma elektron katalogiga
        joylashtiriladi. Adabiyotlarga tizimli ishlov berilib, summar
        hisob-kitob daftariga qayd qilib boriladi.
      </p>
      <div className="row mt-2">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/butlash2.jpg")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
      Bo‘limga dissertatsiya va avtoreferatlar ham qabul qilinib raqamlashtirilib boriladi. Yil davomida talabalar tomonidan yo‘qotilgan adabiyotlarga dalolatnoma tuzilib fonddan chiqariladi va o‘rniga olingan adabiyotlarga inventar raqami berilib fondlarga tarqtiladi.
      </p>
    </div>
  );
};

export default AkKatalog;
