import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../../../../../Super_admin/BaseUrl";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import InventarAll from "./InventarAll/InventarAll";

const KitobBolimBiriktrish = () => {
  const [kutubxonabulimSelect, setKutubxonabulimSelect] = useState([]);
  const [kutubxonabulimTable, setkutubxonabulimTable] = useState([]);
  const [kutubxona_bulim_id, setKutubxona_bulim_id] = useState("");
  const [editId, setEditId] = useState("");
  const [text, setText] = useState("");

  // console.log("kitobId", kitob_id.id);
  const navgate = useNavigate();
  const { id } = useParams();
  // console.log(id);

  const notify = () => {
    if (text === "") {
    } else if (text === "Kitob kutubxona bo'limiga biriktirildi.") {
      toast.success(text);
    } else if (text === "Kitob kutubxona bo'limiga qayta biriktirildi.") {
      toast.success(text);
    } else if (text === "Kitob kutubxona bo'limidan olib tashlandi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };
  useEffect(() => {
    notify();
  }, [text]);
  // select uchun get
  const getkutubxonabulim = () => {
    axios
      .get(BaseUrl + "/api/admin/kutubxonabulim", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        setKutubxonabulimSelect(res.data.kutubxonaBulim);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Kutubxona bo‘limlari post
  const addkutubxonabulim = () => {
    if (editId) {
      axios
        .put(
          BaseUrl + "/api/kutubxonachi/kitobbulim/edit/" + editId,
          {
            kutubxona_bulim_id,
            kitob_id: id,
            user_id: sessionStorage.getItem("userId"),
          },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data.message);
          if (res.status == 200) {
            setText(res.data.message);
            setEditId("");
            getkitobbulimTable();
          }
        })
        .catch((err) => {
          console.log(err.response.data.errors);
          setText(err.response.data.errors);
        });
    } else {
      axios
        .post(
          BaseUrl + "/api/kutubxonachi/kitobbulim",
          {
            kutubxona_bulim_id,
            kitob_id: id,
            user_id: sessionStorage.getItem("userId"),
          },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          //  console.log(res.data);
          if (res.status == 200) {
            setText(res.data.message);
            // setKutubxona_bulim_id("");
            getkitobbulimTable();
          }
        })
        .catch((err) => {
          console.log(err.response.data.errors);
          setText(err.response.data.errors);
        });
    }
  };

  // Kutubxona bo‘limlari get
  const getkitobbulimTable = () => {
    axios
      .get(
        BaseUrl +
          `/api/kutubxonachi/kitobbulim/index/${sessionStorage.getItem(
            "showId"
          )}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setkutubxonabulimTable(res.data.kitobBulimlar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Kutubxona bo‘limlari delete
  const deleteItem = (id) => {
    axios
      .delete(BaseUrl + `/api/kutubxonachi/kitobbulim/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.message);
        if (res.status == 200) {
          getkitobbulimTable();
          setText(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getkutubxonabulim();
    getkitobbulimTable();
  }, []);
  return (
    <>
      <p className="fs-5 text-muted">
        Kitobni filial va bo'limga biriktirish (Kiritish)
      </p>
      <div className="col-md-4 col-sm-12">
        <label className="mt-3" htmlFor="">
          Kutubxona bo‘limlari
        </label>
        <select
          className="form-select"
          name=""
          id=""
          onChange={(e) => setKutubxona_bulim_id(e.target.value)}>
          <option value="Tanlang">Tanlang</option>
          {kutubxonabulimSelect &&
            kutubxonabulimSelect.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.nomi}
                </option>
              );
            })}
        </select>
        <span className="d-flex flex-row-reverse ">
          <button className="btn btn-primary mt-3" onClick={addkutubxonabulim}>
            {editId ? "O'zgartrish" : "Yaratish"}
          </button>
        </span>
      </div>

      <div class="table-responsive">
        <table class="table align-middle table-striped">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Faolmi?</th>
              <th scope="col">Bo‘limlar</th>
              <th scope="col">Mas'ul shaxs</th>
              <th scope="col">Nusxalar soni</th>
              <th scope="col">Kutubxonada bormi?</th>
              <th scope="col">Elektron formatda bormi?</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {kutubxonabulimTable &&
              kutubxonabulimTable.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* {console.log(item)} */}
                    <td>{item.ID}</td>
                    <td>
                      {item.active === 0 ? (
                        <div className="chek2">
                          <CiSquareRemove style={{ color: "white" }} />
                        </div>
                      ) : (
                        <div className="chek">
                          <HiOutlineCheckCircle style={{ color: "white" }} />
                        </div>
                      )}
                    </td>
                    <td>{item.nomi}</td>
                    <td>{item.masul_shaxs}</td>
                    <td>{item.soni}</td>
                    <td>
                      {item.active === 0 ? (
                        <div className="chek2">
                          <CiSquareRemove style={{ color: "white" }} />
                        </div>
                      ) : (
                        <div className="chek">
                          <HiOutlineCheckCircle style={{ color: "white" }} />
                        </div>
                      )}
                    </td>
                    <td>
                      {item.active === 0 ? (
                        <div className="chek2">
                          <CiSquareRemove style={{ color: "white" }} />
                        </div>
                      ) : (
                        <div className="chek">
                          <HiOutlineCheckCircle style={{ color: "white" }} />
                        </div>
                      )}
                    </td>
                    <td>
                      <div
                        class="btn-group"
                        role="group"
                        aria-label="Basic checkbox toggle button group">
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btncheck1"
                          autocomplete="off"
                        />
                        {/* {console.log(item.id)} */}
                        <label
                          class=" btn btn-info"
                          for="btncheck1"
                          onClick={() =>
                            navgate(
                              `/adminMain/books_collection/detail/${id}/${item.id}`
                            )
                          }>
                          Inventar raqam qo'shish
                        </label>

                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btncheck2"
                          autocomplete="off"
                        />
                        <label
                          class="btn btn-success"
                          for="btncheck2"
                          onClick={(e) => setEditId(item.id)}>
                          Tahrirlash
                        </label>

                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btncheck3"
                          autocomplete="off"
                        />
                        <label
                          class="btn btn-danger"
                          for="btncheck3"
                          onClick={() => deleteItem(item.ID)}>
                          O'chrish
                        </label>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <hr class="border border-primary border-3 opacity-75" />
      <InventarAll />
    </>
  );
};

export default KitobBolimBiriktrish;
