/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EyeOutlined, PlusCircleOutlined } from "@ant-design/icons";
import useScanDetection from "use-scan-detection";

const Royhat2 = () => {
  const dispatch = useDispatch();
  const [maqsad, setMaqsad] = useState();
  const [text, setText] = useState(false);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [userCount, setUserCount] = useState(15);

  const [fakultet, setFakultet] = useState("");
  const [yunalish, setYunalish] = useState("");
  const [lavozim, setLavozim] = useState("");
  const [kursi, setKursi] = useState("");
  const [fakultetList, setFakultetList] = useState();
  const [yunalishList, setYunalishList] = useState();
  const [talimShakli, setTalimShakli] = useState();

  const getFakultet = () => {
    axios
      .get(`${BaseUrl}/api/admin/fakultet`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data.fakultetlar);
        setFakultetList(res.data.fakultetlar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getYunalishs = () => {
    axios
      .get(`${BaseUrl}/api/admin/yunalish/${fakultet}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data.yunalishlar);
        setYunalishList(res.data.yunalishlar);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTalimShakli = () => {
    axios
      .get(`${BaseUrl}/api/admin/lavozim/2`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data.lavozimlar);
        setTalimShakli(res.data.lavozimlar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFakultet();
    getTalimShakli();
  }, []);
  useEffect(() => {
    getYunalishs();
  }, [fakultet]);

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Bu foydalanuvchi tashrif buyurgan") {
      toast.error(text);
    } else {
      toast.success(text);
    }
    setText("");
  };
  ///api/kutubxonachi/qatnov  /api/kutubxonachi/qatnovlar

  const getYunalish = (page = 1) => {
    axios
      .get(
        BaseUrl +
          `/api/kutubxonachi/qatnov?lavozim=${lavozim}&fakultet=${fakultet}&kursi=${kursi}&yunalish=${yunalish}&&paginator=${userCount}&page=${page}&search=${searchTerm}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        sessionStorage.setItem("userDataCount", res.data.qatnovlar.total);
        setPageCount(res.data.qatnovlar.last_page);
        setUsers(res.data.qatnovlar.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getYunalish();
  }, [text, searchTerm, userCount, fakultet, yunalish, kursi, lavozim]);

  const userId = sessionStorage.getItem("userId");

  const itemsPerPage = userCount;
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(users && users.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, users]);

  const handlePageClick = (event) => {
    getYunalish(event.selected + 1);
  };
  
  const [userID2, setUserId2] = useState('')
  
  useScanDetection({
    onComplete: setUserId2,
    minLength: 3,
  })

  const QabulQilish = (id) => {
    axios.post(BaseUrl + `/api/kutubxonachi/qatnov`,{ userId, user_id: id, },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // if (res.status == 200) {
        //     getYunalish()
        setText(res.data.message);
        // }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };



  let  testId = '' 

  const QabulQilish2 = () => {
    if (userID2.length == 3) {
       testId = userID2.slice(2)
    }
    else if (userID2.length == 4) {
       testId = userID2.slice(2)
    }
    else if (userID2.length == 5) {
       testId = userID2.slice(2)
    }
    else if (userID2.length == 6) {
       testId = userID2.slice(2)
    }
    else {
       testId = userID2.slice(2, -9)
    }
    console.log('testId', testId);
    if (testId) {
      console.log(testId);
      axios.post(BaseUrl + `/api/kutubxonachi/qatnov`, { userId, user_id: testId, }, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => {
        console.log("resp -", res.data);
        // if (res.status == 200) {
        //     getYunalish()
        setText(res.data.message);
        // setUserId('')
        // console.log("resp - ", res);
        // }
      })
        .catch((err) => {
          console.log(err.response.data.errors);
          setText(err.response.data.errors)
        });
    }
  };


  useEffect(() => {
    QabulQilish2()
    // console.log('userID2',userID2);
  }, [userID2])



  return (
    <div>
      <ToastContainer />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="mt-4">Maqsadli tashrif</h2>
        <div className="for-search">
          <div className="for-search2">
            <img
              style={{
                width: "30px",
                position: "absolute",
                margin: "5px 0 0 5px",
              }}
              src="/images/search.png"
              alt=""
            />
          </div>
          <input
            placeholder="Ism familiya bo'yicha qidiruv"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            className="form-control search_input"
            type="search"
          />
        </div>
      </div>
      <hr />
      <div
        className="mb-3"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div className="d-flex">
          <div style={{ marginLeft: "30px" }}>
            <select
              className="form-select"
              name=""
              id=""
              onChange={(e) => setFakultet(e.target.value)}
            >
              <option disabled selected value="">
                Fakultetni tanlang
              </option>
              <option value="">Umumiy qidirish</option>
              {fakultetList?.map((item, index) => (
                <option value={item.id}>{item.nomi}</option>
              ))}
            </select>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <select
              onChange={(e) => setYunalish(e.target.value)}
              className="form-select"
              name=""
              id=""
            >
              <option disabled selected value="">
                Yo'nalishini tanlang
              </option>
              <option value="">Umumiy qidirish</option>
              {yunalishList?.map((item, index) => (
                <option value={item.id}>{item.nomi}</option>
              ))}
            </select>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <select
              onChange={(e) => setLavozim(e.target.value)}
              className="form-select"
              name=""
              id=""
            >
              <option disabled selected value="">
                Talim shakli
              </option>
              <option value="">Umumiy qidirish</option>
              {talimShakli?.map((item, index) => (
                <option value={item.id}>{item.nomi}</option>
              ))}
            </select>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <select
              onChange={(e) => setKursi(e.target.value)}
              className="form-select"
              name=""
              id=""
            >
              <option disabled selected>
                Kursini tanlang
              </option>
              <option value="">Umumiy qidirish</option>
              <option value="1-Kurs">1-Kurs</option>
              <option value="2-Kurs">2-Kurs</option>
              <option value="3-Kurs">3-Kurs</option>
              <option value="4-Kurs">4-Kurs</option>
              <option value="5-Kurs">5-Kurs</option>
            </select>
          </div>
        </div>
      </div>
      <select onChange={(e) => setUserCount(+e.target.value)} name="" id="">
        <option value="15">15</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        {/* <Link to="/Supermain/maqsad/add">
                <div className="add-kafedra">Maqsad qo'shish</div>
            </Link> */}
      </div>
      <div className="tableScroll">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Ismi</th>
              <th scope="col">Familiyasi</th>
              <th scope="col">Bo'limi</th>
              <th scope="col">Maqsadi</th>
              <th scope="col">Kelgan vaqti</th>
              <th scope="col">Ko'rish</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{item.user_id}</th>
                  <td>{item.user.firstname}</td>
                  <td>{item.user.lastname}</td>
                  <td>{item.bulim.nomi}</td>
                  <td>{item.maqsad.nomi}</td>
                  <td>{item.created_at}</td>
                  <td style={{ width: "7%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "1.5rem",
                      }}
                    >
                      <div
                        disabled
                        onClick={() =>
                          navigate(`/adminMain/royhat2/detail/${item.id}`)
                        }
                        className="edite me-1 p-3"
                      >
                        <EyeOutlined />
                      </div>
                      <div
                        className="p-1"
                        style={{
                          width: "50px",
                          cursor: "pointer",
                          backgroundColor: "#007bff",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                        }}
                        disabled
                        onClick={() => QabulQilish(item.user_id)}
                      >
                        <PlusCircleOutlined />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <b>
        Jami {sessionStorage.getItem("userDataCount")} ta ma'lumotdan{" "}
        {userCount} ko'rsatilmoqda
      </b>
      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default Royhat2;
