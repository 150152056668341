import React, { useEffect, useState } from "react";
import "./adminMain.css";
import { FiUsers } from "react-icons/fi";
import { AiOutlineLogout, AiOutlineSave } from "react-icons/ai";
import { BsBook } from "react-icons/bs";
import { BsFillClipboardFill } from "react-icons/bs";
import { MdOutlineBook } from "react-icons/md";
import { GrCatalogOption } from "react-icons/gr";
import { RxHamburgerMenu } from "react-icons/rx";
import { UnorderedListOutlined } from '@ant-design/icons';
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import Book_type from "./Books/Book_type/Book_type";
import Science_field from "./Books/Science_field/Science_field";
import Book_Language from "./Books/Book_Language/Book_Language";
import Book_Entry from "./Books/Book_Entry/Book_Entry";
import Text_type from "./Books/Text_type/Text_type";
import File_type from "./Books/File_type/File_type";
import Books_collection from "./Books/Books_collection/Books_collection";
import Book_typeAdd from "./Books/Book_type/Book_typeAdd";
import Science_fieldAdd from "./Books/Science_field/Science_fieldAdd";
import Book_LanguageAdd from "./Books/Book_Language/Book_LanguageAdd";
import Book_EntryAdd from "./Books/Book_Entry/Book_EntryAdd";
import Text_typeAdd from "./Books/Text_type/Text_typeAdd";
import File_typeAdd from "./Books/File_type/File_typeAdd";
import Category from "./Books/Category/Category";
import Categoryy from "./Books/Category/Category";
import CategoryAdd from "./Books/Category/CategoryAdd";
import Sumerka from "./Books/Sumerka/Sumerka";
import SumerkaAdd from "./Books/Sumerka/SumerkaAdd";
import SumerkaDetail from "./Books/Sumerka/SumerkaDetail/SumerkaDetail";
import Books_collectionAdd from "./Books/Books_collection/Books_collectionAdd";
import Books_collectionDetail from "./Books/Books_collection/Books_collectionDetail/Books_collectionDetail";
import AdminUsersList from "./AdminUsers/AdminUserList";
import Register from "../Super_admin/Auth/RegisterAdmin";
import AdminStudentList from "./AdminUsers/AdminStudentList";
import AddAdminStudent from "./AdminUsers/AddAdminStudent";

import Book_direction from "./Books/Book_direction/Book_direction";
import Book_directionAdd from "./Books/Book_direction/Book_directionAdd";
import IlmiyNashr from "./IlmiyNashr/IlmiyNashr";
import AddIlmiyNashr from "./IlmiyNashr/AddIlmishNashr";
import IlmiyNashrTuri from "./IlmiyNashr/IlmiyNashrTuri";
import AddIlmiyNashrTuri from "./IlmiyNashr/AddIlmiyNashrTuri";
import Inventar from "./Books/Books_collection/Books_collectionDetail/KitobBolimBiriktrish/Inventar/Inventar";
import BookCatalog from "./BookCatalog/BookCatalog";
import Book_catalog_show from "./BookCatalog/Book_catalog_show/Book_catalog_show";

import GetBooks from "./getBooks/GetBooks";
import AttachUser from "./getBooks/AttachUser";
import ReturnBooks from "./returnBooks/ReturnBooks";
import InnnerReturnBooks from "./returnBooks/InnerReturnBooks";
import { HiOutlineCloudArrowUp } from "react-icons/hi2";
import { HiOutlineCloudArrowDown } from "react-icons/hi2";
import Inventarlar from "./Books/Inventarlar/Inventarlar";
import Journals from "./Journals/Journals/Journals";
import AddJoournal from "./Journals/Journals/AddJournal";
import DetailJurnal from "./Journals/Journals/DetailJurnal";
import JurnalMaqola from "./Journals/JurnalMaqola/JurnalMaqola";
import AddMaqola from "./Journals/JurnalMaqola/AddMaqola";
import JurnalSon from "../user/userPages/JurnalSon/JurnalSon";
import AddJurnalSoniList from "./Journals/JurnalSon/AddJurnalSoniList";
import Sorovlar from "./Sorovlar/Sorovlar";
import BandKitoblar from "./BandKitoblar/BandKitoblar";
import BandKitoblarShow from "./BandKitoblar/BandKitoblarShow/BandKitoblarShow";

import { FaRegMessage } from "react-icons/fa6";
import axios from "axios";
import { BaseUrl } from "../Super_admin/BaseUrl";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import Royhat from "./Royhat/Royhat";
import Royhat2 from "./Royhat/Royhat2";
import Royhat2Detail from "./Royhat/Royhat2Detail";
import XodimlarList from "./AdminUsers/XodimlarList";
import AddXodimlar from "./AdminUsers/AddXodimlar";
import UserDetails from "./AdminUsers/UserDetails";


const AdminMain = () => {
  const navigate = useNavigate();
  const [massegeBandK, setMassegBandK] = useState();
  const [massegeBYuklabK, setMassegYuklabK] = useState();
  const [text, setText] = useState("");


  // const LogOut = () => {
  //   sessionStorage.removeItem("token");
  //   sessionStorage.removeItem("email");
  //   sessionStorage.removeItem("firstname");
  //   sessionStorage.removeItem("lastname");
  //   sessionStorage.removeItem("bulim_id");
  //   sessionStorage.removeItem("fakultet_id");
  //   sessionStorage.removeItem("kafedra_id");
  //   sessionStorage.removeItem("lovozim_id");
  //   sessionStorage.removeItem("role");
  //   sessionStorage.removeItem("uquv_yili_id");
  // navigate("/");
    
  // };

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Foydalanuvchi tizimdan chiqdi.") {
      toast.success(text);
    } 
    setText("");
  };
  // FiUsers

  const [toggle1, setToggle1] = useState(false);

  const Toggle = () => {
    setToggle1(!toggle1);
  };

  const getMessagYuklabOlish = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobsurovlari/yangiSurovlar", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("masseg", res.data);
        setMassegYuklabK(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMessagdata = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobbronlar/yangibronlar", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("masseg", res.data);
        setMassegBandK(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const message = useSelector((state) => state.store.message);

  useEffect(() => {
    getMessagdata();
  }, [message]);

  useEffect(() => {
    getMessagdata();
    getMessagYuklabOlish();
  }, []);

  
  const LogOut = () => {
    axios
      .post(
        BaseUrl + "/api/logout",{},{
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
          console.log(res.data.message);
        if (res?.status == 200) {
          setText(res?.data?.message);
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("firstname");
          sessionStorage.removeItem("lastname");
          sessionStorage.removeItem("bulim_id");
          sessionStorage.removeItem("fakultet_id");
          sessionStorage.removeItem("kafedra_id");
          sessionStorage.removeItem("lovozim_id");
          sessionStorage.removeItem("role");
          sessionStorage.removeItem("uquv_yili_id");
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setText(err?.response?.data?.errors);
        if (err.response.status == 401) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("firstname");
          sessionStorage.removeItem("lastname");
          sessionStorage.removeItem("bulim_id");
          sessionStorage.removeItem("fakultet_id");
          sessionStorage.removeItem("kafedra_id");
          sessionStorage.removeItem("lovozim_id");
          sessionStorage.removeItem("role");
          sessionStorage.removeItem("uquv_yili_id");
          navigate("/");
      }
      });
  };

  return (
    <div className="main-container">
      <ToastContainer />
      <div className={toggle1 ? "side-bar2" : "side-bar shadow"}>
        <div className="for-logo">
          <img
            style={{ width: "80px", height: "80px", borderRadius: "50%" }}
            src={require("./assets/armlogo.png")}
            alt=""
          />
        </div>

        <div className="accordion accordion" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne">
                <FiUsers style={{ margin: "0 10px 0 0", fontSize: "23px" }} />{" "}
                Foydalanuvchilar
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <Link to="/adminMain/AdminUserList">
                  <div className="menus">Foydalanuvchilar ro'yxati</div>
                </Link>
                <Link to="/adminMain/AdminStudentList">
                  <div className="menus">Talabalar ro'yxati</div>
                </Link>
                <Link to="/adminMain/AdminXodimList">
                  <div className="menus">Xodimlar ro'yxati</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo">
                <BsBook style={{ margin: "0 10px 0 0", fontSize: "23px" }} />{" "}
                Kitoblar
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <Link to="/adminMain/book_type">
                  <div className="menus"> Kitob turi</div>
                </Link>
                <Link to="/adminMain/science_field">
                  <div className="menus"> Fan sohasi</div>
                </Link>
                <Link to="/adminMain/book_direction">
                  <div className="menus"> Kitob yo'nalishi</div>
                </Link>
                <Link to="/adminMain/book_language">
                  <div className="menus">Kitob tili</div>
                </Link>
                <Link to="/adminMain/book_entry">
                  <div className="menus">Kitob yozuvi</div>
                </Link>
                <Link to="/adminMain/text_type">
                  <div className="menus">Resurs turi</div>
                </Link>
                <Link to="/adminMain/file_type">
                  <div className="menus">Fayl turi</div>
                </Link>
                <Link to="/adminMain/category">
                  <div className="menus">Toifasi</div>
                </Link>
                <Link to="/adminMain/books_collection">
                  <div className="menus">Kitoblar</div>
                </Link>
                <Link to="/adminMain/inventarlar">
                  <div className="menus">Inventarlar</div>
                </Link>
                <Link to="/adminMain/sumerka">
                  <div className="menus">Shartnomalar</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree">
                <MdOutlineBook
                  style={{ margin: "0 10px 0 0", fontSize: "23px" }}
                />
                Jurnallar
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <Link to="/adminMain/journal">
                  <div className="menus"> Jurnallar</div>
                </Link>
                <Link to="/adminMain/jurnalSon">
                  <div className="menus"> Jurnallar soni</div>
                </Link>
                <Link to="/adminMain/jurnalmaqola">
                  <div className="menus"> Jurnal maqolalari</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Link to="/adminMain/book_catalog/">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <GrCatalogOption
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />
            kitob katalogi
          </div>
        </Link>

        <Link to="/adminMain/ilmiynashr">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <BsFillClipboardFill
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />{" "}
            Ilmiy nashrlar
          </div>
        </Link>

        <Link to="/adminMain/ilmiynashrturi">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <BsFillClipboardFill
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />{" "}
            Ilmiy nashr turi
          </div>
        </Link>

        <Link to="/adminMain/sorovlar">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <AiOutlineSave style={{ margin: "0 10px 0 0", fontSize: "23px" }} />{" "}
            Yuklab olish so'rovlari
            <span className="massage_section">
              <sup>{massegeBYuklabK}</sup>
            </span>
          </div>
        </Link>

        <Link to="/adminMain/getbooks">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <HiOutlineCloudArrowUp
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />{" "}
            Kutubxonadan kitob berish
          </div>
        </Link>

        <Link to="/adminMain/returnbooks">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <HiOutlineCloudArrowDown
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />
            Kutubxonaga kitob qaytarish
          </div>
        </Link>

        <Link to="/adminMain/bandKitoblar">
          <div className="border massage_main">
            <div>
              <BsBook style={{ margin: "0 10px 0 0", fontSize: "23px" }} />
            </div>
            Band qilingan kitoblar
            <span className="massage_section">
              <sup>{massegeBandK}</sup>
            </span>
          </div>
        </Link>
        <Link to="/adminMain/royhat">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <UnorderedListOutlined
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />
            Tashrif
          </div>
        </Link>
        <Link to="/adminMain/royhat2">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <UnorderedListOutlined
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />
            Maqsadli tashrif
          </div>
        </Link>
      </div>

      {/* <<<<<<<<<<<<<<< content >>>>>>>>>>>>>>>>*/}
      <div className="content">
        <div className="content-header">
          <div className="for_header">
            <div>
              <RxHamburgerMenu className="Navbbar" onClick={Toggle} />
            </div>
            <div className="for-profile">
              <div
                className="dropdown-toggle dropdown"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <img
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                  src={
                    sessionStorage.getItem("rasm") == null ||
                    sessionStorage.getItem("rasm") == "null"
                      ? "/images/avatar.png"
                      : BaseUrl + "/storage/" + sessionStorage.getItem("rasm")
                  }
                  alt=""
                />
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      {sessionStorage.getItem("firstname")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {sessionStorage.getItem("lastname")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      RTTR Markazi
                    </a>
                  </li>
                  <li onClick={() => LogOut()}>
                    <a  className="dropdown-item" href="#"  style={{ display: "flex", alignItems: "center",  backgroundColor: "red",  color: "white", }}>
                      <AiOutlineLogout style={{  color: "white", fontSize: "25px", margin: " 0 10px 0 0", }} />
                      <p style={{ color: "white", marginTop: "15px", fontSize: "18px",}}>
                        Chiqish
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-body">
          {
            <Routes>
              {/* <<<<<<<<<<<<<<<<<<<<   Kutubxona >>>>>>>>>>>>>>>>> */}
              <Route path="/book_type/*" element={<Book_type />} />
              <Route path="/royhat" element={<Royhat />} />
              <Route path="/royhat2/*" element={<Royhat2 />} />
              <Route path="/royhat2/detail/:id" element={<Royhat2Detail />} />
              <Route path="/book_type/add" element={<Book_typeAdd />} />
              <Route path="/book_type/add/:id" element={<Book_typeAdd />} />
              <Route path="/science_field/*" element={<Science_field />} />
              <Route path="/science_field/add" element={<Science_fieldAdd />} />
              <Route
                path="/science_field/add/:id"
                element={<Science_fieldAdd />}
              />
              <Route path="/book_direction/*" element={<Book_direction />} />
              <Route
                path="/book_direction/add"
                element={<Book_directionAdd />}
              />
              <Route
                path="/book_direction/add/:id"
                element={<Book_directionAdd />}
              />
              <Route path="/book_language/*" element={<Book_Language />} />
              <Route path="/book_language/add" element={<Book_LanguageAdd />} />
              <Route
                path="/book_language/add/:id"
                element={<Book_LanguageAdd />}
              />
              <Route path="/book_entry/*" element={<Book_Entry />} />
              <Route path="/book_entry/add" element={<Book_EntryAdd />} />
              <Route path="/book_entry/add/:id" element={<Book_EntryAdd />} />
              <Route path="/text_type/*" element={<Text_type />} />
              <Route path="/text_type/add" element={<Text_typeAdd />} />
              <Route path="/text_type/add/:id" element={<Text_typeAdd />} />
              <Route path="/file_type/*" element={<File_type />} />
              <Route path="/file_type/add" element={<File_typeAdd />} />
              <Route path="/file_type/add/:id" element={<File_typeAdd />} />
              <Route path="/category/*" element={<Categoryy />} />
              <Route path="/category/add" element={<CategoryAdd />} />
              <Route path="/category/add/:id" element={<CategoryAdd />} />
              <Route
                path="/books_collection/*"
                element={<Books_collection />}
              />
              <Route
                path="/books_collection/add"
                element={<Books_collectionAdd />}
              />
              <Route
                path="/books_collection/add/:id"
                element={<Books_collectionAdd />}
              />
              <Route
                path="/books_collection/detail/:id/*"
                element={<Books_collectionDetail />}
              />
              <Route
                path="/books_collection/detail/:id/:id"
                element={<Inventar />}
              />
              <Route path="/inventarlar" element={<Inventarlar />} />
              <Route
                path="/books_collection/*"
                element={<Books_collection />}
              />
              <Route
                path="/books_collection/add"
                element={<Books_collectionAdd />}
              />
              <Route
                path="/books_collection/add/:id"
                element={<Books_collectionAdd />}
              />
              <Route
                path="/books_collection/detail/:id/*"
                element={<Books_collectionDetail />}
              />
              <Route
                path="/books_collection/detail/:id/:id"
                element={<Inventar />}
              />
              <Route path="/sumerka/*" element={<Sumerka />} />
              <Route path="/sumerka/add" element={<SumerkaAdd />} />
              <Route path="/sumerka/add/:id" element={<SumerkaAdd />} />
              <Route path="/sumerka/show/:id" element={<SumerkaDetail />} />
              <Route path="/ilmiynashr" element={<IlmiyNashr />} />
              <Route path="/ilmiynashr/add" element={<AddIlmiyNashr />} />
              <Route path="/ilmiynashrturi" element={<IlmiyNashrTuri />} />
              <Route
                path="/ilmiynashrturi/add"
                element={<AddIlmiyNashrTuri />}
              />
              <Route
                path="/ilmiynashrturi/add/:id"
                element={<AddIlmiyNashrTuri />}
              />
              {/* <<<<<<<<<<<<<<<<<<<<   kitob Katalog  >>>>>>>>>>>>>>>>> */}
              <Route path="/book_catalog/*" element={<BookCatalog />} />
              <Route
                path="/book_catalog/show/:id"
                element={<Book_catalog_show />}
              />
              {/* <<<<<<<<<<<<<<<<<<<<   USERS >>>>>>>>>>>>>>>>> */}
              <Route path="/AdminUserList" element={<AdminUsersList />} />
              <Route path="/AdminUserList/add" element={<Register />} />
              <Route path="/AdminUserList/add/:id" element={<Register />} />
              <Route path="/AdminXodimList" element={<XodimlarList />} />
              <Route path="/AdminXodimList/add" element={<AddXodimlar />} />

              {/* <<<<<<<<<<<<<<<<<<<<   STUDENTS >>>>>>>>>>>>>>>>> */}
              <Route path="/AdminStudentList" element={<AdminStudentList />} />
              <Route
                path="/AdminStudentList/add"
                element={<AddAdminStudent />}
              />
              <Route path="/AdminStudentList/show/:id" element={<UserDetails />} />

              <Route
                path="/AdminStudentList/add/:id"
                element={<AddAdminStudent />}
              />

              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<< JOURNAL >>>>>>>>>>>>>>>>>>>>>>> */}

              <Route path="/journal" element={<Journals />} />
              <Route path="/journal/add" element={<AddJoournal />} />
              <Route path="/journal/show/:id" element={<DetailJurnal />} />
              <Route path="/jurnalmaqola" element={<JurnalMaqola />} />
              <Route path="/jurnalmaqola/add" element={<AddMaqola />} />
              <Route path="/jurnalSon" element={<JurnalSon />} />
              <Route path="/jurnalSon/add" element={<AddJurnalSoniList />} />
              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<< JOURNAL >>>>>>>>>>>>>>>>>>>>>>> */}

              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<< Kitb sorovlar >>>>>>>>>>>>>>>>>>>>>>> */}
              <Route
                path="/sorovlar"
                element={
                  <Sorovlar getMessagYuklabOlish={getMessagYuklabOlish} />
                }
              />
              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<< Kitb sorovlar >>>>>>>>>>>>>>>>>>>>>>> */}
              {/* <<<<<<<<<<<<<<<<<<<<   TakeBooks  >>>>>>>>>>>>>>>>> */}
              <Route path="/getbooks/*" element={<GetBooks />} />
              <Route path="/getbooks/attachuser/:id" element={<AttachUser />} />
              {/* <<<<<<<<<<<<<<<<<<<<   ReturnBooks  >>>>>>>>>>>>>>>>> */}
              <Route path="/returnbooks/*" element={<ReturnBooks />} />
              <Route
                path="/returnbooks/innerReturnBooks/:id"
                element={<InnnerReturnBooks />}
              />

              {/* <<<<<<<<<<<<<<<<<<<<   bandKitoblar  >>>>>>>>>>>>>>>>> */}
              <Route path="/bandKitoblar" element={<BandKitoblar />} />
              <Route
                path="/bandKitoblar/show/:id"
                element={<BandKitoblarShow />}
              />
            </Routes>
          }
        </div>
      </div>
    </div>
  );
};

export default AdminMain;
