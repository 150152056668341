/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// import "../Auth/Auth.css"
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BulimAction } from "../../redux/actions/Action";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddXodimlar = () => {

    const { id } = useParams();

    const [hemisId, setHemisId] = useState([])

    const [formValue, setFormValue] = useState({
        hemis_id: '',
        firstname: '',
        lastname: '',
        middlename: '',
        fakultet_id: '',
        kafedra_id: '',
        bulim_id: '',
        lavozim_id: '',
        date_birth: '',
        tel_nomer: '',
        telegram_link: '',
        jinsi: '',
        manzili: '',
        jshshir: '',
        passport: '',
        f_turi_id: 2,
        login: '',
        password: '',
        password_confirmation: '',
        role: 2,
        active: '',
        email: '',
        user_id: sessionStorage.getItem("userId")
    });

    const [rasm, setRasm] = useState('')
    const [passport_pd, setPassportPd] = useState('')

    const setInputData = (e) => {
        const { name, value } = e.target
        setFormValue({ ...formValue, [name]: value })
    }


    // student uchun >>>> /api/kutubxonachi/student-list?search=
    //  xodimlar uchun >>> /api/kutubxonachi/employee-list?search=3012412017

    const getHemisData = () =>{
        if (formValue.hemis_id) {
            axios.get(`${BaseUrl}/api/kutubxonachi/employee-list?search=${formValue.hemis_id}`, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                // console.log(res.data.data);
                // if (res.data.data.items.length === 1) {
                    setHemisId(res.data.data.items[0])
                // }
            }).catch(error => {
                console.log(error.message);
            })
        }
    }

    useEffect(() => {
        getHemisData()
    }, [formValue.hemis_id])

    // POST DATA:
// console.log("hemis data", hemisId.department.name);
    const postSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append("passport_pdf", passport_pd);
        formData.append("hemis_id", formValue.hemis_id);
        formData.append("firstname", hemisId.first_name);
        formData.append("lastname", hemisId.second_name);
        formData.append("middlename", hemisId.third_name);
        formData.append("date_birth", formValue.date_birth);
        formData.append("tel_nomer", formValue.tel_nomer);
        formData.append("telegram_link", formValue.telegram_link);
        formData.append("jinsi", hemisId.gender.name);
        formData.append("manzili", formValue.manzili);
        formData.append("jshshir", formValue.jshshir);
        formData.append("passport", formValue.passport);
        formData.append("f_turi_id", formValue.f_turi_id);
        formData.append("lavozim_id", formValue.lavozim_id);
        formData.append("login", formValue.login);
        formData.append("password", formValue.password);
        formData.append("password_confirmation", formValue.password_confirmation);
        formData.append("bulim_id", formValue.bulim_id);
        formData.append("fakultet_id", formValue.fakultet_id);
        formData.append("kafedra_id", formValue.kafedra_id);
        formData.append("role", formValue.role);
        formData.append("active", formValue.active);
        formData.append("email", formValue.email);
        formData.append("user_id", formValue.user_id);

        axios.post(BaseUrl + '/api/kutubxonachi/users', formData, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            if (res.status == 200) {
                toast.success(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    // EDIT DATA:

    const editSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();

        formData.append("passport_pdf", passport_pd);
        formData.append("rasm", rasm);
        formData.append("hemis_id", formValue.hemis_id);
        formData.append("firstname", hemisId.first_name);
        formData.append("lastname", hemisId.second_name);
        formData.append("middlename", hemisId.third_name);
        formData.append("date_birth", formValue.date_birth);
        formData.append("tel_nomer", formValue.tel_nomer);
        formData.append("telegram_link", formValue.telegram_link);
        formData.append("jinsi", hemisId.gender.name);
        formData.append("manzili", formValue.manzili);
        formData.append("jshshir", formValue.jshshir);
        formData.append("passport", formValue.passport);
        formData.append("f_turi_id", formValue.f_turi_id);
        formData.append("lavozim_id", formValue.lavozim_id);
        formData.append("login", formValue.login);
        formData.append("password", formValue.password);
        formData.append("password_confirmation", formValue.password_confirmation);
        formData.append("kursi", hemisId.level.name);
        formData.append("fakultet_id", formValue.fakultet_id);
        formData.append("yunalish_id", formValue.yunalish_id);
        formData.append("guruh_id", formValue.guruh_id);
        formData.append("uquv_yili_id", formValue.uquv_yili_id);
        formData.append("role", formValue.role);
        formData.append("active", formValue.active);
        formData.append("email", formValue.email);
        formData.append("user_id", formValue.user_id);

        axios.post(BaseUrl + '/api/kutubxonachi/users/edit/' + id, formData, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            },
            params: {
                _method: "PUT"
            }
        }).then(res => {
            if (res.status == 200) {
                toast.info(res.data.message);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    // GET DATA:

    useEffect(() => {
        if (id) {
            axios(BaseUrl + '/api/kutubxonachi/users/show/' + id, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                setFormValue({
                    ...formValue,
                    hemis_id: res.data.user.hemis_id,
                    date_birth: res.data.user.date_birth,
                    tel_nomer: res.data.user.tel_nomer,
                    telegram_link: res.data.user.telegram_link,
                    manzili: res.data.user.manzili,
                    jshshir: res.data.user.jshshir,
                    passport: res.data.user.passport,
                    login: res.data.user.login,
                    password: res.data.user.password_visible,
                    password_confirmation: res.data.user.password_visible,
                    email: res.data.user.email
                })
            }).catch(err => {
                // console.log(err);
            })
        }
    }, [])

    // For education form:

    const [position, setPosition] = useState([]);
    const [faculty, setFaculty] = useState([]);
    const [bulim, setBulim] = useState([]);
    const [kafedra, setKafedra] = useState([]);
    const [educationYear, setEducationYear] = useState([]);

    useEffect(() => {
        axios.get(BaseUrl + '/api/admin/lavozim/' + 1, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log("ma lavozim",res.data.lavozimlar);
            setPosition(res.data.lavozimlar)
        }).catch(err => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        axios.get(BaseUrl + '/api/admin/fakultet', {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            setFaculty(res.data.fakultetlar);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        axios.get(BaseUrl + "/api/admin/bulim", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log("mana bulim",res.data.bulimlar);
            setBulim(res.data.bulimlar);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        axios.get(BaseUrl + "/api/admin/kafedra", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log("Mana kafedra", res.data.kafedralar);
            setKafedra(res.data.kafedralar);
        }).catch(err => {
            console.log(err);
        })
    }, [formValue.yunalish_id])

    useEffect(() => {
        axios.get(BaseUrl + '/api/admin/uquvyili', {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            setEducationYear(res.data.uquvYillari)
        }).catch(err => {
            console.log(err);
        })
    }, [])

    // console.log(allData);

    const [checkingPisiton, setChekingPosition] = useState("")



    // console.log(checkingPisiton);

    return (
        <>
            <ToastContainer />
            <h2 className="mt-4">Ro'yxatga olish</h2>
            <hr />
            <div className="for_main_img">
                <div className="form_data d-flex" style={{ justifyContent: "space-around" }}>
                    <div style={{ width: "40%" }}>
                    <div className="mb-3 mt-2">
                            <label className="form-label">Toifani tanlang:</label>
                            <div>
                                <select onChange={(e)=>setChekingPosition(e.target.value)} className="form-select" name="" id="">
                                    <option disabled selected>Toifani tanlang</option>
                                    <option value="Xodim">Xodim</option>
                                    <option value="O'qituvchi">O'qituvchi</option>
                                </select>
                            </div>
                        </div>

                        <div className="mb-3 mt-3">
                            <label className="form-label">Hemis id:</label>
                            <div><input type="number" className="form-control" placeholder="Hemis id" name="hemis_id" value={formValue.hemis_id} onChange={setInputData} /></div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Ismi:</label>
                            <div><input type="text" className="form-control" placeholder="Ismi" name="firstname" value={hemisId && hemisId.first_name} onChange={setInputData} /></div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Familiyasi:</label>
                            <div><input type="text" className="form-control" placeholder="Familiyasi" name="lastname" value={hemisId && hemisId.second_name} onChange={setInputData} /></div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Otasini ismi:</label>
                            <div><input type="text" className="form-control" placeholder="Otasini ismi" name="middlename" value={hemisId && hemisId.third_name} onChange={setInputData} /></div>
                        </div>
                        {
                            checkingPisiton === "Xodim" ? <>
                            <div className="mb-3">
                            <label className="form-label">Bo'limi {hemisId?.department?.structureType.code == "13" ? `(${hemisId.department.name})` :"()"} :</label>
                            <div>
                                <select className="form-select" name="bulim_id" value={formValue.bulim_id} onChange={setInputData}>
                                    <option value="">Tanlang</option>
                                    {
                                        bulim && bulim.map((item, index) => (
                                            <option key={index} value={item.id}>{item.nomi}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Lavozimi :</label>
                            <div>
                                <select className="form-select" name="lavozim_id" value={formValue.lavozim_id} onChange={setInputData}>
                                    <option value="">Tanlang</option>
                                    {
                                        position && position.map((item, index) => (
                                            <option key={index} value={item.id}>{item.nomi}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                            </> : 
                            <>
                            <div className="mb-3">
                            <label className="form-label">Fakultet {hemisId?.department?.structureType.code == "11" ? `(${hemisId.department.name})` :"()"} :</label>
                            <div>
                                <select className="form-select" name="fakultet_id" value={formValue.fakultet_id} onChange={setInputData}>
                                    <option value="">Tanlang</option>
                                    {
                                        faculty && faculty.map((item, index) => (
                                            <option key={index} value={item.id}>{item.nomi}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Kafedrasi {hemisId?.department?.structureType.code == "12" ? `(${hemisId.department.name})` :"()"}:</label>
                            <div>
                                <select className="form-select" name="kafedra_id" value={formValue.kafedra_id} onChange={setInputData}>
                                    <option value="">Tanlang</option>
                                    {
                                        kafedra && kafedra.map((item, index) => (
                                            <option key={index} value={item.id}>{item.nomi}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                            </>
                        }
                        <div className="mb-3">
                            <label className="form-label">Tug'ilgan sana:</label>
                            <div><input type="date" className="form-control" placeholder="Tug'ilgan sana" name="date_birth" value={formValue.date_birth} onChange={setInputData} /></div>
                        </div>
                       
                        <div className="mb-3">
                            <label className="form-label">Telefon no'mer:</label>
                            <div><input type="text" className="form-control" placeholder="Telefon no'mer" name="tel_nomer" value={formValue.tel_nomer} onChange={setInputData} /></div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Telegram:</label>
                            <div><input type="text" className="form-control" placeholder="Telegram" name="telegram_link" value={formValue.telegram_link} onChange={setInputData} /></div>
                        </div>
                    </div>

                    <div style={{ width: "40%" }}>
                    <div className="mb-3">
                            <label htmlFor="pwd" className="form-label">J.SH.SH.I.R:</label>
                            <div><input onChange={setInputData} type="number" className="form-control" placeholder="J.SH.SH.I.R" name="jshshir"  value={formValue.jshshir}/></div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Jinsi:</label>
                            <div><input type="text" className="form-control" placeholder="Jinsi" name="jinsi" value={hemisId?.gender && hemisId.gender.name} onChange={setInputData} /></div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Manzil:</label>
                            <div><input type="text" className="form-control" placeholder="Manzil" name="manzili" value={formValue.manzili} onChange={setInputData} /></div>
                        </div>
                        <div className="mb-3 mt-3">
                            <label className="form-label">Pasprot PDF:</label>
                            <div><input type="file" className="form-control" placeholder="Pasprot PDF" name="passport_pdf" onChange={(e) => setPassportPd(e.target.files[0])} /></div>
                        </div>

                        <div className="mb-3">
                            {/* <label className="form-label">Foydalanuvchi turi:</label> */}
                            <div><input type="text" className="form-control" placeholder="Talaba" name="f_turi_id" value={formValue.f_turi_id} onChange={setInputData} hidden /></div>
                            {/* Foydalanuvchi turi - f_turi_id: foydalanuvchi turini value si */}
                        </div>

                        <div className="mb-3 mt-3">
                            <label className="form-label">Login:</label>
                            <div>
                                {
                                    id ? <input type="text" className="form-control" placeholder="Login" name="login" value={formValue.login} onChange={setInputData} disabled />
                                        : <input type="text" className="form-control" placeholder="Login" name="login" value={formValue.login} onChange={setInputData} />
                                }

                            </div>
                        </div>

                        <div className="mb-3 mt-3">
                            <label className="form-label">Parol (kamida bitta katta harf va raqam bo'lish shart):</label>
                            <div>
                                {
                                    id ? <input type="text" className="form-control" placeholder="Parol" name="password" value={formValue.password} onChange={setInputData} disabled />
                                        : <input type="text" className="form-control" placeholder="Parol" name="password" value={formValue.password} onChange={setInputData} />
                                }
                            </div>
                        </div>

                        <div className="mb-3 mt-3">
                            <label className="form-label">Parol tasdig'i:</label>
                            <div>
                                {
                                    id ? <input type="text" className="form-control" placeholder="Parol tasdig'i" name="password_confirmation" value={formValue.password_confirmation} onChange={setInputData} disabled /> :
                                        <input type="text" className="form-control" placeholder="Parol tasdig'i" name="password_confirmation" value={formValue.password_confirmation} onChange={setInputData} />

                                }
                            </div>
                        </div>

                        <div className="mb-3 mt-3">
                            <label className="form-label">Foydalanuvchining rasmi:</label>
                            <div><input type="file" className="form-control" placeholder="Foydalanuvchining rasmi" name="rasm" onChange={(e) => setRasm(e.target.files[0])} /></div>
                        </div>

                        

                        <div className="mb-3 mt-3">
                            <label className="form-label">Gmail:</label>
                            <div><input type="text" className="form-control" placeholder="Gmail" name="email" value={formValue.email} onChange={setInputData} /></div>
                        </div>

                        <div className="mb-3 mt-3">
                            {/* <label className="form-label">Role (Kitobxon):</label> */}
                            <div><input type="number" className="form-control" placeholder="Kitobxon" name="role" value={formValue.role} onChange={setInputData} hidden /></div>
                            {/* value = 2 */}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Holati:</label>
                            <div>
                                <select className="form-select" name="active" value={formValue.active} onChange={setInputData}>
                                    <option value="">Tanlang</option>
                                    <option value="1">Faol</option>
                                    <option value="0">Faol emas</option>
                                </select>
                            </div>
                        </div>

                        <div className="mt-4" style={{ display: "flex", justifyContent: "end" }}>
                            {
                                id ? <button type="submit" className="btn btn-primary d-flex justify-content-end mt-4 mb-5" onClick={editSubmit}>
                                    O'zgartirish
                                </button> :
                                    <button type="submit" className="btn btn-primary d-flex justify-content-end mt-4 mb-5" onClick={postSubmit}>Qo'shish</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddXodimlar;