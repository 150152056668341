import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../../../../../Super_admin/BaseUrl";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { CiSquareRemove } from "react-icons/ci" 
import { HiOutlineCheckCircle } from "react-icons/hi";


const KitobshartnomaBiriktrish = () => {
    const [sumerkaSelectData, setSumerkaSelectData] = useState([]);
    const [sumerkalarTableData, setSumerkalarTableData] = useState("");
    const [sumerka_id, setSumerka_id] = useState("");
    const [editeSumId, setEditSumId] = useState("");
    const [text, setText] = useState("");
  // console.log("kitobId", kitob_id.id);

  const { id } = useParams();

  const notify = () => {
    if (text === "") {
    } else if (text === "Kitob Sumerka shartnomasiga biriktirildi.") {
      toast.success(text);
    } else if (text === "Kitob Sumerka shartnomasiga qayta biriktirildi.") {
      toast.success(text);
    } else if (text === "Kitob-sumerka biriktirish o'chirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };
  useEffect(() => {
    notify();
  }, [text]);
  // select uchun get


 // kitob sumerka
 const getSumerkaSelect = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/sumerka", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data.sumerkalar);
        setSumerkaSelectData(res.data.sumerkalar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addSumerka = () => {
    // window.location.reload();
    if (editeSumId) {
      axios
        .put(
          BaseUrl + "/api/kutubxonachi/kitobsumerka/edit/" + editeSumId,
          {
            sumerka_id,
            kitob_id: id,
            user_id: sessionStorage.getItem("userId"),
          },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data.message);
          if (res.status == 200) {
            setText(res.data.message);
            setEditSumId("");
            getSumerka();
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          setText(err.response.data.message);
          setText(err.response.data.errors);
        });
    } else {
      axios
        .post(
          BaseUrl + "/api/kutubxonachi/kitobsumerka",
          {
            sumerka_id,
            kitob_id:id,
            user_id: sessionStorage.getItem("userId"),
          },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          if (res.status == 200) {
            setText(res.data.message);
            setSumerka_id("");
            getSumerka();
          }
        })
        .catch((err) => {
          console.log(err.response.data.errors);
          setText(err.response.data.errors);
        });
    }
  };

  const getSumerka = () => {
    axios
      .get(BaseUrl + `/api/kutubxonachi/kitobsumerka/index/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        setSumerkalarTableData(res.data.sumerkalar);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSumerka()
    getSumerkaSelect();
  }, []);

  const deleteItem = (id) => {
    axios
      .delete(BaseUrl + `/api/kutubxonachi/kitobsumerka/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.message);
        if (res.status == 200) {
          getSumerka();
          setText(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <p className="fs-5 text-muted">
      Kitobni shartnomaga biriktrish
      </p>
      <div className="col-md-4 col-sm-12">
            <label className="mt-3" htmlFor="">
            qayerdan kelgan kelgan yili
            </label>
            <select
              className="form-select"
              name=""
              id=""
              onChange={(e) => setSumerka_id(e.target.value)}
            >
              <option value="Tanlang">Tanlang</option>
              {sumerkaSelectData &&
                sumerkaSelectData.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.sumarka_raqam} - {item.kelgan_sana}
                    </option>
                  );
                })}
            </select>
            <span className="d-flex flex-row-reverse ">
              <button className="btn btn-primary mt-3" onClick={addSumerka}>
                {editeSumId ? "O'zgartrish" : "Yaratish"}
              </button>
            </span>
          </div>

          <div class="table-responsive">
              <table class="table align-middle table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Qayerdan kelgan</th>
                    <th scope="col">Kelgan yili</th>
                    <th scope="col">Summarka Raqam</th>
                    <th scope="col">Narxi (Shartnomadagi narxi)</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sumerkalarTableData &&
                    sumerkalarTableData.map((item, index) => {
                      return (
                        <tr>
                          {/* {console.log(item)} */}
                          <td>{item.id}</td>
                          <td>{item.qayerdan_kelgan}</td>
                          <td>{item.kelgan_sana}</td>
                          <td>{item.sumarka_raqam}</td>
                          <td>{item.narxi}</td>
                          <td>
                            <div className="main_edite-box">
                              <div className="edite_show_box">
                                <div
                                  className="edite"
                                  onClick={(e) => setEditSumId(item.id)}
                                >
                                  <AiOutlineEdit />{" "}
                                </div>
                                <div
                                  className="delete"
                                  onClick={() => deleteItem(item.id)}
                                >
                                  <MdOutlineDelete />{" "}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
    </>
  );
};

export default KitobshartnomaBiriktrish;
