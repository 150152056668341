import React from "react";

const XorijiyAxborot = () => {
  return (
    <div className="container py-3">
      <h3 className="text-center">
        Xorijiy axborot-kutubxona resurslari bilan ishlash bo‘limi
      </h3>

      <p className="m-0 mt-2">Bo‘limning asosiy vazifasi:</p>
      <ul>
        <li>
          Axborot texnologiyalarilari asosida elektron kutubxona yaratish uchun
          adabiyotlarga biliografik ishlov, tavsif berish;
        </li>
        <li>
          Xorijiy OTMlardan keltirilgan adabiyotlarni kutubxona fondiga kirim
          qilish va kitobxonlarning foydalanishlarini tashkil qilish;
        </li>
        <li>
          Xorijiy davlatlar va donor tashkilotlar tomonidan beg‘araz ko‘mak
          sifatida berilgan adabiyotlardan kitobxonlarning faol foydalanishlari
          bo‘yicha chora-tadbirlar rejasini ishlab chiqish;
        </li>
        <li>
          OTM professor-o‘qituvchilari tomonidan xorijiy jurnallarda nashr
          etilgan maqolalarning elektron fondini tashkil etish;
        </li>
        <li>
          “Ebscohost”, “Scopus”, “ScienceDirect”, “Web of Science”, “Springer”
          va boshqa xalqaro ilmiy-ta’limiy ma’lumotlar bazalaridan foydalanish
          ko‘nikmalarini shakllantirish;
        </li>
        <li>
          Bo‘limga yangi keltirilgan adabiyotlarni elektron shaklga o‘tkazish
          faoliyatini nazorat qilib borish;
        </li>
        <li>
          Foydalanuvchilarni ro‘yxatga olish va foydalanish ko‘rsatkichlarini
          nazorat qilish.
        </li>
      </ul>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/xorijiy1.png")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
        Andijon davlat universitetining axborot-resurs markazi kitob fondiga
        professor-o‘qituvchi va talabalarni ingliz-tilini o‘rganish uchun qulay
        bo‘lgan zamonaviy adabiyotlarni Amerika qo‘shma shtatlarining
        O‘zbekistondagi elchixonasi vakili tomonidan 216 nomda 471 nusxa 12.823$
        dollarlik har xil o‘quv adabiyotlar va boshqa turdagi adabiyotlar sovg‘a
        qilingan.
      </p>
      <div className="row mt-2">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/xorijiy2.jpg")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
        Andijon davlat universitetining axborot-resurs markazi kitob fondiga
        professor-o‘qituvchi va talabalarni ingliz-tilini o‘rganish uchun qulay
        bo‘lgan zamonaviy adabiyotlarni Amerika qo‘shma shtatlarining
        O‘zbekistondagi elchixonasi vakili tomonidan 216 nomda 471 nusxa 12.823$
        dollarlik har xil o‘quv adabiyotlar va boshqa turdagi adabiyotlar sovg‘a
        qilingan.
      </p>
      <div className="row mt-2">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/xorijiy3.jpg")}
            alt=""
          />
        </div>
      </div>

      <p className="m-0 mt-2">
        Shuningdek bo‘lim boshlig‘i A.Aliyev tomonidan
        <a href="http://kutubxona.adu.uz" target="_blank">
          <span style={{color:"blue"}}> http://kutubxona.adu.uz,</span>
        </a>
        <a href="https://t.me/aduarmbot" target="_blank">
          <span style={{color:"blue"}}> https://t.me/aduarmbot</span>
        </a>

        <a href="https://aliyev.uz" target="_blank">
          <span style={{color:"blue"}}> https://aliyev.uz </span>
        </a>
        interaktiv elektron kutubxona
        platformalari tashkil etilib barcha turdagi xorijiy o‘quv va ilmiy
        adabiyotlar bazasi shakllantirilgan.
      </p>
    </div>
  );
};

export default XorijiyAxborot;
