import React, { useState } from "react";
import "../main/main.css";
import { FiUsers } from "react-icons/fi";
import { MdAccountBalance, MdAccountBalanceWallet } from "react-icons/md";
import { LiaUniversitySolid } from "react-icons/lia";
import { AiOutlineLogout } from "react-icons/ai";
import { RxHamburgerMenu } from 'react-icons/rx';
import { BsBook } from "react-icons/bs";
import { BsFillClipboardFill } from "react-icons/bs";
import { MdOutlineBook } from "react-icons/md";
import { Route, Routes, useNavigate } from "react-router-dom";
import UniversityStructureBulim from "./UniversityStructure/UniversityStructureBulim";
import { Link } from "react-router-dom";
import UniversityStructureFaculty from "./UniversityStructure/UniversityStructureFaculty";
import UniversityStructureKafedra from "./UniversityStructure/UniversityStructureKafedra";
import UniversityStructureGroup from "./UniversityStructure/UniversityStructureGroup";
import AddUniversityKafedra from "./UniversityStructure/AddUiversityKafedra";
import AddUniversityFakultet from "./UniversityStructure/AddUniversityFakultet";
import AddUniversityBulim from "./UniversityStructure/AddUniversityBulim";
import UserType from "./Users/UserType";
import UserLavozimi from "./Users/UserLavozimi";
import UserAddLavozim from "./Users/UserAddLavozim";
import UsersList from "./Users/UsersList";
import Register from "../Auth/RegisterAdmin";
import UniversityYunalish from "./UniversityStructure/UniversityYunalish";
import AddUniversityYunalish from "./UniversityStructure/AddUniversityYunalish";
import AddUniversityGroup from "./UniversityStructure/AddUniversityGroup";
import UserDetail from "./Users/UserDetail";
import UserUquvYili from "./Users/UserUquvYili";
import AddUquvYili from "./Users/AddUquvYili";
import UniversityKutubxona from "./UniversityStructure/UniversityKutubxona";
import AddUniversityKutubxona from "./UniversityStructure/AddUniversityKutubxona";
import { BaseUrl } from "../BaseUrl";
import UniversityDavlatList from "./UniversityStructure/UniversityDavlatList";
import AddUniversityDavlat from "./UniversityStructure/AddUniversityDavlat";
import UniversityShahar from "./UniversityStructure/UniversityShahar";
import AddUniversityShahar from "./UniversityStructure/AddUniversityShahar";
import Book_type from "../../admin/Books/Book_type/Book_type";
import Book_typeAdd from "../../admin/Books/Book_type/Book_typeAdd";
import Science_field from "../../admin/Books/Science_field/Science_field";
import Science_fieldAdd from "../../admin/Books/Science_field/Science_fieldAdd";
import Book_direction from "../../admin/Books/Book_direction/Book_direction";
import Book_directionAdd from "../../admin/Books/Book_direction/Book_directionAdd";
import Book_Language from "../../admin/Books/Book_Language/Book_Language";
import Book_LanguageAdd from "../../admin/Books/Book_Language/Book_LanguageAdd";
import Book_entry from "../../admin/Books/Book_Entry/Book_Entry";
import Book_EntryAdd from "../../admin/Books/Book_Entry/Book_EntryAdd";
import Text_type from "../../admin/Books/Text_type/Text_type";
import Text_typeAdd from "../../admin/Books/Text_type/Text_typeAdd";
import File_type from "../../admin/Books/File_type/File_type";
import File_typeAdd from "../../admin/Books/File_type/File_typeAdd";
import Categoryy from "../../admin/Books/Category/Category";
import CategoryAdd from "../../admin/Books/Category/CategoryAdd";
import Books_collection from "../../admin/Books/Books_collection/Books_collection";
import Books_collectionAdd from "../../admin/Books/Books_collection/Books_collectionAdd";
import Books_collectionDetail from "../../admin/Books/Books_collection/Books_collectionDetail/Books_collectionDetail";
import Inventar from "../../admin/Books/Books_collection/Books_collectionDetail/KitobBolimBiriktrish/Inventar/Inventar";
import Sumerka from "../../admin/Books/Sumerka/Sumerka";
import SumerkaAdd from "../../admin/Books/Sumerka/SumerkaAdd";
import SumerkaDetail from "../../admin/Books/Sumerka/SumerkaDetail/SumerkaDetail";
import IlmiyNashr from "../../admin/IlmiyNashr/IlmiyNashr";
import AddIlmiyNashr from "../../admin/IlmiyNashr/AddIlmishNashr";
import IlmiyNashrTuri from "../../admin/IlmiyNashr/IlmiyNashrTuri";
import AddIlmiyNashrTuri from "../../admin/IlmiyNashr/AddIlmiyNashrTuri";
import IlmiyNashrDetail from "../../admin/IlmiyNashr/IlmiyNashrDetail";
import Journals from "../../admin/Journals/Journals/Journals";
import AddJoournal from "../../admin/Journals/Journals/AddJournal";
import AdminStudentList from "../../admin/AdminUsers/AdminStudentList";
import JurnalMaqola from "../../admin/Journals/JurnalMaqola/JurnalMaqola";
import AddMaqola from "../../admin/Journals/JurnalMaqola/AddMaqola";
import JournalSon from "../../admin/Journals/JurnalSon/JurnalSon";
import AddJurnalSoniList from "../../admin/Journals/JurnalSon/AddJurnalSoniList";
import DetailJurnal from "../../admin/Journals/Journals/DetailJurnal";
import TavsiyaetilganKitob from "./TavsiyaetilganKitob/TavsiyaetilganKitob";
import DetailJurnalMaqola from "../../admin/Journals/JurnalMaqola/DetailJurnalMaqola";
import Toplam from "../../admin/Journals/Toplam/Toplam";
import AddToplam from "../../admin/Journals/Toplam/AddToplam";
import DetailToplam from "../../admin/Journals/Toplam/DetailToplam";
import axios from "axios";
import AddAdminStudent from "../../admin/AdminUsers/AddAdminStudent";
import Maqsad from "./UniversityStructure/Maqsad";
import AddMaqsad from "./UniversityStructure/AddMaqsad";
import BulimXona from "./UniversityStructure/BulimXona";
import AddBulimXona from "./UniversityStructure/AddBulimXona";
import Tashrif from "./Statistika/Tashrif/Tashrif";
import TashrifShow from "./Statistika/Tashrifshow/TashrifShow"

const Main = () => {
  const [text, setText] = useState("");
  // FiUsers

  const navigate = useNavigate();

  const LogOut = () => { 
    axios
      .post(
        BaseUrl + "/api/logout",{},{
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
          console.log(res.data.message);
        if (res?.status == 200) {
          setText(res?.data?.message);
          sessionStorage.clear()
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("firstname");
          sessionStorage.removeItem("lastname");
          sessionStorage.removeItem("bulim_id");
          sessionStorage.removeItem("fakultet_id");
          sessionStorage.removeItem("kafedra_id");
          sessionStorage.removeItem("lovozim_id");
          sessionStorage.removeItem("role");
          sessionStorage.removeItem("uquv_yili_id");
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setText(err?.response?.data?.errors);
        if (err.response.status == 401) {
          sessionStorage.clear()
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("firstname");
          sessionStorage.removeItem("lastname");
          sessionStorage.removeItem("bulim_id");
          sessionStorage.removeItem("fakultet_id");
          sessionStorage.removeItem("kafedra_id");
          sessionStorage.removeItem("lovozim_id");
          sessionStorage.removeItem("role");
          sessionStorage.removeItem("uquv_yili_id");
          navigate("/");
      }
      });
  };

  const [toggle1, setToggle1] = useState(false);
  // console.log(toggle1);

  

  const Toggle = () => {
    setToggle1(!toggle1);
  };
  
  return (
    <div className="main-container">
      <div className={toggle1 ? "side-bar2" : "side-bar shadow"}>
        <div className="for-logo">
          <img
            style={{ width: "80px", height: "80px", borderRadius: "50%" }}
            src="../images/armlogo.png"
            alt=""
          />
        </div>
        <div className="accordion accordion" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseZero"
                aria-expanded="false"
                aria-controls="flush-collapseZero">
                <LiaUniversitySolid
                  style={{ margin: "0 10px 0 0", fontSize: "23px" }}
                />
                Universitet tuzilmalari
              </button>
            </h2>
            <div
              id="flush-collapseZero"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <Link to="/Supermain/bulim">
                  <div className="menus">Bo'lim</div>
                </Link>
                <Link to="/Supermain/faculty">
                  <div className="menus">Fakultetlar</div>
                </Link>
                <Link to="/Supermain/kafedra">
                  <div className="menus">Kafedralar</div>
                </Link>
                <Link to="/Supermain/yunalish">
                  <div className="menus">Yo'nalishlar</div>
                </Link>
                <Link to="/Supermain/group">
                  <div className="menus">Guruhlar</div>
                </Link>
                <Link to="/Supermain/kutubxona">
                  <div className="menus">Kutubxona bo'limlari</div>
                </Link>
                <Link to="/Supermain/maqsad">
                  <div className="menus">Xizmat turi</div>
                </Link>
                <Link to="/Supermain/Bulim hona">
                  <div className="menus">Bo'lim xonasi</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne">
                <FiUsers style={{ margin: "0 10px 0 0", fontSize: "23px" }} />{" "}
                Foydalanuvchilar
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <Link to="/Supermain/usertype">
                  <div className="menus">Foydalanuvchilar turi</div>
                </Link>
                <Link to="/Supermain/userlist">
                  <div className="menus">Adminlar ro'yxati</div>
                </Link>
                <Link to="/Supermain/AdminStudentList">
                  <div className="menus">Talabalar ro'yxati</div>
                </Link>
                
                <Link to="/Supermain/userlavozim">
                  <div className="menus">Toifasi</div>
                </Link>
                <Link to="/Supermain/uquvYili">
                  <div className="menus">O'quv yili</div>
                </Link>
                {/* <Link to="/adminMain/AdminUserList">
                  <div className="menus">Foydalanuvchilar ro'yxati</div>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo">
                <BsBook style={{ margin: "0 10px 0 0", fontSize: "23px" }} />{" "}
                Kitoblar
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <Link to="/Supermain/book_type">
                  <div className="menus"> Kitob turi</div>
                </Link>
                <Link to="/Supermain/science_field">
                  <div className="menus"> Fan sohasi</div>
                </Link>
                <Link to="/Supermain/book_direction">
                  <div className="menus"> Kitob yo'nalishi</div>
                </Link>
                <Link to="/Supermain/book_language">
                  <div className="menus">Kitob tili</div>
                </Link>
                <Link to="/Supermain/book_entry">
                  <div className="menus">Kitob yozuvi</div>
                </Link>
                <Link to="/Supermain/text_type">
                  <div className="menus">Resurs turi</div>
                </Link>
                <Link to="/Supermain/file_type">
                  <div className="menus">Fayl turi</div>
                </Link>
                <Link to="/Supermain/category">
                  <div className="menus">Toifasi</div>
                </Link>
                <Link to="/Supermain/books_collection">
                  <div className="menus">Kitoblar</div>
                </Link>
                <Link to="/Supermain/sumerka">
                  <div className="menus">Shartnomalar</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree">
                <MdOutlineBook
                  style={{ margin: "0 10px 0 0", fontSize: "23px" }}
                />
                Jurnallar
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <Link to="/Supermain/journal">
                  <div className="menus"> Jurnallar</div>
                </Link>
                <Link to="/Supermain/jurnalSon">
                  <div className="menus"> Jurnallar soni</div>
                </Link>
                <Link to="/Supermain/jurnalmaqola">
                  <div className="menus"> Jurnal maqolalari</div>
                </Link>
                {/* <Link to="/Supermain/jurnalmaqola">
                  <div className="menus">Maqolalar</div>
                </Link> */}
                <Link to="/Supermain/toplam">
                  <div className="menus">To'plamlar</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive">
                <MdOutlineBook
                  style={{ margin: "0 10px 0 0", fontSize: "23px" }}
                />
                Statistika
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <Link to="/Supermain/tashrif">
                  <div className="menus"> Tashrif statistikasi</div>
                </Link>
               
                {/* <Link to="/Supermain/toplam">
                  <div className="menus">To'plamlar</div>
                </Link> */}
              </div>
            </div>
          </div>
        </div>

        <Link to="/Supermain/tavsiya-etilgan-kitob">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <BsBook style={{ margin: "0 10px 0 0", fontSize: "23px" }} />
            Tavsiya etilgan kitoblar
          </div>
        </Link>

        <Link to="/Supermain/ilmiynashr">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <BsFillClipboardFill

              style={{ margin: "0 10px 0 0", fontSize: "23px" }} />
            Ilmiy nashrlar
          </div>
        </Link>
        <Link to="/Supermain/ilmiynashrturi">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <BsFillClipboardFill
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />
            Ilmiy nashr turi
          </div>
        </Link>
        <Link to="/Supermain/davlat">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <MdAccountBalance
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />
            Davlat
          </div>
        </Link>
        <Link to="/Supermain/shahar">
          <div
            className="border"
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "0 19px",
              cursor: "pointer",
            }}>
            <MdAccountBalanceWallet
              style={{ margin: "0 10px 0 0", fontSize: "23px" }}
            />
            Shahar
          </div>
        </Link>
       
      </div>



      <div className={"content"}>
        <div className="content-header">
          <div className="for_header">
            <div>
              <RxHamburgerMenu className="Navbbar" onClick={Toggle} />
            </div>
            <div className="for-profile">
              <div
                className="dropdown-toggle dropdown"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <img
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                  src={
                    sessionStorage.getItem("rasm") == null ||
                      sessionStorage.getItem("rasm") == "null"
                      ? "/images/avatar.png"
                      : BaseUrl + "/storage/" + sessionStorage.getItem("rasm")
                  }
                  alt=""
                />
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      {sessionStorage.getItem("firstname")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {sessionStorage.getItem("lastname")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      RTTR Markazi
                    </a>
                  </li>
                  <li onClick={() => LogOut()}>
                    <a
                      className="dropdown-item"
                      href="#"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "red",
                        color: "white",
                      }}>
                      <AiOutlineLogout
                        style={{
                          color: "white",
                          fontSize: "25px",
                          margin: " 0 10px 0 0",
                        }}
                      />{" "}
                      <p
                        style={{
                          color: "white",
                          marginTop: "15px",
                          fontSize: "18px",
                        }}>
                        Chiqish
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div><div className="content-body">
          {
            <Routes>
              {/* Universitet tuzilmalari */}
              <Route path="/maqsad" element={<Maqsad />} />
              <Route path="/maqsad/add" element={<AddMaqsad />} />
              <Route path="/maqsad/add/:id" element={<AddMaqsad />} />
              <Route path="/bulim hona" element={<BulimXona />} />
              <Route path="/bulim hona add" element={<AddBulimXona />} />
              <Route path="/bulim hona add/:id" element={<AddBulimXona />} />
              <Route path="/bulim" element={<UniversityStructureBulim />} />
              <Route path="/bulim/add" element={<AddUniversityBulim />} />
              <Route path="/bulim/add/:id" element={<AddUniversityBulim />} />
              <Route path="/faculty" element={<UniversityStructureFaculty />} />
              <Route path="/kafedra" element={<UniversityStructureKafedra />} />
              <Route path="/kafedra/add" element={<AddUniversityKafedra />} />\
              <Route path="/kafedra/add/:id" element={<AddUniversityKafedra />} />
              <Route path="/faculty/add" element={<AddUniversityFakultet />} />
              <Route path="/faculty/add/:id"  element={<AddUniversityFakultet />} />
              <Route path="/group" element={<UniversityStructureGroup />} />
              <Route path="/group/add" element={<AddUniversityGroup />} />
              <Route path="/group/add/:id" element={<AddUniversityGroup />} />
              <Route path="/yunalish" element={<UniversityYunalish />} />
              <Route path="/yunalish/add" element={<AddUniversityYunalish />} />
              <Route path="/yunalish/add/:id" element={<AddUniversityYunalish />} />
              <Route path="/kutubxona" element={<UniversityKutubxona />} />
              <Route path="/kutubxona/add" element={<AddUniversityKutubxona />} />
              <Route path="/kutubxona/add/:id" element={<AddUniversityKutubxona />} />
              <Route path="/davlat" element={<UniversityDavlatList />} />
              <Route path="/davlat/add" element={<AddUniversityDavlat />} />
              <Route path="/shahar" element={<UniversityShahar />} />
              <Route path="/shahar/add" element={<AddUniversityShahar />} />
              {/* User component */}
              <Route path="/usertype" element={<UserType />} />
              <Route path="/userlavozim" element={<UserLavozimi />} />
              <Route path="/userlavozim/add" element={<UserAddLavozim />} />
              <Route path="/userlavozim/add/:id" element={<UserAddLavozim />} />
              <Route path="/userlist" element={<UsersList />} />
              <Route path="/AdminStudentList" element={<AdminStudentList />} />
              <Route path="/AdminStudentList/add" element={<AddAdminStudent />} />
              <Route path="/userlist/detail" element={<UserDetail />} />
              <Route path="/userRegister/add" element={<Register />} />
              <Route path="/userRegister/add/:id" element={<Register />} />
              <Route path="/uquvYili" element={<UserUquvYili />} />
              <Route path="/uquvYili/add" element={<AddUquvYili />} />
              <Route path="/uquvYili/add/:id" element={<AddUquvYili />} />

              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<< BOOK >>>>>>>>>>>>>>>>>>>>>>>>>> */}
              <Route path="/book_type/*" element={<Book_type />} />
              <Route path="/book_type/add" element={<Book_typeAdd />} />
              <Route path="/book_type/add/:id" element={<Book_typeAdd />} />
              <Route path="/science_field/*" element={<Science_field />} />
              <Route path="/science_field/add" element={<Science_fieldAdd />} />
              <Route path="/science_field/add/:id" element={<Science_fieldAdd />} />
              <Route path="/book_direction/*" element={<Book_direction />} />
              <Route path="/book_direction/add" element={<Book_directionAdd />} />
              <Route path="/book_direction/add/:id" element={<Book_directionAdd />} />
              <Route path="/book_language/*" element={<Book_Language />} />
              <Route path="/book_language/add" element={<Book_LanguageAdd />} />
              <Route path="/book_language/add/:id" element={<Book_LanguageAdd />} />
              <Route path="/book_entry/*" element={<Book_entry />} />
              <Route path="/book_entry/add" element={<Book_EntryAdd />} />
              <Route path="/book_entry/add/:id" element={<Book_EntryAdd />} />
              <Route path="/text_type/*" element={<Text_type />} />
              <Route path="/text_type/add" element={<Text_typeAdd />} />
              <Route path="/text_type/add/:id" element={<Text_typeAdd />} />
              <Route path="/file_type/*" element={<File_type />} />
              <Route path="/file_type/add" element={<File_typeAdd />} />
              <Route path="/file_type/add/:id" element={<File_typeAdd />} />
              <Route path="/category/*" element={<Categoryy />} />
              <Route path="/category/add" element={<CategoryAdd />} />
              <Route path="/category/add/:id" element={<CategoryAdd />} />
              <Route path="/books_collection/*" element={<Books_collection />} />
              <Route path="/books_collection/add" element={<Books_collectionAdd />} />
              <Route path="/books_collection/add/:id" element={<Books_collectionAdd />} />
              <Route path="/books_collection/detail/:id/*" element={<Books_collectionDetail />} />
              <Route path="/books_collection/detail/:id/:id" element={<Inventar />} />
              <Route path="/sumerka/*" element={<Sumerka />} />
              <Route path="/sumerka/add" element={<SumerkaAdd />} />
              <Route path="/sumerka/add/:id" element={<SumerkaAdd />} />
              <Route path="/sumerka/show/:id" element={<SumerkaDetail />} />
              <Route path="/ilmiynashr" element={<IlmiyNashr />} />
              <Route path="/ilmiynashr/add" element={<AddIlmiyNashr />} />
              <Route path="/ilmiynashr/show/:id" element={<IlmiyNashrDetail />} />
              <Route path="/ilmiynashrturi" element={<IlmiyNashrTuri />} />
              <Route path="/ilmiynashrturi/add" element={<AddIlmiyNashrTuri />} />
              <Route path="/ilmiynashrturi/add/:id" element={<AddIlmiyNashrTuri />} />
              <Route path="/tavsiya-etilgan-kitob" element={<TavsiyaetilganKitob />} />
              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<< BOOK >>>>>>>>>>>>>>>>>>>>>>>>>> */}
   

              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<< JOURNAL >>>>>>>>>>>>>>>>>>>>>>> */}

              <Route path="/journal" element={<Journals />} />
              <Route path="/journal/add" element={<AddJoournal />} />
              <Route path="/journal/show/:id" element={<DetailJurnal />} />
              <Route path="/jurnalmaqola" element={<JurnalMaqola />} />
              <Route path="/jurnalmaqola/add" element={<AddMaqola />} />
              <Route path="/jurnalmaqola/show/:id" element={<DetailJurnalMaqola />} />
              <Route path="/jurnalSon" element={<JournalSon />} />
              <Route path="/jurnalSon/add" element={<AddJurnalSoniList />} />
              <Route path="/jurnalSon/show/:id" element={<AddJurnalSoniList />} />

              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<< JOURNAL >>>>>>>>>>>>>>>>>>>>>>> */}

              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<< Toplam >>>>>>>>>>>>>>>>>>>>>>> */}
              <Route path="/toplam" element={<Toplam />} />
              <Route path="/toplam/add" element={<AddToplam />} />
              <Route path="/toplam/show/:id" element={<DetailToplam/>} />
              {/* <<<<<<<<<<<<<<<<< Statistika >>>>>>>>>>>>>>>>>>>> */}
              <Route path="/tashrif" element={<Tashrif/>} />
              <Route path="/tashrif/show/:id" element={<TashrifShow/>} />
            </Routes>
          }
        </div>
      </div>
    </div>
  );
};

export default Main;
