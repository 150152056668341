import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../Auth/Auth.css"
import { BaseUrl } from "../BaseUrl";
import axios from "axios";
import { Oval } from 'react-loader-spinner'
import { FaUser } from "react-icons/fa6";
import { BsEyeFill , BsEyeSlashFill } from "react-icons/bs";
const Login = () => {

    const navigate = useNavigate()
    const [hidePassword, setHidePassword] = useState(false)

    // email.trim().length>0 && password.trim().length>0 ()=>navigate("/main")

    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [text, setText] = useState(false)
    const [text1, setText1] = useState(false)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        notify();
    }, [text, text1]);

    const notify = () => {
        if (text === "", text1 === "") { } else text ? toast.error(text) : toast.error(text1);
        setText("")
        setText1("")
    };

    // console.log(window.location.href);

    const LoginAuth = e => {
        e.preventDefault();
        setLoading(true)
        if (login.trim().length > 0 && password.trim().length > 0) {
            axios.post(BaseUrl + "/api/login", { login, password }).then(res => {
                // console.log(res.data.user);
                if (res.status == 200) {
                    sessionStorage.setItem("token", res.data.token)
                    // localStorage.setItem("token", res.data.token)
                    sessionStorage.setItem("email", res.data.user.email)
                    sessionStorage.setItem("firstname", res.data.user.firstname)
                    sessionStorage.setItem("lastname", res.data.user.lastname)
                    sessionStorage.setItem("bulim_id", res.data.user.bulim_id)
                    sessionStorage.setItem("fakultet_id", res.data.user.fakultet_id)
                    sessionStorage.setItem("kafedra_id", res.data.user.kafedra_id)
                    sessionStorage.setItem("lovozim_id", res.data.user.lovozim_id)
                    sessionStorage.setItem("role", res.data.user.role)
                    sessionStorage.setItem("userId", res.data.user.id)
                    sessionStorage.setItem("uquv_yili_id", res.data.user.uquv_yili_id)
                    sessionStorage.setItem("rasm", res.data.user.rasm)
                    // 
                    if (res.data.user.role == 0) {
                        setLoading(false)
                        navigate("/Supermain")
                    }
                    else if (res.data.user.role == 1) {
                        setLoading(false)
                        navigate("/adminMain")
                    }else if (res.data.user.role == 2) {
                        setLoading(false)
                        navigate(sessionStorage.getItem("href") === "https://ak.adu.uz/" ? "/" : sessionStorage.getItem("href").slice(17)) 
                        // navigate(sessionStorage.getItem("href") === "http://localhost:3000/" ? "/" : sessionStorage.getItem("href").slice(21)) 
                        // https://ak.adu.uz/electronic_catalog/show/633
                    }
                }
              
            }).catch(err => {
                console.log(err);
                if (err.response.status == 400) {
                    setLoading(false)
                    setText1(err.response.data.message)
                }
                else {
                    setLoading(false)
                    setText(err.message)
                }
            })
        }
        else {
            setText("Iltimos fo'rmani toldiring")
        }
    }

    return (
        <div className="for_main_img2">
            {
                loading == true ? <div className="for_loader">
                    <Oval
                        height={80}
                        width={80}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}

                    />
                </div> :
                    <>
                        <ToastContainer />
                        <div className="for_blur2">
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                   <img className="for_logo" src="/images/armlogo.png" />
                            </div>
                            <p className="for_title text-uppercase">Andijon davlat universiteti axborot-resurs markazi</p>
                            <form>
                                <div className="form_data d-flex" style={{ justifyContent: "space-around" }}>
                                    <div style={{ width: "80%" }}>
                                            <label htmlFor="" className="form-label" style={{ color: "white" }}>Login: </label> 
                                        <div className="input-group mt-1 mb-1">
                                            <input onChange={(e) => setLogin(e.target.value)} type="text" className="form-control"  aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                            <button className="btn btn-secondary" type="button" id="button-addon2"><FaUser /></button>
                                        </div>
                                            <label htmlFor="" className="form-label" style={{ color: "white" }}>Parol: </label>
                                        <div className="input-group mt-1 mb-2">
                                            <input onChange={(e) => setPassword(e.target.value)} type={hidePassword ? "text" : "password" } className="form-control"  aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                            <button onClick={()=>setHidePassword(!hidePassword)} className="btn btn-secondary" type="button" id="button-addon2">{hidePassword ? <BsEyeFill /> : <BsEyeSlashFill />}</button>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop:"20px", alignItems:"center"}}>
                                             <div className="d-flex">
                                                <Link to={"/register-student"}>
                                                    <h6 className="text-primary me-3">Ro'yxatdan o'tish!</h6>
                                                </Link>
                                                {/* <Link to={"/reset-password"}>
                                                    <h6 className="text-white">Parolni undingizmi ?</h6>
                                                </Link> */}
                                             </div>
                                            <button onClick={LoginAuth} type="submit" className="btn btn-primary d-flex justify-content-end">
                                                Kirish
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </div>
    )
}

export default Login;