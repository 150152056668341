import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { BaseUrl } from "../../Super_admin/BaseUrl";

const IlmiyNashrDetail = () => {
  const [detailData, setDetailData] = useState({});
 
  const { id } = useParams();

  const getDetail = () => {
    axios
      .get(BaseUrl + `/api/kutubxonachi/ilmiynashr/show/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.ilmiyNashr);
        setDetailData(res?.data?.ilmiyNashr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // kitob sumerka
  
  useEffect(() => {
    getDetail();
  }, []);



 
  return (
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item d-flex py-2">
                <span>
                  <b>Faolmi?:</b>
                </span>
                {detailData && detailData.active === 0 ? (
                  <div className="chek2" style={{ marginLeft: "10px" }}>
                    <CiSquareRemove style={{ color: "white" }} />
                  </div>
                ) : (
                  <div className="chek" style={{ marginLeft: "10px" }}>
                    <HiOutlineCheckCircle style={{ color: "white" }} />
                  </div>
                )}
              </li>
              <li class="list-group-item py-2">
                <b>Annotatsiya: </b>
                {detailData && detailData.anotatsiya}
              </li>
              <li class="list-group-item py-2">
                <b>Invertar raqam: </b>
                {detailData && detailData.invertar_raqam}
              </li>
              <li class="list-group-item py-2">
                <b>Izoh: </b>
                {detailData && detailData.izoh}
              </li>
              <li class="list-group-item py-2">
                <b>nomi: </b>
                {detailData && detailData.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob mualliflari: </b>
                {detailData && detailData.mualliflar && detailData.mualliflar.map((item, index) => {
                    return <span key={index}>{item.nomi}, </span>;
                  })}
              </li>
              <li class="list-group-item py-2">
                <b>Kalit so'zlar: </b>
                {detailData && detailData.kalitSuzlar && detailData.kalitSuzlar.map((item, index) => {
                    return <span key={index}>{item.nomi}, </span>;
                  })}
              </li>
              <li class="list-group-item py-2">
                <b>Sahifa soni: </b>
                {detailData && detailData.sahifa_soni}
              </li>
              <li class="list-group-item py-2">
                <b>Resurs tili: </b>
                { detailData.resurs_tili_id ?  detailData && detailData.resurs_tili.nomi : ""}
              </li>
              <li class="list-group-item py-2">
                <b>Yo'nalishi: </b>
                { detailData.resurs_tili_id ?  detailData && detailData.yunalishi.nomi : ""}
              </li>
              <li class="list-group-item py-2">
                <b>SHtrix kod: </b>
                {detailData && detailData.shtrix_code}
              </li>
              <li class="list-group-item py-2">
                <b>Yili: </b>
                {detailData && detailData.yili}
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
            <li class="list-group-item py-2">
                <b>Fan sohasi: </b>
                { detailData.fan_sohasi_id ?  detailData && detailData.fan_sohasi.nomi : ""}
              </li>
              <li class="list-group-item py-2">
                <b>Ilmiy nashr turi: </b>
                { detailData.ilmiy_nashr_turi_id ?  detailData && detailData.ilmiy_nashr_turi.nomi : ""}
              </li>
              <li class="list-group-item py-2">
                <b>Davlat: </b>
                { detailData.country_id ?  detailData && detailData.country.nomi : ""}
              </li>
              <li class="list-group-item py-2">
                <b>Shahar: </b>
                { detailData.city_id ?  detailData && detailData.city.nomi : ""}
              </li>
              <li class="list-group-item py-2">
                <b>Kim tomonidan yaratilgan: </b>
                {  detailData.created_by && detailData.created_by.firstname} { detailData.created_by && detailData.created_by.lastname}
              </li>
            </ul>
          </div>
        </div>
  );
};

export default IlmiyNashrDetail;

