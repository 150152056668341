import React, { useState, useEffect } from "react";
import { Button, Input, Modal, Form } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../../../Super_admin/BaseUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ElectronModal = (props) => {

    const { modal1Open, setModal1Open, kitobId } = props

    const [text, setText] = useState(false)
    const navigate = useNavigate()

  const {id} = useParams()
    
    const notify = () => {
        if (text === "") { } else if (text === "Kitobni yuklab olish uchun so'rov yuborildi. Javobi 3 ish kunida yuboriladi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };
    const [sorov, setSorov] = useState({
        user_id: sessionStorage.getItem("userId"),
        kitob_id: id
    })

    const postSorov = () => {
        axios.post(BaseUrl + `/api/user/kitobsurovlar`, sorov, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data.message);
            setText(res.data.message)
        }).catch(err => {
            console.log(err);
            setText(err.response.message)
        })
    }

    useEffect(() => {
        notify();
    }, [text]);

    return (
        <Modal
            title="Haqiqatdan ham kitob o'qish uchun ruxsat so'ramoqchimisiz? "
            open={modal1Open}
            onOk={() => setModal1Open(false)}
            onCancel={() => setModal1Open(false)}>
                 <ToastContainer />
            <p>Ruxsat berilgandan so'ng belgilangan muddat oralig'ida kitobdan foydalanishingiz mumkin</p>
            <p className="btnn_foot">
                <button
                    onClick={() => {
                        setModal1Open(false);
                    }}
                    style={{ marginTop: "20px" }}
                    type="submit"
                    className="btn btn-danger d-flex justify-content-end">
                    Bekor qilish
                </button>
                {
                    sessionStorage.getItem("token") ?
                        <button
                            onClick={() => {{postSorov()} }}
                            style={{ marginTop: "20px" }}
                            type="submit"
                            className="btn btn-primary d-flex justify-content-end">
                            Tasdiqlash
                        </button>
                        :
                        <button
                            onClick={() => { sessionStorage.setItem("href", window.location.href); navigate("/auth"); }}
                            style={{ marginTop: "20px" }}
                            type="submit"
                            className="btn btn-primary d-flex justify-content-end">
                            Tasdiqlash
                        </button>
                }
            </p>
        </Modal>
    )
}

export default ElectronModal;