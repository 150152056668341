import "./MyProfile.css";
import { useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { useState } from "react";
import QRCode from "qrcode";
import { usePDF } from "react-to-pdf";
import MyPDF from "./PDFViewer";
import { PDFViewer } from "@react-pdf/renderer";
import userImg from "../../assets/profilImg.jpg";
// import {
//   Document,
//   Page,
//   View,
//   Text,
//   PDFViewer,
//   Image,
//   StyleSheet,
// } from "@react-pdf/renderer";

function MyProfile() {
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const [userInfo, setUserInfo] = useState([]);
  const [imgUri, setImgUri] = useState("");

  useEffect(() => {
    axios
      .get(
        BaseUrl + "/api/admin/users/show/" + sessionStorage.getItem("userId"),

        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(async (res) => {
        console.log(res.data.user);
        setUserInfo(res.data.user);
        const imgdata = await QRCode.toDataURL(`${"00" + res.data.user?.id}`);
        setImgUri(imgdata);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //

  return (
    <div className="container-fluid ">
      <div className="row profilCard table-responsive">
        <div
          className="studentID col-md-8 offset-md-2 col-sm-12"
          ref={targetRef}
        >
          <div className="id_card_wrapper col-xl-6 offset-xl-3 col-lg-4 offset-lg-2 col-lg-12 p-0">
            <div className="id_card_head d-flex justify-content-center">
              <div className="profileImg">
                {userInfo.rasm === undefined || userInfo.rasm === null ? (
                  <img src={userImg} alt="rasm yuklanmagan" />
                ) : (
                  <img
                    className="w-100"
                    src={BaseUrl + "/image/" + userInfo.id}
                    // src="https://hemis.adu.uz/static/crop/2/1/120_120_90_2157298616.jpg"
                    alt="rasm yuklangan"
                  />
                )}
              </div>
            </div>
            <div className="line"> </div>
            <div className="line"> </div>
            <div className="id_card_body ">
              <div className="id_card_wrap">
                <div className="QrImg">
                  <img src={imgUri} alt="profile-img w-100" />
                </div>
                <div className="li-span">
                  <p className="p-0 m-0">
                    &nbsp;
                    {userInfo && userInfo.lastname + " " + userInfo.firstname}
                  </p>
                  <p className="p-0 m-0">{userInfo.middlename}</p>
                </div>

                <ul>
                  <li className="id_title">
                    HEMIS ID:
                    <span>
                      &nbsp;
                      {userInfo && userInfo.hemis_id}
                    </span>
                  </li>
                  <li className="id_title">
                    ARM ID:
                    <span>
                      &nbsp;
                      {userInfo && userInfo.id}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="line mb-2"> </div>
            <div className="line mb-2"> </div>

            <div className="id_card_footer">
              <span className="akLink">WWW.AK.ADU.UZ</span>
            </div>
          </div>
        </div>
        <div className="w-100">
          <button
            type="button"
            onClick={() => toPDF()}
            // onClick={handlePrint}
            class="w-auto btn btn-success mt-3"
          >
            Download PDF
          </button>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;

// const styles = StyleSheet.create({
//   img_div: {
//     width: 420,
//     height: 240,
//     backgroundColor: "#3f48cc",
//   },
//   img_main: {
//     padding: "20",
//     margin: "0 auto",
//     width: 200,
//     backgroundColor: "#121b9b",
//   },
//   user_img: {
//     margin: "0 auto",
//     width: 150,
//     border: "solid",
//   },
//   line: {
//     marginTop: 5,
//     height: 5,
//     backgroundColor: "#3f48cc",
//   },
//   qrImg:{
//     padding:10
//   },
//   user_title: {
//     marginTop: 5,
//     margin: "0 auto",
//     color:'blue'
//   },

//   hemis_title: {
//     paddingTop: 10,
//     margin: "0 auto",
//   },
//   footerr: {
//     marginTop: 5,
//     width: 420,
//     height:41,
//     backgroundColor: "#3f48cc",
//   },
//   link:{
//     textAlign:'center',
//     padding:5,
//     color:'white'
//   }
// });

{
  /* <div className="container ">
<PDFViewer style={styles.document1} height={1200} width={1200}>
  <Document title="ARM Id karta">
    <Page size="A5" style={styles.page1}>
      <View style={styles.img_div}>
        <View style={styles.img_main}>
          {userInfo.rasm === undefined || userInfo.rasm === null ? (
            <Image src={{ uri: userImg }} alt="rasm yuklanmagan" />
          ) : (
            <Image
              style={styles.user_img}
              src={{ uri: BaseUrl + "/storage/" + userInfo.rasm }}
            />
          )}
        </View>
      </View>
      <View style={styles.line}> </View>
      <View style={styles.line}> </View>
      <View style={styles.qrImg}>
        <Image style={styles.user_img} src={{ uri: imgUri }} />
        <Text style={styles.user_title}>
          {userInfo && userInfo.lastname + " " + userInfo.firstname}
        </Text>
        <Text style={styles.user_title}>{userInfo.middlename}</Text>
        <Text style={styles.hemis_title}>
          HEMIS ID:{userInfo && userInfo.hemis_id}
        </Text>
        <Text style={styles.hemis_title}>
          ARM ID: {userInfo && userInfo.id}
        </Text>
      </View>
      <View style={styles.line}> </View>
      <View style={styles.line}> </View>
      <View style={styles.footerr}>
        <Text style={styles.link}>WWW.AK.ADU.UZ</Text>
      </View>
    </Page>
  </Document>
</PDFViewer>
</div> */
}
