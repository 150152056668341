/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Select, Radio, message } from "antd";

const Books_collectionAdd = () => {
  // <<<<<<<<<<<<< data id >>>>>>>>>>>>>>
  const [book_id, setBook_id] = useState([]);
  const [fansohasiData, setFansohasiData] = useState([]);
  const [kitobYunalishData, setKitobYunalishData] = useState([]);
  const [bookData, setBookData] = useState([]);
  const [bookEntryData, setBookEntryData] = useState([]);
  const [matnTuriData, setMatnTuriData] = useState([]);
  const [faylTuriData, setFaylTuriData] = useState([]);
  const [toifaData, setToifaData] = useState([]);
  const [davlatData, setDavlatData] = useState([]);
  const [shaharData, setShaharData] = useState([]);

  const [text, setText] = useState("");

  // <<<<<<<<<<<<< data id >>>>>>>>>>>>>>

  const { id } = useParams();
  const navgate = useNavigate();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Yangi kitob bazaga kiritildi.") {
      toast.success(text);
    } else if (text === "Kitob bazadan o'zgartirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };

  let formData = new FormData();

  const [allData, setAllData] = useState({
    nomi: "",
    user_id: sessionStorage.getItem("userId"),
    nashriyot_nomi: "",
    mualliflar: "",
    udk: "",
    bbk: "",
    mualliflik_belgisi: "",
    isbn: "",
    anotatsiya: "",
    nash_yili: "",
    betlar_soni: "",
    adadi: "",
    narxi: "",
    kitob_turi_id: "",
    fan_sohasi_id: "",
    kitob_tili_id: "",
    kitob_yozuvi_id: "",
    matn_turi_id: "",
    kirish_huquqi: "",
    kimga_muljallangan_id: "",
    fayl_turi_id: "",
    kitob_yunalishi_id: "",
    country_id: "",
    city_id: "",
    active: "",
    kutubxonadaBormi: false,
    elektroniBormi: false,
  });

  const [fansohasiId, setFansohasiId] = useState("");
  const [davlatId, setDavlatId] = useState("");

  // console.log(kutubxonadaBormi1,elektroniBormi1);

  for (const key in allData) {
    if (allData.hasOwnProperty(key)) {
      const value = allData[key];
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const subObj = value[i];
          for (const subKey in subObj) {
            if (subObj.hasOwnProperty(subKey)) {
              const subValue = subObj[subKey];
              const subKeyWithIndex = `${key}[${i}][${subKey}]`;
              formData.append(subKeyWithIndex, subValue);
            }
          }
        }
      } else {
        formData.append(key, value);
      }
    }
  }
  // console.log(allData);

  const [disable, setDisable] = useState(false)
  const addDate = () => {
    if (id) {
      axios
        .post(BaseUrl + `/api/kutubxonachi/kitoblar/edit/${id}`, formData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token")
          },
          params: {
            _method: 'PUT'
          }
        })
        .then((res) => {
          console.log(res.data);
          if (res.status == 200) {
            // console.log(res.data.message);
            setText(res.data.message);
          }
          navgate(sessionStorage.getItem("userId") == 1 ? "/Supermain/books_collection" : "/adminMain/books_collection");
        })
        .catch((err) => {
          console.log(err);
          // console.log(formData.get("sumarka_raqam"));
          setText(err.response.data.errors);
        });
    } else {
      setDisable(true)
      axios
        .post(BaseUrl + "/api/kutubxonachi/kitoblar", formData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res.data);
          setDisable(false)
          if (res.status == 200) {
            // console.log(res.data.message);
            setText(res.data.message);
          }
          navgate(sessionStorage.getItem("userId") == 1 ? "/Supermain/books_collection" : "/adminMain/books_collection");
        })
        .catch((err) => {
          setDisable(false)
          console.log(err);
          // console.log(formData.get("sumarka_raqam"));
          setText(err.response.data.errors);
        });
    }
  };
  // console.log(nomi);

  const getKitob_turi = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobturi", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setBook_id(res.data.kitobTuri);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFansohasi = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/fansohasi", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data.fanSohalari);
        setFansohasiData(res.data.fanSohalari);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getKitobYunalish = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobyunalishi/" + fansohasiId, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setKitobYunalishData(res.data.kitobYunalishlari);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBook_language = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobtili", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data.kitobTillari);
        setBookData(res.data.kitobTillari);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getKitobYozuvi = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobyozuvi", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        setBookEntryData(res.data.kitobYozuvlari);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMatnTuri = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/matnturi", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        setMatnTuriData(res.data.matnTurlari);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFaylTuri = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/faylturi", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        setFaylTuriData(res.data.faylTurlari);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getToifa = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kimgamuljal", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        setToifaData(res.data.kimgaMuljallanganlar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBook_language();
    getFansohasi();
    getKitob_turi();
    getKitobYozuvi();
    getMatnTuri();
    getFaylTuri();
    getToifa();
    getDavlat();
  }, []);

  const getDavlat = () => {
    axios
      .get(BaseUrl + "/api/admin/country", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setDavlatData(res.data.countries);
      })
      .catch((err) => {
        console.log("davlat", err);
      });
  };

  const getShahar = () => {
    axios
      .get(BaseUrl + "/api/admin/city/" + davlatId, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        setShaharData(res.data.cities);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getShahar();
  }, [davlatId]);

  useEffect(() => {
    getKitobYunalish();
  }, [fansohasiId]);

  // kitob edit input value
  const getEdit = (e) => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitoblar/show/" + id, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.kitob.nomi);
        //  allData.nomi = res.data.kitob.nomi;
        setAllData({
          nomi: res.data.kitob.nomi,
          nashriyot_nomi: res.data.kitob.nashriyot_nomi,
          mualliflar: res.data.kitob.mualliflar,
          udk: res.data.kitob.udk,
          bbk: res.data.kitob.bbk,
          mualliflik_belgisi: res.data.kitob.mualliflik_belgisi,
          isbn: res.data.kitob.isbn,
          anotatsiya: res.data.kitob.anotatsiya,
          nash_yili: res.data.kitob.nash_yili,
          betlar_soni: res.data.kitob.betlar_soni,
          adadi: res.data.kitob.adadi,
          narxi: res.data.kitob.narxi,
          active: res.data.kitob.active,
          user_id: sessionStorage.getItem("userId")
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEdit();
  }, []);



  // Mualiflar
  const { Option } = Select;

  const [muallifArr, setMuallifArr] = useState([]);

  const handleChange = (value) => {
    setMuallifArr(value && value.map((it) => ({ nomi: it })));
  };
  useEffect(() => {
    setAllData({ ...allData, mualliflar: muallifArr });
  }, [muallifArr]);

  const [ISBN, setISBN] = useState("")

  const chechInISBN = () => {
    axios.get(`${BaseUrl}/api/kutubxonachi/kitoblar/isbn-check/${ISBN}`,{
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then(res=>{
      // console.log(res.data[0]);
      if (res.data[0] === true) {
        message.error("Bu kitob odin kiritilgan")
      }
      else{
        message.success("Bu kitob kiritilmagan")
      }
    }).catch(err=>{
      console.log(err);
    })
  }


  return (
    <div style={{ width: "100%" }}>
      <div className="navBar">
        <p>Kitob {id ? "o'zgartrish" : "yaratish"}</p>
        <ToastContainer />
        <Link to={sessionStorage.getItem("userId") == 1 ? "/Supermain/books_collection" : "/adminMain/books_collection"}>
          <div className="add-kafedra">Orqaga</div>
        </Link>
      </div>
      <hr />

      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="card shadow p-3">
            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                ISBN
              </label>
            </div>
            <input
              className="form-control"
              type="text"
              value={allData.isbn}
              onChange={(e) => {setAllData({ ...allData, isbn: e.target.value }); setISBN(e.target.value)}}
            />
            <button onClick={chechInISBN} className="btn btn-primary mt-1">ISBN orqali tekshirish</button>
            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                Kitob nomi
              </label>
            </div>
            <input
              value={allData.nomi}
              className="form-control"
              type="text"
              onChange={(e) => setAllData({ ...allData, nomi: e.target.value })}
            />

            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                Muallif belgisi (Авторский Знак)
              </label>
            </div>
            <input
              className="form-control"
              type="text"
              value={allData.mualliflik_belgisi}
              onChange={(e) =>
                setAllData({ ...allData, mualliflik_belgisi: e.target.value })
              }
            />

            {/* <div className="mt-2">
              <label className="mt-3" htmlFor="">
                Kitob nashr qilingan shahar
              </label>
            </div>
            <input
              className="form-control"
              type="text"
              value={allData.nashr_shahri}
              onChange={(e) =>
                setAllData({ ...allData, nashr_shahri: e.target.value })
              }
            /> */}

            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                Kitob nashriyoti nomi
              </label>
            </div>
            <input
              className="form-control"
              type="text"
              value={allData.nashriyot_nomi}
              onChange={(e) =>
                setAllData({ ...allData, nashriyot_nomi: e.target.value })
              }
            />

            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                Kitob mualliflari
              </label>
            </div>
            <Select
              placeholder="Kimga"
              mode="tags"
              style={{ width: "100%" }}
              tokenSeparators={[","]}
              onChange={handleChange}
            >
              {/* {userName.map((item, index) => ( */}
              <Option></Option>
              {/* ))} */}
            </Select>

            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                UDK
              </label>
            </div>
            <input
              className="form-control"
              type="text"
              value={allData.udk}
              onChange={(e) => setAllData({ ...allData, udk: e.target.value })}
            />

            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                BBK
              </label>
            </div>
            <input
              className="form-control"
              type="text"
              value={allData.bbk}
              onChange={(e) => setAllData({ ...allData, bbk: e.target.value })}
            />


            <div className="mt-2">
              <label for="exampleFormControlTextarea1">
                Kitob annotatsiyasi
              </label>
            </div>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={(e) =>
                setAllData({ ...allData, anotatsiya: e.target.value })
              }
            ></textarea>

            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                Kitob nashr qilingan yili
              </label>
            </div>
            <input
              className="form-control"
              type="number"
              value={allData.nash_yili}
              onChange={(e) =>
                setAllData({ ...allData, nash_yili: e.target.value })
              }
            />

            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                Betlar soni
              </label>
            </div>
            <input
              className="form-control"
              type="number"
              value={allData.betlar_soni}
              onChange={(e) =>
                setAllData({ ...allData, betlar_soni: e.target.value })
              }
            />

            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                Narxi
              </label>
            </div>
            <input
              className="form-control"
              type="text"
              value={allData.narxi}
              onChange={(e) =>
                setAllData({ ...allData, narxi: e.target.value })
              }
            />

            <div className="mt-2">
              <label className="mt-3" htmlFor="">
                Nechta kitob
              </label>
            </div>
            <input
              className="form-control"
              type="number"
              value={allData.adadi}
              onChange={(e) =>
                setAllData({ ...allData, adadi: e.target.value })
              }
            />

            <label className="mt-3" htmlFor="">
              Faolligi ?
            </label>
            <select
              className="form-select "
              name=""
              id=""
              onChange={(e) =>
                setAllData({ ...allData, active: e.target.value })
              }
            >
              <option value="1">tanlang</option>
              <option value="1">Faol</option>
              <option value="0">Faol emas</option>
            </select>
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className="card shadow p-3">
            {/* --------------------------------------- */}

            <label className="mt-4" htmlFor="">
              Davlatni Tanlang
            </label>
            <select
              onChange={(e) => {
                setAllData({ ...allData, country_id: e.target.value });
                setDavlatId(e.target.value);
              }}
              className="form-select"
              name=""
              id=""
            >
              <option value="">Tanlang</option>
              {davlatData &&
                davlatData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            <label className="mt-3" htmlFor="">
              Shaxarni Tanlang
            </label>
            <select
              onChange={(e) =>
                setAllData({ ...allData, city_id: e.target.value })
              }
              className="form-select"
              name=""
              id=""
            >
              <option value="">Tanlang</option>
              {shaharData &&
                shaharData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            {/* --------------------------------------- */}
            <div>
              <label className="mt-4" htmlFor="">Kitob turini tanlang</label>
            </div>
            <select
              onChange={(e) => {
                setAllData({ ...allData, kitob_turi_id: e.target.value });
              }}
              className="form-select"
              name=""
              id=""
            >
              <option>tanlang</option>
              {book_id &&
                book_id.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            <label className="mt-4" htmlFor="">
              Fan sohalari
            </label>
            <select
              onChange={(e) => {
                setAllData({ ...allData, fan_sohasi_id: e.target.value });
                setFansohasiId(e.target.value);
              }}
              className="form-select"
              name=""
              id=""
            >
              <option value="">Tanlang</option>
              {fansohasiData &&
                fansohasiData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            <label className="mt-4" htmlFor="">
              Kitob yo'nalishi
            </label>
            <select
              onChange={(e) =>
                setAllData({ ...allData, kitob_yunalishi_id: e.target.value })
              }
              className="form-select"
              name=""
              id=""
            >
              <option value="">Tanlang</option>
              {kitobYunalishData &&
                kitobYunalishData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            <label className="mt-4" htmlFor="">
              Tili
            </label>
            <select
              onChange={(e) =>
                setAllData({ ...allData, kitob_tili_id: e.target.value })
              }
              className="form-select"
              name=""
              id=""
            >
              <option value="">tanlang</option>
              {bookData &&
                bookData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            <label className="mt-4" htmlFor="">
              Kitob yozuvi
            </label>
            <select
              onChange={(e) =>
                setAllData({ ...allData, kitob_yozuvi_id: e.target.value })
              }
              className="form-select"
              name=""
              id=""
            >
              <option value="">tanlang</option>
              {bookEntryData &&
                bookEntryData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            <label className="mt-4" htmlFor="">
              Resurs turi
            </label>
            <select
              onChange={(e) =>
                setAllData({ ...allData, matn_turi_id: e.target.value })
              }
              className="form-select"
              name=""
              id=""
            >
              <option value="">tanlang</option>
              {matnTuriData &&
                matnTuriData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            <label className="mt-4" htmlFor="">
              Kirish huquq turi
            </label>
            <select
              className="form-select "
              name=""
              id=""
              onChange={(e) =>
                setAllData({ ...allData, kirish_huquqi: e.target.value })
              }
            >
              <option value="1">tanlang</option>
              <option value="1">Ochiq</option>
              <option value="2">Qisman Ochiq</option>
              <option value="3">Yopiq</option>
            </select>

            <label className="mt-4" htmlFor="">
              Fayl turi
            </label>
            <select
              onChange={(e) =>
                setAllData({ ...allData, fayl_turi_id: e.target.value })
              }
              className="form-select"
              name=""
              id=""
            >
              <option value="">tanlang</option>
              {faylTuriData &&
                faylTuriData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            <label className="mt-4" htmlFor="">
              Kimlarga mo‘ljallangan
            </label>
            <select
              onChange={(e) =>
                setAllData({
                  ...allData,
                  kimga_muljallangan_id: e.target.value,
                })
              }
              className="form-select"
              name=""
              id=""
            >
              <option value="">tanlang</option>
              {toifaData &&
                toifaData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>

            <div className="mt-2">
              <label className="mt-4" htmlFor="">
                Muqova tasviri
              </label>
            </div>
            <input
              className="form-control"
              type="file"
              onChange={(e) =>
                setAllData({ ...allData, muqova_jpg: e.target.files[0] })
              }
            />

            <div className="mt-2">
              <label className="mt-4" htmlFor="">
                To‘liq matn fayli
              </label>
            </div>
            <input
              className="form-control"
              type="file"
              onChange={(e) =>
                setAllData({ ...allData, kitob_pdf: e.target.files[0] })
              }
            />

            <div class="form-check mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                onChange={(e) =>
                  // setKutubxonadaBormi1(kutubxonadaBormi1 === true ? false : true)
                  setAllData({ ...allData, kutubxonadaBormi: e.target.checked })
                }
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Kutubxonada bormi?
              </label>
            </div>

            <div class="form-check mt-2">
              <label class="form-check-label" for="flexCheckChecked">
                Elektron formatda bormi?
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                onChange={(e) =>
                  // setElektroniBormi1(elektroniBormi1 === true ? false : true)
                  setAllData({ ...allData, elektroniBormi: e.target.checked })
                }
                id="flexCheckChecked"
              />
            </div>

            <span className="d-flex flex-row-reverse ">
              {
                disable == true ?
                  <button className="btn btn-primary mt-3">
                    Qo'shilmoqda
                  </button>
                  :
                  <button className="btn btn-primary mt-3" onClick={addDate}>
                    {id ? "O'zgartrish" : "Yaratish"}
                  </button>
              }

            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Books_collectionAdd;
