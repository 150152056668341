import axios from "axios";
import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../BaseUrl";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux";
import { UserLavozim } from "../../../redux/actions/Action";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md"
import { CiSquareRemove } from "react-icons/ci"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserLavozimi = () => {

    const dispatch = useDispatch()
    const navgate = useNavigate()
    const [text, setText] = useState(false)
   


    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") { } else if (text === "Yangi Fakultet yaratildi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };


    const lavozim = useSelector(state => state.store.lavozim)
    console.log("lavozim",lavozim);

    const getUserType = () => {
        axios.get(BaseUrl + "/api/admin/lavozim", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res);
            dispatch(UserLavozim(res.data.lavozimlar))
        }).catch(err => {
            console.log(err);
        })
    }

    const deleteLavozim = (id) => {
        axios.delete(BaseUrl + `/api/admin/lavozim/${id}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data);
            if (res.status == 200) {
                getUserType()
                setText(res.data.message)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getUserType()
    }, [])

    return (
        <div>
            <ToastContainer />
            <h2 className="mt-4">Toifalar</h2>
            <hr />
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Link to="/Supermain/userlavozim/add">
                    <div className="add-kafedra">Toifa qo'shish</div>
                </Link>
            </div>
            <div className="tableScroll">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Foydalanuvchi turi</th>
                        <th scope="col">Foydalanuvchi toifasi</th>
                        <th scope="col">Izoh</th>
                        <th scope="col">Foydalanuvchilar soni</th>
                        <th scope="col">Faolmi</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {lavozim && lavozim.map((item, index) => (
                        <tr>
                            <th scope="row">{item.id}</th>
                            <td>{item.f_turi.nomi}</td>
                            <td>{item.nomi}</td>
                            <td>{item.izoh}</td>
                            <td>{item.soni}</td>
                            <td>
                                {
                                    item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                    :
                                    <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div> 
                                }
                                
                            </td>
                            <td>
                                <div className="main_edite-box">
                                    <div className="edite_show_box">
                                        {/* <div className="show_data"><AiOutlineEye /></div> */}
                                        <div onClick={() => navgate(`/Supermain/userlavozim/add/${item.id}`)} className="edite"><AiOutlineEdit /></div>
                                        <div onClick={() => deleteLavozim(item.id)} className="delete"><MdOutlineDelete /></div>
                                    </div>
                                    {/* <div className="allDelete">Ma'lumotlar bazasidan o'chirish</div> */}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>

    )
}

export default UserLavozimi;