import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
// import { BookCatalogAction } from "../../redux/actions/Action";
// import { CiSquareRemove } from "react-icons/ci";
// import { HiOutlineCheckCircle } from "react-icons/hi";
import { BsSearch } from "react-icons/bs";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { BaseUrl } from "../../BaseUrl";


const TavsiyaetilganKitob = () => {
const [tavsiyaKitob, setTavsiyaKitob] = useState([])
const [loading, setLoading] = useState(true)

const navgate = useNavigate();

  // get
  const getBook_type = () => {
    axios
      .get(BaseUrl + "/api/admin/kitobtavsiya", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("kitobTavsiya", res.data);
        setTavsiyaKitob(res.data.kitobTavsiyalar);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBook_type()
  }, []);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 13;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(tavsiyaKitob && tavsiyaKitob.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(tavsiyaKitob.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, tavsiyaKitob]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % tavsiyaKitob.length;
    setItemOffset(newOffset);
  };
  return (
    <div>
      <div className="navBar">
        <p>Tavsiya etilgan kitoblar</p>
        <div>
          <div class="input-group  mb-3">
            <input
            //   onChange={(event) => {
            //     setSearch(event.target.value);
            //   }}
              class="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              type="search"
            />
            <span class="input-group-text" id="inputGroup-sizing-sm">
              <BsSearch />
            </span>
          </div>
        </div>
      </div>
      <ToastContainer />
      <hr />
      {loading ? <div class="loading">Loading&#8230;</div> :
       <table class="table">
       <thead>
         <tr>
           <th scope="col">No</th>
           <th scope="col">Muqova tasviri</th>
           <th scope="col">Nomi</th>
           <th scope="col">Mualliflar</th>
           <th scope="col">Nashr yili</th>
           <th scope="col">nashiryot nomi</th>
           <th scope="col">Ko'rish </th>
         </tr>
       </thead>
       <tbody>
         {
           currentItems.map((item, index) => (
             <tr>
               <th scope="row">{item.kitob.id}</th>
               <td>
                 <a
                   href={BaseUrl + "/storage/" + item.kitob.kitob_pdf}
                   target={"_blank"}>
                   <img
                     width={100}
                     height={130}
                     src={BaseUrl + "/storage/" + item.kitob.muqova_jpg}
                     alt="gfsdgsdf"
                   />
                 </a>
               </td>
               <td><b>{item.kitob.nomi}</b></td>
               <td>{item.mualliflar.map((mualifItem, index)=>{
                 return(
                   mualifItem.nomi
                 )
               })}</td>
               <td>{item.kitob.nash_yili}</td>
               <td>{item.kitob.nashriyot_nomi}</td>
               <td>
                 <div className="main_edite-box d-flex">
                   <div className="edite_show_box">
                     <div
                       className="show_data"
                      onClick={() =>
                        navgate(`/adminMain/book_catalog/show/${item.kitob.id}`)
                       }
                       >
                       <AiOutlineEye />
                     </div>
                   </div>
                 </div>
               </td>
             </tr>
           ))}
       </tbody>
     </table>
      }
     
      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default TavsiyaetilganKitob;
