import React, { useState, useEffect } from "react";
import { Button, Input, Modal, Form, Select } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseUrl } from "../../Super_admin/BaseUrl";
import Item from "antd/es/list/Item";



const SorovModal = (props) => {

    const { modal1Open, setModal1Open, kitobId, sorovId, KitibSorovlari} = props

    const [text, setText] = useState(false)
    const navigate = useNavigate()
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const [day, setDay] = useState([
        {days:"1"}, {days:"2"}, {days:"3"}, {days:"4"}, {days:"5"},
        {days:"6"}, {days:"7"}, {days:"8"}, {days:"9"}, {days:"10"}, 
        {days:"11"}, {days:"12"}, {days:"13"}, {days:"14"}, {days:"15"},
        {days:"16"}, {days:"17"}, {days:"18"}, {days:"19"}, {days:"20"},
        {days:"21"}, {days:"22"}, {days:"23"}, {days:"24"}, {days:"25"},
        {days:"26"}, {days:"27"}, {days:"28"}, {days:"29"}, {days:"30"},
    ])

    // const options = () => {
        // for (let i = 0; i < 30; i++) {
        //     setDay(i+1)
        // }
    // }




    console.log('salom w', day);

    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") { } else if (text === "Kitobni yuklab olish uchun so'rov yuborildi. Javobi 3 ish kunida yuboriladi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };

    const [sorov, setSorov] = useState({
        user_id: sessionStorage.getItem("userId"),
        kitob_id: sessionStorage.getItem("kitobId"),
        muddati:""
    })

    const [rad, setRad] = useState({
        user_id: sessionStorage.getItem("userId"),
        kitob_id: sessionStorage.getItem("kitobId"),
        izoh:""
    })
    console.log(typeof rad);

    const postTasdiq = () => {
        axios.post(BaseUrl + `/api/kutubxonachi/kitobsurovlari/tasdiqlash/${sorovId}`, sorov, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data.message);
            setText(res.data.message)
            setModal1Open(false)
            
        }).catch(err => {
            console.log(err);
        })
    }

   
    const postRad = () => {
        axios.post(BaseUrl + `/api/kutubxonachi/kitobsurovlari/radqilish/${sorovId}`, rad, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data.message);
            setText(res.data.message)
            setModal1Open(false)
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <Modal
            title="So'rovga javob berish"
            open={modal1Open}
            onOk={() => setModal1Open(false)}
            onCancel={() => setModal1Open(false)}>
            <ToastContainer />
            <div style={{ display: "flex" ,justifyContent:"space-between"}}>
               <div style={{width:"77%"}}>
                <div><label htmlFor="">Izoh</label></div>
               <Input onChange={(e)=>setRad({...rad, izoh:e.target.value})} className="form-control" placeholder="Izoh" />
               </div>
                <button onClick={() => { setModal1Open(false) ; postRad()}} style={{ width: "23%",height:"40px", marginTop:"20px"}} type="submit" className="btn btn-danger d-flex justify-content-end">
                    Bekor qilish
                </button>
            </div>
            <hr />
            <div style={{ display: "flex" }}>

                <div style={{width:"80%"}}>
                    <label htmlFor="">Muddatni tanlang</label>
                    <div>
                    <select onChange={(e)=>setSorov({...sorov, muddati:+e.target.value})} className="form-select" name="" id="">
                    <option>Tanlang</option>
                    
                    {
                        day && day.map((item, index)=>(
                            <option  value={item.days}>{item.days}</option>
                        ))
                    }
                </select>
                    </div>
                </div>
                <button onClick={() => { postTasdiq() }} type="submit" style={{height:"40%",marginTop:"20px"}} className="btn btn-primary d-flex justify-content-end">
                    Tasdiqlash
                </button>
            </div>
        </Modal>
    )
}

export default SorovModal;