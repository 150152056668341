import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { Link, useParams } from "react-router-dom";

const SumerkaAdd = () => {
  const [shaxs_nomi, setShaxs_nomi] = useState("");
  const [tashkilot_nomi, setTashkilot_nomi] = useState("");
  const [sumarka_raqam, setSumarka_raqam] = useState("");
  const [qayerdan_kelgan, setQayerdan_kelgan] = useState("");
  const [kelgan_sana, setKelgan_sana] = useState("");
  const [harid_malumotnoma, setHarid_malumotnoma] = useState("");
  const [izoh, setIzoh] = useState("");
  const [narxi, setNarxi] = useState("");
  const [active, setActive] = useState("");
  const [text, setText] = useState("");

  const { id } = useParams();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Yangi Sumerka jadvali yaratildi.") {
      toast.success(text);
    } else if (text === "Sumerka jadvali bazadan o'zgartirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };

  let formData = new FormData();
  let user_id = sessionStorage.getItem("userId");
  formData.append("shaxs_nomi", shaxs_nomi);
  formData.append("user_id", user_id);
  formData.append("tashkilot_nomi", tashkilot_nomi);
  formData.append("sumarka_raqam", sumarka_raqam);
  formData.append("qayerdan_kelgan", qayerdan_kelgan);
  formData.append("kelgan_sana", kelgan_sana);
  formData.append("harid_malumotnoma", harid_malumotnoma);
  formData.append("izoh", izoh);
  formData.append("narxi", narxi);
  formData.append("active", active);

  const addDate = () => {  
    if (id) {
      // console.log(formData.get("harid_malumotnoma"));
      axios.post(BaseUrl + `/api/kutubxonachi/sumerka/edit/${id}`,
            formData,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token")
            },
            params: {
              _method: 'PUT'
            }
          }
        )
        .then((res) => {
            console.log(res.data.sumerkalar);
          if (res.status == 200) {
            setText(res.data.message);
            setShaxs_nomi("");
            setTashkilot_nomi("");
            setSumarka_raqam("");
            setQayerdan_kelgan("");
            setKelgan_sana("");
            setHarid_malumotnoma("");
            setIzoh("");
            setNarxi("");
          }
        })
        .catch((err) => {
          console.log(err.response);
          setText(err.response.data.message);
        });
    } else {
      axios 
        .post(
          BaseUrl + "/api/kutubxonachi/sumerka",

          formData,

          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          // console.log(res.data.message);
          if (res.status == 200) {
            setText(res.data.message);
            setShaxs_nomi("");
            setTashkilot_nomi("");
            setSumarka_raqam("");
            setQayerdan_kelgan("");
            setKelgan_sana("");
            setHarid_malumotnoma("");
            setIzoh("");
            setNarxi("");
          }
        })
        .catch((err) => {
          console.log(err);
          // console.log(formData.get("sumarka_raqam"));
          setText(err.response.data.errors);
        });
    }
  };

  const getEdit = (e) => {
    // console.log("salom", id);
    axios
      .get(BaseUrl + "/api/kutubxonachi/sumerka/", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        res.data.sumerkalar.forEach((item, index) => {
          if (item.id == id) {
            setShaxs_nomi(item.shaxs_nomi);
            setTashkilot_nomi(item.tashkilot_nomi);
            setSumarka_raqam(item.sumarka_raqam);
            setQayerdan_kelgan(item.qayerdan_kelgan);
            setKelgan_sana(item.kelgan_sana);
            setHarid_malumotnoma(harid_malumotnoma);
            setIzoh(item.izoh);
            setNarxi(item.narxi);
            setActive(item.active);
            console.log(e);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEdit();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div className="navBar">
        <p>Kitob shartnomalari {id ? "o'zgartirish" : "yaratish"}</p>
        <ToastContainer />
        <Link to={sessionStorage.getItem("userId") == 1 ? "/Supermain/sumerka" : "/adminMain/sumerka"}>
          <div className="add-kafedra">Orqaga</div>
        </Link>
      </div>
      <hr />

      <div className="card shadow p-3">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div>
              <label className="mt-3" htmlFor="">
                Shaxs nomi
              </label>
            </div>
            <input
              onChange={(e) => setShaxs_nomi(e.target.value)}
              value={shaxs_nomi}
              placeholder="Shaxs nomi..."
              className="form-control"
              type="text"
            />

            <div>
              <label className="mt-3" htmlFor="">
                Tashkilot nomi
              </label>
            </div>
            <input
              onChange={(e) => setTashkilot_nomi(e.target.value)}
              value={tashkilot_nomi}
              placeholder="Tashkilot nomi..."
              className="form-control"
              type="text"
            />

            <div>
              <label className="mt-3" htmlFor="">
                {id ? "Sumerka raqam o'zgartirilmaydi" : "Sumerka raqam"}
              </label>
            </div>
            {id ? (
              <>
                <input
                  onChange={(e) => setSumarka_raqam(e.target.value)}
                  disabled
                  value={sumarka_raqam}
                  className="form-control"
                  type="text"
                />
              </>
            ) : (
              <>
                <input
                  onChange={(e) => setSumarka_raqam(e.target.value)}
                  value={sumarka_raqam}
                  placeholder="Sumerka raqam..."
                  className="form-control"
                  type="text"
                />
              </>
            )}

            <div>
              <label className="mt-3" htmlFor="">
                Qayerdan kelgan
              </label>
            </div>
            <input
              onChange={(e) => setQayerdan_kelgan(e.target.value)}
              value={qayerdan_kelgan}
              placeholder="Qayerdan kelgan..."
              className="form-control"
              type="text"
            />

            <div>
              <label className="mt-3" htmlFor="">
                Kelgan sana
              </label>
            </div>
            <input
              onChange={(e) => setKelgan_sana(e.target.value)}
              value={kelgan_sana}
              className="form-control"
              type="date"
            />
          </div>
          {/* ////////////////////////////////////////////////////////////// */}
          <div className="col-md-6 col-sm-12">
            {/* ////////////////////////////////////////////////////// */}
            <div>
              <label className="mt-3" htmlFor="">
                Harid ma'lumotnoma
              </label>
            </div>
            <input
              onChange={(e) => setHarid_malumotnoma(e.target.files[0])}
              // value={harid_malumotnoma}
              placeholder="Harid ma'lumotnoma..."
              className="form-control"
              type="file"
            />

            <div>
              <label className="mt-3" htmlFor="">
                Izoh
              </label>
            </div>
            <input
              onChange={(e) => setIzoh(e.target.value)}
              value={izoh}
              placeholder="Izoh..."
              className="form-control"
              type="text"
            />

            <div>
              <label className="mt-3" htmlFor="">
                Narxi
              </label>
            </div>
            <input
              onChange={(e) => setNarxi(e.target.value)}
              value={narxi}
              placeholder="Narxi..."
              className="form-control"
              type="text"
            />

            <label className="mt-3" htmlFor="">
              Faoligi
            </label>
            <select
              className="form-select "
              onChange={(e) => setActive(e.target.value)}
              name=""
              id=""
            >
              <option value="1">tanlang</option>
              <option value="1">Faol</option>
              <option value="0">Faol emas</option>
            </select>
          </div>
          <span className="w-100  d-flex justify-content-end">
            <button onClick={addDate} className="btn btn-primary mt-3">
              {id ? "O'zgartirish" : "Yaratish"}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SumerkaAdd;
