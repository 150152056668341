import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../../../../Super_admin/BaseUrl";
import { BsSearch } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import "./Electronic_catalog.css";
import { useNavigate } from "react-router-dom";
import { RxLockClosed, RxLockOpen1,RxLockOpen2  } from 'react-icons/rx';

const Electronic_catalog = () => {
  const [catalog, setCatlog] = useState([]);



  const [pageCount, setPageCount] = useState();
  const [usersData, setUsersData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);

  const navgate = useNavigate();

 
  const getAllUsers = (page = 1) => {
    axios
      .get(
        BaseUrl +
          `/api/public/kitobkatalog?page=${page}&search=${searchTerm}`,
        {
          headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
        }
      )
      .then((res) => {
        // console.log(res.data.kitoblar.data);
        // localStorage.setItem("userDataCount", res.data.kaloglar.total);
        setPageCount(res.data.kitoblar.last_page);
        setUsersData(res.data.kitoblar.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [searchTerm]);

  useEffect(() => {
    setCurrentItems(usersData && usersData.slice(itemOffset));
  }, [itemOffset, usersData]);

  const handlePageClick = (event) => {
    getAllUsers(event.selected + 1);
  };



  // const getNews = () => {
  //   axios
  //     .get(BaseUrl + "/api/public/kitobkatalog")
  //     .then((res) => {
  //       console.log("dsdsd", res.data);
  //       setCatlog(res.data.kitoblar);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getNews();
  // }, []);

  // const [currentItems, setCurrentItems] = useState([]);
  // const [pageCount, setPageCount] = useState(0);
  // const [itemOffset, setItemOffset] = useState(0);
  // const itemsPerPage = 13;

  // useEffect(() => {
  //   const endOffset = itemOffset + itemsPerPage;
  //   setCurrentItems(catalog && catalog.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(catalog.length / itemsPerPage));
  // }, [itemOffset, itemsPerPage, catalog]);

  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % catalog.length;
  //   setItemOffset(newOffset);
  // };

  // search

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-4 offset-md-4 col-sm-12">
          <div class="input-group input-group-sm mb-3">
            <input
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              class="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              type="search"
            />
            <span class="input-group-text" id="inputGroup-sizing-sm">
              <BsSearch />
            </span>
          </div>
        </div>
        {
          currentItems.map((item, index) => {
            // console.log("item", item);
            return (
              <div key={index} className="card_catalog card py-2 mt-2 mb-2">
                <span className="px-1 py-1">
                  <b
                    className="catalog_name"
                    onClick={() =>
                      navgate(`/electronic_catalog/show/${item.id}`)
                    }>
                    {item.nomi}
                  </b>
                  <span className="status_icon">
                    {item.kirish_huquqi == 1 ? < RxLockOpen2 style={{color:"#2E8B57"}}/> : item.kirish_huquqi == 2 ? <  RxLockOpen1 style={{color:"#FF8C00"}}/> : item.kirish_huquqi == 3 ?<  RxLockClosed style={{color:"#FF6347"}}/> : ""}
                     </span>
                </span>
                <span className="text-muted px-1">
                  Mualliflar:
                  <q>
                    {item &&
                      item.mualliflar &&
                      item.mualliflar.map((item, index) => {
                        return <span key={index}>{item.nomi}, </span>;
                      })}
                  </q>
                </span>
                <span className="text-muted px-1">
                  Nashriyot: <q>{item.nashriyot_nomi}</q>
                </span>
                <span className="d-flex text-muted ">
                  <span className="px-1">{item.kitob_yunalishi.nomi}</span>,
                  <span className="px-1">{item.kitob_turi.nomi}</span>,
                  <span className="px-1">{item.nash_yili}</span>,
                  <span className="px-1">{item.country.nomi}</span>,
                  <span className="px-1">{item.kitob_tili.nomi}</span>
                </span>
              </div>
            );
          })}
      </div>

      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default Electronic_catalog;



// (searchTerm !== "" ? catalog : currentItems)
//           ?.filter((value) => {
//             if (searchTerm === "") {
//               return value;
//             } else if (
//               value?.nomi?.toLowerCase().includes(searchTerm.toLowerCase())
//             ) {
//               return value;
//             } else if (
//               value?.mualliflar[0]?.nomi?.toLowerCase().includes(searchTerm.toLowerCase())
//             ) {
//               return value;
//             }else if (
//               value?.mualliflar[1]?.nomi?.toLowerCase().includes(searchTerm.toLowerCase())
//             ) {
//               return value;
//             } else if (
//               value?.nashriyot_nomi
//                 ?.toLowerCase()
//                 .includes(searchTerm.toLowerCase())
//             ) {
//               return value;
//             }
//           })
