import React from "react";

const Material_technical_base = () => {
  return (
    <div className="container py-3">
      <h3 className="text-center">
        Axborot-resurs markazi moddiy-texnika bazasining holati:
      </h3>
      <p className="m-0 mt-3">Axborot-resurs markazi 2015 yilda maxsus loyiha asosida qurilib, foydalanishga topshirilgan. Bino 1967.4 m2 ni tashkil qiladi va 500 ming adabiyot sig‘imiga ega. 90 ta o‘ringa ega 3 ta elektron o‘quv zallari, 250 o‘rindan iborat 5 ta an’ananaviy shakldagi adabiyotlar bilan xizmat qiluvchi o‘quv zallari mavjud. Kitobni uyda foydalanish uchun abonement bo‘limi va 200 mingdan ortiq kitoblarni saqlash xonalari mavjud bo‘lib, xonalar barcha me’yoriy talablarga javob beradi. 204, 301 hamda 305 o‘quv zallarida sovutish vositalari (konditsioner) jihozlar bilan ta’minlangan. Foydalanuvchi professor-o‘qituvchi va talabalar uchun barcha sharoitlar yaratilgan.</p>
      <p className="m-0 mt-3">Axborot-resurs markazi 2015 yilda maxsus loyiha asosida qurilib, foydalanishga topshirilgan. Bino 1967.4 m2 ni tashkil qiladi va 500 ming adabiyot sig‘imiga ega. 90 ta o‘ringa ega 3 ta elektron o‘quv zallari, 250 o‘rindan iborat 5 ta an’ananaviy shakldagi adabiyotlar bilan xizmat qiluvchi o‘quv zallari mavjud. Kitobni uyda foydalanish uchun abonement bo‘limi va 200 mingdan ortiq kitoblarni saqlash xonalari mavjud bo‘lib, xonalar barcha me’yoriy talablarga javob beradi. 204, 301 hamda 305 o‘quv zallarida sovutish vositalari (konditsioner) jihozlar bilan ta’minlangan. Foydalanuvchi professor-o‘qituvchi va talabalar uchun barcha sharoitlar yaratilgan.</p>
      <p className="m-0 mt-3">Axborot-resurs markazi 2015 yilda maxsus loyiha asosida qurilib, foydalanishga topshirilgan. Bino 1967.4 m2 ni tashkil qiladi va 500 ming adabiyot sig‘imiga ega. 90 ta o‘ringa ega 3 ta elektron o‘quv zallari, 250 o‘rindan iborat 5 ta an’ananaviy shakldagi adabiyotlar bilan xizmat qiluvchi o‘quv zallari mavjud. Kitobni uyda foydalanish uchun abonement bo‘limi va 200 mingdan ortiq kitoblarni saqlash xonalari mavjud bo‘lib, xonalar barcha me’yoriy talablarga javob beradi. 204, 301 hamda 305 o‘quv zallarida sovutish vositalari (konditsioner) jihozlar bilan ta’minlangan. Foydalanuvchi professor-o‘qituvchi va talabalar uchun barcha sharoitlar yaratilgan.</p>
      <p className=" mt-3 mb-3">Axborot-resurs markazining 5 ta talabalar turar joyi kutubxonalarida o‘quv zali mavjud bo‘lib, kerakli barcha   o‘quv adabiyotlar bilan ta’minlangan. ARM xodimlarini asosiy ish vaqti 8-00 dan 22-00 gacha bo‘lib, talabalar turar joylarida jadval asosidagi ish vaqti 18-00 dan 22-00 gacha tashkil qilingan. </p>
    </div>
  );
};

export default Material_technical_base;
