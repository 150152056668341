import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { Link, useParams } from "react-router-dom";

const Book_directionAdd = () => {
  const [fansoxasiSelect, setFansoxasiSelect] = useState([]);
  const [nomi, setNomi] = useState("");
  const [fan_sohasi_id, setFan_sohasi_id] = useState("");
  const [izoh, setIzoh] = useState("");
  const [text, setText] = useState("");
  const [active, setActive] = useState("");
  const { id } = useParams();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Yangi kitob yo'nalishi yaratildi.") {
      toast.success(text);
    } else if (text === "Kitob yo'nalishi bazadan o'zgartirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };

  // selectga get
  const getFansoxasiSelect = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/fansohasi", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setFansoxasiSelect(res.data.fanSohalari);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  useEffect(() => {
    getFansoxasiSelect();
  }, []);

  const addKitobYunalish = () => {
    if(id){
        axios
        .put(
          BaseUrl + "/api/kutubxonachi/kitobyunalishi/edit/" + id,
          {fan_sohasi_id, nomi, izoh, active },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data.message);
          if (res.status == 200) {
            setText(res.data.message);
            setNomi("");
            setIzoh("");
            
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          setText(err.response.data.message);
          setText(err.response.data.errors);
        });
    }else{
        axios
          .post(
            BaseUrl + "/api/kutubxonachi/kitobyunalishi",
            { fan_sohasi_id, nomi },
            {
              headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res.data.message);
            if (res.status == 200) {
              setText(res.data.message);
              setNomi("");
            }
          })
          .catch((err) => {
            console.log(err.response);
            setText(err.response.data.errors);
          });

    }
  };

// edit input value
  const getBook_direction = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobyunalishi", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        res.data.kitobYunalishlari.forEach(item => {
            // console.log(item.id);
          if (item.id == id) {
            setFan_sohasi_id(item.fan_sohasi_id)
            setNomi(item.nomi);
            setIzoh(item.izoh);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBook_direction();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div className="navBar">
        <p>Kitob yo'nalishi {id ? "o'zgartirish" : "yaratish"}</p>
        <ToastContainer />
        <Link to={sessionStorage.getItem("userId") == 1 ? "/Supermain/book_direction" : "/adminMain/book_direction"}>
          <div className="add-kafedra">Orqaga</div>
        </Link>
      </div>
      <hr />

      <div className="row">
        <div className="col-md-4 col-sm-12">
          <div className="card shadow p-3">
            <div>
              <label htmlFor="">Kitob yo'nalishi tanlang</label>
            </div>
            <select
              onChange={(e) => setFan_sohasi_id(e.target.value)}
              className="form-select"
              name=""
              id=""
            >
              <option value="">tanlang</option>
              {fansoxasiSelect &&
                fansoxasiSelect.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nomi}
                  </option>
                ))}
            </select>
            <div>
              <label className="mt-3" htmlFor="">
                Nomini kiriting
              </label>
            </div>
            <input
              onChange={(e) => setNomi(e.target.value)}
              value={nomi}
              placeholder="Nomi..."
              className="form-control"
              type="text"
            />
            <div>
              <label className="mt-3" htmlFor="">
                Izoh kiriting
              </label>
            </div>
            <input
              onChange={(e) => setIzoh(e.target.value)}
              value={izoh}
              placeholder="Izoh..."
              className="form-control"
              type="text"
            />
            <label className="mt-3" htmlFor="">
              Faoligi
            </label>
            <select
              className="form-select "
              onChange={(e) => setActive(e.target.value)}
              name=""
              id=""
            >
              <option value="1">tanlang</option>
              <option value="1">Faol</option>
              <option value="0">Faol emas</option>
            </select>
            <button onClick={addKitobYunalish} className="btn btn-primary mt-3">
              {id ? "O'zgartirish" : "Yaratish"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book_directionAdd;
