import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsSearch } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import "../../userComponents/HeaderMenuPage/Electronic_catalog/Electronic_catalog.css";
import { useNavigate } from "react-router-dom";
import { CiSquareRemove } from "react-icons/ci"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { BaseUrl } from "../../../Super_admin/BaseUrl";


const IlmiyNashr = () => {

    const navigate = useNavigate()

    const [pageCount, setPageCount] = useState();
    const [scientificData, setScientificData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);

    const getScientificData = (page = 1) => {
        axios.get(BaseUrl + `/api/public/ilmiynashrkatalog?page=${page}&search=${searchTerm}`).then((res) => {
            setScientificData(res.data.ilmiyNashrlar.data);
            setPageCount(res.data.ilmiyNashrlar.last_page);
        })
            .catch((err) => {
                console.log(err);
            });
    };


    useEffect(() => {
        getScientificData();
    }, [searchTerm])

    useEffect(() => {
        setCurrentItems(scientificData && scientificData.slice(itemOffset));
    }, [itemOffset, scientificData]);

    const handlePageClick = (event) => {
        getScientificData(event.selected + 1)
    };


    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-4 offset-md-4 col-sm-12">
                    <div class="input-group input-group-sm mb-3">
                        <input
                            onChange={event => { setSearchTerm(event.target.value) }}
                            class="form-control"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-sm"
                            type="search"
                        />
                        <span class="input-group-text" id="inputGroup-sizing-sm">
                            <BsSearch />
                        </span>
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">Rasmi</th>
                            <th scope="col">Nomi</th>
                            <th scope="col">Ilmiy nashr turi</th>
                            <th scope="col">Yil</th>
                            <th scope="col">Davlat</th>
                            <th scope="col">Faolmi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems && currentItems.map((item, index) => (
                            <tr>
                                <th scope="row">{item.id}</th>
                                <td ><img src={BaseUrl + '/storage/' + item.muqova_jpg} alt="book-img" width='80' height='100px' /></td>
                                <td>{item.nomi}</td>
                                <td>{item.ilmiy_nashr_turi.nomi}</td>
                                <td>{item.yili}</td>
                                <td>{item.country.nomi}</td>
                                <td>
                                    {
                                        item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                            :
                                            <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                    }

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
        </div>
    );
};

export default IlmiyNashr;
