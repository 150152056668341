import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import { BaseUrl } from "../BaseUrl";
import axios from "axios";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const StudentRegister = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [lavozim, setLavozim] = useState([]);
  const [fakultet, setFakultet] = useState([]);
  const [yunalish, setYunalish] = useState([]);
  const [uquvyili, setUquvyili] = useState([]);
  const [bulim, setBulim] = useState([]);
  const [hemisId, setHemisId] = useState("");
  const [hemisIdCheck, setHemisIdCheck] = useState(true);
  const [kafedra, setKafedra] = useState([]);
  const [images, setImages] = useState(null);
  const [passportImg, setPassportImg] = useState(null);
  const [position, setPosition] = useState();
  const [facultet, setFacultet] = useState();
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    date_birth: "",
    email: "",
    password: "",
    password_confirmation: "",
    jinsi: "",
    rasm: "",
    lavozim_id: "",
    yunalish_id: "",
    uquv_yili_id: "",
    kursi: "",
    bulim_id: "",
    kafedra_id: "",
    izoh: "",
    tel_nomer: "",
    telegram_link: "",
    manzili: "",
    passport: "",
    jshshir: "",
    passport_pdf: "",
  });
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  const checkedHemisId = async () => {
    try {
      if (hemisId.length === 12) {
        const res = await axios.get(
          `https://akapi.andalofai.uz/api/check-user/${hemisId}`
        );
        setHemisIdCheck(res.data.isHas);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    checkedHemisId();
  }, [hemisId]);

  const handleChange = (e) => {
    setValues(() => {
      return {
        ...values,
        [e.target.name]: e.target.value,
      };
    });
  };

  const getFoydalanuvchi = async () => {
    try {
      const res = await axios.get(BaseUrl + `/api/public/fturi`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setUsers(res.data.fTurlari);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getLavozim = async () => {
    try {
      const res = await axios.get(BaseUrl + `/api/public/lavozim/${position}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setLavozim(res.data.lavozimlar);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getFakultet = async () => {
    try {
      const res = await axios.get(BaseUrl + `/api/public/fakultet`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setFakultet(res.data.fakultetlar);
      console.log(res);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getYunalish = async () => {
    console.log("facultet", facultet);
    try {
      const res = await axios.get(
        BaseUrl + `/api/public/yunalish/${facultet}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setYunalish(res.data.yunalishlar);
      console.log(yunalish);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getUquvYili = async () => {
    try {
      const res = await axios.get(BaseUrl + `/api/public/uquvyili`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setUquvyili(res.data.uquvYillari);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getBulim = async () => {
    try {
      const res = await axios.get(BaseUrl + `/api/public/bulim`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setBulim(res.data.bulimlar);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getKafedra = async () => {
    try {
      const res = await axios.get(BaseUrl + `/api/public/kafedra`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setKafedra(res.data.kafedralar);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        ...values,
        passport_pdf: passportImg,
        rasm: images,
        f_turi_id: position,
        fakultet_id: facultet,
        hemis_id: hemisId,
      };
      await axios.post(BaseUrl + `/api/register`, body);
      navigate("/auth");
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    getFoydalanuvchi();
    checkedHemisId();
    getLavozim();
    getFakultet();
    getYunalish();
    getUquvYili();
    getBulim();
    getKafedra();
  }, [position, facultet]);

  const notify = (text) => {
    toast.error(text);
  };

  return (
    <div>
      <div className="for_main_img2">
        {loading == true ? (
          <div className="for_loader">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            <ToastContainer />
            <div className="for_blur2">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img className="for_logo" src="/images/armlogo.png" />
              </div>
              <p className="for_title">
                Andijon davlat universiteti axborot resurs markazi <br />
                Ro'yxatdan o'tish
              </p>
              <form onSubmit={handleSubmit}>
                <div
                  className="form_data d-flex"
                  style={{ justifyContent: "space-around" }}
                >
                  <div style={{ width: "100%" }}>
                    <div className="row">
                      <div className="col-md-12">
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Foydalanuvchi turini tanlang
                          <span className="text-danger">* </span> :
                        </label>
                        <select
                          defaultValue={position}
                          onChange={(e) => {
                            setPosition(e.target.value);
                          }}
                          className="form-select w-100 mb-2"
                          aria-label="Default select example"
                        >
                          <option value={"tanlash"} selected disabled>
                            Tanlash
                          </option>
                          {users?.map((v) => (
                            <option key={v.id} value={v.id}>
                              {v.nomi}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        {position === "2" && (
                          <div>
                            <label
                              htmlFor=""
                              className="form-label"
                              style={{ color: "white" }}
                            >
                              Hemis id <span className="text-danger">* </span>:
                            </label>
                            <div class="input-group mt-1 mb-2">
                              <input
                                required
                                type="text"
                                onChange={(e) => setHemisId(e.target.value)}
                                name="hemis_id"
                                class="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                              />
                              <div className="text-danger mt-2">
                                {
                                  !hemisIdCheck &&"Siz ro'yxatdan o'tgansiz login parolni kutubxonachidan oling"
                                }
                              </div>
                            </div>
                          </div>
                        )}
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Ismingiz <span className="text-danger">* </span>:
                        </label>
                        <div className="input-group mt-1 mb-1">
                          <input
                            type="text"
                            onChange={handleChange}
                            value={values.firstname}
                            name="firstname"
                            required
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Familyangiz <span className="text-danger">* </span>:
                        </label>
                        <div class="input-group mt-1 mb-2">
                          <input
                            required
                            onChange={handleChange}
                            value={values.lastname}
                            name="lastname"
                            type="text"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Otangizni ismi
                          <span className="text-danger">* </span>:
                        </label>
                        <div className="input-group mt-1 mb-1">
                          <input
                            required
                            type="text"
                            onChange={handleChange}
                            value={values.middlename}
                            name="middlename"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Tug'ilgan sanangiz
                          <span className="text-danger">* </span>:
                        </label>
                        <div class="input-group mt-1 mb-2">
                          <input
                            type="text"
                            required
                            onChange={handleChange}
                            value={values.date_birth}
                            name="date_birth"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Email <span className="text-danger">* </span>:
                        </label>
                        <div class="input-group mt-1 mb-2">
                          <input
                            required
                            type="email"
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Parol <span className="text-danger">* </span>:
                        </label>
                        <div class="input-group mt-1 mb-2">
                          <input
                            required
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            type="text"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Parolni qayta kiriting
                          <span className="text-danger">* </span>:
                        </label>
                        <div class="input-group mt-1 mb-2">
                          <input
                            required
                            onChange={handleChange}
                            value={values.password_confirmation}
                            name="password_confirmation"
                            type="text"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Rasm yuklash <span className="text-danger">* </span>:
                        </label>
                        <div class="input-group mt-1 mb-2">
                          <input
                            type="file"
                            onChange={(e) => setImages(e.target.files[0])}
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Jinsni tanlang
                          <span className="text-danger">* </span>:
                        </label>
                        <select
                          onChange={handleChange}
                          name="jinsi"
                          className="form-select w-100  mt-1 mb-2 "
                          aria-label="Default select example"
                        >
                          <option selected disabled>
                            Tanlash
                          </option>
                          <option value="erkak">Erak</option>
                          <option value="ayol">Ayol</option>
                        </select>
                      </div>
                      {position === "2" && (
                        <div className="col-md-12">
                          <label
                            htmlFor=""
                            className="form-label"
                            g
                            style={{ color: "white" }}
                          >
                            Ta'lim shakli
                            <span className="text-danger">* </span>:
                          </label>
                          <select
                            onChange={handleChange}
                            name="lavozim_id"
                            className="form-select w-100 mt-1 mb-2"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Tanlash
                            </option>
                            {lavozim?.map((v) => (
                              <option key={v.id} value={v.id}>
                                {v.nomi}
                              </option>
                            ))}
                          </select>
                          <label
                            htmlFor=""
                            className="form-label"
                            style={{ color: "white" }}
                          >
                            Fakulteti <span className="text-danger">* </span>:
                          </label>
                          <select
                            onChange={(e) => setFacultet(e.target.value)}
                            className="form-select w-100 mt-1 mb-2"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Tanlash
                            </option>
                            {fakultet.map((v) => (
                              <option key={v.id} value={v.id}>
                                {v.nomi}
                              </option>
                            ))}
                          </select>
                          <label
                            htmlFor=""
                            className="form-label"
                            style={{ color: "white" }}
                          >
                            Yo'nalishi <span className="text-danger">* </span>:
                          </label>
                          <select
                            onChange={handleChange}
                            name="yunalish_id"
                            className="form-select w-100 mt-1 mb-2"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Tanlash
                            </option>
                            {yunalish.map((v) => (
                              <option key={v.id} value={v.id}>
                                {v.nomi}
                              </option>
                            ))}
                          </select>
                          <label
                            htmlFor=""
                            className="form-label"
                            style={{ color: "white" }}
                          >
                            O'qishga kirgan yilingiz <span className="text-danger">* </span>:
                          </label>
                          <select
                            onChange={handleChange}
                            name="uquv_yili_id"
                            className="form-select w-100 mt-1 mb-2"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Tanlash
                            </option>
                            {uquvyili.map((v) => (
                              <option key={v.id} value={v.id}>
                                {v.nomi}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {position === "1" && (
                        <div className="col-md-12">
                          <label
                            htmlFor=""
                            className="form-label"
                            style={{ color: "white" }}
                          >
                            Lavozimi <span className="text-danger">* </span>:
                          </label>
                          <select
                            onChange={handleChange}
                            name="lavozim_id"
                            className="form-select w-100 mt-1 mb-2"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Tanlash
                            </option>
                            {lavozim.map((v) => (
                              <option key={v.id} value={v.id}>
                                {v.nomi}
                              </option>
                            ))}
                          </select>

                          <label
                            htmlFor=""
                            className="form-label"
                            style={{ color: "white" }}
                          >
                            Kafedira <span className="text-danger">* </span>:
                          </label>
                          <select
                            onChange={handleChange}
                            name="kafedra_id"
                            className="form-select w-100 mt-1 mb-2"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Tanlash
                            </option>
                            {kafedra.map((v) => (
                              <option key={v.id} value={v.id}>
                                {v.nomi}
                              </option>
                            ))}
                          </select>
                          <label
                            htmlFor=""
                            className="form-label"
                            style={{ color: "white" }}
                          >
                            Bo'limlar <span className="text-danger">* </span>:
                          </label>
                          <select
                            onChange={handleChange}
                            name="bulim_id"
                            className="form-select w-100 mt-1 mb-2"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Tanlash
                            </option>
                            {bulim.map((v) => (
                              <option key={v.id} value={v.id}>
                                {v.nomi}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {position === "3" && (
                        <div className="col-md-12">
                          <label
                            for="floatingTextarea2"
                            className="text-white mb-2 mt-1"
                          >
                            Ta'lim muassasangiz va o'zingiz haqingizda to'liq
                            ma'lumot yozing !
                            <span className="text-danger">* </span>
                          </label>
                          <textarea
                            required
                            onChange={handleChange}
                            value={values.izoh}
                            name="izoh"
                            class="form-control"
                            id="floatingTextarea2"
                            style={{ height: "100px" }}
                          ></textarea>
                          <label
                            htmlFor=""
                            className="form-label mt-1"
                            style={{ color: "white" }}
                          >
                            Toifangiz <span className="text-danger">* </span>:
                          </label>
                          <select
                            onChange={handleChange}
                            name="lavozim_id"
                            className="form-select w-100 mt-1 mb-2"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Tanlash
                            </option>
                            {lavozim?.map((v) => (
                              <option key={v.id} value={v.id}>
                                {v.nomi}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Telefon <span className="text-danger">* </span>:
                        </label>
                        <div className="input-group mt-1 mb-1">
                          <input
                            required
                            onChange={handleChange}
                            value={values.tel_nomer}
                            name="tel_nomer"
                            type="number"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Telegram manzili
                          <span className="text-danger">* </span>:
                        </label>
                        <div class="input-group mt-1 mb-2">
                          <input
                            required
                            onChange={handleChange}
                            value={values.telegram_link}
                            name="telegram_link"
                            type="text"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Manzili <span className="text-danger">* </span>:
                        </label>
                        <div className="input-group mt-1 mb-1">
                          <input
                            required
                            onChange={handleChange}
                            value={values.manzili}
                            name="manzili"
                            type="text"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Passport seryasi
                          <span className="text-danger">* </span>:
                        </label>
                        <div className="input-group mt-1 mb-1">
                          <input
                            required
                            onChange={handleChange}
                            value={values.passport}
                            name="passport"
                            type="text"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          Passport rasmi (pdf holatda)
                          <span className="text-danger">* </span>:
                        </label>
                        <div class="input-group mt-1 mb-2">
                          <input
                            onChange={(e) => setPassportImg(e.target.files[0])}
                            type="file"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ color: "white" }}
                        >
                          JSHSHIR <span className="text-danger">* </span>:
                        </label>
                        <div class="input-group mt-1 mb-2">
                          <input
                            required
                            onChange={handleChange}
                            value={values.jshshir}
                            name="jshshir"
                            type="text"
                            class="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-success mt-3">
                  Ro'yxatdan o'tish
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StudentRegister;
