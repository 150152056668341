import './AttachUser.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BaseUrl } from '../../Super_admin/BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HiOutlineCheckCircle } from "react-icons/hi";
import { CiSquareRemove } from "react-icons/ci";
import moment from 'moment/moment';


function AttachUser() {

    const [userData, setUserData] = useState([]);
    const [bookData, setBookData] = useState([]);
    const [search, setSearch] = useState('');

    const getOneUser = () => {
        axios.get(BaseUrl + `/api/admin/kitobberish/show/${sessionStorage.getItem("attachId")}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            setUserData(res?.data?.user)
        }).catch(err => {
            console.log(err);
        })
    }

    const getOneBook = () => {
        
        axios.get(BaseUrl + `/api/kutubxonachi/kitobinventar/kitobberish/${search}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res);
            if (res.data.inventar !== null) {
                setBookData(res?.data?.inventar)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getOneUser()
    }, [])

    useEffect(() => {
        getOneBook()
    }, [search])


    // console.log('search encode', encodeURIComponent(search));
    // console.log('search decode', decodeURIComponent(search));

    /////////////////////////////////////////////////////////////////////////////

    const [oneBook, setOneBook] = useState({ inventar_id: '', user_id: '', kitob_id: '' })

    useEffect(() => {
        if (bookData) {
            setOneBook({
                ...oneBook,
                inventar_id: bookData?.id,
                user_id: userData?.id,
                kitob_id: bookData.kitob_id
            })
        }
    }, [bookData])


    const saveBook = (event) => {
        event.preventDefault()

        axios.post(BaseUrl + "/api/admin/kitobberishtuplash", oneBook, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            getAllBooks()
        }).catch(err => {
            console.log(err);
        })
    }

    ////////////////////////////////////////////////////////////////////////

    const [allBooks, setAllBooks] = useState([]);

    const getAllBooks = () => {
        axios.get(BaseUrl + "/api/admin/kitobberishtuplash/" + userData?.id, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            setAllBooks(res?.data?.massiv)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getAllBooks()
    }, [userData.id])


    ////////////////////////////////////////////////////////////////////////

    const [post, setPost] = useState({
        items: {},
        userId: "",
        user_id: "",
        muddati: ""
    });

    useEffect(() => {
        setPost({
            ...post,
            items: allBooks,
            userId: sessionStorage.getItem("userId"),
            user_id: userData.id
        })
    }, [allBooks, userData])


    const postAllBooks = (e) => {
        e.preventDefault()

        axios.post(BaseUrl + "/api/admin/kitobberish", post, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            toast.success(res?.data?.message);
            getOneUser()
            getAllBooks()
        }).catch(err => {
            toast.warning(err?.response?.data?.errors);
        })
    }

    ////////////////////////////////////////////////////////////////////////

    const removeSelectedBook = (id) => {
        axios.delete(BaseUrl + `/api/admin/kitobtanlash/${id}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            toast.warning(res?.data?.message);
            getAllBooks()
        }).catch(err => {
            console.log(err);
        })
    }

    // console.log('bookData', bookData);

    return (
        <div className='container my-5'>
            <div className='row'>
                <div className='col-md-4'>
                    {
                        userData?.rasm == null ? <img src={require('../assets/user-avatar.png')} alt="user-img" width='300' height='350px' /> :
                            <img src={BaseUrl + '/storage/' + userData?.rasm} alt="user-img" width='270' height='350px' />
                    }
                </div>
                <div className='col-md-8'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4'>
                            <b>Faolmi:</b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            {
                                userData.active == 1 ? <div className='chek'>
                                    <HiOutlineCheckCircle style={{ color: "white" }} />
                                </div>
                                    :
                                    <div className='chek2'>
                                        <CiSquareRemove style={{ color: "white" }} />
                                    </div>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4'>
                            <b>F.I.SH:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.firstname + ' ' + userData?.lastname + ' ' + userData?.middlename}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4'>
                            <b>Foydalanuvchi:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.f_turi?.nomi == null ? 'topilmadi' : userData?.f_turi?.nomi}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Tug'ilgan sana:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.date_birth == null ? 'topilmadi' : userData?.date_birth}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Jinsi:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.jinsi == null ? 'topilmadi' : userData?.jinsi}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Kirgan yili:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.uquv_yili?.nomi == null ? 'topilmadi' : userData?.uquv_yili?.nomi}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Fakultet:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.fakultet?.nomi == null ? 'topilmadi' : userData?.fakultet?.nomi}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Yo'nalish:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.yunalish?.nomi == null ? 'topilmadi' : userData?.yunalish?.nomi}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Ta'lim shakli:</b>
                        </div>
                        <div className='col-md-8'>
                            {userData?.f_turi_id == 2 && userData?.lavozim_id != 4 ?
                                <p>{userData?.lavozim?.nomi != null ? userData?.lavozim?.nomi + ' (bakalavr)' : 'topilmadi'}</p> : <p>{userData?.lavozim?.nomi}</p>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Kursi:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.kursi == null ? 'topilmadi' : userData?.kursi}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Hemis id:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.hemis_id == null ? 'topilmadi' : userData?.hemis_id}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Email:</b>
                        </div>
                        <div className='col-md-8'>
                            <p>{userData?.email == null ? 'topilmadi' : userData?.email}</p>
                        </div>
                    </div>
                </div>
                <div className='row my-4'>
                    <div className='col-lg-12'>
                        <input type="text" className='form-control form-control-sm p-2' placeholder="Kitob inventar raqamini kiriting" onChange={(e) => setSearch(e.target.value)} />
                        <div className='row my-5'>
                            <div className='col-md-4'>
                                {
                                    bookData?.kitob?.muqova_jpg == null ? '' : <img src={BaseUrl + '/storage/' + bookData?.kitob?.muqova_jpg} alt="book-img" width='300' height='300px' />
                                }
                            </div>
                            <div className='col-md-8'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        {bookData === undefined ? '' : 'kitob nomi'}
                                    </div>
                                    <div className='col-md-8'>
                                        <p>{bookData?.kitob?.nomi}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        {bookData === undefined  ? '' : 'nashriyot nomi'}
                                    </div>
                                    <div className='col-md-8'>
                                        <p>{bookData?.kitob?.nashriyot_nomi}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        {bookData === undefined  ? '' : 'inventar raqami'}
                                    </div>
                                    <div className='col-md-8'>
                                        <p>{bookData?.inventar_raqam}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        {bookData === undefined  ? '' : 'mualliflar'}
                                    </div>
                                    <div className='col-md-8'>
                                        {
                                            bookData?.mualliflar?.map(item => {
                                                return (
                                                    <p key={item?.id}>{item?.nomi}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        {bookData === undefined  ? '' : 'nashr yili'}
                                    </div>
                                    <div className='col-md-8'>
                                        <p>{bookData?.kitob?.nash_yili}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        {
                                            bookData === undefined  ? '' : <button className='btn btn-success' onClick={saveBook}>kitobni tanlash</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row my-5'>
                                {
                                    allBooks != '' ? <h3>Tanlangan kitoblar:</h3> : ''
                                }
                                <div className='col-md-12'>
                                    <table className='table'>
                                        <thead>
                                            {
                                                allBooks != '' ? <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Inventar raqam</th>
                                                    <th scope="col">Kitob nomi</th>
                                                    <th scope="col">O'chirish</th>
                                                </tr> : ''
                                            }
                                        </thead>
                                        <tbody>
                                            {
                                                allBooks?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item?.id}</td>
                                                            <td>{item?.inventar?.inventar_raqam}</td>
                                                            <td>{item?.kitob?.nomi}</td>
                                                            <td><button className='btn btn-danger' onClick={() => removeSelectedBook(item?.id)}>o'chirish</button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        allBooks != '' ?
                                            <div className='row my-5'>
                                                <div className="col-md-6">
                                                    <label className="form-label">Kitob qaytarish muddatini kiriting:</label>
                                                    <input type="date" placeholder="Example@0717" className="form-control w-50" onChange={(e) => setPost({ ...post, muddati: e.target.value })} />
                                                </div>
                                                <div className="col-md-6 my-4">
                                                    <button className='btn btn-success w-50' onClick={postAllBooks}>saqlash</button>
                                                </div>
                                            </div> : ''
                                    }
                                    <div className='row my-5'>
                                        <h3>Biriktirilgan kitoblar:</h3>
                                        <div className='col-md-12 my-3'>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Kitob nomi</th>
                                                        <th>Inventar raqam</th>
                                                        <th>Olgan vaqti</th>
                                                        <th>Topshirish muddati</th>
                                                        <th>Status</th>
                                                        <th>Kitob rasmi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        userData?.olgan_kitoblar?.map((item, index) => {

                                                            let currentTime = new Date().getTime();
                                                            let takenTime = new Date(item?.muddati).getTime();
                                                            let resultTime = currentTime - takenTime;
                                                            let daysLeft = Math.floor(resultTime / (1000 * 60 * 60 * 24));

                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item?.id}</td>
                                                                    <td>{item?.kitobInventar?.kitob.nomi}</td>
                                                                    <td>{item?.kitobInventar?.inventar_raqam}</td>
                                                                    <td>{moment(item?.created_at).format('YYYY-MM-DD')}</td>
                                                                    <td>{item?.muddati}</td>
                                                                    <td>
                                                                        {item?.status == 2 ? <button className='btn btn-danger'>muddati o'tkan ({`${daysLeft}`} kun)</button> :
                                                                            item?.status == 1 ? <button className='btn btn-warning'>o'qilmoqda</button> : item?.status == 0 ? <button className='btn btn-success'>topshirgan</button> : item?.status}
                                                                    </td>
                                                                    <td>
                                                                        <img src={BaseUrl + '/storage/' + item?.kitobInventar?.kitob?.muqova_jpg} alt="user-img" width='50' height='50px' />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <ToastContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AttachUser