import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { BookCatalogAction } from "../../redux/actions/Action";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";

const BandKitoblar = () => {
  const [text, setText] = useState(false);

  const [userCount, setUserCount] = useState(15);
  const [pageCount, setPageCount] = useState();
  const [usersData, setUsersData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const itemsPerPage = userCount;
  //  console.log("statusFilter", statusFilter);
  const navgate = useNavigate();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Kitob berish uchun tayyor.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };
  const getAllUsers = (page = 1) => {
    axios
      .get(
        BaseUrl +
          `/api/kutubxonachi/kitobbronlar?paginator=${userCount}&page=${page}&search=${searchTerm}&status=${statusFilter}`,
        {
          headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
        }
      )
      .then((res) => {
        sessionStorage.setItem("userDataCount", res.data.kitobBronlar.total);
        setPageCount(res.data.kitobBronlar.last_page);
        setUsersData(res.data.kitobBronlar.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [userCount, searchTerm]);

  const handlePageClick = (event) => {
    getAllUsers(event.selected + 1);
  };

  // const [filterData, setFilterData] = useState()

  // const selectFiterFun =()=>{
  //    const arrdata = usersData.filter(v => v.status === Number(statusFilter))
  //     setFilterData(arrdata)
  //     console.log("ishladi", arrdata);
  // }

  useEffect(() => {
    getAllUsers();
  }, [statusFilter]);

  // kitob berish post

  // const user_id =  sessionStorage.getItem("userId")
  const addBook_type = (bronId) => {
    axios
      .post(
        BaseUrl + `/api/kutubxonachi/kitobbronlar/kitobberish/${bronId}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.status == 200) {
          setText(res.data.message);
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.response.data.errors);
        setText(err.response.data.errors);
      });
  };


  // 
  const getOneUser = (id) => {
    axios.get(BaseUrl + `/api/kutubxonachi/users/show/${id}`, {
        headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        }
    }).then(res => {
        sessionStorage.setItem('attachId', id);
        navgate("/adminMain/getbooks/attachuser/" + id)
    }).catch(err => {
        console.log(err);
    })
}
  return (
    <div>
      <div className="navBar">
        <p>Band qilingan kitoblar</p>
      </div>
      <ToastContainer />
      <hr />
      <div className="d-flex justify-content-between">
        <select
          onChange={(e) => setUserCount(+e.target.value)}
          className="form-select getbooks-option w-auto">
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>

        <div className="for-search">
          <div className="for-search2">
            <img
              style={{
                width: "30px",
                position: "absolute",
                margin: "5px 0 0 5px",
              }}
              src="/images/search.png"
              alt=""
            />
          </div>
          <input
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            className="form-control search_input"
            type="search"
          />
        </div>
        <select
          onChange={(e) => setStatusFilter(e.target.value)}
          className="form-select getbooks-option w-auto">
          <option value="">Barchasi</option>
          <option value="0">Yangi</option>
          <option value="1">Tasdiqlangan</option>
          <option value="2">Kitob berilgan</option>
          <option value="3">Muddati o'tgan</option>
        </select>
      </div>
      <ToastContainer />
      <hr />
      <table class="table">
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">Muqova tasviri</th>
            <th scope="col">Kitob nomi</th>
            <th scope="col">Inventar raqam</th>
            <th scope="col">Hemis ID</th>
            <th scope="col">Foydalanuvchi</th>
            <th scope="col">Status</th>
            <th scope="col">Ko'rish</th>
          </tr>
        </thead>
        <tbody>
          {usersData?.map((item, index) => (
            <tr>
              <th scope="row">{item.id}</th>
              <td>
                <img
                  width={100}
                  height={130}
                  src={BaseUrl + "/storage/" + item.kitob.muqova_jpg}
                  alt="rasm yo'q"
                />
              </td>
              <td>{item.kitob.nomi}</td>
              {/* {item.inventar.inventar_raqam} */}
              <td>{item.inventar?.inventar_raqam}</td>
              <td>{item.user.hemis_id}</td>
              <td>
                {item.user.lastname} {item.user.firstname}
              </td>
              <td>
                {item.status == 0
                  ? "Yangi"
                  : item.status == 1
                  ? "Tasdiqlangan"
                  : item.status == 2
                  ? "Kitob berilgan"
                  : "Muddati o'tgan"}
              </td>
              {/* <td>{item.umumiySoni}</td>
                  <td>{item.soni}</td>
                  <td>{item.bbk}</td>
                  <td style={{ width: "100px" }}>
                    {item.anotatsiya.slice(0, 80) + "..."}
                  </td>
                  <td>{item.elektroniBormi == 1 ? "Bor" : "Yo'q"}</td> */}
              <td>
                <div className="main_edite-box d-flex justify-content-between  align-items-center">
                  <div className="edite_show_box ">
                    <div
                      className="show_data "
                      onClick={() =>
                        navgate(`/adminMain/bandKitoblar/show/${item.id}`)
                      }>
                      <AiOutlineEye />
                    </div>
                  </div>
                  {item.status == 1 && (
                    <button
                      onClick={() => {addBook_type(item.id);  getOneUser(item?.user?.id) }}
                      type="button"
                      class="btn btn-outline-success ">
                      Kitob berish
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <b>
        Jami {sessionStorage.getItem("userDataCount")} ta ma'lumotdan
        {currentItems.length} ko'rsatilmoqda
      </b>

      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default BandKitoblar;
