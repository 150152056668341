import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { GetMessageData } from "../../../redux/actions/Action";

const BandKitoblarShow = () => {
  const { id } = useParams();
  const [bandKitobShow, setBandKitobShow] = useState([]);
  const [izoh, setIzoh] = useState("");
  const [text, setText] = useState("");


  const getBandKitobShow = () => {
    axios
      .get(BaseUrl + `/api/kutubxonachi/kitobbronlar/show/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setBandKitobShow(res.data.kitobBron);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getBandKitobShow();
  }, []);

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Kitob band qilish so'rovi tasdiqlandi.") {
      toast.success(text);
    }else {
      toast.error(text);
    }
    setText("");
  };
  const [massegeBandK , setMassegBandK] = useState()
  const user_id =  sessionStorage.getItem("userId") 
  const dispatch = useDispatch()
  const addBook_type = () => {
      axios
        .post(
          BaseUrl + `/api/kutubxonachi/kitobbronlar/tasdiqlash/${id}`,
          {  izoh, user_id },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          if (res.status == 200) {
            setText(res.data.message);
            console.log(res.data.message);
            setIzoh("");
          }
        })
        .catch((err) => {
          console.log(err.response.data.errors);
          setText(err.response.data.errors);
        });

          axios
            .get(BaseUrl + "/api/kutubxonachi/kitobbronlar/yangibronlar", {
              headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            })
            .then((res) => {
              // console.log("masseg", res.data);
              dispatch(GetMessageData(res.data))
              setMassegBandK(res.data)
            })
            .catch((err) => {
              console.log(err);
            });

      
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="navBar">
        <p>Band qilingan kitoblarni ko'rish </p>
        <ToastContainer />
        <Link to="/adminMain/bandKitoblar">
          <div className="add-kafedra">Orqaga</div>
        </Link>
      </div>
      <hr />
      <div className="card shadow p-3">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item py-2">
                <b>Kitob nomi: </b>
                {bandKitobShow?.kitob?.nomi}
              </li>

              <li class="list-group-item py-2">
                <b>Kitob turi: </b>
                {bandKitobShow?.kitob?.kitob_turi?.nomi}
              </li>

              <li class="list-group-item py-2">
                <b>Fan sohalari: </b>
                {bandKitobShow?.kitob?.fan_sohasi?.nomi}
              </li>

              {/* <li class="list-group-item py-2">
                <b>Kitob mualliflari: </b>
                {bandKitobShow &&
                  bandKitobShow.mualliflar &&
                  bandKitobShow.mualliflar.map((item, index) => {
                    return <span key={index}>{item.nomi}, </span>;
                  })}
              </li> */}

              <li class="list-group-item py-2">
                <b>Kitob nashriyoti nomi: </b>
                {bandKitobShow?.kitob?.nashriyot_nomi}
              </li>

              <li class="list-group-item py-2">
                <b>Kitob annotatsiyasi: </b>
                {bandKitobShow?.kitob?.anotatsiya}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob nashr qilingan yili: </b>
                {bandKitobShow?.kitob?.nash_yili}
              </li>

              <li class="list-group-item py-2">
                <b>Yaratilgan vaqti: </b>
                {new Date(bandKitobShow?.created_at).toLocaleString()}
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item py-2">
                <b>Foydalanuvchi: </b>
                {bandKitobShow?.user?.lastname +
                  " " +
                  bandKitobShow?.user?.firstname}
              </li>
              <li class="list-group-item py-2">
                <b>Foydalanuvchi turi: </b>
                {bandKitobShow?.user?.f_turi?.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>HemisId: </b>
                {bandKitobShow?.user?.hemis_id}
              </li>
              <li class="list-group-item py-2">
                <b>Kursi: </b>
                {bandKitobShow?.user?.kursi}
              </li>
              <li class="list-group-item py-2">
                <b>Fakultet: </b>
                {bandKitobShow?.user?.fakultet?.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Yo'nalish: </b>
                {bandKitobShow?.user?.yunalish?.nomi}
              </li>
              <h4 className="mt-3">Bog'lanish uchun:</h4>
              <hr />
              <li class="list-group-item py-2">
                <b>Telefon: </b>
                {bandKitobShow?.user?.tel_nomer}
              </li>
              <li class="list-group-item py-2">
                <b>Telegram: </b>
                {bandKitobShow?.user?.telegram_link}
              </li>

              <li class="list-group-item py-2">
                <b>e-pochta: </b>
                {bandKitobShow?.user?.email}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <div className="card shadow p-3">
            <p className="fs-5">Buyurtma qilingan kitob tayyorligi haqida foydalanuvchiga xabar yuboring</p>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Izoh </label>
              <textarea 
               onChange={(e) => setIzoh(e.target.value)}
               value={izoh}
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"></textarea>
            </div>
            <button onClick={addBook_type} type="button"  class="btn btn-outline-primary mt-2">Yuborish</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BandKitoblarShow;
