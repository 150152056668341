export const FakultetAction = data =>{
    return{
        type:"GET_FAKULTET",
        payload:data
    }
};
export const KafedraAction = data =>{
    return{
        type:"GET_KAFEDRA",
        payload:data
    }
};
export const BulimAction = data =>{
    return{
        type:"GET_BULIM",
        payload:data
    }
};

export const UserLavozim = data =>{
    return{
        type:"GET_LAVOZIM",
        payload:data
    }
};
export const UserListAction = data =>{
    return{
        type:"GET_USER",
        payload:data
    }
};
export const YunalishAction = data =>{
    return{
        type:"GET_YUNALISH",
        payload:data
    }
};
export const GroupAction = data =>{
    return{
        type:"GET_GROUP",
        payload:data
    }
};
export const UquvYiliAction = data =>{
    return{
        type:"GET_UQUVYILI",
        payload:data
    }
};

export const KutubxonaActions = data =>{
    return{
        type:"GET_KUTUBXONA",
        payload:data
    }
};

export const DavlatAction = data =>{
    return{
        type:"GET_DAVLAT",
        payload:data
    }
};

export const GetMessageData = data =>{
    return{
        type:"GET_MESSAGE",
        payload:data
    }
};


export const ShaharAction = data =>{
    return{
        type:"GET_SHAHAR",
        payload:data
    }
};

export const IlmiyNashAction = data =>{
    return{
        type:"GET_ILMIYNASHR",
        payload:data
    }
}

export const IlmiyNashTuriAction = data =>{
    return{
        type:"GET_ILMIYNASHR_TURI",
        payload:data
    }
}

export const ToplamListAction = data =>{
    return{
        type:"GET_TOPLAMLAR",
        payload:data
    }
}

export const JurnalListAction = data =>{
    return{
        type:"GET_JURNAL",
        payload:data
    }
}
export const JurnalSoniListAction = data =>{
    return{
        type:"GET_JURNAL_SONI",
        payload:data
    }
}
export const MaqolaAction = data =>{
    return{
        type:"GET_MAQOLA",
        payload:data
    }
}

// AdminMain

export const AdminUserListAction = data =>{
    return{
        type:"GET_ADMIN_USERS",
        payload:data
    }
};

export const Book_typeAction = data =>{
    return{
        type:"GET_BOOKTYPE",
        payload:data
    }
};

export const Science_fieldAction = data =>{
    return{
        type:"GET_FIELD",
        payload:data
    }
};
export const Book_directionAction = data =>{
    return{
        type:"GET_BOOK_DIRECTION",
        payload:data
    }
};
export const Book_languageAction = data =>{
    return{
        type:"GET_BOOK_LANGUAGE",
        payload:data
    }
};

export const Book_entryAction = data =>{
    return{
        type:"GET_BOOK_ENTRY",
        payload:data
    }
};

export const Text_typeAction = data =>{
    return{
        type:"GET_TEXT_TYPE",
        payload:data
    }
};

export const File_typeAction = data =>{
    return{
        type:"GET_FILE_TYPE",
        payload:data
    }
};

export const Books_collectionAction = data =>{
    return{
        type:"GET_BOOKS_COLLECTION",
        payload:data
    }
};
export const CategoryAction = data =>{
    return{
        type:"GET_CATEGORY",
        payload:data
    }
};

export const SumerkaAction = data =>{
    return{
        type:"GET_SUMERKA",
        payload:data
    }
};
export const BookCatalogAction = data =>{
    return{
        type:"GET_BOOKCATALOG",
        payload:data
    }
};


