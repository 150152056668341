import "./Profile.css";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import MyProfile from "./myProfile/MyProfile";
import ChangeProfile from "./changeProfile/ChangeProfile";
import { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { RxHamburgerMenu } from "react-icons/rx";
import MyReadBooks from "./myReadBooks/MyReadBooks";
import MyDebts from "./myDebts/MyDebts";
import YuklashSorov from "./myProfile/YuklabOlishSorovlari/YuklashSorov";
import Books from "./Books/Books";
import BookDeatail from "./Books/BookDeatail";
import Buyurtma from "./Buyurtma/Buyurtma";
import KitobUqish from "../userComponents/HeaderMenuPage/Electronic_catalog/Electronic_catalogShow/KitobUqish";

const Profile = () => {
  const [text, setText] = useState("");

  const navigate = useNavigate();

  const logOut = () => {
    axios
      .post(
        BaseUrl + "/api/logout",
        {},
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data.message);
        if (res?.status == 200) {
          setText(res?.data?.message);
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("firstname");
          sessionStorage.removeItem("lastname");
          sessionStorage.removeItem("bulim_id");
          sessionStorage.removeItem("fakultet_id");
          sessionStorage.removeItem("kafedra_id");
          sessionStorage.removeItem("lovozim_id");
          sessionStorage.removeItem("role");
          sessionStorage.removeItem("uquv_yili_id");
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setText(err?.response?.data?.errors);
        if (err.response.status == 401) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("firstname");
          sessionStorage.removeItem("lastname");
          sessionStorage.removeItem("bulim_id");
          sessionStorage.removeItem("fakultet_id");
          sessionStorage.removeItem("kafedra_id");
          sessionStorage.removeItem("lovozim_id");
          sessionStorage.removeItem("role");
          sessionStorage.removeItem("uquv_yili_id");
          navigate("/");
        }
      });
  };

  const [profile, setProfile] = useState([]);

  useEffect(() => {
    axios
      .get(
        BaseUrl + "/api/admin/users/show/" + sessionStorage.getItem("userId"),
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setProfile(res.data.user);
      })
      .catch((res) => {
        console.log(res);
      });
  }, []);

  //////////////////////////////////////////////////////////////////////////

  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    axios
      .get(
        BaseUrl + "/api/admin/users/show/" + sessionStorage.getItem("userId"),
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setUserInfo(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [toggle1, setToggle1] = useState(false);

  const Toggle = () => {
    navigate("/profile")
    setToggle1(!toggle1);
  };
  let [sorovson, setSorov] = useState();
  let [sorovson1, setSorov1] = useState();

  const getYuklashSorovSoni = () => {
    axios
      .get(
        BaseUrl +
          `/api/user/kitobsurovlar/tasdiqsoni/${sessionStorage.getItem(
            "userId"
          )}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setSorov(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBronSorovSoni = () => {
    axios
      .get(
        BaseUrl +
          `/api/user/kitobbron/tasdiqsoni/${sessionStorage.getItem("userId")}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setSorov1(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getYuklashSorovSoni();
    getBronSorovSoni();
  }, []);

  return (
    <div className="main-container">
      <div className={toggle1 ? "side-bar2 shadow" : "side-bar shadow"}>
        <div className="for-logo">
          <img
            style={{ width: "70px", height: "60px", borderRadius: "50%" }}
            src={require("../assets/armlogo.png")}
            alt=""
          />
        </div>
        <div className="sidebar my-5">
          <Link to="/profile/myprofile">
            <div className="sidebar-menu" onClick={() => setToggle1(false)}>
              Profilim
            </div>
          </Link>
          <Link to="/">
            <div className="sidebar-menu" onClick={() => setToggle1(false)}>Bosh sahifaga qaytish</div>
          </Link>
          <Link to="myreadbooks">
            <div className="sidebar-menu" onClick={() => setToggle1(false)}>Men o'qigan kitoblar</div>
          </Link>
          <Link to="mydebts">
            <div className="sidebar-menu" onClick={() => setToggle1(false)}>Mening qarzlarim</div>
          </Link>
          {/* <Link to="#">
                        <div className="sidebar-menu">
                            Maxsus buyurtma
                        </div>
                    </Link> */}
          <Link to="kitoblar">
            <div className="sidebar-menu" onClick={() => setToggle1(false)}>Kitob buyurtma qilish</div>
          </Link>
          <Link to="buyurtmalar">
            <div className="sidebar-menu" onClick={() => setToggle1(false)}>
              Mening buyurtmalarim
              <span className="massage_section">
                <sup>{sorovson1}</sup>
              </span>
            </div>
          </Link>
          <Link to="sorov">
            <div style={{ width: "100%" }} className="sidebar-menu" onClick={() => setToggle1(false)}>
              Yuklab olish so'rovlari
              <span className="massage_section">
                <sup>{sorovson}</sup>
              </span>
            </div>
          </Link>
          <Link to="/profile/changeprofile">
            <div className="sidebar-menu" onClick={() => setToggle1(false)}>Parol o'zgartirish</div>
          </Link>
        </div>
      </div>
      <div className="content">
        <div className="content-header2">
          <div className="for_header">
            <div style={{ display: "flex" }}>
              <div>
                <RxHamburgerMenu className="Navbbar"   onClick={Toggle} />
              </div>
              {/* <div className="for-search">
                                <div className="for-search2">
                                    <img
                                        style={{
                                            width: "30px",
                                            position: "absolute",
                                            margin: "5px 0 0 5px",
                                        }}
                                        src="/images/search.png"
                                        alt=""
                                    />
                                </div>
                                <input className="form-control search_input" type="search" />
                            </div> */}
            </div>

            <div className="for-profile">
              <div
                className="dropdown-toggle dropdown"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={profile && BaseUrl + "/storage/" + profile.rasm}
                  alt=""
                  style={{ width: "60px", height: "50px", borderRadius: "50%" }}
                />

                <ul className="dropdown-menu">
                  <Link
                    onClick={() => navigate("/profile/myprofile")}
                    className="dropdown-item"
                  >
                    <li>Profilim</li>
                  </Link>
                  <li>
                    <Link
                      onClick={() => navigate("/profile/changeprofile")}
                      className="dropdown-item"
                    >
                      Parol o'zgartirish
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => navigate("/profile/sorov")}
                      className="dropdown-item"
                    >
                      Yuklab olish so'rovlari
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="dropdown-item">
                      login: {profile.login}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => logOut()} className="dropdown-item">
                      Chiqish
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-body">
          {/* <div className="for-search3">
            <div className="for-search2">
              <img
                style={{
                  width: "25px",
                  position: "absolute",
                  margin: "5px 0 0 5px",
                }}
                src="/images/search.png"
                alt=""
              />
            </div>
            <input className="form-control search_input" type="search" />
          </div> */}
          {/* content data here */}

          <Routes>
            <Route path="/kitoblar" element={<Books />} />
            <Route path="/kitoblar/show/:id" element={<BookDeatail />} />
            <Route path="/buyurtmalar" element={<Buyurtma />} />
            <Route path="/readbook" element={<KitobUqish />} />
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/changeprofile" element={<ChangeProfile />} />
            <Route path="/sorov" element={<YuklashSorov />} />
            {/* Men o'qigan kitoblar */}
            <Route path="/myreadbooks" element={<MyReadBooks />} />
            {/* Mening qarzlarim */}
            <Route path="/mydebts" element={<MyDebts />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Profile;
