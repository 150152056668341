import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { CategoryAction } from "../../../redux/actions/Action";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { CiSquareRemove } from "react-icons/ci" 

 
const Categoryy = () => {
  const [text, setText] = useState(false);

  const dispatch = useDispatch();
  const category = useSelector((state) => state.store.category);
  // console.log(science_field);
  const navgate = useNavigate();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Kimga muljallanganlik bazadan o'chirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };
  // get
  const getDate = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kimgamuljal", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data.kimgaMuljallanganlar);
        dispatch(CategoryAction(res.data.kimgaMuljallanganlar));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDate();
  }, []);

  const deleteItem = (id) => {
    axios
      .delete(BaseUrl + `/api/kutubxonachi/kimgamuljal/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.message);
        if (res.status == 200) {
          getDate();
          setText(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 13;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(category && category.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(category.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, category]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % category.length;
    setItemOffset(newOffset);
  };
  return (
    <div>
      <div className="navBar">
        <p>Toifasi</p>
        <ToastContainer />
        <Link to={sessionStorage.getItem("userId") == 1 ? "/Supermain/category/add" : "/adminMain/category/add"}>
          <div className="add-kafedra">Yaratmoq</div>
        </Link>
      </div>
      <hr />
      <table class="table">
        {" "}
        <thead>
          <tr>
            <th scope="col">No</th> 
            <th scope="col">Nomi</th> 
            <th scope="col">Izoh</th> 
            <th scope="col">Faolmi?</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((item, index) => (
              <tr>
                <th scope="row">{item.id}</th>
               
                <td>{item.nomi}</td> 
                <td>{item.izoh}</td>
                <td>
                  {item.active === 0 ? (
                    <div className="chek2">
                      <CiSquareRemove style={{ color: "white" }} />
                    </div>
                  ) : (
                    <div className="chek">
                      <HiOutlineCheckCircle style={{ color: "white" }} />
                    </div>
                  )}
                </td>
                <td>
                  <div className="main_edite-box">
                    <div className="edite_show_box">
                      <div
                        className="edite"
                        onClick={() =>
                          navgate(`/adminMain/category/add/${item.id}`)
                        }
                      >
                        <AiOutlineEdit />
                      </div>
                      <div
                        className="delete"
                        onClick={() => deleteItem(item.id)}
                      >
                        <MdOutlineDelete />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default Categoryy;
