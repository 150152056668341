import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../../Super_admin/BaseUrl";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";

const DetailBooks = () => {
  const [detailData, setDetailData] = useState({});
 
  // const { id } = useParams();

  const getDetail = () => {
    axios
      .get(BaseUrl + `/api/kutubxonachi/kitoblar/show/${sessionStorage.getItem("showId")}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        setDetailData(res.data.kitob);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // kitob sumerka
  
  useEffect(() => {
    getDetail();
  }, []);



 
  return (
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item d-flex py-2">
                <span>
                  <b>Faolmi?:</b>
                </span>
                {detailData && detailData.active === 0 ? (
                  <div className="chek2" style={{ marginLeft: "10px" }}>
                    <CiSquareRemove style={{ color: "white" }} />
                  </div>
                ) : (
                  <div className="chek" style={{ marginLeft: "10px" }}>
                    <HiOutlineCheckCircle style={{ color: "white" }} />
                  </div>
                )}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob nomi: </b>
                {detailData && detailData.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Joylashuv indeksi: </b>
                {detailData && detailData.bbk}
              </li>
              <li class="list-group-item py-2">
                <b>Muallif belgisi (Авторский знак): </b>
                {detailData && detailData.mualliflik_belgisi}
              </li>
              <li class="list-group-item py-2">
                <b>Fan sohalari: </b>
                {detailData &&
                  detailData.fan_sohasi &&
                  detailData.fan_sohasi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob mualliflari: </b>
                {detailData &&
                  detailData.mualliflar &&
                  detailData.mualliflar.map((item, index) => {
                    return <span key={index}>{item.nomi}, </span>;
                  })}
              </li>
              <li class="list-group-item py-2">
                <b>UDK: </b>
                {detailData && detailData.udk}
              </li>
              <li class="list-group-item py-2">
                <b>BBK: </b>
                {detailData && detailData.bbk}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob nashriyoti nomi: </b>
                {detailData && detailData.nashriyot_nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob nashr qilingan shahar: </b>
                {detailData && detailData.nashr_shahri}
              </li>
              <li class="list-group-item py-2">
                <b>ISBN: </b>
                {detailData && detailData.isbn}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob annotatsiyasi: </b>
                {detailData && detailData.anotatsiya}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob nashr qilingan yili: </b>
                {detailData && detailData.nash_yili}
              </li>
              <li class="list-group-item py-2">
                <b>Betlar Soni: </b>
                {detailData && detailData.betlar_soni}
              </li>
              <li class="list-group-item py-2">
                <b>Narxi: </b>
                {detailData && detailData.narxi}
              </li>
              <li class="list-group-item py-2">
                <b>Yaratilgan vaqti: </b>
                {new Date(detailData && detailData.created_at).toLocaleString()}
              </li>
              <li class="list-group-item py-2">
                <b>Yangilangan vaqti: </b>
                {new Date(detailData && detailData.updated_at).toLocaleString()}
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item py-2">
                <b>Kitob yozuvi: </b>
                {detailData &&
                  detailData.kitob_yozuvi &&
                  detailData.kitob_yozuvi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Matn turi: </b>
                {detailData &&
                  detailData.matn_turi &&
                  detailData.matn_turi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kirish huquq turi: </b>
                {detailData && detailData.kirish_huquqi === 1
                  ? "Ochiq"
                  : detailData.kirish_huquqi === 2
                  ? "Qisman ochiq"
                  : "Yopiq"}
              </li>
              <li class="list-group-item py-2">
                <b>Fayl turi: </b>
                {detailData &&
                  detailData.fayl_turi &&
                  detailData.fayl_turi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kimlarga muljallangan: </b>
                {detailData &&
                  detailData.kimga_muljallangan &&
                  detailData.kimga_muljallangan.nomi}
              </li>
            </ul>
          </div>
        </div>
  );
};

export default DetailBooks;

