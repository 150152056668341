import './ReturnBooks.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../Super_admin/BaseUrl';
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router-dom';


function ReturnBooks() {

    const navigate = useNavigate()

    const [userCount, setUserCount] = useState(15)
    const [pageCount, setPageCount] = useState();
    const [usersData, setUsersData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = userCount;

    const getAllUsers = (page = 1) => {
        axios.get(BaseUrl + `/api/admin/kitobqaytarish?paginator=${userCount}&page=${page}&search=${searchTerm}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            sessionStorage.setItem("userDataCount", res.data.users.total)
            setPageCount(res.data.users.last_page)
            setUsersData(res.data.users.data)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getAllUsers()
    }, [userCount, searchTerm])

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(usersData && usersData.slice(itemOffset, endOffset));
    }, [itemOffset, itemsPerPage, usersData]);


    const handlePageClick = (event) => {
        getAllUsers(event.selected + 1)
    };

    //////////////////////////////////////////////////////////////////////////////////////////

    const getOneUser = (id) => {
        axios.get(BaseUrl + `/api/kutubxonachi/users/show/${id}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            sessionStorage.setItem('attachId', id);
            navigate("/adminMain/returnbooks/innerreturnbooks/" + id)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getAllUsers()
    }, [])

    return (
        <div className='container-fluid returnbooks'>
            <div className='returnbooks-wrapper'>
                <h2>Kitobxonaga kitob qaytarish</h2>
                <div className='returnbooks-search'>
                    <div className="returnbooks-inner-search">
                        <img src="/images/search.png" alt="" />
                    </div>
                    <input type="text" className='form-control  p-2 w-100' placeholder="Foydalanuvchi ma'lumotlarini kiriting" onChange={event => { setSearchTerm(event.target.value) }} />
                </div>
                <select onChange={(e) => setUserCount(+e.target.value)} className="form-select returnbooks-option">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
            <hr />
            <table className='table my-4'>
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Full name</th>
                        <th scope="col">Hemis id</th>
                        <th scope="col">Login</th>
                        <th scope="col">Yo'nalish nomi</th>
                        <th scope="col">Tanlash</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentItems.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item?.id}</td>
                                    <td>{item?.firstname} {item?.lastname}</td>
                                    <td>{item?.hemis_id}</td>
                                    <td>{item?.login}</td>
                                    <td>{item?.yunalish_id ? item?.yunalish.nomi : ''}</td>
                                    <td><button onClick={() => { getOneUser(item?.id) }} className='btn btn-outline-dark'>Tanlash</button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <b>Jami {sessionStorage.getItem("userDataCount")} ta ma'lumotdan {currentItems.length} ko'rsatilmoqda</b>

            <ReactPaginate
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeLinkClassName="active"
            />
        </div>
    )
}

export default ReturnBooks