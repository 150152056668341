/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";

const Book_catalog_show = () => {
  const { id } = useParams();
  const [bookCatalogShow, setBookCatalogShow] = useState([]);

  const getBook_type = () => {
    axios
      .get(BaseUrl + `/api/kutubxonachi/kitoblar/showkatalog/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.kitob);
        setBookCatalogShow(res.data.kitob);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getBook_type();
  }, []);

  

  
  return (
    <div style={{ width: "100%" }}>
      <div className="navBar">
        <p>Kitob katalogini ko'rish </p>
        <ToastContainer />
        <Link to="/adminMain/book_catalog">
          <div className="add-kafedra">Orqaga</div>
        </Link>
      </div>
      <hr />
      <div className="card shadow p-3">
      <div className="row">
      <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item d-flex py-2">
                <span>
                  <b>Faolmi?:</b>
                </span>
                {bookCatalogShow && bookCatalogShow.active === 0 ? (
                  <div className="chek2" style={{ marginLeft: "10px" }}>
                    <CiSquareRemove style={{ color: "white" }} />
                  </div>
                ) : (
                  <div className="chek" style={{ marginLeft: "10px" }}>
                    <HiOutlineCheckCircle style={{ color: "white" }} />
                  </div>
                )}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob nomi: </b>
                {bookCatalogShow && bookCatalogShow.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Joylashuv indeksi: </b>
                {bookCatalogShow && bookCatalogShow.bbk}
              </li>
              <li class="list-group-item py-2">
                <b>Muallif belgisi (Авторский знак): </b>
                {bookCatalogShow && bookCatalogShow.mualliflik_belgisi}
              </li>
              <li class="list-group-item py-2">
                <b>Fan sohalari: </b>
                {bookCatalogShow &&
                  bookCatalogShow.fan_sohasi &&
                  bookCatalogShow.fan_sohasi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob mualliflari: </b>
                {bookCatalogShow &&
                  bookCatalogShow.mualliflar &&
                  bookCatalogShow.mualliflar.map((item, index) => {
                    return <span key={index}>{item.nomi}, </span>;
                  })}
              </li>
              <li class="list-group-item py-2">
                <b>UDK: </b>
                {bookCatalogShow && bookCatalogShow.udk}
              </li>
              <li class="list-group-item py-2">
                <b>BBK: </b>
                {bookCatalogShow && bookCatalogShow.bbk}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob nashriyoti nomi: </b>
                {bookCatalogShow && bookCatalogShow.nashriyot_nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob nashr qilingan shahar: </b>
                {bookCatalogShow && bookCatalogShow.nashr_shahri}
              </li>
              <li class="list-group-item py-2">
                <b>ISBN: </b>
                {bookCatalogShow && bookCatalogShow.isbn}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob annotatsiyasi: </b>
                {bookCatalogShow && bookCatalogShow.anotatsiya}
              </li>
              <li class="list-group-item py-2">
                <b>Kitob nashr qilingan yili: </b>
                {bookCatalogShow && bookCatalogShow.nash_yili}
              </li>
              <li class="list-group-item py-2">
                <b>Betlar Soni: </b>
                {bookCatalogShow && bookCatalogShow.betlar_soni}
              </li>
              <li class="list-group-item py-2">
                <b>Narxi: </b>
                {bookCatalogShow && bookCatalogShow.narxi}
              </li>
              <li class="list-group-item py-2">
                <b>Yaratilgan vaqti: </b>
                {new Date(bookCatalogShow && bookCatalogShow.created_at).toLocaleString()}
              </li>
              <li class="list-group-item py-2">
                <b>Yangilangan vaqti: </b>
                {new Date(bookCatalogShow && bookCatalogShow.updated_at).toLocaleString()}
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item py-2">
                <b>Kitob yozuvi: </b>
                {bookCatalogShow &&
                  bookCatalogShow.kitob_yozuvi &&
                  bookCatalogShow.kitob_yozuvi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Matn turi: </b>
                {bookCatalogShow &&
                  bookCatalogShow.matn_turi &&
                  bookCatalogShow.matn_turi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kirish huquq turi: </b>
                {bookCatalogShow && bookCatalogShow.kirish_huquqi === 1
                  ? "Ochiq"
                  : bookCatalogShow.kirish_huquqi === 2
                  ? "Qisman ochiq"
                  : "Yopiq"}
              </li>
              <li class="list-group-item py-2">
                <b>Fayl turi: </b>
                {bookCatalogShow &&
                  bookCatalogShow.fayl_turi &&
                  bookCatalogShow.fayl_turi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kimlarga muljallangan: </b>
                {bookCatalogShow &&
                  bookCatalogShow.kimga_muljallangan &&
                  bookCatalogShow.kimga_muljallangan.nomi}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book_catalog_show;
