import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsSearch } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import "../../userComponents/HeaderMenuPage/Electronic_catalog/Electronic_catalog.css";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { CiSquareRemove } from "react-icons/ci"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { RxLockClosed, RxLockOpen1, RxLockOpen2 } from 'react-icons/rx';


const Books = () => {

    const navigate = useNavigate()

    const [pageCount, setPageCount] = useState();
    const [usersData, setUsersData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);


    const getNews = (page = 1) => {
        axios.get(BaseUrl + `/api/public/kitobkatalog?page=${page}&search=${searchTerm}`).then((res) => {
            setUsersData(res.data.kitoblar.data);
            setPageCount(res.data.kitoblar.last_page);
            // catalog
        })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getNews();
    }, [searchTerm])

    useEffect(() => {
        setCurrentItems(usersData && usersData.slice(itemOffset));
    }, [itemOffset, usersData]);

    const handlePageClick = (event) => {
        getNews(event.selected + 1)
    };


    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-4 offset-md-4 col-sm-12">
                    <div class="input-group input-group-sm mb-3">
                        <input
                            onChange={event => { setSearchTerm(event.target.value) }}
                            class="form-control"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-sm"
                            type="search"
                        />
                        <span class="input-group-text" id="inputGroup-sizing-sm">
                            <BsSearch />
                        </span>
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">Rasmi</th>
                            <th scope="col">Maqola</th>
                            <th scope="col">Yil</th>
                            <th scope="col">Davlat</th>
                            <th scope="col">Faolmi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems && currentItems.map((item, index) => (
                            <tr>
                                <th scope="row">{item.id}</th>
                                <td onClick={() => navigate(`/electronic_catalog/show/${item.id}`)}><img src={BaseUrl + '/storage/' + item.muqova_jpg} alt="book-img" width='100' height='70px' /></td>
                                <td>{item.nomi} {item.kirish_huquqi == 1 ? < RxLockOpen2 style={{ color: "#2E8B57" }} /> : item.kirish_huquqi == 2 ? <  RxLockOpen1 style={{ color: "#FF8C00" }} /> : item.kirish_huquqi == 3 ? <  RxLockClosed style={{ color: "#FF0000" }} /> : ""}</td>
                                <td>{item.nash_yili}</td>
                                <td>{item.country.nomi}</td>
                                <td>
                                    {
                                        item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                            :
                                            <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                    }

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
        </div>
    );
};

export default Books;





{/* {currentItems &&
          currentItems
            .filter((value) => {
              if (searchTerm === "") {
                return value;
              } else if (value.nomi.toLowerCase().includes(searchTerm.toLowerCase())) {
                return value;
              } else if (value.nashriyot_nomi.toLowerCase().includes(searchTerm.toLowerCase())) {
                return value
            }
            })
            .map((item, index) => {
              return (
                <div key={index} className="card_catalog card py-2 mt-2 mb-2">
                  <span className="px-1 py-1">
                    <b className="catalog_name" onClick={() =>
                            navgate(`/articles/show/${item.id}`)
                          }>{item.nomi}</b>
                  </span>
                  <span className="text-muted px-1">
                    Mualliflar:{" "}
                    <q>
                      {item &&
                        item.mualliflar &&
                        item.mualliflar.map((item, index) => {
                          return <span key={index}>{item.nomi}, </span>;
                        })}
                    </q>
                  </span>
                  <span className="text-muted px-1">
                    Nashriyot: <q>{item.nashriyot_nomi}</q>
                  </span>
                  <span className="d-flex text-muted ">
                    <span className="px-1">{item.kitob_yunalishi.nomi}</span>,
                    <span className="px-1">{item.kitob_turi.nomi}</span>,
                    <span className="px-1">{item.nash_yili}</span>,
                    <span className="px-1">{item.country.nomi}</span>,
                    <span className="px-1">{item.kitob_tili.nomi}</span>
                  </span>
                </div>
              );
            })} */}