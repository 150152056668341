import React, { useEffect, useState } from "react";
import { SumerkaAction } from "../../../../redux/actions/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../../Super_admin/BaseUrl";

const SumerkaDetail = () => {
  const [detailData, setDetailData] = useState({});
  const { id } = useParams();

  const getDate = () => {
    axios
      .get(BaseUrl + `/api/kutubxonachi/sumerka/show/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.sumerka);
        setDetailData(res.data.sumerka);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDate();
  }, []);

  // const date = new Date();
  // const formattedDate = date.toLocaleString('en-US', { timeZoneName: 'short' });
  // console.log(formattedDate);

  // var ts = new Date(1692513172 * 1000);
  // console.log(ts.toISOString());

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="navBar">
          <p>Kitob shartnomalari ko'rsatish</p>

          <Link to="/adminMain/sumerka">
            <div className="add-kafedra">Orqaga</div>
          </Link>
        </div>
        <hr />

        <div className="card shadow p-3">
          <div className="row">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <span>
                  <b>Qayerdan kelgan: </b>
                </span>{" "}
                {detailData && detailData.qayerdan_kelgan}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Narxi:</b>
                </span>{" "}
                {detailData && detailData.narxi}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Summarka Raqam:</b>
                </span>{" "}
                {detailData && detailData.sumarka_raqam}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Kelgan Sana:</b>
                </span>{" "}
                {new Date(
                  detailData && detailData.kelgan_sana
                ).toLocaleDateString()}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Tashkilot:</b>
                </span>{" "}
                {detailData && detailData.tashkilot_nomi}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Kim tomonidan yaratilgan:</b>
                </span>{" "}
                {detailData && detailData.createdBy}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Kim tomonidan yangilangan:</b>
                </span>{" "}
                {detailData && detailData.updatedBy}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Shaxs Nomi:</b>
                </span>{" "}
                {detailData && detailData.sumarka_raqam}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Yaratilgan vaqti:</b>
                </span>{" "}
                {new Date(detailData && detailData.created_at).toLocaleString()}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Yangilangan vaqti:</b>
                </span>{" "}
                {new Date(detailData && detailData.updated_at).toLocaleString()}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Izoh:</b>
                </span>{" "}
                {detailData && detailData.izoh}
              </li>
              <li class="list-group-item">
                <span>
                  <b>Shaxs Nomi:</b>
                </span>{" "}
                {detailData.harid_malumotnoma ? (
                  <a
                    className="border p-2 m-2"
                    href={BaseUrl + "/storage/" + detailData.harid_malumotnoma}
                    target={"_blank"}
                  >
                    {" "}
                    yuklab olish{" "}
                  </a>
                ) : (
                  "File yo'q"
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SumerkaDetail;
