import "./Home.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Header from "../../userComponents/header/Header";
import { Routes, Route } from "react-router-dom";
import Content from "../homeContent/Content";
import Books from "../books/Books";
import Articles from "../articles/Articles";
import XorijiyContent from "../XorijiyContent/XorijiyContent";
import Electronic_catalog from "../../userComponents/HeaderMenuPage/Electronic_catalog/Electronic_catalog";
import Article from "../articles/Articles";
import ArticleShow from "../articles/ArticleShoow";
import JurnalSon from "../JurnalSon/JurnalSon";
import JurnalShow from "../JurnalSon/JurnalShow";
import IlmiyToplam from "../IlmiyNashr/IlmiyNashr";
import IlmiyNashr from "../IlmiyNashr/IlmiyNashr";
import Statistic from "antd/es/statistic/Statistic";
import Statistik from "../homeContent/Statistik/Statistik";

function Home() { 
  return (
    <>
      <div className="home">
        <div
          id="carouselExampleAutoplaying"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner coruselWrapper">
            <div class="carousel-item">
              <img
                src={require("../../assets/0001.jpg")}
                className="d-block w-100"
                alt=" "
              />
            </div>
            <div class="carousel-item ">
              <img
                src={require("../../assets/0002.jpg")}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item active">
              <img
                src={require("../../assets/001.png")}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item">
              <img
                src={require("../../assets/002.png")}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item">
              <img
                src={require("../../assets/003.png")}
                className="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="container my-5 library-fond">
        <h1>Statistik ma'lumotlar</h1>
        <Statistik />
      </div>
      <div className="container-fluid user-menu my-5">
        <div className="row">
          <div className="col-md-12 menu-buttons flex-wrap">
            <Link to="/books">
              <button className="btn btn-outline-dark">Adabiyotlar</button>
            </Link>
            <Link to="/articles">
              <button className="btn btn-outline-dark">Maqolalar</button>
            </Link>
            <Link to="/toplam">
              <button className="btn btn-outline-dark">Ilmiy nashrlar</button>
            </Link>
            <Link to="/jurnal">
              <button className="btn btn-outline-dark">Jurnallar</button>
            </Link>
          </div>
        </div>
      </div>

      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/books" element={<Books />} />
        {/* <Route path="/electronic_catalog" element={<Electronic_catalog />} /> */}
        <Route path="/articles" element={<Article />} />

        <Route path="/article/show/:id" element={<ArticleShow />} />
        <Route path="/jurnal" element={<JurnalSon />} />
        <Route path="/jurnal/show/:id" element={<JurnalShow />} />
        <Route path="/toplam" element={<IlmiyNashr />} />
      </Routes>

      <XorijiyContent />
    </>
  );
}

export default Home;
