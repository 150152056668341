const initialState = {
  fakultets: [],
  kafedra: [],
  bulim: [],
  lavozim: [],
  yunalish:[],
  group:[],
  users: [],
  uquvyili:[],
  kutubxona:[],
  davlat:[],
  shahar:[],
  jurnal:[],
  jurnal_soni:[],
  maqolalar:[],
  toplamlar:[],
  ilmiynashr:[],
  ilmiyNashrTuri:[],
  book_type: [],
  science_field:[],
  book_direction:[],
  book_language:[],
  book_entry:[],
  text_type:[],
  file_type:[],
  books_collection:[],
  category:[],
  sumerka:[],
  adminusers:[],
  bookcatalog:[],
  message:""
};

export const Reducers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FAKULTET": {
      return { ...state, fakultets: action.payload };
    }
    case "GET_KAFEDRA": {
      return { ...state, kafedra: action.payload };
    }
    case "GET_BULIM": {
      return { ...state, bulim: action.payload };
    }
    case "GET_LAVOZIM": {
      return { ...state, lavozim: action.payload };
    }  
    case "GET_USER": {
      return { ...state, users: action.payload };
    } 
    case "GET_YUNALISH": {
      return { ...state, yunalish: action.payload };
    }  
    case "GET_ILMIYNASHR": {
      return { ...state, ilmiynashr: action.payload };
    }  
    case "GET_ILMIYNASHR_TURI": {
      return { ...state, ilmiyNashrTuri: action.payload };
    }
    case "GET_GROUP": {
      return { ...state, group: action.payload };
    }   
    case "GET_UQUVYILI": {
      return { ...state, uquvyili: action.payload };
    }
    case "GET_KUTUBXONA": {
      return { ...state, kutubxona: action.payload };
    }
    case "GET_JURNAL": {
      return { ...state, jurnal: action.payload };
    }
    case "GET_JURNAL_SONI": {
      return { ...state, jurnal_soni: action.payload };
    }
    case "GET_MAQOLA": {
      return { ...state, maqolalar: action.payload };
    }
    case "GET_DAVLAT": {
      return { ...state, davlat: action.payload };
    }
    case "GET_SHAHAR": {
      return { ...state, shahar: action.payload };
    }
    case "GET_TOPLAMLAR": {
      return { ...state, toplamlar: action.payload };
    }
    case "GET_MESSAGE": {
      return { ...state, message: action.payload };
    }
   
    // Kutubxonachi

    case "GET_ADMIN_USERS": {
      return { ...state, adminusers: action.payload };
    }

    case "GET_BOOKTYPE": {
      return { ...state, book_type: action.payload };
    }
    case "GET_FIELD": {
      return { ...state, science_field: action.payload };
    }
    case "GET_BOOK_DIRECTION": {
      return { ...state, book_direction: action.payload };
    }
    case "GET_BOOK_LANGUAGE": {
      return { ...state, book_language: action.payload };
    }
    case "GET_BOOK_ENTRY": {
      return { ...state, book_entry: action.payload };
    }
    case "GET_TEXT_TYPE": {
      return { ...state, text_type: action.payload };
    }
    case "GET_FILE_TYPE": {
      return { ...state, file_type: action.payload };
    }
    case "GET_BOOKS_COLLECTION": {
      return { ...state, books_collection: action.payload };
    }
    case "GET_CATEGORY": {
      return { ...state, category: action.payload };
    }
    case "GET_SUMERKA": {
      return { ...state, sumerka: action.payload };
    }
    case "GET_BOOKCATALOG": {
      return { ...state, bookcatalog: action.payload };
    }
    default: {
      return state;
    }
  }
};
