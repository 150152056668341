/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux";
import { CiSquareRemove } from "react-icons/ci"
import axios from "axios";
import { MdOutlineDelete } from "react-icons/md"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { AdminUserListAction } from "../../redux/actions/Action";
import ReactPaginate from "react-paginate"




const XodimlarList = () => {

    const navigate = useNavigate()
    // useEffect(() => {
    //     notify();
    // }, [text]);

    // const notify = () => {
    //     if (text === "") { } else if (text === "Yangi Fakultet yaratildi.") {
    //         toast.success(text)
    //     }
    //     else {
    //         toast.error(text)
    //     }
    //     setText("")
    // };

    const deleteLUser = (id) => {
        // axios.delete(BaseUrl + `/api/admin/lavozim/${id}`, {
        //     headers: {
        //         "Authorization": "Bearer " + sessionStorage.getItem("token")
        //     }
        // }).then(res => {
        //     console.log(res.data);
        //     if (res.status == 200) {
        //         getUserList()
        //         setText(res.data.message)
        //     }
        // }).catch(err => {
        //     console.log(err);
        // })
    }


    // const dispatch = useDispatch()

    // const adminusers = useSelector(state => state.store.adminusers)
    // // console.log(bulim);

    // const getUserList = () => {
    //     axios.get( BaseUrl+ "/api/kutubxonachi/users", {
    //         headers: {
    //             "Authorization": "Bearer " + sessionStorage.getItem("token")
    //         }
    //     }).then(res => {
    //         console.log(res.data.users);
    //         dispatch(AdminUserListAction(res.data.users))
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // }

    // useEffect(() => {
    //     getUserList()
    // }, [])


    // const [currentItems, setCurrentItems] = useState([]);
    // const [pageCount, setPageCount] = useState(0);
    // const [itemOffset, setItemOffset] = useState(0);
    // const itemsPerPage = 13;

    // useEffect(() => {
    //     const endOffset = itemOffset + itemsPerPage;
    //     // console.log(Loading items from ${itemOffset} to ${endOffset});
    //     setCurrentItems(adminusers && adminusers.slice(itemOffset, endOffset));
    //     setPageCount(Math.ceil(adminusers.length / itemsPerPage));
    // }, [itemOffset, itemsPerPage, adminusers]);


    // const handlePageClick = (event) => {
    //     const newOffset = (event.selected * itemsPerPage) % adminusers.length;
    //     setItemOffset(newOffset);
    // };


    const [userCount, setUserCount] = useState(15)
    const [pageCount, setPageCount] = useState();
    const [studentsData, setStudentsData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = userCount;

   



    const getTalabaList = (page=1) =>{
        axios(BaseUrl + `/api/kutubxonachi/users/xodimlar?paginator=${userCount}&page=${page}&search=${searchTerm}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            sessionStorage.setItem("userDataCount", res.data.users.total)
            setPageCount(res.data.users.last_page)
            setStudentsData(res.data.users.data)
        }).catch(err=>{
            console.log(err);
        })
    }
    
    useEffect(() => {
        getTalabaList()
    }, [userCount,searchTerm])

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(studentsData && studentsData.slice(itemOffset, endOffset));
    }, [itemOffset, itemsPerPage, studentsData]);


    const handlePageClick = (event) => {
        getTalabaList(event.selected+1)
    };

    return (
        <div>
            <ToastContainer />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="mt-4">Xodimlar ro'yxati</h2>
                <div className="for-search">
                    <div className="for-search2">
                        <img style={{ width: "30px", position: "absolute", margin: "5px 0 0 5px" }} src="/images/search.png" alt="" />
                    </div>
                    <input onChange={event => { setSearchTerm(event.target.value) }} className="form-control search_input" type="search" />
                </div>
            </div>

            <hr />
            <select style={{}} onChange={(e) => setUserCount(+e.target.value)} name="" id="">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Link to={sessionStorage.getItem("role") == 0 ? "/Supermain/AdminStudentList/add" : "/adminMain/AdminXodimList/add"}>
                    <div className="add-kafedra"> Qo'shish</div>
                </Link>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Ism familiya</th>
                        <th scope="col">Login</th>
                        <th scope="col">Parol</th>
                        <th scope="col">Ta'lim shakli</th>
                        <th scope="col">Fakultet</th>
                        <th scope="col">Kursi</th>
                        <th scope="col">Yo'nalishi</th>
                        <th scope="col">Holati</th>
                        <th style={{ textAlign: "center" }}>Amallar</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        currentItems && currentItems.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{item?.id}</th>
                                    <td>{item?.firstname + " "} {item?.lastname}</td>
                                    <td>{item?.login}</td>
                                    <td>{item?.password_visible}</td>
                                    <td>{item?.lavozim?.nomi}</td>
                                    <td>{item?.fakultet?.nomi}</td>
                                    <td>{item?.kursi}</td>
                                    <td>{item?.yunalish?.nomi}</td>
                                    <td>
                                        {
                                            item?.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                                :
                                                <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                        }
                                    </td>
                                    <td>
                                        <div className="main_edite-box">
                                            <div className="edite_show_box">
                                                <div onClick={() => navigate('#')} className="show_data"><AiOutlineEye /></div>
                                                <div onClick={() => navigate('/adminMain/AdminStudentList/add/' + item.id)} className="edite"><AiOutlineEdit /></div>
                                                <div onClick={() => deleteLUser('#')} className="delete"><MdOutlineDelete /></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }





                    {/* {
                        studentsData && studentsData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{item && item.id}</th>
                                    <td>{item.firstname + " "} {item.lastname}</td>
                                    <td>{item.login}</td>
                                    <td>{item.password_visible}</td>
                                    <td>{item.lavozim.nomi}</td>
                                    <td>{item.fakultet.nomi}</td>
                                    <td>{item.yunalish.nomi}</td>
                                    <td>
                                        {
                                            item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                                :
                                                <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                        }
                                    </td>
                                    <td>
                                        <div className="main_edite-box">
                                            <div className="edite_show_box">
                                                <div onClick={() => navigate('#')} className="show_data"><AiOutlineEye /></div>
                                                <div onClick={() => navigate('#')} className="edite"><AiOutlineEdit /></div>
                                                <div onClick={() => deleteLUser('#')} className="delete"><MdOutlineDelete /></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    } */}
                </tbody>
            </table>
            <b>Jami {sessionStorage.getItem("userDataCount")} ta ma'lumotdan {currentItems.length} ko'rsatilmoqda</b>
            <div className="my-pagination">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
        </div>
    )
}

export default XodimlarList;