import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../BaseUrl";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye } from "react-icons/ai";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

const Tashrif = () => {
  const [tashrif, setTashrif] = useState([]);
  const [userCount, setUserCount] = useState(15);
  const [pageCount, setPageCount] = useState();
  const [allDate, setAllDate] = useState({ start: "", end: "" });

  const navgate = useNavigate();

  const getTashrif = async (page = 1) => {
    try {
      const res = await axios.get(
        BaseUrl +
          `/api/admin/qatnov-hisobot?paginator=${userCount}&page=${page}&start_date=${allDate.start}&end_date=${allDate.end}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      setPageCount(res?.data?.users?.last_page);
      setTashrif(res?.data?.users?.data);
      sessionStorage.setItem("userDataCount", res.data.users.total);
    } catch (error) {
      console.log(error.message);
    }
  };

  const exportDataToExcel = async () => {
    let arr = [];
    let obj = {
      ID: "",
      "Hemis id": "",
      "Foydalanuvchi turi": "",
      Familyasi: "",
      Ismi: "",
      Fakulteti: "",
      "Yo'nalishi": "",
      Kursi: "",
      "Qatnovlar soni": 0,
    };
    console.log("page count", pageCount);
    for (
      let i = 1;
      i <= sessionStorage.getItem("userDataCount") / 1000 + 1;
      i++
    ) {
      try {
        const { data } = await axios.get(
          BaseUrl +
            `/api/admin/qatnov-hisobot?paginator=1000&page=${i}&start_date=${allDate.start}&end_date=${allDate.end}`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        );
        for (let j = 0; j < data.users.data.length; j++) {
          obj["ID"] = data?.users?.data[j]?.id;
          obj["Hemis id"] = data?.users?.data[j]?.hemis_id;
          obj["Foydalanuvchi turi"] = data?.users?.data[j]?.f_turi?.nomi;
          obj["Familyasi"] = data?.users?.data[j]?.lastname;
          obj["Ismi"] = data?.users?.data[j]?.firstname;
          obj["Fakulteti"] = data?.users?.data[j]?.fakultet?.nomi;
          obj["Yo'nalishi"] = data?.users?.data[j]?.yunalish?.nomi;
          obj["Kursi"] = data?.users?.data[j]?.kursi;
          obj["Qatnovlar soni"] = data?.users?.data[j]?.qatnovlar?.length;
          arr.push(obj);
          obj = {
            ID: "",
            "Hemis id": "",
            "Foydalanuvchi turi": "",
            Familyasi: "",
            Ismi: "",
            Fakulteti: "",
            "Yo'nalishi": "",
            Kursi: "",
            "Qatnovlar soni": 0,
          };
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    return arr;
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    const arr = await exportDataToExcel();
    const ws = XLSX.utils.json_to_sheet(arr);
    ws["!cols"] = [
      { width: 7 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 50 },
      { width: 50 },
      { width: 7 },
      { width: 13 },
    ];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const datas = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(datas, `Tashrif hisoboti` + fileExtension);
  };

  useEffect(() => {
    getTashrif();
  }, [pageCount]);

  const handlePageClick = (event) => {
    getTashrif(event.selected + 1);
  };

  const handledate = (e) => {
    setAllDate((data) => {
      return {
        ...data,
        [e.target.name]: e.target.value,
      };
    });
  };

  const filterDate = () => {
    axios
      .get(
        BaseUrl +
          `/api/admin/qatnov-hisobot?start_date=${allDate.start}&end_date=${allDate.end}&paginator=${userCount}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setTashrif(res.data.users.data);
        sessionStorage.setItem("userDataCount", res.data.users.total);
        setPageCount(res.data.users.last_page);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <ToastContainer />
      <h2 className="mt-4">Tashrif statistikasi</h2>
      <hr />
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-3">
          <input
            className="form-control"
            name="start"
            formAction="mm-yyyy-dd"
            type="date"
            onChange={handledate}
          />
          <input
            className="form-control"
            name="end"
            type="date"
            onChange={handledate}
          />
          <button
            onClick={filterDate}
            type="button"
            className="btn btn-danger d-flex align-items-center gap-2"
          >
            <svg
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"></path>
            </svg>
            Qidiruv
          </button>
        </div>
        <div className="d-flex gap-2">
          <select
            onChange={(e) => setUserCount(+e.target.value)}
            className="form-select getbooks-option w-auto"
          >
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <div>
            <button onClick={exportToCSV} className="btn btn-success">
              Export excel
            </button>
          </div>
        </div>
      </div>

      <table className="table mt-3">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Familya Ism</th>
            <th scope="col">Foydalanuvchi turi</th>
            <th scope="col">Fakulteti</th>
            <th scope="col">Qanovlar soni</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {tashrif.map((item, index) => (
            <tr key={index}>
              <th scope="row">{item.id}</th>
              <th>
                {item.lastname} {item.firstname}
              </th>
              <td>{item.f_turi.nomi}</td>
              <td>{item.fakultet.nomi}</td>
              <td className="text-center fs-5">
                <b>{item.qatnovlar.length}</b>
              </td>
              <td>
                <div
                  onClick={() => navgate(`/Supermain/tashrif/show/${item.id}`)}
                  className="show_data"
                >
                  <AiOutlineEye />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <b>
        Jami {sessionStorage.getItem("userDataCount")} ta ma'lumotdan{" "}
        {userCount} ko'rsatilmoqda
      </b>

      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default Tashrif;
