/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { Books_collectionAction } from "../../../redux/actions/Action";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

const Books_collection = () => {
  const [text, setText] = useState(false);
  const [switchs, setSwitchs] = useState();

  const [userCount, setUserCount] = useState(15);
  const [pageCount, setPageCount] = useState();
  const [usersData, setUsersData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = userCount;


  const navgate = useNavigate();

  useEffect(() => {
    notify();
  }, [text]);

  const handleSwitch = async (kitobId, kitob_tavsiya) => {
    setSwitchs(kitob_tavsiya ? true : false);
    const data = { switchs: !switchs, kitobId, user_id };
    await axios
      .post(BaseUrl + "/api/admin/kitobtavsiya", data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        getAllUsers();
        setText(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const notify = () => {
    if (text === "") {
    } else if (text === "Kimga muljallanganlik bazadan o'chirildi.") {
      return toast.success(text);
    } else if (text === "Kitob tavsiya etish uchun tanlandi.") {
      return toast.success(text);
    } else if (
      text ===
      "Ushbu kitob va unga biriktirilgan mualliflar, sumerka va bo'lim bog'lanishlari, inventar raqamlar va kitob fayllari bazadan o'chirildi."
    ) {
      return toast.success(text);
    } else {
      return toast.error(text);
    }
    setText("");
  };

  const getAllUsers = (page = 1) => {
    axios
      .get(
        BaseUrl +
          `/api/kutubxonachi/kitoblar?paginator=${userCount}&page=${page}&search=${searchTerm}`,
        {
          headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
        }
      )
      .then((res) => {
        sessionStorage.setItem("userDataCount", res.data.kitoblar.total);
        setPageCount(res.data.kitoblar.last_page);
        setUsersData(res.data.kitoblar.data);
        // console.log(res.data.kitoblar.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [userCount, searchTerm]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(usersData && usersData.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, usersData]);

  const handlePageClick = (event) => {
    getAllUsers(event.selected + 1);
  };

  const deleteItem = (id) => {
    axios
      .delete(BaseUrl + `/api/kutubxonachi/kitoblar/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.message);
        if (res.status == 200) {
          getAllUsers();
          setText(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const user_id = sessionStorage.getItem("userId");

  return (
    <div>
      <div className="navBar">
        <p>Kitoblar</p>
        <ToastContainer />
        <Link
          to={
            sessionStorage.getItem("userId") == 1
              ? "/Supermain/books_collection/add"
              : "/adminMain/books_collection/add"
          }>
          <div className="add-kafedra">Yaratmoq</div>
        </Link>
      </div>
      <hr />

      <div className="d-flex justify-content-between">
        <select
          onChange={(e) => setUserCount(+e.target.value)}
          className="form-select getbooks-option w-auto">
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>

          <div className="for-search">
            <div className="for-search2">
              <img
                style={{
                  width: "30px",
                  position: "absolute",
                  margin: "5px 0 0 5px",
                }}
                src="/images/search.png"
                alt=""
              />
            </div>
            <input
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              className="form-control search_input"
              type="search"
            />
          </div>
      </div>

      <hr />
      <div class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Muqova tasviri </th>
              <th scope="col">Kitob nomi</th>
              <th scope="col">ISBN</th>
              <th scope="col">Joylashuv indeksi</th>
              <th scope="col">Kitob mualliflari </th>
              <th scope="col">Turi </th>
              <th scope="col">Tili </th>
              <th scope="col">Jami soni </th>
              <th scope="col">Mavjud soni </th>
              <th scope="col">pdf </th>
              <th>
                {sessionStorage.getItem("userId") == 1 && "Tavsiya etilsinmi?"}
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((item) => {
                console.log("item", item);
                return (
                  <tr>
                    <td>{item.id}</td>
                    <td>
                      <a
                        href={BaseUrl + "/storage/" + item.kitob_pdf}
                        target={"_blank"}>
                        <img
                          width={100}
                          height={80}
                          src={BaseUrl + "/storage/" + item.muqova_jpg}
                          alt="muqova yoq"
                        />
                      </a>
                    </td>
                    <td>{item.nomi}</td>
                    <td>{item.isbn}</td>
                    <td>{item.bbk}</td>
                    <td>
                      {item.mualliflar &&
                        item.mualliflar.map((it, index) => {
                          return <div>{it.nomi}</div>;
                        })}
                    </td>
                    <td>{item.kitob_turi.nomi}</td>
                    <td>{item.kitob_tili.nomi}</td>
                    <td>{item.umumiySoni}</td>
                    <td>{item.soni}</td>
                    <td>{item.kitob_pdf  ? "Bor" : "Yo'q"}</td>

                    <td>
                      {sessionStorage.getItem("userId") == 1 && (
                        <div class="form-check form-switch h-100 d-flex justify-content-center aligin-itens-center">
                          <input
                            class="form-check-input fs-5 cursor-pointer"
                            type="checkbox"
                            role="switch"
                            name={item.id}
                            id={item.id}
                            checked={item.kitob_tavsiya ? true : false}
                            onClick={() =>
                              handleSwitch(item.id, item.kitob_tavsiya)
                            }
                          />
                        </div>
                      )}
                    </td>

                    <td>
                      <div className="main_edite-box ">
                        <div className="edite_show_box">
                          <div
                            className="show_data"
                            onClick={() => {
                              navgate(
                                `/adminMain/books_collection/detail/${item.id}`
                              );
                              sessionStorage.setItem("showId", item.id);
                            }}>
                            <AiOutlineEye />
                          </div>
                          <div
                            className="edite"
                            onClick={() =>
                              navgate(
                                `/adminMain/books_collection/add/${item.id}`
                              )
                            }>
                            <AiOutlineEdit />
                          </div>
                          {sessionStorage.getItem("userId") == 1 && (
                            <div
                              className="delete"
                              onClick={() => deleteItem(item.id)}>
                              <MdOutlineDelete />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <b>Jami {sessionStorage.getItem("userDataCount")} ta ma'lumotdan {currentItems.length} ko'rsatilmoqda</b>

      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default Books_collection;
