import { useEffect } from 'react';
import { BaseUrl } from '../../../Super_admin/BaseUrl';
import './MyReadBooks.css';
import axios from 'axios';
import { useState } from 'react';
import moment from 'moment/moment';
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router-dom';


function MyReadBooks() {

    const navigate = useNavigate()

    const [readBooks, setReadBooks] = useState([]);

    useEffect(() => {
        axios.get(BaseUrl + '/api/user/uqilgankitoblar/' + sessionStorage.getItem("userId"), {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data);
            setReadBooks(res.data.kitoblar)
        }).catch(err=>{
            console.log(err);
        })
    }, [])

    const [currentPage, setCurrentPage] = useState(0);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const itemsPerPage = 10;

    const pageCount = Math.ceil(readBooks.length / itemsPerPage);

    const displayedData = readBooks.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    return (
        <div className="container-fluid myreadbooks">
            <div className="myreadbooks-wrapper">
                <h1>Men o'qigan kitoblar</h1>
            </div>
            <div className='row'>
                <div className='col-lg-12'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Kitob nomi</th>
                                <th scope="col">Inventar raqam</th>
                                <th scope="col">Olgan vaqti</th>
                                <th scope="col">Topshirish vaqti</th>
                                <th scope="col">Mualliflar</th>
                                <th scope="col">Status</th>
                                <th scope="col">Kitobni ko'rish</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                displayedData?.map((item, index) => {
                                    console.log(item.kitob_id);
                                    let currentTime = new Date().getTime();
                                    let takenTime = new Date(item?.muddati).getTime();
                                    let resultTime = currentTime - takenTime;
                                    let daysLeft = Math.floor(resultTime / (1000 * 60 * 60 * 24));
                                    return (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>{item?.kitob?.nomi}</td>
                                            <td>{item?.kitob_inventar?.inventar_raqam}</td>
                                            <td>{moment(item?.created_at).format('YYYY-MM-DD')}</td>
                                            <td>{item?.muddati}</td>
                                            <td> {
                                                item?.kitob?.mualliflar?.map(item => {
                                                    return (
                                                        <span key={item?.id}>{item?.nomi}, </span>
                                                    )
                                                })
                                            }</td>
                                            <td>
                                                {item?.status == 2 ? <button className='btn btn-danger'>muddati o'tkan ({`${daysLeft}`} kun)</button> :
                                                    item?.status == 1 ? <button className='btn btn-warning'>o'qilmoqda</button> : item?.status == 0 ? <button className='btn btn-success'>topshirgan</button> : item?.status}
                                            </td>
                                            <td>
                                                <button className='btn btn-outline-dark' onClick={() => navigate(`/electronic_catalog/show/${item.kitob_id}`)}>ko'rish</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                pageLinkClassName="page-num"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeClassName={'active'}
            />
        </div>
    )
}

export default MyReadBooks 
