import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import KitobBolimBiriktrish from "./KitobBolimBiriktrish/KitobBolimBiriktrish";
import KitobshartnomaBiriktrish from "./KitobshartnomaBiriktrish/KitobshartnomaBiriktrish";
import DetailBooks from "../DetailBooks/DetailBooks";
import BibliografikYozuv from "./BibliografikYozuv/BibliografikYozuv";

const Books_collectionDetail = () => {
  return (
    <div style={{ width: "100%" }}>
      <div className="navBar">
        <p>Kitoblar Ko'rsatish </p>
        <ToastContainer />
        <Link to="/adminMain/books_collection">
          <div className="add-kafedra">Orqaga</div>
        </Link>
      </div>
      <hr />

      <div className="card shadow p-3">
        <DetailBooks />

        <div className="row">
          <hr className="mt-2" />
          <div className="col-sm-12">
            {/* kitobni shartnomaga biriktrish */}
            <KitobshartnomaBiriktrish />
            {/* Biblografik yozuv */}
            <BibliografikYozuv />
          </div>
          <hr className="mt-2" />

          <KitobBolimBiriktrish />
        </div>
      </div>
    </div>
  );
};

export default Books_collectionDetail;
