import React, { useState, useEffect } from "react";
import "./Statistik.css"
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { PiStudent } from "react-icons/pi";
import { LiaChalkboardTeacherSolid,LiaUsersSolid,LiaUserSolid } from "react-icons/lia";
import { BaseUrl } from "../../../../Super_admin/BaseUrl";
import axios from "axios";

const Statistik = () => {
    const [counterOn, setCounterOn] = useState(false);


    const [statistik, setStatistik] = useState([]);
  
    const getNews = () => {
      axios
        .get(BaseUrl + "/api/public/statistika/users")
        .then((res) => {
          // console.log("statistik", res.data);
          setStatistik(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    useEffect(() => {
      getNews();
    }, []);

  return (
    <div className="counter_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-md-6 col-sm-12 text-center py-2 ">
            <div className="card card_skalet  p-2">
            <div className="counter_logo ">
            <PiStudent />
            </div>
            <span className="counter_animate">
              <ScrollTrigger onEnter={() => setCounterOn(true)}>
                {counterOn && (
                  <CountUp start={0} end={statistik?.talabalar} duration={4} delay={0} />
                )}
              </ScrollTrigger>
            </span>
            <div className="counter_title">Talabalar</div>

            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-12 text-center py-2 ">
            <div className="card card_skalet p-2">
            <div className="counter_logo ">
            <LiaChalkboardTeacherSolid/>
            </div>
            <span className="counter_animate">
              <ScrollTrigger onEnter={() => setCounterOn(true)}>
                {counterOn && (
                  <CountUp start={0} end={statistik?.xodimlar} duration={4} delay={0} />
                )}
              </ScrollTrigger>
            </span>
            <div className="counter_title">Professor-o'qituvchilar</div>

            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-12 text-center py-2 ">
            <div className="card card_skalet p-2">
            <div className="counter_logo ">
            <LiaUserSolid />
            </div>
            <span className="counter_animate">
              <ScrollTrigger onEnter={() => setCounterOn(true)}>
                {counterOn && (
                  <CountUp start={0} end={statistik?.mehmonlar} duration={4} delay={0} />
                )}
              </ScrollTrigger>
            </span>
            <div className="counter_title">Mehmon</div>

            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-12 text-center py-2 ">
            <div className="card card_skalet p-2">
            <div className="counter_logo ">
            <LiaUsersSolid/>
            </div>
            <span className="counter_animate">
              <ScrollTrigger onEnter={() => setCounterOn(true)}>
                {counterOn && (
                  <CountUp start={0} end={statistik?.foydalanuvchilar} duration={4} delay={0} />
                )}
              </ScrollTrigger>
            </span>
            <div className="counter_title">Umumiy foydalanuvchilar</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Statistik