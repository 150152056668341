import axios from "axios";
import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../BaseUrl";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux";
import { BulimAction } from "../../../redux/actions/Action";

const UserType = () => {

    const dispatch = useDispatch()

    // const bulim = useSelector(state => state.store.bulim)
    // console.log(bulim);
    const [fturi, setFturi] = useState([])

    const getUserType = () => {
        axios.get(BaseUrl + "/api/admin/fturi", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res);
            setFturi(res.data.fTurlari)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getUserType()
    }, [])

    return (
        <div>
            <h2 className="mt-4">Foydalanuvchi turlari</h2>
            <hr />
            <div className="tableScroll">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Nomi</th>
                        <th scope="col">Izoh</th>
                        <th scope="col">Foydalanuvchilar soni</th>
                        <th scope="col">Faolmi</th>
                        {/* <th scope="col"></th> */}
                    </tr>
                </thead>
                <tbody>
                    {fturi && fturi.map((item, index) => (
                        <tr>
                            <th scope="row">{item.id}</th>

                            <td>{item.nomi}</td>
                            <td>{item.izoh}</td>
                            <td>{item.soni}</td>
                            <td><div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div></td>
                            {/* <td> */}
                            {/* <div className="main_edite-box"> */}
                            {/* <div className="edite_show_box"> */}
                            {/* <div className="show_data"><AiOutlineEye /></div> */}
                            {/* <div className="edite"><AiOutlineEdit /></div>
                                    <div  className="delete"><MdOutlineDelete /></div> */}
                            {/* </div> */}
                            {/* <div className="allDelete">Ma'lumotlar bazasidan o'chirish</div> */}
                            {/* </div> */}
                            {/* </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    )
}

export default UserType;