import React from "react";

const AkResurs = () => {
  return (
    <div className="container py-3">
      <h3 className="text-center">
        Axborot-kutubxona resurslari bilan xizmat ko‘rsatish bo‘limi
      </h3>
      <p className="m-0 mt-3">
        <b>
          2011-yil 13-aprelda qabul qilingan O‘zbekiston Respublikasining
          “Axborot-kutubxona faoliyati to‘g‘risida”gi qonunida ko‘rsatilgan
          vazifalarni amalga oshiradi.
        </b>
      </p>
      <p className="m-0 mt-2">Bo‘limning asosiy vazifasi:</p>
      <ul>
        <li>
          O‘quv zallarida, abonementlarda va fondlarni saqlash bo‘limida yagona
          kitobxonlik guvohnomasi bo‘yicha xizmat ko‘rsatish metodlarni
          qo‘llagan holda, foydalanuvchilarga tabaqalashtirilgan xizmat
          ko‘rsatishni tashkil etish;
        </li>
        <li>
          Foydalanuvchilar tomonidan berilayotgan buyurtmalarni bajarish, arxiv
          materiallarini foydalanuvchilarga yetkazib berish;
        </li>
        <li>
          Bo‘limdagi adabiyotlarni, arxiv materiallarni to‘g‘ri va sifatli
          saqlashni ta’minlash;
        </li>
        <li>
          Bo‘limdagi mavjud noyob, yagona nusxadagi adabiyotlarni elektron
          shaklini yaratish va yagona nusxadagi adabiyot va arxiv
          materiallaridan foydalanishni yo‘lga qo‘yish;
        </li>
        <li>
          Bo‘limga berilayotgan barcha adabiyotlarni qabul qilish, AK tizimida
          qayd etish va ro‘yxatga olish, fondga joylashtirish, foydalanishni
          yo‘lga qo‘yish;
        </li>
        <li>
          Kitobxonlarni (muassasa xodimlari, professor-o‘qituvchilar, talabalar
          ilmiy izlanuvchilar) axborot manbaalariga bo‘lgan ehtiyojlarini
          muntazam o‘rganib boorish;
        </li>
        <li>
          AT tizimlaridan foydalanib tezkor va sifatli xizmat ko‘rsatish;{" "}
        </li>
        <li>
          ARM o‘quv zallari tarkibini o‘rganib kerakli adabiyotlar bilan
          ta’minlash;
        </li>
        <li>
          Yaroqsiz holga kelgan adabiyotlarni bo‘lim tarkibidan dalolatnoma
          asosida chiqarish;
        </li>
        <li>
          Muassasada o’tkaziladigan tadbirlar uchun kitoblar ko‘rgazmalari va
          targ‘ibot materiallarini tashkil etish.
        </li>
      </ul>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid"
            src={require("..//../assets/akresurs1.jpg")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
        Axborot-kutubxona resurslari bilan xizmat ko‘rsatish bo‘limida jami 16
        nafar xodim kitobxonlarga xizmat ko‘rsatadi. Professor-o‘qituvchi va
        talabalarning markazda o‘zlari uchun zarur bo‘lgan axborot manbaalarini
        yetkazib berish va ularning darsdan tashqari vaqtlarini mazmunli
        o‘tkazishlari uchun markazdan tashqari talabalar yotoqxonalari, alohida
        xududlarda joylashgan fakultet binolarining har birida kunduzgi va
        kechki o‘quv zallari, kompyuter xonalarida muntazam xizmat ko‘rsatish
        navbatchiliklari tashkil etilgan. Bo‘lim xodimlari yil davomida bo‘limga
        yangi foydalanuvchi talaba va professor-o‘qituvchilarni qabul qilish,
        ularni kitobxonlik guvohnomasi bilan ta’minlash va axborot resurs
        markazi qonun qoidalarini tushuntirish ishlari olib boriladi.
        Talabalarga muntazam ravishda kerakli adabiyotlarni berish va foydalanib
        topshirilgan kitoblarni KBK asosida kitob javonlariga joylab boriladi.
        Bo‘limdan foydalanayotgan talaba va professor o‘qituvchining kitobxonlik
        varaqasi asosida ular olgan va topshirgan kitoblarini yil davomida
        kompyuter ma’lumotlar bazasiga kiritib boriladi.
      </p>
      <div className="row mt-2">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/akresurs2.jpg")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
        Axborot-kutubxona resurslarini butlash, kataloglashtirish va
        tizimlashtirish bo‘limidan berilgan yangi kitoblarni bo‘lim qayt
        daftariga ro‘yxatga kiritib, bo‘lim muhri qo‘yiladi. Har bir berilgan
        adabiyotlarni skaner yordamida elektron nusxalari tayyorlanadi.
        Bo‘limdan kunlik foydalangan kitobxonlarni qatnovi hamda berilgan
        adabiyotlarni kundalik va ro‘yxatga olish daftariga yozib boriladi.
        Bitiruvchi talabalarga va o‘qishni boshqa oliy o‘quv yurtlariga
        ko‘chirib ketayotgan talabalarga HEMIS platformasi orqali ijozat qog‘ozi
        beriladi. Universitet hamda ARMda o‘tkaziladigan tadbirlar uchun
        muntazam yangi kitoblar reklamasi va “Tanishing yangi adabiyotlar”
        shiori ostida ko‘rgazma va targ‘ibot dasturlari tashkil qilinadi.
      </p>
    </div>
  );
};

export default AkResurs;
