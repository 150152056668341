import React, { useEffect, useState } from "react";
// import "./UniversityStructure.css"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { MdOutlineDelete } from "react-icons/md"
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import ReactPaginate from "react-paginate"
import { useDispatch, useSelector } from "react-redux";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CiSquareRemove } from "react-icons/ci" 
import { IlmiyNashAction, IlmiyNashTuriAction, ShaharAction } from "../../redux/actions/Action";

const IlmiyNashrTuri = () => {

    // const [fakultet, setFakultet] = useState([])
    const navgate = useNavigate()
    const dispatch = useDispatch()
    const ilmiyNashrTuri = useSelector(state =>state.store.ilmiyNashrTuri)
    // console.log(fakultet);

    const [text, setText] = useState(false)
    const [nomi, setNomi] = useState("")
    const [Izoh, setIzoh] = useState("")
    const [active, setActive] = useState("")





  
    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === ""){} 
        else if (text == "Ilmiy nashr turi yaratildi."){
            toast.success(text)
        }
        else if (text === "Fakultet o'zgartirildi."){
            toast.success(text)
        }
        else{
            toast.error(text)
        }
        setText("")
    };

      const  getIlmiyNashrTuri = () => {
        axios.get(BaseUrl + "/api/kutubxonachi/ilmiynashrturi", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            dispatch(IlmiyNashTuriAction(res.data.ilmiyNashrTurlari))
            
        }).catch(err => {
            console.log(err);
        })
    }

    const deleteIlmiyTuri = (id) =>{
        axios.delete(BaseUrl+`/api/kutubxonachi/ilmiynashrturi/${id}`,{
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res.data);
            if (res.status == 200) {
                getIlmiyNashrTuri()
                setText(res.data.message)
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(() => {
        getIlmiyNashrTuri()
    }, [])



    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 13;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(Loading items from ${itemOffset} to ${endOffset});
        setCurrentItems(ilmiyNashrTuri && ilmiyNashrTuri.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(ilmiyNashrTuri.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, ilmiyNashrTuri]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % ilmiyNashrTuri.length;
        setItemOffset(newOffset);
    };

    return (
        <div>
             <ToastContainer/>
            <h2 className="mt-4">Ilmiy nashr turlari</h2>
            <hr />
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Link to={ sessionStorage.getItem("userId") == 1 ? "/Supermain/ilmiynashrturi/add" : "/Supermain/ilmiynashrturi/add"}>
                    <div className="add-kafedra">Qo'shsih</div>
                </Link>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Nomi</th>
                        <th scope="col">Izoh</th>
                        <th scope="col">Faolmi</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems && currentItems.map((item,index)=>(
                        <tr key={index}>
                        <th scope="row">{item.id}</th>
                        <td>{item.nomi}</td> 
                        <td>{item.izoh}</td> 
                        <td>{
                                item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                    :
                                    <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                }</td>
                        <td>
                            <div className="main_edite-box">
                                <div className="edite_show_box">
                                {/*  */}
                                {/*  */}
                                    {/* <div className="show_data"><AiOutlineEye /></div> */}
                                    <div onClick={() => navgate(`/Supermain/ilmiynashrturi/add/${item.id}`)} className="edite"><AiOutlineEdit /></div>
                                    <div onClick={()=>deleteIlmiyTuri(item.id)} className="delete"><MdOutlineDelete /></div>
                                </div>
                                {/* <div className="allDelete">Ma'lumotlar bazasidan o'chirish</div> */}
                            </div>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>


            <div className="my-pagination">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
        </div>
    )
}

export default IlmiyNashrTuri