import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { BaseUrl } from "../../../Super_admin/BaseUrl";

const DetailJurnal = () => {
  const [detailData, setDetailData] = useState({});
 
  const { id } = useParams();

  const getDetail = () => {
    axios
      .get(BaseUrl + `/api/kutubxonachi/jurnallar/show/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setDetailData(res?.data?.jurnal);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // kitob sumerka
  
  useEffect(() => {
    getDetail();
  }, []);



 
  return (
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item d-flex py-2">
                <span>
                  <b>Faolmi?:</b>
                </span>
                {detailData && detailData.active === 0 ? (
                  <div className="chek2" style={{ marginLeft: "10px" }}>
                    <CiSquareRemove style={{ color: "white" }} />
                  </div>
                ) : (
                  <div className="chek" style={{ marginLeft: "10px" }}>
                    <HiOutlineCheckCircle style={{ color: "white" }} />
                  </div>
                )}
              </li>
              <li class="list-group-item py-2">
                <b>Nomi: </b>
                {detailData && detailData.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Annotatsiya: </b>
                {detailData && detailData.anotatsiya}
              </li>
              <li class="list-group-item py-2">
                <b>ISSN: </b>
                {detailData && detailData.issn}
              </li>
              <li class="list-group-item py-2">
                <b>Telefon no'meri: </b>
                {detailData && detailData.tel_nomer}
              </li>
              <li class="list-group-item py-2">
                <b>Turi: </b>
                {detailData && detailData.turi}
              </li>
              <li class="list-group-item py-2">
                <b>Tahririyat a'zolari: </b>
                {detailData && detailData.tahririyatAzolari && detailData.tahririyatAzolari.map((item, index) => {
                    return <span key={index}>{item.nomi}, </span>;
                  })}
              </li>
              <li class="list-group-item py-2">
                <b>Fan sohasi: </b>
                {detailData && detailData.sahifa_soni}
              </li>
              <li class="list-group-item py-2">
                <b>Fan yo'nalishi: </b>
                {detailData.fan_sohasi && detailData.fan_sohasi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Davlati: </b>
                { detailData.country && detailData.country.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Fax: </b>
                {detailData && detailData.fax}
              </li>
              <li class="list-group-item py-2">
                <b>Email: </b>
                {detailData && detailData.email}
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
            <li class="list-group-item py-2">
                <b>Veb sayt: </b>
                <a target="_blank" href={detailData && detailData.web_sayt }>{detailData && detailData.web_sayt }</a>
              </li>
              <li class="list-group-item py-2">
                <b>Bosh muharrir: </b>
                {detailData && detailData.bosh_muharrir}
              </li>
              <li class="list-group-item py-2">
                <b>Muassasa nomi: </b>
                {detailData && detailData.muassasa_nomi}
              </li>
              {/* <li class="list-group-item py-2">
                <b>Nashr yozuvi: </b>
                { detailData.nashr_yozuvi && detailData.nashr_yozuvi.nomi}
              </li> */}
              <li class="list-group-item py-2">
                <b>Matn turi: </b>
                {  detailData.matn_turi && detailData.matn_turi.nomi}
              </li>
              <li class="list-group-item py-2">
                <b>Kirish huquqi: </b>
                {detailData && detailData.kirish_huquqi}
              </li>
              <li class="list-group-item py-2">
                <b>Muqova: </b>
                {  detailData.created_by && detailData.created_by.firstname} { detailData.created_by && detailData.created_by.lastname}
              </li>
              <li class="list-group-item py-2">
                <b>Izoh: </b>
                {  detailData.created_by && detailData.created_by.firstname} { detailData.created_by && detailData.created_by.lastname}
              </li>
            </ul>
          </div>
        </div>
  );
};

export default DetailJurnal;

