import React, { useState } from "react";
import { Oval } from "react-loader-spinner";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <div className="for_main_img2">
        {loading == true ? (
          <div className="for_loader">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            <div className="for_blur2">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img className="for_logo" src="/images/armlogo.png" />
              </div>
              <p className="for_title">
                Andijon davlat universiteti axborot resurs markazi
              </p>
              <form>
                <div
                  className="form_data d-flex"
                  style={{ justifyContent: "space-around" }}
                >
                  <div style={{ width: "80%" }}>
                    {loading ? (
                      <div>
                        <div class="input-group mt-3 mb-2">
                          <input
                            required
                            type="text"
                            class="form-control rounded-0"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            placeholder="Yangi parolni kiriting"
                          />
                        </div>
                        <div class="input-group mt-3 mb-3">
                          <input
                            type="email"
                            class="form-control rounded-0"
                            placeholder="Pochta manzilingizni kiriting"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn rounded-0 btn-danger"
                              id="basic-addon2"
                              type="button"
                            >
                              Prolni yangilash
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div class="input-group mb-3">
                        <input
                          type="email"
                          class="form-control rounded-0"
                          placeholder="Pochta manzilingizni kiriting"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn rounded-0 btn-primary"
                            id="basic-addon2"
                            type="button"
                          >
                            Prolni olish
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
