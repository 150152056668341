import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { BaseUrl } from "../../../../../Super_admin/BaseUrl";
import "./Electronic_catalogShow.css";
import { BsDownload } from "react-icons/bs";

import { MdLogin } from "react-icons/md";
import { spaceChildren } from "antd/es/button";
import ElectronModal from "./ElectronModal";

const Electronic_catalogShow = () => {
  const [catalogshow, setCatlogshow] = useState([]);
  const [modal1Open, setModal1Open] = useState(false);
  const [ruxsat, setRuxsat]= useState('')
  const { id } = useParams();
  const navigate = useNavigate();

  // console.log("fdsfdsf", id);asASaAA
  const getNews = () => {
    console.log("salom",sessionStorage.getItem("userId"))
    axios
      .get(BaseUrl + "/api/public/kitobkatalog/show/" + id)
      .then((res) => {
        console.log("show", res.data.kitob);
        setCatlogshow(res.data.kitob);
        setRuxsat(res.data.kitob.surovlar)
        res.data.kitob.surovlar.forEach(item=>{
          if (item.user_id == sessionStorage.getItem("userId")) {
            setRuxsat(item.user_id)
          }
        })
        sessionStorage.setItem("kitobId",res.data.kitob.id)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNews();
  }, []);

  console.log("salom",sessionStorage.getItem("userId"))
  // antd modal

  return (
    <div className="container mt-5 mb-5">
      <div className="row w-100">
        <p>{catalogshow && catalogshow.nomi}</p>
        <div className="col-md-4 col-sm-12 ">
          <img
            className="w-100 mb-3"
            src={BaseUrl + "/storage/" + catalogshow.muqova_jpg}
            alt="gfsdgsdf"
          />
          {catalogshow?.kirish_huquqi == 1 ? (
            <a
              href={BaseUrl + "/storage/" + catalogshow?.kitob_pdf}
              target={"_blank"}>
              <span className="download text-center w-100 border d-inline-block">
                <BsDownload /> Adabiyotni yuklab olish
              </span>
            </a>
          ) : catalogshow?.kirish_huquqi == 2 ? (
            sessionStorage.getItem("token") ? (
              <a
                href={BaseUrl + "/storage/" + catalogshow?.kitob_pdf}
                target={"_blank"}>
                <span className="download text-center w-100 border d-inline-block">
                  <BsDownload /> Adabiyotni yuklab olish
                </span>
              </a>
            ) : (
              <span
                className="download text-center w-100 border d-inline-block"
                onClick={() => {
                  sessionStorage.setItem("href", window.location.href);
                  navigate("/auth");
                }}>
                <BsDownload /> Kitobni yuklab olish uchun tizimga kiring!!
              </span>
            )
          ) : catalogshow?.kirish_huquqi == 3 ? (
            <span
              onClick={() => {ruxsat == sessionStorage.getItem("userId") ? setModal1Open(false) : setModal1Open(true)}}
              className="download text-center w-100 border d-inline-block">
              <BsDownload className="fs-4" /> {ruxsat == sessionStorage.getItem("userId") ? <span onClick={()=>{navigate(`/profile/readbook`);sessionStorage.setItem("kitob",catalogshow.kitob_pdf)}}>Adaboyotni o'qish</span> : "Adabiyotni o'qish so'rovini yuborish"} 
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-8 col-sm-12 py-2">
          <p className="m-0">
            <span className="text-muted">Mualliflar: </span>
            <span className="title_katalog">
              {catalogshow?.mualliflar?.map((item, index) => {
                return <span key={index}>{item.nomi}</span>;
              })}
            </span>
          </p>
          <p className="m-0">
            <span className="text-muted">Nashriyot: </span>
            <span className="title_katalog">{catalogshow?.nashriyot_nomi}</span>
          </p>
          <p className="m-0">
            <span className="text-muted">Resursni qo'shgan kutubxonachi: </span>
            <span className="title_katalog">
              {catalogshow?.created_by?.firstname + " "}
              {catalogshow?.created_by?.lastname}
            </span>
          </p>
          <div className="catalog_show_main">
            <span class="catalog_span">
              <samp className="text-muted">Resurs statusi</samp>{" "}
              {catalogshow?.kirish_huquqi == 1
                ? "Ochiq"
                : catalogshow?.kirish_huquqi == 2
                ? "Qisman ochiq"
                : "Yopiq"}
            </span>
            <span class="catalog_span ">
              <samp className="text-muted">Resurs turi:</samp>{" "}
              {catalogshow?.kitob_turi?.nomi}
            </span>
            <span class="catalog_span">
              <samp className="text-muted">Resurs tili:</samp>{" "}
              {catalogshow?.kitob_tili?.nomi}
            </span>
            <span class="catalog_span">
              <samp className="text-muted">Davlat:</samp>{" "}
              {catalogshow?.country?.nomi}
            </span>
            <span class="catalog_span">
              <samp className="text-muted">Nashr yili:</samp>{" "}
              {catalogshow?.nash_yili}
            </span>
            <span class="catalog_span">
              <samp className="text-muted">Resurs sohasi:</samp>{" "}
              {catalogshow?.fan_sohasi?.nomi}
            </span>
            <span class="catalog_span">
              <samp className="text-muted">Sahifa soni:</samp>{" "}
              {catalogshow?.betlar_soni}
            </span>
            <span class="catalog_span">
              <samp className="text-muted">Resurs ruxsati:</samp>{" "}
              {catalogshow?.kimga_muljallangan?.nomi}
            </span>
          </div>
        </div>
      </div>
      {/* <iframe src={BaseUrl + "/storage/" + catalogshow?.kitob_pdf} frameborder="0"></iframe> */}
      {/* onClick={() =>localStorage.setItem("href", window.location.href.slice(21)) } */}
      <ElectronModal setModal1Open={setModal1Open} modal1Open={modal1Open} kitobId={catalogshow.id}/>
    </div>
  );
};

export default Electronic_catalogShow;
