import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";

const AddUquvYili = () => {

    const [start_year, setStartYear] = useState("")
    const [nomi, setNomi] = useState("")
    const [izoh, setIzoh] = useState("")
    const [active, setActive] = useState("")
    const [text, setText] = useState(false)
    const { id } = useParams()


    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") { } else if (text === "Yangi O'quv yili yaratildi.") {
            toast.success(text)
        }
        else if (text === "O'quv yili bazadan o'zgartirildi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };


    const AddUquvYili = () => {
        if (id) {
            axios.put(BaseUrl + "/api/admin/uquvyili/edit/" + id, { start_year, nomi, izoh,active }, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                }
            }).catch(err => {
                console.log(err.response.data.message);
                setText(err.response.data.message)
                setText(err.response.data.errors)
            })
        }
        else {
            axios.post(BaseUrl + "/api/admin/uquvyili", { start_year, nomi, izoh }, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                }
            }).catch(err => {
                console.log(err.response.data.errors);
                setText(err.response.data.errors)
            })
        }
    }


    const getUquvYili = () => {
        axios.get(BaseUrl + "/api/admin/uquvyili", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log("Salom", res.data);
            res.data.uquvYillari.forEach((item, index) => {
                if (item.id == id) {
                    setStartYear(item.start_year)
                    setNomi(item.nomi);
                    setIzoh(item.izoh);
                }
            })
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getUquvYili()
    }, [])


    return (
        <div style={{ width: "100%" }}>
            <ToastContainer />
            <h2 className="mt-4">{id ? "O'quv yili o'zgartirish" : "O'quv yili yaratish"}</h2>
            <hr />
            <div style={{ width: "100%", padding: "10px 50px", display: "flex", justifyContent: "space-between" }} className="main_box">
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        <div>
                        <div>
                                <div><label className="mt-3" htmlFor="">Boshlanish yili</label></div>
                                <input type="number" onChange={(e)=>setStartYear(e.target.value)} value={start_year} className="form-control" placeholder="2023" />
                            </div>
                            {/* <div>
                                <div><label className="mt-3" htmlFor="">O'quv yili</label></div>
                                <input type="text" onChange={(e)=>set} className="form-control"  />
                            </div> */}
                            <div>
                            <div>
                            <div><label className="mt-3" htmlFor="">O'quv yili</label></div>
                            <input onChange={(e) => setNomi(e.target.value)} value={nomi} placeholder="2023-2024" className="form-control" type="text" />
                        </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        <div>
                        <div><label className="mt-3" htmlFor="">Izoh kiriting</label></div>
                                <input onChange={(e) => setIzoh(e.target.value)} value={izoh} placeholder="Izoh..." className="form-control" type="text" />
                        </div>
                        {id ? <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                           <div>
                            <div><label  htmlFor="">Faolligi</label></div>
                            <select  style={{width:"200px",height:"40px"}} className="form-select" onChange={(e)=>setActive(e.target.value)} name="" id="">
                                <option value="1">tanlang</option>
                                <option value="1">Faol</option>
                                <option value="0">Faol emas</option>
                            </select>
                           </div>
                            <button onClick={AddUquvYili} style={{ width: "30%", height:"40px" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                        </div>
                        : 
                        <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                            <button onClick={AddUquvYili} style={{ width: "30%", height:"30px"}} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUquvYili;