import axios from "axios";
import React, { useState } from "react";
import { BaseUrl } from "../../Super_admin/BaseUrl";

export const InnerModal = ({ propId, setOpen, setMsg }) => {
  const [dedlineDate, setDedlineDate] = useState("");
  const userId = sessionStorage.getItem("userId");

  const updateDate = async () => {
    try {
      const res = await axios.post(
        BaseUrl + `/api/admin/kitobqaytarish/muddat/${propId}`,
        {
          yangi_muddat: dedlineDate,
          user_id: userId,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      setMsg(res.data);
      setOpen((state) => !state);
      setDedlineDate("");
    } catch (error) {
      setDedlineDate("");
      setOpen((state) => !state);
      setMsg(error.response.data);
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Kitob muddatini uzaytirish
              </h1>
            </div>
            <div className="modal-body">
              <input
                onChange={(e) => setDedlineDate(e.target.value)}
                type="date"
                className="form-control"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Bekor qilish
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={updateDate}
              >
                Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
