import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const AddAdminStudent = () => {
  const { id } = useParams();

  const [hemisId, setHemisId] = useState([]);

  const [formValue, setFormValue] = useState({
    hemis_id: "",
    firstname: "",
    lastname: "",
    middlename: "",
    date_birth: "",
    tel_nomer: "",
    telegram_link: "",
    jinsi: "",
    manzili: "",
    jshshir: "",
    passport: "",
    f_turi_id: 2,
    lavozim_id: "",
    login: "",
    password: "",
    password_confirmation: "",
    kursi: "",
    yunalish_id: "",
    guruh_id: "",
    uquv_yili_id: "",
    role: 2,
    active: "",
    email: "",
    fakultet_id:"",
    user_id: sessionStorage.getItem("userId"),
  });

  const [rasm, setRasm] = useState("");
  const [passport_pd, setPassportPd] = useState("");

  const setInputData = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  // useEffect(() => {
  //   const getStudentData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://student.adu.uz/rest/v1/data/student-list?search=${formValue.hemis_id}`,
  //         {
  //           headers: {
  //             accept: "application/json",
  //             Authorization: "Bearer PsgNenky8yaqBfyVfz6EB5knSMaHVae_",
  //           },
  //         }
  //       );
  //       console.log("response - ", response);

  //       if (response?.data?.data?.items?.length === 1) {
  //         setHemisId(response?.data?.data?.items[0]);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       console.log("unaccepted error");
  //     }
  //   };

  //   getStudentData();
  // }, [formValue?.hemis_id]);

  // https://akapi.adu.uz/api/kutubxonachi/student-list?search=301211102124

  console.log(sessionStorage.getItem("token"));
  

  useEffect(() => {
    axios
      .get(
        `https://akapi.adu.uz/api/kutubxonachi/student-list?search=${formValue.hemis_id}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("hemisId - ", res);
        if (res.data.data.items.length === 1) {
          setHemisId(res.data.data.items[0]);
        }
      })
      .catch((err) => {
        console.log("jnbkijsnkjn");

        console.log(err);
      });
  }, [formValue.hemis_id]);

  // POST DATA:

  const postSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("passport_pdf", passport_pd);
    formData.append("rasm", rasm);
    formData.append("hemis_id", formValue.hemis_id);
    formData.append("firstname", hemisId.first_name);
    formData.append("lastname", hemisId.second_name);
    formData.append("middlename", hemisId.third_name);
    formData.append("date_birth", formValue.date_birth);
    formData.append("tel_nomer", formValue.tel_nomer);
    formData.append("telegram_link", formValue.telegram_link);
    formData.append("jinsi", hemisId.gender.name);
    formData.append("manzili", formValue.manzili);
    formData.append("jshshir", formValue.jshshir);
    formData.append("passport", formValue.passport);
    formData.append("f_turi_id", formValue.f_turi_id);
    formData.append("lavozim_id", formValue.lavozim_id);
    formData.append("login", formValue.login);
    formData.append("password", formValue.password);
    formData.append("password_confirmation", formValue.password_confirmation);
    formData.append("kursi", hemisId.level.name);
    formData.append("fakultet_id", formValue.fakultet_id);
    formData.append("yunalish_id", formValue.yunalish_id);
    formData.append("guruh_id", formValue.guruh_id);
    formData.append("uquv_yili_id", formValue.uquv_yili_id);
    formData.append("role", formValue.role);
    formData.append("active", formValue.active);
    formData.append("email", formValue.email);
    formData.append("user_id", formValue.user_id);

    axios
      .post(BaseUrl + "/api/kutubxonachi/users", formData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // EDIT DATA:

  const editSubmit = (e) => {
    // console.log("jashshr", typeof formValue.jshshir);
    e.preventDefault();

    let formData = new FormData();

    formData.append("passport_pdf", passport_pd);
    formData.append("rasm", rasm);
    formData.append("hemis_id", formValue.hemis_id);
    formData.append("firstname", hemisId.first_name);
    formData.append("lastname", hemisId.second_name);
    formData.append("middlename", hemisId.third_name);
    formData.append("date_birth", formValue.date_birth);
    formData.append("tel_nomer", formValue.tel_nomer);
    formData.append("telegram_link", formValue.telegram_link);
    formData.append("jinsi", hemisId.gender.name);
    formData.append("manzili", formValue.manzili);
    formData.append("jshshir", formValue.jshshir);
    formData.append("passport", formValue.passport);
    formData.append("f_turi_id", formValue.f_turi_id);
    formData.append("lavozim_id", formValue.lavozim_id);
    formData.append("login", formValue.login);
    formData.append("password", formValue.password);
    formData.append("password_confirmation", formValue.password_confirmation);
    formData.append("kursi", hemisId.level.name);
    formData.append("fakultet_id", formValue.fakultet_id);
    formData.append("yunalish_id", formValue.yunalish_id);
    formData.append("guruh_id", formValue.guruh_id);
    formData.append("uquv_yili_id", formValue.uquv_yili_id);
    formData.append("role", formValue.role);
    formData.append("active", formValue.active);
    formData.append("email", formValue.email);
    formData.append("user_id", formValue.user_id);

    axios
      .post(BaseUrl + "/api/kutubxonachi/users/edit/" + id, formData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        params: {
          _method: "PUT",
        },
      })
      .then((res) => {
        if (res.status == 200) {
          toast.info(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // GET DATA:

  useEffect(() => {
    if (id) {
      axios(BaseUrl + "/api/kutubxonachi/users/show/" + id, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((res) => {
          setFormValue({
            ...formValue,
            hemis_id: res.data.user.hemis_id,
            date_birth: res.data.user.date_birth,
            tel_nomer: res.data.user.tel_nomer,
            telegram_link: res.data.user.telegram_link,
            manzili: res.data.user.manzili,
            jshshir: res.data.user.jshshir,
            passport: res.data.user.passport,
            login: res.data.user.login,
            password: res.data.user.password_visible,
            password_confirmation: res.data.user.password_visible,
            email: res.data.user.email,
          });
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, []);

  // For education form:

  const [position, setPosition] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [yunalish, setYunalish] = useState([]);
  const [group, setGroup] = useState([]);
  const [educationYear, setEducationYear] = useState([]);

  // console.log('yunalish_id', formValue.yunalish_id);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/lavozim/" + 2, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setPosition(res.data.lavozimlar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/fakultet", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setFaculty(res.data.fakultetlar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/yunalish/" + formValue.fakultet_id, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setYunalish(res.data.yunalishlar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [formValue.fakultet_id]);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/guruh/" + formValue.yunalish_id, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setGroup(res.data.guruhlar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [formValue.yunalish_id]);

  useEffect(() => {
    axios
      .get(BaseUrl + "/api/admin/uquvyili", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setEducationYear(res.data.uquvYillari);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      <h2 className="mt-4">Ro'yxatga olish</h2>
      <hr />
      <div className="for_main_img">
        <div
          className="form_data d-flex"
          style={{ justifyContent: "space-around" }}
        >
          <div style={{ width: "40%" }}>
            <div className="mb-3 mt-3">
              <label className="form-label">Hemis id:</label>
              <div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Hemis id"
                  name="hemis_id"
                  value={formValue.hemis_id}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Ismi:</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ismi"
                  name="firstname"
                  value={hemisId && hemisId.first_name}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Familiyasi:</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Familiyasi"
                  name="lastname"
                  value={hemisId && hemisId.second_name}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Otasini ismi:</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Otasini ismi"
                  name="middlename"
                  value={hemisId && hemisId.third_name}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Tug'ilgan sana:</label>
              <div>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Tug'ilgan sana"
                  name="date_birth"
                  value={formValue.date_birth}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Telefon no'mer:</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Telefon no'mer"
                  name="tel_nomer"
                  value={formValue.tel_nomer}
                  onChange={setInputData}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Telegram:</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Telegram"
                  name="telegram_link"
                  value={formValue.telegram_link}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Jinsi:</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Jinsi"
                  name="jinsi"
                  value={hemisId.gender && hemisId.gender.name}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Manzil:</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Manzil"
                  name="manzili"
                  value={formValue.manzili}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">J.SH.SH.I.R:</label>
              <div>
                <input
                  type="number"
                  maxLength="14"
                  className="form-control"
                  placeholder="JSHSHIR (Faqat raqam kirgizing)"
                  name="jshshir"
                  value={formValue.jshshir}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Pasport: (AC 0000000)</label>
              <div>
                <input
                  type="text"
                  maxLength="9"
                  className="form-control"
                  placeholder="Pasport"
                  name="passport"
                  value={formValue.passport}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Pasprot PDF:</label>
              <div>
                <input
                  type="file"
                  className="form-control"
                  placeholder="Pasprot PDF"
                  name="passport_pdf"
                  onChange={(e) => setPassportPd(e.target.files[0])}
                />
              </div>
            </div>

            <div className="mb-3">
              {/* <label className="form-label">Foydalanuvchi turi:</label> */}
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Talaba"
                  name="f_turi_id"
                  value={formValue.f_turi_id}
                  onChange={setInputData}
                  hidden
                />
              </div>
              {/* Foydalanuvchi turi - f_turi_id: foydalanuvchi turini value si */}
            </div>
          </div>

          <div style={{ width: "40%" }}>
            <div className="mb-3">
              <label className="form-label">
                Ta'lim shakli ({formValue.f_turi_id}):
              </label>
              {/* Ta'lim shakli - lavozim_id */}
              <div>
                <select
                  className="form-select"
                  name="lavozim_id"
                  value={formValue.lavozim_id}
                  onChange={setInputData}
                >
                  <option value="">Tanlang</option>
                  {position &&
                    position.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="mb-3 mt-3">
              <label className="form-label">Login:</label>
              <div>
                {id ? (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Login"
                    name="login"
                    value={formValue.login}
                    onChange={setInputData}
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Login"
                    name="login"
                    value={formValue.login}
                    onChange={setInputData}
                  />
                )}
              </div>
            </div>

            <div className="mb-3 mt-3">
              <label className="form-label">
                Parol (kamida bitta katta harf va raqam bo'lish shart):
              </label>
              <div>
                {id ? (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Parol"
                    name="password"
                    value={formValue.password}
                    onChange={setInputData}
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Parol"
                    name="password"
                    value={formValue.password}
                    onChange={setInputData}
                  />
                )}
              </div>
            </div>

            <div className="mb-3 mt-3">
              <label className="form-label">Parol tasdig'i:</label>
              <div>
                {id ? (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Parol tasdig'i"
                    name="password_confirmation"
                    value={formValue.password_confirmation}
                    onChange={setInputData}
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Parol tasdig'i"
                    name="password_confirmation"
                    value={formValue.password_confirmation}
                    onChange={setInputData}
                  />
                )}
              </div>
            </div>

            {/* <div className="mb-3 mt-3">
              <label className="form-label">Foydalanuvchining rasmi:</label>
              <div>
                <input
                  type="file"
                  className="form-control"
                  placeholder="Foydalanuvchining rasmi"
                  name="rasm"
                  onChange={(e) => setRasm(e.target.files[0])}
                />
              </div>
            </div> */}

            <div className="mb-3 mt-3">
              <label className="form-label">Kursi:</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Kursi"
                  name="kursi"
                  value={hemisId.level && hemisId.level.name}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Fakultet ({formValue.fakultet_id}) :
              </label>
              <div>
                <select
                  className="form-select"
                  name="fakultet_id"
                  value={formValue.fakultet_id}
                  onChange={setInputData}
                >
                  <option value="">Tanlang</option>
                  {faculty &&
                    faculty.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Yo'nalishi ({formValue.yunalish_id}):
              </label>
              <div>
                <select
                  className="form-select"
                  name="yunalish_id"
                  value={formValue.yunalish_id}
                  onChange={setInputData}
                >
                  <option value="">Tanlang</option>
                  {yunalish &&
                    yunalish.map((item, index) => (
                      <option key={index} value={item.id}>
                        { item ? item.nomi : "Biriktirilmagan"}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Guruhi ({hemisId.group && hemisId.group.name}):
              </label>
              <div>
                <select
                  className="form-select"
                  name="guruh_id"
                  value={formValue.guruh_id}
                  onChange={setInputData}
                >
                  <option value="">Tanlang</option>
                  {group &&
                    group.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Kirgan yili:</label>
              <div>
                <select
                  className="form-select"
                  name="uquv_yili_id"
                  value={formValue.uquv_yili_id}
                  onChange={setInputData}
                >
                  <option value="">Tanlang</option>
                  {educationYear &&
                    educationYear.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nomi}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="mb-3 mt-3">
              <label className="form-label">Mail:</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mail"
                  name="email"
                  value={formValue.email}
                  onChange={setInputData}
                />
              </div>
            </div>

            <div className="mb-3 mt-3">
              {/* <label className="form-label">Role (Kitobxon):</label> */}
              <div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Kitobxon"
                  name="role"
                  value={formValue.role}
                  onChange={setInputData}
                  hidden
                />
              </div>
              {/* value = 2 */}
            </div>

            <div className="mb-3">
              <label className="form-label">Holati:</label>
              <div>
                <select
                  className="form-select"
                  name="active"
                  value={formValue.active}
                  onChange={setInputData}
                >
                  <option value="">Tanlang</option>
                  <option value="1">Faol</option>
                  <option value="0">Faol emas</option>
                </select>
              </div>
            </div>

            <div
              className="mt-4"
              style={{ display: "flex", justifyContent: "end" }}
            >
              {id ? (
                <button
                  type="submit"
                  className="btn btn-primary d-flex justify-content-end mt-4 mb-5"
                  onClick={editSubmit}
                >
                  O'zgartirish
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary d-flex justify-content-end mt-4 mb-5"
                  onClick={postSubmit}
                >
                  Qo'shish
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdminStudent;
