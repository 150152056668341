import axios from "axios";
import { useState, useEffect } from "react";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { CiSquareRemove } from "react-icons/ci";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InnerModal } from "./InnerModal";

function InnnerReturnBooks() {
  const [oneUser, setOneUser] = useState([]);
  const [propId, setPropId] = useState("");
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const getOneUser = () => {
    axios
      .get(
        BaseUrl +
          `/api/admin/kitobqaytarish/show/${sessionStorage.getItem(
            "attachId"
          )}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setOneUser(res?.data?.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOneUser();
  }, []);

  useEffect(() => {
    if (!msg.errors) toast.success(msg.message);
    else toast.error(msg.errors);
    getOneUser();
  }, [open]);

  /////////////////////////////////////////////////////////

  const [postBook, setPostBook] = useState({
    user_id: sessionStorage.getItem("userId"),
  });

  const returnBook = (id) => {
    window.confirm("Rostdan ham resursni qa'bul qilmoqchimisiz ?");

    axios
      .post(BaseUrl + `/api/admin/kitobqaytarish/${id}`, postBook, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success(res?.data?.message);
        getOneUser();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container my-5">
      <InnerModal propId={propId} setOpen={setOpen} setMsg={setMsg} />
      <div className="row">
        <div className="col-md-4">
          {oneUser?.rasm == null ? (
            <img
              src={require("../assets/user-avatar.png")}
              alt="user-img"
              width="300"
              height="350px"
            />
          ) : (
            <img
              src={BaseUrl + "/storage/" + oneUser?.rasm}
              alt="user-img"
              width="270"
              height="350px"
            />
          )}
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-sm-4 col-md-4">
              <b>Faolmi:</b>
            </div>
            <div className="col-md-8 mb-3">
              {oneUser.active == 1 ? (
                <div className="chek">
                  <HiOutlineCheckCircle style={{ color: "white" }} />
                </div>
              ) : (
                <div className="chek2">
                  <CiSquareRemove style={{ color: "white" }} />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 col-md-4">
              <b>F.I.SH:</b>
            </div>
            <div className="col-md-8">
              <p>
                {oneUser?.firstname +
                  " " +
                  oneUser?.lastname +
                  " " +
                  oneUser?.middlename}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 col-md-4">
              <b>Foydalanuvchi:</b>
            </div>
            <div className="col-md-8">
              <p>
                {oneUser?.f_turi?.nomi == null
                  ? "topilmadi"
                  : oneUser?.f_turi?.nomi}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <b>Tug'ilgan sana:</b>
            </div>
            <div className="col-md-8">
              <p>
                {oneUser?.date_birth == null
                  ? "topilmadi"
                  : oneUser?.date_birth}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <b>Jinsi:</b>
            </div>
            <div className="col-md-8">
              <p>{oneUser?.jinsi == null ? "topilmadi" : oneUser?.jinsi}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <b>Kirgan yili:</b>
            </div>
            <div className="col-md-8">
              <p>
                {oneUser?.uquv_yili?.nomi == null
                  ? "topilmadi"
                  : oneUser?.uquv_yili?.nomi}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <b>Fakultet:</b>
            </div>
            <div className="col-md-8">
              <p>
                {oneUser?.fakultet?.nomi == null
                  ? "topilmadi"
                  : oneUser?.fakultet?.nomi}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <b>Yo'nalish:</b>
            </div>
            <div className="col-md-8">
              <p>
                {oneUser?.yunalish?.nomi == null
                  ? "topilmadi"
                  : oneUser?.yunalish?.nomi}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <b>Ta'lim shakli:</b>
            </div>
            <div className="col-md-8">
              {oneUser?.f_turi_id == 2 && oneUser?.lavozim_id != 4 ? (
                <p>
                  {oneUser?.lavozim?.nomi != null
                    ? oneUser?.lavozim?.nomi + " (bakalavr)"
                    : "topilmadi"}
                </p>
              ) : (
                <p>{oneUser?.lavozim?.nomi}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <b>Kursi:</b>
            </div>
            <div className="col-md-8">
              <p>{oneUser?.kursi == null ? "topilmadi" : oneUser?.kursi}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <b>Hemis id:</b>
            </div>
            <div className="col-md-8">
              <p>
                {oneUser?.hemis_id == null ? "topilmadi" : oneUser?.hemis_id}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <b>Email:</b>
            </div>
            <div className="col-md-8">
              <p>{oneUser?.email == null ? "topilmadi" : oneUser?.email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-lg-12">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Kitob nomi</th>
                <th>Inventar raqam</th>
                <th>Olgan vaqti</th>
                <th>Topshirish muddati</th>
                <th>Status</th>
                <th>Kitob rasmi</th>
                <th>Kitob qaytarish</th>
                <th>Topshirish muddatini o'zgartirish</th>
              </tr>
            </thead>
            <tbody>
              {oneUser?.olgan_kitoblar?.map((item, index) => {
                let currentTime = new Date().getTime();
                let takenTime = new Date(item?.muddati).getTime();
                let resultTime = currentTime - takenTime;
                let daysLeft = Math.floor(resultTime / (1000 * 60 * 60 * 24));

                return (
                  <tr key={index}>
                    <td>{item?.id}</td>
                    <td>{item?.kitobInventar?.kitob.nomi}</td>
                    <td>{item?.kitobInventar?.inventar_raqam}</td>
                    <td>{moment(item?.created_at).format("YYYY-MM-DD")}</td>
                    <td>{item?.muddati}</td>
                    <td>
                      {item?.status == 2 ? (
                        <button className="btn btn-danger">
                          muddati o'tkan ({`${daysLeft}`} kun)
                        </button>
                      ) : item?.status == 1 ? (
                        <button className="btn btn-warning">o'qilmoqda</button>
                      ) : item?.status == 0 ? (
                        <button className="btn btn-success">topshirgan</button>
                      ) : (
                        item?.status
                      )}
                    </td>
                    <td>
                      <img
                        src={
                          BaseUrl +
                          "/storage/" +
                          item?.kitobInventar?.kitob?.muqova_jpg
                        }
                        alt="user-img"
                        width="50"
                        height="50px"
                      />
                    </td>
                    <td>
                      {item?.status == 0 ? (
                        <button className="btn btn-success" disabled>
                          kitob qaytarish
                        </button>
                      ) : (
                        <td>
                          <button
                            className="btn btn-success"
                            onClick={() => returnBook(item?.id)}
                          >
                            kitob qaytarish
                          </button>
                        </td>
                      )}
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        onClick={() => setPropId(item?.id)}
                      >
                        Muddatni yangilash
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default InnnerReturnBooks;
