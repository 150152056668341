import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../../../../../../Super_admin/BaseUrl";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";

const InventarAll = () => {
  const [allInventarTable, setAllInventarTable] = useState([]);
  const [text, setText] = useState("");
  const navgate = useNavigate();
  const { id } = useParams();

  const notify = () => {
    if (text === "") {
    } else if (text === "Yangi Kitob inventar yaratildi.") {
      toast.success(text);
    } else if (text === "Kitob inventar bazadan o'chirildi.") {
      toast.success(text);
    } else if (text === "Kitob inventar bazadan o'zgartirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };


  useEffect(() => {
    notify();
  }, [text]);

  const getInventarAllTable = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobinventar/" + id ,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data);
        setAllInventarTable(res.data.kitobInventarlar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(()=>{
    getInventarAllTable()
  })

  const deleteItem = (id) => {
    axios
      .delete(BaseUrl + `/api/kutubxonachi/kitobinventar/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.message);
        if (res.status == 200) {
          getInventarAllTable();
          setText(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div class="table-responsive">
      <table class="table align-middle table-striped">
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">Faolmi?</th>
            <th scope="col">Bo‘limlar</th>
            <th scope="col">Inventar raqami</th>
            <th scope="col">Shtrixli kod</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {allInventarTable &&
            allInventarTable.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>
                    {item.active === 0 ? (
                      <div className="chek2">
                        <CiSquareRemove style={{ color: "white" }} />
                      </div>
                    ) : (
                      <div className="chek">
                        <HiOutlineCheckCircle style={{ color: "white" }} />
                      </div>
                    )}
                  </td>
                  <td>{item.bulim.nomi}</td>
                  <td>{item.inventar_raqam}</td>
                  <td>{item.shtrix_code}</td>
                  <td>
                    <div className="main_edite-box">
                      <div className="edite_show_box">
                        {/* <div
                          className="edite"
                          onClick={(e) => {
                            navgate(
                              `/adminMain/books_collection/detail/${id}/${item.id}`
                            )
                          }}>
                          <AiOutlineEdit />{" "}
                        </div> */}
                        <div
                          className="delete"
                          onClick={() => deleteItem(item.id)}
                        >
                          <MdOutlineDelete />{" "}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default InventarAll;
