import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../BaseUrl";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux";
import { BulimAction, UserListAction } from "../../../redux/actions/Action";
import { CiSquareRemove } from "react-icons/ci"
import axios from "axios";
import { MdOutlineDelete } from "react-icons/md"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate"



const UsersList = () => {


    const [text, setText] = useState(false)
    const user_id = sessionStorage.getItem("userId")


    const navigate = useNavigate()
    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") { } else if (text === "Yangi Fakultet yaratildi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };


    const deleteUquvYili = (id) => {
        // console.log(user_id);
        axios.post(BaseUrl + "/api/admin/users/" + id, { user_id }, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data);
            getUserList()
            if (res.status == 200) {
                // getUquvYili()
                setText(res.data.message)
            }
        }).catch(err => {
            console.log(err);
        })
    }

 
    const dispatch = useDispatch()

    const [userCount, setUserCount] = useState(15)
    const [searchTerm, setSearchTerm] = useState("")
    const [ users, setUsers] = useState([])
    const [pageCount, setPageCount] = useState();

    const getUserList = (page=1) => {
        axios.get(BaseUrl + `/api/admin/users?paginator=${userCount}&page=${page}&search=${searchTerm}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.users.data);
            sessionStorage.setItem("userDataCount", res.data.users.total)
            setPageCount(res.data.users.last_page)
            setUsers(res.data.users.data)
            
        }).catch(err => {
            console.log(err);
        })
    }
    
    useEffect(() => {
        getUserList()
    }, [userCount,searchTerm])
    
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = userCount;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(users && users.slice(itemOffset, endOffset));
    }, [itemOffset, itemsPerPage, users]);


    const handlePageClick = (event) => {
        getUserList(event.selected+1)
    };

    
    

    return (
        <div>
            <ToastContainer />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="mt-4">Adminlar ro'yxati</h2>
                <div className="for-search">
                    <div className="for-search2">
                        <img style={{ width: "30px", position: "absolute", margin: "5px 0 0 5px" }} src="/images/search.png" alt="" />
                    </div>
                    <input onChange={event => { setSearchTerm(event.target.value) }} className="form-control search_input" type="search" />
                </div>
            </div>

            <hr />
            <select onChange={(e)=>setUserCount(+e.target.value)} name="" id="">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                {/* {console.log(userCount)} */}
            </select>
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Link to="/Supermain/userRegister/add">
                    <div className="add-kafedra" > Qo'shish</div>
                </Link>
            </div>
            <div className="tableScroll">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Ism familiya</th>
                        <th scope="col">Foydalanuvchi turi</th>
                        <th scope="col">Login</th>
                        <th scope="col">Parol</th>
                        <th scope="col">Holati</th>
                        <th style={{ textAlign: "center" }}>Amallar</th>
                    </tr>
                </thead>
                
               <tbody>
                    {currentItems && currentItems.map((item, index) => (
                        <tr>
                            <th scope="row">{item.id}</th>
                            <td>{item.firstname + " "} {item.lastname}</td>
                            <td>{item.f_turi_id == null || item.f_turi_id == "null" ? "Yo'q" : item.f_turi.nomi}</td>
                            <td>{item.login}</td>
                            <td>{item.password_visible}</td>
                            {/* <td>{item.bulim_id == null || item.bulim_id == "null" ? "Yo'q" : item.bulim.nomi}</td> */}
                            {/* <td>{item.lovozim_id == null || item.lovozim_id == "null" ? "Yo'q" : item.lavozim.nomi}</td> */}
                            {/* <td>{item.fakultet_id == null || item.fakultet_id == "null" ? "Yo'q" : item.fakultet.nomi}</td> */}
                            {/* <td>{item.kafedra_id == null ? "Yo'q" : item.kafedra.nomi}</td> */}
                            {/* <td>{item.yunalish_id == null || item.yunalish_id == "null" ? "Yo'q" : item.yunalish.nomi}</td> */}
                            {/* <td>{item.guruh_id == null ? "Yo'q" : item.guruh.nomi}</td> */}
                            {/* <td>{item.uquv_yili_id == null ? "Yo'q" : item.uquv_yili.nomi}</td> */}
                            <td>
                                {
                                    item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                        :
                                        <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                }
                            </td>
                            <td>
                                <div className="main_edite-box">
                                    <div className="edite_show_box">
                                        <div onClick={() => navigate('/Supermain/userlist/detail')} className="show_data"><AiOutlineEye /></div>
                                        <div onClick={() => navigate('/Supermain/userRegister/add/' + item.id)} className="edite"><AiOutlineEdit /></div>
                                        <div onClick={() => deleteUquvYili(item.id)} className="delete"><MdOutlineDelete /></div>
                                    </div>
                                    {/* <div className="allDelete">Ma'lumotlar bazasidan o'chirish</div> */}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
                
            </table>
            </div>
            <b>Jami {sessionStorage.getItem("userDataCount")} ta ma'lumotdan {currentItems.length} ko'rsatilmoqda</b>

            <div className="my-pagination">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
        </div>
    )
}

export default UsersList;