/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";

const UserDetails = () => {
  const { id } = useParams();

  const [bookCatalogShow, setBookCatalogShow] = useState();

  const getAllData = async () => {
    try {
      const res = await axios.get(
        BaseUrl + `/api/kutubxonachi/users/show/${id}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      console.log("details", res.data.user);
      setBookCatalogShow(res.data.user);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div className="navBar">
        <p style={{ textTransform: "uppercase", width:"100%", fontSize:"24px" }} className="text-center">
          To'liq ma'lumotlar
        </p>
        <Link to="/adminMain/book_catalog">
          <div className="add-kafedra">Orqaga</div>
        </Link>
      </div>
      <hr />
      <div className="card shadow p-3">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item d-flex py-2">
                <span>
                  <b>Faolmi:</b>
                </span>
                {bookCatalogShow && bookCatalogShow.active === 0 ? (
                  <div className="chek2" style={{ marginLeft: "10px" }}>
                    <CiSquareRemove style={{ color: "white" }} />
                  </div>
                ) : (
                  <div className="chek" style={{ marginLeft: "10px" }}>
                    <HiOutlineCheckCircle style={{ color: "white" }} />
                  </div>
                )}
              </li>
              <li class="list-group-item py-2">
                <b>Shaxsiy id: </b>
                {bookCatalogShow && bookCatalogShow.id
                  ? bookCatalogShow.id
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Hemis id: </b>
                {bookCatalogShow && bookCatalogShow.hemis_id
                  ? bookCatalogShow.hemis_id
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Ismi: </b>
                {bookCatalogShow && bookCatalogShow.firstname
                  ? bookCatalogShow.firstname
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Familyasi: </b>
                {bookCatalogShow && bookCatalogShow.lastname
                  ? bookCatalogShow.lastname
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Otasining ismi: </b>
                {bookCatalogShow && bookCatalogShow.middlename
                  ? bookCatalogShow.middlename
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Tug'ilgan kuni: </b>
                {bookCatalogShow && bookCatalogShow.date_birth
                  ? bookCatalogShow.date_birth
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Manzili: </b>
                {bookCatalogShow && bookCatalogShow.manzili
                  ? bookCatalogShow.manzili
                  : "Ma'lumot topilmadi"}
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-sm-12">
            <ul class="list-inline">
              <li class="list-group-item py-2">
                <b>JSHSHIR: </b>
                {bookCatalogShow && bookCatalogShow.jshshir
                  ? bookCatalogShow.jshshir
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Elektron pochtasi: </b>
                {bookCatalogShow && bookCatalogShow.email
                  ? bookCatalogShow.email
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Telefon nomeri: </b>
                {bookCatalogShow && bookCatalogShow.tel_nomer
                  ? bookCatalogShow.tel_nomer
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Yaratilgan vaqti: </b>
                {new Date(
                  bookCatalogShow && bookCatalogShow.created_at
                ).toLocaleString()
                  ? new Date(
                      bookCatalogShow && bookCatalogShow.created_at
                    ).toLocaleString()
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Yangilangan vaqti: </b>
                {new Date(
                  bookCatalogShow && bookCatalogShow.updated_at
                ).toLocaleString()
                  ? new Date(
                      bookCatalogShow && bookCatalogShow.updated_at
                    ).toLocaleString()
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Fakultet : </b>
                {bookCatalogShow && bookCatalogShow.fakultet.nomi
                  ? bookCatalogShow.fakultet.nomi
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>Ta'lim yo'nalishi : </b>
                {bookCatalogShow && bookCatalogShow.yunalish.nomi
                  ? bookCatalogShow.yunalish.nomi
                  : "Ma'lumot topilmadi"}
              </li>
              <li class="list-group-item py-2">
                <b>O'qishga kirgan o'quv yili : </b>
                {bookCatalogShow && bookCatalogShow.uquv_yili.nomi
                  ? bookCatalogShow.uquv_yili.nomi
                  : "Ma'lumot topilmadi"}
              </li>
            </ul>
          </div>
        </div>
        <hr/>
        {bookCatalogShow && (
          <>
            {bookCatalogShow?.olgan_kitoblar.length != 0 && (
              <div class="table-responsive">
                <div
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  className="text-center my-4"
                >
                  O'qigan kitoblari
                </div>
                <table class="table align-middle table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Muqova</th>
                      <th scope="col">Kitob nomi</th>
                      <th scope="col">Kitob mualliflari</th>
                      <th scope="col">Kitob invertar</th>
                      <th scope="col">Topshirish muddati</th>
                      <th scope="col">Topshirgan vaqti</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookCatalogShow &&
                      bookCatalogShow.olgan_kitoblar.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                width={60}
                                height={60}
                                src={
                                  BaseUrl + "/storage/" + item.kitob?.muqova_jpg
                                }
                              />
                            </td>
                            <td width={500}>{item.kitob?.nomi}</td>
                            <td>
                              {item.kitob.mualliflar.map((v) => (
                                <span>{v.nomi}</span>
                              ))}
                            </td>
                            <td>{item.kitob_inventar?.inventar_raqam}</td>
                            <td>{item.muddati}</td>
                            <td>{item.topshirgan_vaqti}</td>
                            <td>
                              {item.status === 0
                                ? "Topshirdi"
                                : item.status === 1
                                ? "O'qilmoqda"
                                : "Muddati o'tgan"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {bookCatalogShow?.surovlar.length != 0 && (
              <div class="table-responsive">
                <div
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  className="text-center my-4"
                >
                  Kitob yuklash uchun so'rovlari
                </div>
                <table class="table align-middle table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Muqova</th>
                      <th scope="col">Kitob nomi</th>
                      <th scope="col">Kitob mualliflari</th>
                      <th scope="col">So'rov amal qilish muddati</th>
                      <th scope="col">Kutubxonachi</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookCatalogShow &&
                      bookCatalogShow.surovlar.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                width={60}
                                height={60}
                                src={
                                  BaseUrl + "/storage/" + item.kitob?.muqova_jpg
                                }
                              />
                            </td>
                            <td width={500}>{item.kitob?.nomi}</td>
                            <td>
                              {item.kitob.mualliflar.map((v) => (
                                <span>{v.nomi}</span>
                              ))}
                            </td>
                            <td>{item.muddati} - kun</td>
                            <td>
                              {item.kutubxonachi.lastname}{" "}
                              {item.kutubxonachi.firstname}
                            </td>
                            <td>
                              {item.status === 0
                                ? "Jarayonda"
                                : item.status === 1
                                ? "Faol"
                                : item.status === 2
                                ? "Rad etildi"
                                : "Muddati o'tgan"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {bookCatalogShow?.bron_kitoblar.length != 0 && (
              <div class="table-responsive">
                <div
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  className="text-center my-4"
                >
                  Bron qilgan kitoblari
                </div>
                <table class="table align-middle table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Muqova</th>
                      <th scope="col">Kitob nomi</th>
                      <th scope="col">Kitob mualliflari</th>
                      <th scope="col">Kitob invertar</th>
                      <th scope="col">Topshirish muddati</th>
                      <th scope="col">Topshirgan vaqti</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookCatalogShow &&
                      bookCatalogShow.bron_kitoblar.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                width={60}
                                height={60}
                                src={
                                  BaseUrl + "/storage/" + item.kitob?.muqova_jpg
                                }
                              />
                            </td>
                            <td width={500}>{item.kitob?.nomi}</td>
                            <td>
                              {item.kitob.mualliflar.map((v) => (
                                <span>{v.nomi}</span>
                              ))}
                            </td>
                            <td>{item.kitob_inventar?.inventar_raqam}</td>
                            <td>{item.muddati}</td>
                            <td>{item.topshirgan_vaqti}</td>
                            <td>
                              {item.status === 0
                                ? "Yangi"
                                : item.status === 1
                                ? "Qabul qilidi"
                                : item.status === 2
                                ? "Kitob berildi"
                                : "Muddati o'tgan"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {bookCatalogShow?.qatnovlar.length != 0 && (
              <div class="table-responsive">
                <div
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  className="text-center my-4"
                >
                  Kutubxonaga qatnovlari
                </div>
                <table class="table align-middle table-striped mb-5">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Maqsadi</th>
                      <th scope="col">Bo'limi</th>
                      <th scope="col">Kelgan sanasi</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookCatalogShow &&
                      bookCatalogShow.qatnovlar.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.maqsad?.nomi}</td>
                            <td>{item.bulim?.nomi}</td>
                            <td>{item.created_at}</td>
                            <td>{item.status === 0 ? "Yangi" : "Maqsadli"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
