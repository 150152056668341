import React from "react";
import "./XorijiyContent.css";

const XorijiyContent = () => {
  return (
    <div className="container my-5 library-fond">
      <h1>Xorijiy ilmiy-ta'limiy ma'lumotlar bazasi</h1>
      <div className="row py-3">
        <div className="col-md-4 col-sm-12 content_wrapper">
          <a href="https://www.webofscience.com/" target={"_blank"}>
            <img
              src={require("../../assets/xorijiy_bazalar1.png")}
              className="img-fluid shadow"
              alt=""
            />
          </a>
        </div>
        <div className="col-md-4 col-sm-12 content_wrapper">
          <a href="https://search.ebscohost.com/" target={"_blank"}>
            <img
              src={require("../../assets/xorijiy_bazalar2.png")}
              className="img-fluid shadow"
              alt=""
            />
          </a>
        </div>
        <div className="col-md-4 col-sm-12 content_wrapper">
          <a href="https://link.springer.com/" target={"_blank"}>
            <img
              src={require("../../assets/xorijiy_bazalar3.png")}
              className="img-fluid shadow"
              alt=""
            />
          </a>
        </div>
      </div>

      <h1>Elektron kutubxona axborot tizimlari</h1>
      <section></section>
      <div className="row py-3">
        <div className="col-md-3 col-sm-12 content_wrapper">
          <a href="http://kutubxona.adu.uz/" target={"_blank"}>
            <img
              src={require("../../assets/kutubxonaAdu.jpg")}
              className="img-fluid shadow"
              alt=""
            />
          </a>
        </div>

        <div className="col-md-3 col-sm-12 content_wrapper">
          <a href="https://t.me/aduarmbot" target={"_blank"}>
            <img
              src={require("../../assets/telegram_bot.jpg")}
              className="img-fluid shadow"
              alt=""
            />
          </a>
        </div>

        <div className="col-md-3 col-sm-12 content_wrapper">
          <a href="https://unilibrary.uz/" target={"_blank"}>
            <img
              src={require("../../assets/unilibrary.jpg")}
              className="img-fluid shadow"
              alt=""
            />
          </a>
        </div>

        <div className="col-md-3 col-sm-12 content_wrapper">
          <a href="https://www.natlib.uz/" target={"_blank"}>
            <img
              src={require("../../assets/natlib.jpg")}
              className="img-fluid shadow"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default XorijiyContent;
