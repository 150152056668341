import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux";
import { BulimAction, JurnalListAction, ToplamListAction, UserListAction } from "../../../redux/actions/Action";
import { CiSquareRemove } from "react-icons/ci"
import axios from "axios";
import { MdOutlineDelete } from "react-icons/md"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate"
import { BaseUrl } from "../../../Super_admin/BaseUrl";



const Toplam = () => {


    const [text, setText] = useState(false)
    const user_id = sessionStorage.getItem("userId")


    const navigate = useNavigate()
    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") { } else if (text === "Yangi Fakultet yaratildi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };

    // const deleteLUser = (id) => {
    //     axios.delete(BaseUrl + `/api/admin/users/${2}`,user_id ,{
    //         headers: {
    //             "Authorization": "Bearer " + sessionStorage.getItem("token")
    //         }
    //     }).then(res => {
    //         console.log(res.data);
    //         // if (res.status == 200) {
    //         //     getUserList()
    //         //     setText(res.data.message)
    //         // }
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // }

    const deleteToplam = (id) => {
        axios.delete(BaseUrl + "/api/kutubxonachi/ilmiytuplam/" + id, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data);
            getToplam()
            if (res.status == 200) {
                // getUquvYili()
                setText(res.data.message)
            }
        }).catch(err => {
            console.log(err);
        })
    }


    const dispatch = useDispatch()

    const toplamlar = useSelector(state => state.store.toplamlar)
    // console.log(bulim);

    const getToplam = () => {
        axios.get(BaseUrl + "/api/kutubxonachi/ilmiytuplam", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data.ilmiyNashrTuplamlar);
            dispatch(ToplamListAction(res.data.ilmiyNashrTuplamlar))
        }).catch(err => {
            console.log(err.response);
        })
    }

    useEffect(() => {
        getToplam()
    }, [])

    const [userCount, setUserCount] = useState("15")
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = userCount;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(Loading items from ${itemOffset} to ${endOffset});
        setCurrentItems(toplamlar && toplamlar.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(toplamlar.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, toplamlar]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % toplamlar.length;
        setItemOffset(newOffset);
    };

    const [searchTerm, setSearchTerm] = useState("")
    

    return (
        <div>
            <ToastContainer />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="mt-4">Ma'qola va tezislar to'plamlari</h2>
                {/* <div className="for-search">
                    <div className="for-search2">
                        <img style={{ width: "30px", position: "absolute", margin: "5px 0 0 5px" }} src="/images/search.png" alt="" />
                    </div>
                    <input onChange={event => { setSearchTerm(event.target.value) }} className="form-control search_input" type="search" />
                </div> */}
            </div>

            <hr />
            {/* <select style={{}} onChange={(e)=>setUserCount(e.target.value)} name="" id="">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select> */}
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Link to={sessionStorage.getItem("userId") == 1 ? "/Supermain/toplam/add" : "/adminMain/toplam/add"}>
                    <div className="add-kafedra" > Qo'shish</div>
                </Link>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Rasmi</th>
                        <th scope="col">Nomi</th>
                        <th scope="col">Nashr yili</th>
                        <th scope="col">Muassasa nomi</th>
                        <th scope="col">Davlati</th>
                        {/* <th scope="col">Kafedra</th> */}
                        {/* <th scope="col">Guruh</th> */}
                        {/* <th scope="col">O'quv yili</th> */}
                        {/* <th scope="col">Holati</th> */}
                        <th style={{ textAlign: "center" }}>Amallar</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems && currentItems.filter((value => {
                        if (searchTerm === "") {
                            return value
                        } else if (value.firstname.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return value
                        }
                        else if (value.lastname.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return value
                        }
                        else if (value.bulim_id != null && value.bulim_id != "null") {
                            if (value.bulim.nomi.toLowerCase().includes(searchTerm.toLowerCase())) {
                                return value
                            }
                        }
                        else if (value.fakultet_id != null && value.fakultet_id != "null") {
                            if (value.fakultet.nomi.toLowerCase().includes(searchTerm.toLowerCase())) {
                                return value
                            }
                        }
                        else if (value.hemis_id != null && value.hemis_id != "null") {
                            if (value.hemis_id.toLowerCase().includes(searchTerm.toLowerCase())) {
                                return value
                            }
                        }})).map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{item.id}</th>
                            <td> <img src={BaseUrl + '/storage/' + item.muqova_jpg} alt="book-img" width='100' height='70px' /></td>
                            <td>{item.nomi}</td>
                            <td>{item.nashr_yili}</td>
                            <td>{item.muassasa_nomi}</td>
                            <td>{item.country.nomi}</td>
                            {/* <td>{item.issn}</td> */}
                            {/* <td>{item.kafedra_id == null ? "Yo'q" : item.kafedra.nomi}</td> */}
                            {/* <td>{item.guruh_id == null ? "Yo'q" : item.guruh.nomi}</td> */}
                            {/* <td>{item.uquv_yili_id == null ? "Yo'q" : item.uquv_yili.nomi}</td> */}
                            {/* <td>
                                {
                                    item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                        :
                                        <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                }
                            </td> */}
                            <td>
                                <div className="main_edite-box">
                                    <div className="edite_show_box">
                                        <div onClick={() => navigate(`/Supermain/toplam/show/${item.id}`)} className="show_data"><AiOutlineEye /></div>
                                        {/* <div onClick={() => navigate('/Supermain/userRegister/add/' + item.id)} className="edite"><AiOutlineEdit /></div> */}
                                        <div onClick={() => deleteToplam(item.id)} className="delete"><MdOutlineDelete /></div>
                                    </div>
                                    {/* <div className="allDelete">Ma'lumotlar bazasidan o'chirish</div> */}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="my-pagination">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
        </div>
    )
}

export default Toplam;