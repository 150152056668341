import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../../BaseUrl";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


const TashrifShow = () => {
  const { id } = useParams();
  const [tashrifshow, setTashrifShow] = useState()
 


  const getTashrifShow = () => {
    axios
      .get(BaseUrl + `/api/admin/qatnov-hisobot/show/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log("tashrifshof",res.data);
        setTashrifShow(res.data.qatnovlar)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTashrifShow();
  }, []);


 

  return (
    <div style={{ width: "100%" }}>
      <div className="navBar">
        <p>Tashrif </p>
        <ToastContainer />
        <Link to="/Supermain/tashrif">
          <div className="add-kafedra">Orqaga</div>
        </Link>
      </div>
      <hr />
      <div className="tableScroll">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Ism</th>
                        <th scope="col">Familya</th>
                        <th scope="col">Bo'lim</th>
                        <th scope="col">Maqsadi</th>
                        <th scope="col">Kelgan vati</th>
                        
                        {/* <th scope="col"></th> */}
                    </tr>
                </thead>
                <tbody>
                    {tashrifshow && tashrifshow.map((item, index) => (
                        <tr>
                            <th scope="row">{index + 1 }</th>
                            <td>{item.user.firstname}</td>
                            <td>{item.user.lastname}</td>
                            <td>{item.bulim.nomi}</td>
                            <td>{item.maqsad.nomi}</td>
                            <td>{item.created_at}</td>
                            {/* <td><div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div></td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
    </div>
  );
};

export default TashrifShow;
