import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../../Super_admin/BaseUrl";
import "./KutubxonaFond.css";
import { BsJournalBookmarkFill } from "react-icons/bs";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { PiStudent } from "react-icons/pi";
import { LiaChalkboardTeacherSolid,LiaUsersSolid,LiaUserSolid } from "react-icons/lia";


const KutubxonaFond = () => {
  const [fond, setFond] = useState([]);
  const [counterOn, setCounterOn] = useState(false);

  // const navgate = useNavigate();

  const getNews = () => {
    axios
      .get(BaseUrl + "/api/public/statistika/fond")
      .then((res) => {
        // console.log(res.data);
        setFond(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNews();
  }, []);


  return (
   <>
    <div class="container text-center">
      <div class="row gy-2">
        <div class="col-md-2 col-sm-12 p-2 m-0">
          <div className="card">

            <div className="card-body" style={{display:"flex", justifyContent:"center"}}>
              <div style={{width:"100px"}}><img  className="img-fluid" src={require("../../../assets/01_kitob_nomda.png")} alt="" /></div>
            </div>
            <div className="card-footer"><h2>{fond ? fond.kitoblarNomda : "0"}</h2></div>
            <div className="card-footer "><h5>Kitoblar nomda</h5></div>
        </div>
          </div>

        <div class="col-md-2 col-sm-12 p-2 m-0">
          <div className="card">

        <div className="card-body" style={{display:"flex", justifyContent:"center"}}>
              <div style={{width:"100px"}}><img  className="img-fluid" src={require("../../../assets/02_kitob sonda.png")} alt="" /></div>
            </div>
            <div className="card-footer"><h2>{fond ? fond.kitoblarSonda : "0"}</h2></div>
            <div className="card-footer "><h5>Kitoblar sonda</h5></div>
        </div>
          </div>

        <div class="col-md-2 col-sm-12 p-2 m-0">
          <div className="card">

        <div className="card-body" style={{display:"flex", justifyContent:"center"}}>
              <div style={{width:"100px"}}><img  className="img-fluid" src={require("../../../assets/03_jurnal_nomda.png")} alt="" /></div>
            </div>
            <div className="card-footer"><h2>{fond ? fond.jurnallarNomda : "0"}</h2></div>
            <div className="card-footer "><h5>Jurnallar nomda</h5></div>
        </div>
          </div>

        <div class="col-md-2 col-sm-12 p-2 m-0">
          <div className="card">

        <div className="card-body" style={{display:"flex", justifyContent:"center"}}>
              <div style={{width:"100px"}}><img  className="img-fluid" src={require("../../../assets/04_jurnal_sonda.png")} alt="" /></div>
            </div>
            <div className="card-footer"><h2>{fond ? fond.jurnallarSonda : "0"}</h2></div>
            <div className="card-footer "><h5>Jurnallar sonda</h5></div>
        </div>
          </div>

        <div class="col-md-2 col-sm-12 p-2 m-0">
          <div className="card">

        <div className="card-body" style={{display:"flex", justifyContent:"center"}}>
              <div style={{width:"100px"}}><img  className="img-fluid" src={require("../../../assets/05_maqolalar.png")} alt="" /></div>
            </div>
            <div className="card-footer"><h2>{fond ? fond.maqolalarSoni : "0"}</h2></div>
            <div className="card-footer "><h5>Maqolalar</h5></div>
        </div>
          </div>

        <div class="col-md-2 col-sm-12 p-2 m-0">
          <div className="card">

        <div className="card-body" style={{display:"flex", justifyContent:"center"}}>
              <div style={{width:"100px"}}><img  className="img-fluid" src={require("../../../assets/06_ilmiy_nashrlar.png")} alt="" /></div>
            </div>
            <div className="card-footer "><h2>{fond ? fond.ilmiyNashrlar : "0"}</h2></div>
            <div className="card-footer "><h5>Ilmiy nashrlar</h5></div>
        </div>
        
      </div>   </div>
    </div>
{/* {require("../../../assets/01_kitob nomda.png")} */}

   </>
  );
};

export default KutubxonaFond;
