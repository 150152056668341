import React, { useEffect, useState } from "react";
import "./UniversityStructure.css"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { MdOutlineDelete } from "react-icons/md"
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { KafedraAction } from "../../../redux/actions/Action";
import ReactPaginate from "react-paginate"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CiSquareRemove } from "react-icons/ci"     
// import { UseSelector } from "react-redux/es/hooks/useSelector";

const UniversityStructureKafedra = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const kafedra = useSelector(state => state.store.kafedra)
    const [text, setText] = useState(false)

    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === ""){} 
        else if (text === "Yangi Fakultet yaratildi."){
            toast.success(text)
        }
        else if (text === "Fakultet o'zgartirildi."){
            toast.success(text)
        }
        else{
            toast.error(text)
        }
        setText("")
    };


    const getKafedra = () => {
        axios.get(BaseUrl + "/api/admin/kafedra", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.kafedralar);
            // setFakultet(res.data.fakultetlar)
            dispatch(KafedraAction(res.data.kafedralar))
        }).catch(err => {
            console.log(err);
        })
    }

    const deleteFaculty = (id) =>{
        axios.delete(BaseUrl+`/api/admin/kafedra/${id}`,{
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res.data.message);
            if (res.status == 200) {
                getKafedra()
                setText(res.data.message)
            }
        }).catch(err=>{
            console.log(err);
        })
    }

   

    useEffect(() => {
        getKafedra()
    }, [])



    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 13;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(Loading items from ${itemOffset} to ${endOffset});
        setCurrentItems(kafedra && kafedra.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(kafedra.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, kafedra]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % kafedra.length;
        setItemOffset(newOffset);
    };

    return (
        <div>
              <ToastContainer/> 
            <h2 className="mt-4">Kafedralar</h2>
            <hr />
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Link to="/Supermain/kafedra/add">
                    <div className="add-kafedra">Kafedra qo'shish</div>
                </Link>
            </div>
            <div className="tableScroll">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Fakulteti</th>
                        <th scope="col">Nomi</th>
                        <th scope="col">Izoh</th>
                        <th scope="col">Foydalanuvchilar soni</th>
                        <th scope="col">Faolmi</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentItems && kafedra.map((item, index) => (
                            <tr key={index}>
                                {/* {console.log(item.fakultet)} */}
                                <th scope="row">{item.id}</th>
                                <td>{item.fakultet.nomi}</td>
                                <td>{item.nomi}</td>
                                <td>{item.izoh}</td>
                                <td>{item.soni}</td>
                                <td>{
                                item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                    :
                                    <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                }</td>
                                <td>
                                    <div className="main_edite-box">
                                        <div className="edite_show_box">
                                            {/* <div className="show_data"><AiOutlineEye /></div> */}
                                            <div onClick={()=>navigate("/Supermain/kafedra/add/"+item.id)} className="edite"><AiOutlineEdit /></div>
                                            <div onClick={()=>deleteFaculty(item.id)} className="delete"><MdOutlineDelete /></div>
                                        </div>
                                        {/* <div className="allDelete">Ma'lumotlar bazasidan o'chirish</div> */}
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            </div>
            <div className="my-pagination">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
        </div>
    )
}

export default UniversityStructureKafedra