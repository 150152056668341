import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Books_collectionAction } from "../../../redux/actions/Action";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { BaseUrl } from "../../../Super_admin/BaseUrl";

const Books = () => {
  const [text, setText] = useState(false);
  const [switchs, setSwitchs] = useState();



  const dispatch = useDispatch();

  const navgate = useNavigate();

  useEffect(() => {
    notify();
  }, [text]);





  const handleSwitch = async (kitobId, kitob_tavsiya) => {
    setSwitchs(kitob_tavsiya ? true : false)
    const data = { switchs: !switchs, kitobId, user_id }
    await axios.post(
      BaseUrl + "/api/admin/kitobtavsiya",
      data,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        getDate()
        setText(res.data.message)
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const notify = () => {
    if (text === "") {
    } else if (text === "Kimga muljallanganlik bazadan o'chirildi.") {
      return toast.success(text);
    } else if (text === "Kitob tavsiya etish uchun tanlandi.") {
      return toast.success(text);
    } else if (
      text === "Ushbu kitob va unga biriktirilgan mualliflar, sumerka va bo'lim bog'lanishlari, inventar raqamlar va kitob fayllari bazadan o'chirildi."
    ) {
      return toast.success(text);
    } else {
      return toast.error(text);
    }
    setText("");
  };

  const [userCount, setUserCount] = useState(15)
  const [searchTerm, setSearchTerm] = useState("")
  const [users, setUsers] = useState([])
  const [pageCount, setPageCount] = useState();
  const [books, setBooks] = useState([])

  const getDate = (page = 1) => {
    axios.get(BaseUrl + `/api/user/kitoblar?paginator=${userCount}&page=${page}&search=${searchTerm}`, {
      headers: {
        "Authorization": "Bearer " + sessionStorage.getItem("token")
      }
    }).then(res => {
      console.log(res.data.kitoblar);
      sessionStorage.setItem("userDataCount", res.data.kitoblar.total)
      setPageCount(res.data.kitoblar.last_page)
      setUsers(res.data.kitoblar.data)

    }).catch(err => {
      console.log(err);
    })
  }


  const deleteItem = (id) => {
    axios
      .delete(BaseUrl + `/api/kutubxonachi/kitoblar/${id}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.message);
        if (res.status == 200) {
          getDate();
          setText(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDate()
  }, [userCount, searchTerm])

  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = userCount;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(users && users.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, users]);


  const handlePageClick = (event) => {
    getDate(event.selected + 1)
  };

  const user_id = sessionStorage.getItem("userId");

  useEffect(() => {
    getDate()
  }, [])
  return (
    <div>
      <div className="navBar">
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <h2 className="mt-4">Kitoblar</h2>
          <div className="for-search">
            <div className="for-search2">
              <img style={{ width: "30px", position: "absolute", margin: "5px 0 0 -15px" }} src="/images/search.png" alt="" />
            </div>
            <input onChange={event => { setSearchTerm(event.target.value) }} className="form-control search_input" type="search" />
          </div>
        </div>
        <ToastContainer />
      </div>
      <hr />
      <div class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Muqova tasviri</th>
              <th scope="col">Kitob nomi</th>
              <th scope="col">Mualliflar</th>
              <th scope="col">ISBN</th>
              <th scope="col">Soni</th>
              <th scope="col"></th>
              <th>
                {sessionStorage.getItem("userId") == 1 && "Tavsiya etilsinmi?"}
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((item,index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>
                        <img width={100}  height={80} src={BaseUrl + "/storage/" + item.muqova_jpg} alt="gfsdgsdf"  />
                    </td>
                    <td>{item.nomi}</td>
                    <td>
                      {item.mualliflar &&
                        item.mualliflar.map((it, index) => {
                          return <div>{it.nomi}</div>;
                        })}
                    </td>
                    <td>{item.isbn}</td>
                    <td>{item.soni}</td>
                    <td></td>
                    {/* <td>{item.kitob_turi.nomi}</td>
                    <td>{item.kitob_tili.nomi}</td> */}

                    <td>
                      {sessionStorage.getItem("userId") == 1 && (
                        <div class="form-check form-switch h-100 d-flex justify-content-center aligin-itens-center">
                          <input
                            class="form-check-input fs-5 cursor-pointer"
                            type="checkbox"
                            role="switch"
                            name={item.id}
                            id={item.id}
                            checked={item.kitob_tavsiya ? true : false}

                            onClick={() => handleSwitch(item.id, item.kitob_tavsiya)}
                          />
                        </div>
                      )}
                    </td>

                    <td>
                      <div className="main_edite-box ">
                        <div className="edite_show_box">
                          <div
                            className="show_data"
                            onClick={() => {
                              navgate(
                                `/profile/kitoblar/show/${item.id}`
                              );
                              sessionStorage.setItem("showId", item.id);
                            }}>
                            <AiOutlineEye />
                          </div>

                          {
                            sessionStorage.getItem("userId") == 1 &&
                            <div
                              className="delete"
                              onClick={() => deleteItem(item.id)}>
                              <MdOutlineDelete />
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default Books;

