import React, { useEffect, useState } from "react";
// import "./UniversityStructure.css"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { MdOutlineDelete } from "react-icons/md"
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { KafedraAction, UquvYiliAction, YunalishAction } from "../../../redux/actions/Action";
import ReactPaginate from "react-paginate"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CiSquareRemove } from "react-icons/ci"   
const UserUquvYili = () =>{


    
    const dispatch = useDispatch()
    const uquvyili = useSelector(state => state.store.uquvyili)
    const [text, setText] = useState(false)
    const navigate = useNavigate()


    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === ""){} 
        else if (text === "Yangi Fakultet yaratildi."){
            toast.success(text)
        }
        else if (text === "Fakultet o'zgartirildi."){
            toast.success(text)
        }
        else{
            toast.error(text)
        }
        setText("")
    };

    const getUquvYili = () => {
        axios.get(BaseUrl + "/api/admin/uquvyili", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            dispatch(UquvYiliAction(res.data.uquvYillari))
        }).catch(err => {
            console.log(err.response);
        })
    }

    useEffect(() => {
        getUquvYili()
    }, [])

    const deleteUquvYili = (id) =>{
        axios.delete(BaseUrl+`/api/admin/uquvyili/${id}`,{
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res.data.message);
            if (res.status == 200) {
                getUquvYili()
                setText(res.data.message)
            }
        }).catch(err=>{
            console.log(err);
        })
    }



    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 13;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(Loading items from ${itemOffset} to ${endOffset});
        setCurrentItems(uquvyili && uquvyili.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(uquvyili.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, uquvyili]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % uquvyili.length;
        setItemOffset(newOffset);
    };



    return(
        <div>
        <ToastContainer/> 
   <h2 className="mt-4">O'quv yili</h2>
   <hr />
   <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
       <Link to="/Supermain/uquvYili/add">
           <div className="add-kafedra">O'quv yili qo'shish</div>
       </Link>
   </div>
   <div className="tableScroll">
   <table className="table">
       <thead>
           <tr>
               <th scope="col">№</th>
               <th scope="col">O'quv yili</th>
               <th scope="col">Izoh</th>
               <th scope="col">Faolmi</th>
               <th scope="col"></th>
           </tr>
       </thead>
       <tbody>
           {
               currentItems && currentItems.map((item, index) => (
                   <tr key={index}>
                       <th scope="row">{item.id}</th>
                       <td>{item.nomi}</td>
                       <td>{item.izoh}</td>
                       <td>{
                           item.active === 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                               :
                               <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                           }</td>
                       <td>
                           <div className="main_edite-box">
                               <div className="edite_show_box">
                                   {/* <div className="show_data"><AiOutlineEye /></div> */}
                                   <div onClick={() => navigate(`/Supermain/uquvYili/add/${item.id}`)} className="edite"><AiOutlineEdit /></div>
                                   <div onClick={()=>deleteUquvYili(item.id)} className="delete"><MdOutlineDelete /></div>
                               </div>
                               {/* <div className="allDelete">Ma'lumotlar bazasidan o'chirish</div> */}
                           </div>
                       </td>
                   </tr>
               ))
           }
       </tbody>
   </table>
   </div>

   <div className="my-pagination">
       <ReactPaginate
           breakLabel="..."
           nextLabel=" >"
           onPageChange={handlePageClick}
           pageRangeDisplayed={2}
           pageCount={pageCount}
           previousLabel="<"
           renderOnZeroPageCount={null}
           containerClassName="pagination"
           pageLinkClassName="page-num"
           previousLinkClassName="page-num"
           nextLinkClassName="page-num"
           activeLinkClassName="active"
       />
   </div>
</div>
    )
}

export default UserUquvYili;