import "./User.css";
import { Routes, Route } from "react-router-dom";
import Header from "./userComponents/header/Header";
import Footer from "./userComponents/footer/Footer"
import Material_technical_base from "./userComponents/HeaderMenuPage/Material_technical_base/Material_technical_base"
import Remote_service from "./userComponents/HeaderMenuPage/Remote_service/Remote_service"
import Traditional_service from "./userComponents/HeaderMenuPage/Traditional_service/Traditional_service"
import Nizom from "./userComponents/HeaderMenuPage/Nizom/Nizom"
import Home from "./userPages/home/Home";
import Electronic_catalog from "./userComponents/HeaderMenuPage/Electronic_catalog/Electronic_catalog";
import Electronic_catalogShow from "./userComponents/HeaderMenuPage/Electronic_catalog/Electronic_catalogShow/Electronic_catalogShow";
import IlmiyUslubiy from "./userComponents/HeaderMenuPage/KutubxonaTuzilma/IlmiyUslubiy/IlmiyUslubiy";
import AkKatalog from "./userComponents/HeaderMenuPage/KutubxonaTuzilma/AkKatalog/AkKatalog";
import AkResurs from "./userComponents/HeaderMenuPage/KutubxonaTuzilma/AkResurs/AkResurs";
import ElektronAxborot from "./userComponents/HeaderMenuPage/KutubxonaTuzilma/ElektronAxborot/ElektronAxborot";
import XorijiyAxborot from "./userComponents/HeaderMenuPage/KutubxonaTuzilma/XorijiyAxborot/XorijiyAxborot";


function User() {
  return (
    <div className="wrapper">
      <Header />
      <div className="main_contentt">
      <Routes>
          <Route path="/*" element={<Home />} />
          {/* HeaderPage menu */}
          {/* Kutubxona Tuzilmasi */}
          <Route path="/ilmiy_uslubiy" element={<IlmiyUslubiy />}/>
          <Route path="/butlash_kataloglashtrish" element={<AkKatalog />}/>
          <Route path="/axborot_kutubxona_xizmat" element={<AkResurs />}/>
          <Route path="/elektron_axborot" element={<ElektronAxborot />}/>
          <Route path="/xorijiy_axborot" element={<XorijiyAxborot />}/>
          {/* Kutubxona Xizmatlari */}
          <Route path="/remote_service" element={<Remote_service />} />
          <Route path="/traditional_service" element={<Traditional_service />}/>
          {/* Kutubxona Fondi */}
           <Route path="/electronic_catalog/*" element={<Electronic_catalog />} />
           <Route path="/electronic_catalog/show/:id" element={<Electronic_catalogShow />} />
          <Route path="/material_technical_base" element={<Material_technical_base />}/>
         {/* Yo'riqnoma */}
          <Route path="/nizom" element={<Nizom />} />
          
        </Routes>
      </div>
      <footer>
        <Footer />
        
      </footer>
    </div>
  );
}

export default User;
