import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";

const UserDetail = () =>{
    return(
        <div>
        <ToastContainer />
       <h2 className="mt-4">Foydalanuvchining barcha ma'lumotlari</h2>
       <hr />
             
   </div>
    )
}

export default UserDetail;