import React from "react";

const IlmiyUslubiy = () => {
  return (
    <div className="container py-3">
      <h3 className="text-center">
        Ilmiy-uslubiy va axborot-ma’lumotlar (davriy nashrlar) bo‘limi
      </h3>
      <p className="m-0 mt-2">Bo‘limning asosiy vazifasi:</p>
      <ul>
        <li>
          Ilmiy-uslubiy va axborot-ma’lumotlar bo‘limida muhim sanalar va
          bayramlarga bag‘ishlab buklet, devoriy gazeta va ko‘rgazmalar tashkil
          qilish;
        </li>
        <li>
          Yil davomida ARMga keltirilgan yangi adabiyotlar haqida ma’lumot
          beruvchi “Axborot kunlari”ni tashkil qilish va kafedralarga ma’lumot
          tarqatishda ishtirok etish;
        </li>
        <li>
          Bo‘lim ma’lumot-axborot apparatini tashkil qilish uchun maqolalarni
          ko‘rib chiqish, analitik tavsiflash va sistemalashtirish, kartoteka
          to‘ldirish va tahrir qilish ishlarini yuritib borish.
        </li>
        <li>
          Bo‘limda mavjud bo‘lgan kataloglarni (sistemali, alfavitli) to‘ldirib
          borish.
        </li>
        <li>
          Ma’lumotlar bibliografik xizmat ko‘rsatishni savol va javob
          tarkibidagi ma’lumot turlarini (mavzuli, bibliografik aniqlash,
          manzilli bibliografik, faktografik) amalga oshirish.{" "}
        </li>
        <li>
          Muntazam ravishda matbuot nashrlarini qabul qilib olish, tarqatish va
          va ular bilan tanishtirishni amalga oshirish.
        </li>
      </ul>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid"
            src={require("..//../assets/ilmiy-uslubiy.jpg")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
        Ilmiy uslubiy va axborot ma’lumotlar (davriy nashrlar) bo‘limida
        professor o‘qituvchilar, doktarant, magistr, talabalar ilmiy adabiyotlar
        va gazeta jurnallardan foydalanadilar. Bo‘limda yillik ish reja asosida
        ishlar olib boriladi. Bo‘lim xodimlari universitetda o‘tkaziladigan
        tadbirlarda kitob ko‘rgazmalari tashkil etishda ishtirok etadilar.
      </p>
      <div className="row mt-2">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <img
            className="img-fluid shadow"
            src={require("..//../assets/ilmiy_uslubiy2.jpg")}
            alt=""
          />
        </div>
      </div>
      <p className="m-0 mt-2">
        Shu bilan birga shoir va yozuvchilarning tavallud topgan kuni munosabati
        bilan buklet, tavsiyaviy ro‘yxat va tavsiyaviy ma’lumotlar
        tayyorlaydilar. Bo‘lim xodimlari ARM ga kelgan yangi adabiyotlarni
        talabalarga yetkazish uchun kitob targ‘iboti o‘tkazadilar. Bo‘limdagi
        yagona nusxadagi adabiyotlar skaner qilinib, elektron bo‘limiga taqdim
        etiladi. Bo‘limga kelgan gazeta va jurnallar ro‘yxatdan o‘tkazilib
        ularning xar bir soni to‘plab boriladi. Gazetalarda chop etilgan qonun
        va qarorlar, o‘lkaga oid yangiliklar sistemali kortotekaga joylab
        boriladi. Shu bilan birga ARMga kelgan yangi kitobxonlar, litsey va
        maktab o‘quvchilari, hamda universitetning 1-bosqich talabalarini ARM
        faoliyati bilan muntazam tanishtiradilar.
      </p>
    </div>
  );
};

export default IlmiyUslubiy;
