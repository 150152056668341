import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { CiSquareRemove } from "react-icons/ci";
import { Book_directionAction } from "../../../redux/actions/Action";
import { BsSearch } from "react-icons/bs";

const Inventarlar = () => {
  const [allInventarTable, setAllInventarTable] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getInventarAllTable = () => {
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobinventar", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setAllInventarTable(res.data.kitobInventarlar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInventarAllTable();
  },[]);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 13;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      allInventarTable && allInventarTable.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(allInventarTable.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, allInventarTable]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allInventarTable.length;
    setItemOffset(newOffset);
  };
  return (
    <div>
      <div className="navBar">
        <span>
          <p>Kitob inventrlar</p>
        </span>
        <span className="input-group input-group-sm  w-25">
          <input
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            type="search"
          />
          <span class="input-group-text" id="inputGroup-sizing-sm">
            <BsSearch />
          </span>
        </span>
      </div>
      <hr />

      <div class="table-responsive">
        <table class="table align-middle table-striped">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Faolmi?</th>
              <th scope="col">Bo‘limlar</th>
              <th scope="col">Inventar raqami</th>
              <th scope="col">Shtrixli kod</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems
                .filter((value) => {
                  if (searchTerm === "") {
                    return value;
                  } else if (value.inventar_raqam.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return value;
                  }     
                })
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>
                        {item.active === 0 ? (
                          <div className="chek2">
                            <CiSquareRemove style={{ color: "white" }} />
                          </div>
                        ) : (
                          <div className="chek">
                            <HiOutlineCheckCircle style={{ color: "white" }} />
                          </div>
                        )}
                      </td>
                      <td>{item.bulim.nomi}</td>
                      <td>{item.inventar_raqam}</td>
                      <td>{item.shtrix_code}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>

      <div className="my-pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default Inventarlar;
