import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../userComponents/HeaderMenuPage/Electronic_catalog/Electronic_catalogShow/Electronic_catalogShow.css"
import { BsDownload } from "react-icons/bs";
import { BaseUrl } from "../../../Super_admin/BaseUrl";

const ArticleShow = () => {
  const [catalogshow, setCatlogshow] = useState([]);
  const { id } = useParams();

  console.log("fdsfdsf", id);
  const getNews = () => {
    axios
      .get(BaseUrl + "/api/public/maqolakatalog/show/" + id)
      .then((res) => {
        console.log("show", res.data.maqola);
        setCatlogshow(res.data.maqola);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNews();
  }, []);
  return (
    <div className="container mt-5 mb-5">
      <div className="row w-100">
        <h3>{catalogshow && catalogshow.nomi}</h3>
        <div className="col-md-4 col-sm-12 ">
          <img
            className="w-100 mb-3"
            src={BaseUrl + "/storage/" + catalogshow.muqova_jpg}
            alt="gfsdgsdf"
          />
            <a href={BaseUrl + "/storage/" + catalogshow.kitob_pdf} target={"_blank"} >  <span className="download text-center w-100 border d-inline-block"><BsDownload /> Adabiyotni yuklab olish </span></a>
        </div>
        <div className="col-md-8 col-sm-12 py-2">
          <p className="m-0"><span className="text-muted">Mualliflar: </span><span className="title_katalog">{catalogshow && catalogshow.mualliflar && catalogshow.mualliflar.map((item, index)=>{
            return (
                <span key={index}>{item.nomi}</span>
            )
          })}</span></p>
          <p className="m-0"><span className="text-muted">Nashriyot: </span><span className="title_katalog">{catalogshow && catalogshow.nashriyot_nomi}</span></p>
          <p className="m-0"><span className="text-muted">Resursni qo'shgan muassasa: </span><span className="title_katalog">{catalogshow && catalogshow.created_by &&  catalogshow.created_by.lastname}</span></p>
         <div className="catalog_show_main">
         <span class="catalog_span"><samp className="text-muted">Resurs statusi</samp> {catalogshow && catalogshow.kitob_turi && catalogshow.kirish_huquqi === 1 ? "Ochiq" :  catalogshow.kirish_huquqi === 2 ? "Qisman ochiq"
                  : "Yopiq"}</span>
          <span class="catalog_span "><samp className="text-muted">Resurs turi:</samp> {catalogshow && catalogshow.kitob_turi && catalogshow.kitob_turi.nomi}</span>
          <span class="catalog_span"><samp className="text-muted">Resurs tili:</samp> {catalogshow && catalogshow.kitob_tili && catalogshow.kitob_tili.nomi}</span>
          <span class="catalog_span"><samp className="text-muted">Davlat:</samp> {catalogshow && catalogshow.country && catalogshow.country.nomi}</span>
          <span class="catalog_span"><samp className="text-muted">Nashr yili:</samp> {catalogshow && catalogshow.nash_yili}</span>
          <span class="catalog_span"><samp className="text-muted">Resurs sohasi:</samp> {catalogshow && catalogshow.fan_sohasi && catalogshow.fan_sohasi.nomi}</span>
          <span class="catalog_span"><samp className="text-muted">Sahifa soni:</samp> {catalogshow && catalogshow.betlar_soni}</span>
          <span class="catalog_span"><samp className="text-muted">Resurs ruxsati:</samp> {catalogshow && catalogshow.kimga_muljallangan && catalogshow.kimga_muljallangan.nomi}</span>
         </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleShow;
