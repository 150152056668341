import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { BaseUrl } from "../../BaseUrl";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AddUniversityKutubxona = () => {

    const kafedra = useSelector(state => state.store.kafedra)

    const [masul_shaxs, setMasulshaxs] = useState("")
    const [nomi, setNomi] = useState("")
    const [izoh, setIzoh] = useState("")
    const [text, setText] = useState(false)
    const { id } = useParams()
    const [active, setActive] = useState("")
    // const [text, setText] = useState(false)


    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") { } else if (text === "Yangi Kutubxona bo'limi yaratildi.") {
            toast.success(text)
        }
        else if (text === "Kutubxona bo'limi bazadan o'zgartirildi.") {
            toast.success(text)
        }
        else {
            toast.error(text)
        }
        setText("")
    };


    const addKutubxona = () => {
        if (id) {
            axios.put(BaseUrl + "/api/admin/kutubxonabulim/edit/" + id, { nomi, izoh, masul_shaxs, active }, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                // console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                    setMasulshaxs("")
                }
            }).catch(err => {
                console.log(err.response);
                setText(err.response.data.errors)
            })
        }
        else {
            axios.post(BaseUrl + "/api/admin/kutubxonabulim", { nomi, izoh, masul_shaxs }, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            }).then(res => {
                // console.log(res.data);
                if (res.status == 200) {
                    setText(res.data.message)
                    setNomi("")
                    setIzoh("")
                    setMasulshaxs("")
                }
            }).catch(err => {
                console.log(err.response.data.errors);
                setText(err.response.data.errors)
            })
        }
    }

    const getBulim = () => {
        axios.get(BaseUrl + "/api/admin/kutubxonabulim", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            res.data.kutubxonaBulim.forEach((item, index) => {
                if (item.id == id) {
                    setMasulshaxs(item.masul_shaxs)
                    setNomi(item.nomi);
                    setIzoh(item.izoh);
                    // setLastName(item.lastname);
                    // setFathername(item.fathername);
                    // console.log(item);
                    // console.log(res.data)
                }
            })
            // dispatch(BulimAction(res.data.bulimlar))

        }).catch(err => {
            console.log(err);
        })
    }

    const getKutubxona = () => {
        axios.get(BaseUrl + "/api/admin/kutubxonabulim", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            res.data.kutubxonaBulim.forEach((item, index) => {
                if (item.id == id) {
                    setMasulshaxs(item.masul_shaxs)
                    setNomi(item.nomi);
                    setIzoh(item.izoh);
                    // setLastName(item.lastname);
                    // setFathername(item.fathername);
                    // console.log(item);
                    // console.log(res.data)
                }
            })
            // dispatch(BulimAction(res.data.bulimlar))

        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getKutubxona()
    }, [])



    useEffect(() => {
        getBulim()
    }, [])


    return (
        <div style={{ width: "100%" }}>
            <ToastContainer />
            <h2 className="mt-4">Kutubxona bo'limi yaratish</h2>
            <hr />
            <div style={{ width: "100%", padding: "10px 50px", display: "flex", justifyContent: "space-between" }} className="main_box">
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">
                        <div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Ma'sul shaxsni kiriting</label></div>
                                <input type="text" className="form-control " onChange={(e) => setMasulshaxs(e.target.value)} value={masul_shaxs} />
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Nomini kiriting</label></div>
                                <input onChange={(e) => setNomi(e.target.value)} value={nomi} placeholder="Nomi..." className="form-control" type="text" />
                            </div>
                            <div>
                                <div><label className="mt-3" htmlFor="">Izoh kiriting</label></div>
                                <input onChange={(e) => setIzoh(e.target.value)} value={izoh} placeholder="Izoh..." className="form-control" type="text" />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%" }} className="first">
                    <div style={{ width: "100%" }} className="main-add">

                        <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                            {id ? <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <select style={{ width: "200px", height: "40px" }} className="form-select mt-3" onChange={(e) => setActive(e.target.value)} name="" id="">
                                    <option value="">tanlang</option>
                                    <option value="1">Faol</option>
                                    <option value="0">Faol emas</option>
                                </select>
                                <button onClick={addKutubxona} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                            </div>
                                :
                                <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                    <button onClick={addKutubxona} style={{ width: "30%" }} className="btn btn-primary mt-3">{id ? "O'zgartirish" : "Yaratish"}</button>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUniversityKutubxona;