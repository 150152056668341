import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CiSquareRemove } from "react-icons/ci";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { GetMessageData } from "../../redux/actions/Action";

const Royhat2Detail = () => {
    const { id } = useParams();
    const [bandKitobShow, setBandKitobShow] = useState([]);
    const [izoh, setIzoh] = useState("");
    const [text, setText] = useState("");


    const getBandKitobShow = () => {
        axios
            .get(BaseUrl + `/api/kutubxonachi/qatnov/show/${id}`, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data.qatnov);
                setBandKitobShow(res.data.qatnov);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getBandKitobShow();
    }, []);

    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === "") {
        } else if (text === "Kitob band qilish so'rovi tasdiqlandi.") {
            toast.success(text);
        } else {
            toast.error(text);
        }
        setText("");
    };

    const [allBulims, setAllBulims] = useState()
    const [allXona, setAllXona] = useState()
    const [allMaqsad, setAllMaqsad] = useState()

    const getBulim = () => {
        axios.get(BaseUrl + `/api/admin/kutubxonabulim`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        }).then(res => {
            // console.log(res.data.kutubxonaBulim);
            setAllBulims(res.data.kutubxonaBulim)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getBulim()
    }, [])

    const userId = sessionStorage.getItem("userId")
    const [maqsad_id, setMaqsadId] = useState()
    const [bulim_id, setBulimId] = useState()
    const [xona_id, setXonaId] = useState()

    const getXona = () => {
        axios.get(BaseUrl + `/api/admin/bulimxona/${bulim_id}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        }).then(res => {
            console.log("xona", res.data.xonalar);
            setAllXona(res.data.xonalar)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getXona()
    }, [bulim_id])


    const getMaqsad = () => {
        axios.get(BaseUrl + `/api/admin/qatnovmaqsadi/${bulim_id}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        }).then(res => {
            console.log("maqsad", res.data.maqsadlar);
            setAllMaqsad(res.data.maqsadlar)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getMaqsad()
    }, [bulim_id])


    const biriktirish = () => {
        axios.put(BaseUrl + `/api/kutubxonachi/qatnov/edit/${id}`, { userId, maqsad_id, bulim_id, xona_id }, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        }).then(res => {
            // console.log(res.data.message);
            setText(res.data.message)
            getBandKitobShow()
        }).catch(err => {
            // console.log(err.response.data.errors);
            setText(err.response.data.errors)
        })
    }


    return (
        <div style={{ width: "100%" }}>
            <div className="navBar">
                <p>Maqsadli tashrifni biriktirish</p>
                <ToastContainer />
                <Link to="/adminMain/bandKitoblar">
                    <div className="add-kafedra">Orqaga</div>
                </Link>
            </div>
            <hr />
            <div className="card shadow p-3">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <ul class="list-inline">
                            <li class="list-group-item py-2">
                                <b>F.I.O: </b>
                                {bandKitobShow.user && bandKitobShow.user[0].firstname} {bandKitobShow.user && bandKitobShow.user[0].lastname} {bandKitobShow.user && bandKitobShow.user[0].middlename}
                            </li>
                            <li class="list-group-item py-2">
                                <b>Foydalanuvchi toifasi: </b>
                                {bandKitobShow.user && bandKitobShow.user[0].lavozim.nomi}
                            </li>
                            <li class="list-group-item py-2">
                                <b>Fakulteti: </b>
                                {bandKitobShow.user && bandKitobShow.user[0]?.fakultet.nomi}
                            </li>
                            <li class="list-group-item py-2">
                                <b>Yo'nalishi: </b>
                                {bandKitobShow.user && bandKitobShow.user[0].yunalish.nomi}
                            </li>
                            <li class="list-group-item py-2">
                                <b>Kursi: </b>
                                {bandKitobShow.user && bandKitobShow.user[0].kursi}
                            </li>
                            <li class="list-group-item py-2">
                                <b>Bo'limi: </b>
                                {bandKitobShow.bulim && bandKitobShow.bulim.nomi}
                            </li>
                            <li class="list-group-item py-2">
                                <b>Xonasi: </b>
                                {bandKitobShow.xona && bandKitobShow.xona.nomi}
                            </li>
                            <li class="list-group-item py-2">
                                <b>Maqsadi: </b>
                                {bandKitobShow.maqsad && bandKitobShow.maqsad.nomi}
                            </li>


                            {/* <li class="list-group-item py-2">
                <b>Kitob mualliflari: </b>
                {bandKitobShow &&
                  bandKitobShow.mualliflar &&
                  bandKitobShow.mualliflar.map((item, index) => {
                    return <span key={index}>{item.nomi}, </span>;
                  })}
              </li> */}

                        </ul>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <li class="list-group-item py-2">
                            <b>Kutubxona bolimini tanlang: </b>
                            <select className="form-control" onChange={(e) => setBulimId(e.target.value)} name="" id="">
                                <option>Tanlang</option>
                                {
                                    allBulims?.map((item, index) => (
                                        <option key={index} value={item.id}>{item.nomi}</option>
                                    ))
                                }
                            </select>
                        </li>
                        <li class="list-group-item py-2">
                            <b>Kutubxona xonasini tanlang: </b>
                            <select className="form-control" onChange={(e) => setXonaId(e.target.value)} name="" id="">
                                <option>Tanlang</option>
                                {
                                    allXona?.map((item, index) => (
                                        <option key={index} value={item.id}>{item.nomi}</option>
                                    ))
                                }
                            </select>
                        </li>
                        <li class="list-group-item py-2">
                            <b>Maqsadni tanlang: </b>
                            <select className="form-control" onChange={(e) => setMaqsadId(e.target.value)} name="" id="">
                                <option>Tanlang</option>
                                {
                                    allMaqsad?.map((item, index) => (
                                        <option key={index} value={item.id}>{item.nomi}</option>
                                    ))
                                }
                            </select>
                        </li>
                        <button style={{ width: "90px", border: "0", height: "40px", borderRadius: "7px", backgroundColor: "primary" }} onClick={biriktirish}>Add</button>
                        {bandKitobShow?.bulim?.nomi !== "Belgilanmadi" ? <button style={{ width: "150px", border: "0", height: "40px", borderRadius: "7px", backgroundColor: "primary", marginLeft: "15px" }}>
                            <Link to={'/adminMain/getbooks'}>kitob berish</Link>
                        </button> : ""}
                    </div>
                </div>
            </div>

            <hr />
            <div className="row">
                <div className="col-md-4 col-sm-12">
                    {/* <div className="card shadow p-3">
            <p className="fs-5">Buyurtma qilingan kitob tayyorligi haqida foydalanuvchiga xabar yuboring</p>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Izoh </label>
              <textarea 
               onChange={(e) => setIzoh(e.target.value)}
               value={izoh}
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"></textarea>
            </div>
            <button  type="button"  class="btn btn-outline-primary mt-2">Yuborish</button>
          </div> */}
                </div>
            </div>
        </div>
    );
};

export default Royhat2Detail;
