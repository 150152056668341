/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { Link, useParams } from "react-router-dom";

const Book_EntryAdd = () => {
  const [nomi, setNomi] = useState("");
  const [izoh, setIzoh] = useState("");
  const [text, setText] = useState("");
  const [active, setActive] = useState("");
  const { id } = useParams();

  useEffect(() => {
    notify();
  }, [text]);

  const notify = () => {
    if (text === "") {
    } else if (text === "Yangi kitob yozuvi yaratildi.") {
      toast.success(text);
    } else if (text === "Kitob yozuvi bazadan o'zgartirildi.") {
      toast.success(text);
    } else {
      toast.error(text);
    }
    setText("");
  };

  const addDate = () => {
    if (id) {
      axios
        .put(
          BaseUrl + "/api/kutubxonachi/kitobyozuvi/edit/" + id,
          { nomi, izoh, active },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
        //   console.log(res.data.message);
          if (res.status == 200) {
            setText(res.data.message);
            setNomi("");
            setIzoh("");
          }
        })
        .catch((err) => {
        //   console.log(err.response.data.message);
          setText(err.response.data.message);
        });
    } else
      axios
        .post(
          BaseUrl + "/api/kutubxonachi/kitobyozuvi",
          { nomi, izoh },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
        //   console.log(res.data.message);
          if (res.status == 200) {
            setText(res.data.message);
            setNomi("");
          }
        })
        .catch((err) => {
          console.log(err);
          setText(err.response.data.errors);
        });
  };



  const getEdit = () => {
    // console.log("salom", id);
    axios
      .get(BaseUrl + "/api/kutubxonachi/kitobyozuvi/", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        res.data.kitobYozuvlari.forEach((item, index) => {
          if (item.id == id) {
            setNomi(item.nomi);
            setIzoh(item.izoh);
            setActive(item.active)
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEdit();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <div className="navBar">
        <p>Kitob yozuvi {id ? "o'zgartirish" : "yaratish"}</p>
        <ToastContainer />
        <Link to={sessionStorage.getItem("userId") == 1 ? "/Supermain/book_entry" : "/adminMain/book_entry"}>
          <div className="add-kafedra">Orqaga</div>
        </Link>
      </div>
      <hr />

      <div className="row">
        <div className="col-md-4 col-sm-12">
          <div className="card shadow p-3">
            <div>
              <label className="mt-3" htmlFor="">
                Nomini kiriting
              </label>
            </div>
            <input
              onChange={(e) => setNomi(e.target.value)}
              value={nomi}
              placeholder="Nomi..."
              className="form-control"
              type="text"
            />
            <div>
              <label className="mt-3" htmlFor="">
                Izoh kiriting
              </label>
            </div>
            <input
              onChange={(e) => setIzoh(e.target.value)}
              value={izoh}
              placeholder="Izoh..."
              className="form-control"
              type="text"
            />
            <label className="mt-3" htmlFor="">
              Faoligi
            </label>
            <select
              className="form-select "
              onChange={(e) => setActive(e.target.value)}
              name=""
              id=""
            >
              <option value="1">tanlang</option>
              <option value="1">Faol</option>
              <option value="0">Faol emas</option>
            </select>
            <button onClick={addDate} className="btn btn-primary mt-3">
              {id ? "O'zgartirish" : "Yaratish"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book_EntryAdd;
