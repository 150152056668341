import React from "react";
import { HiOutlineCheckCircle } from "react-icons/hi"
import { MdOutlineDelete } from "react-icons/md"
import { CiSquareRemove } from "react-icons/ci"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import SorovModal from "./SorovModal";
import { BaseUrl } from "../../Super_admin/BaseUrl";

const Sorovlar = ({ getMessagYuklabOlish }) => {

    const [sorov, setSorov] = useState([])
    const [sorovId, setSorovId] = useState([]);
    const [kitobId, setKitobId] = useState([]);
    const [modal1Open, setModal1Open] = useState(false);

    const KitibSorovlari = () => {
        axios.get(BaseUrl + `/api/kutubxonachi/kitobsurovlari`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data)
            setSorov(res.data.surovlar)
            getMessagYuklabOlish()
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        KitibSorovlari()
    }, [modal1Open])

    return (
        <div>
            <ToastContainer />
            <h2 className="mt-4">Kitob uchun so'rovlar</h2>
            <hr />
            <div className="tableScroll">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">Nomi</th>
                            <th scope="col">Nashiryot nomi</th>
                            <th scope="col">Kimdan</th>
                            <th scope="col">Hemis id</th>
                            <th scope="col">Muddati</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sorov && sorov.map((item, index) => (
                                <tr key={index}>
                                    {console.log('sorov status ', typeof item.status)}
                                    <th scope="row">{item.id}</th>
                                    <td>{item.kitob.nomi}</td>
                                    <td>{item.kitob.nashriyot_nomi}</td>
                                    <td>{item.user.firstname} {item.user.lastname}</td>
                                    <td>{item.user.hemis_id}</td>
                                    <td>{item.muddati}</td>
                                    <td>
                                        {/* 0 javob berish
                                            3 muddati o'tkan
                                            1 / 2 javob berilgan
                                        */}
                                        {
                                            item.status === 0 ? <button onClick={() => { setModal1Open(true); setSorovId(item.id); setKitobId(item.kitob.id) }} className="btn btn-primary">Javob berish</button>
                                            : item.status === 3 ? <button onClick={() => { setModal1Open(true); setSorovId(item.id); setKitobId(item.kitob.id) }} className="btn btn-danger">Muddati o'tkan</button>
                                            : <button onClick={() => { setModal1Open(true); setSorovId(item.id); setKitobId(item.kitob.id) }} className="btn btn-warning">Javob berilgan</button>
                                        }
                                    </td>
                                    {/* <td>{item.status == 0 ? "Jarayonda" :""}{item.satus == 1 ? "Ruxsat berildi" :""} {item.satus == 2 ? "Rad etildi" :""} {item.satus == 3 ? "Muddat o'tib ketdi" :""}</td>
                                    <td>{item.status == 0 ? "Jarayonda" :""} {item.status == 1 ? item.muddati :""} {item.status == 2 ? "Rad etildi" :""} {item.status == 3 ? item.muddati :""}</td> */}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <SorovModal modal1Open={modal1Open} setModal1Open={setModal1Open} sorovId={sorovId} kitobId={kitobId} />
        </div>
    )
}

export default Sorovlar