import "./Content.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "..//..//..//Super_admin/BaseUrl";
import KutubxonaFond from "./KutubxonaFond/KutubxonaFond";
import TavsiyaEtilgan from "./TavsiyaEtilgan/TavsiyaEtilgan";
import Statistik from "./Statistik/Statistik";

function Content() {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };

  const [catalog, setCatlog] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const navgate = useNavigate();

  const getNews = () => {
    axios
      .get(BaseUrl + "/api/public/kitobkatalog")
      .then((res) => {
        let grantCount = [];
        for (let i = 0; i < 1; i++) {
          const element = res.data.kitoblar[i];
          grantCount.push(element);
        }
        setCatlog(grantCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNews();
  }, []);

  // console.log("catalog", catalog);

  return (
    <>
      {/* <Home /> */}
      <div className="home">
        <div className="container my-5 library-fond">
          <h1>Kutubxona fondi</h1>
          <KutubxonaFond />


          <h1>Tavsiya etilgan adabiyotlar</h1>
          <TavsiyaEtilgan />


          {/* <h1>Eng faol kitobxonlar</h1>
          <div className="row row_main">
            {catalog &&
              catalog.map((item, index) => {
                console.log("catalogggg", item);
                return (
                  <div key={index} class="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="card card_book" onClick={() =>
                            navgate(`/electronic_catalog/show/${item.id}`)} > 
                       <img
                        className=""
                        src={BaseUrl + "/storage/" + item.muqova_jpg}
                        alt="gfsdgsdf"
                      /> 
                      <div class="card-content">
                        <h2>{item.nomi}</h2>
                      </div> 
                    </div>
                  </div>
                );
              })}
          </div>  */}

          
        </div>
      </div>
    </>
  );
}

export default Content;
