import React from "react";
import { HiOutlineCheckCircle } from "react-icons/hi"
import { MdOutlineDelete } from "react-icons/md"
import { CiSquareRemove } from "react-icons/ci"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { BaseUrl } from "../../../../Super_admin/BaseUrl";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const YuklashSorov = () => {

    const [sorov, setSorov] = useState([])
    const navigate = useNavigate()

    const yuklabOlishSorovlari = () => {
        axios.get(BaseUrl + `/api/user/kitobsurovlar/${sessionStorage.getItem("userId")}`, {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data)
            setSorov(res.data.surovlar)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        yuklabOlishSorovlari()
    }, [])

    console.log(sorov);
    return (
        <div>
            <ToastContainer />
            <h2 className="mt-4">Yuklab olish so'rovlari</h2>
            <hr />
            <div className="tableScroll">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">Nomi</th>
                            <th scope="col">Nashiryot nomi</th>
                            <th scope="col">Izoh</th>
                            <th scope="col">Status</th>
                            <th scope="col">Muddati</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sorov && sorov.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.kitob.nomi}</td>
                                    <td>{item.kitob.nashriyot_nomi}</td>
                                    <td>{item.izoh ? item.izoh : "-"}</td>
                                    <td>
                                        {item.status == 0 ? <div className="btn btn-info" >Jarayonda</div> : ""}
                                        {item.status == 1 ? <div onClick={()=>navigate(`/electronic_catalog/show/${item.kitob_id}`)} className="btn btn-success" >Ruxsat berildi</div> : ""}
                                        {item.status == 2 ? <div className="btn btn-danger" >Rad etildi</div> : ""}
                                        {item.status == 3 ? <div className="btn btn-warning" >Muddat o'tib ketdi</div> : ""}
                                    </td>
                                    <td>{item.status == 0 ? "-" : ""} {item.status == 1 ? item.muddati : ""} {item.status == 2 ? "Rad etildi" : ""} {item.status == 3 ? item.muddati : ""}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default YuklashSorov