import React from "react";

const Traditional_service = () => {
  return (
    <div className="container py-3" >
      <p className="m-0 text-center">
        <b>
          Andijon davlat universiteti Axborot-resurs markazi faoliyati haqida
          umumiy
        </b>
      </p>
      <p className="m-0">
        Universitet Axborot-resurs markazi (keyingi o‘rinlarda – ARM deyiladi)
        faoliyati O‘zbekiston Respublikasi Vazirlar Maxkamasining 2022 yil 30
        sentyabrda tasdiqlangan 552-sonli qarori asosida tartibga solingan.
        Vazirlar Mahkaмаsining 552-sonli qarori asosida universitet axborot
        resurs markazi faoliyatini tartibga soluvchi NIZOM ishlab chiqilgan
        hamda tasdiqlangan(2022 yil 2 dekabrda tasdiqlangan). Axborot resurs
        markazining faoliyati mazkuг Nizomga to‘liq mos keladi. Axborot-resurs
        markazi xodimlarining lavozim yo‘riqnomasi ishlab chiqilgan va shu
        asosida ish tashkil etilgan. Axborot resurs markazining ish rejalari
        mavjud bo‘lib, mazkur rejasi bo‘yicha ishlar tashkil etilgan.
      </p>
      <p className="m-0">
        Universitet Axborot-resurs markazi (keyingi o‘rinlarda – ARM deyiladi)
        faoliyati O‘zbekiston Respublikasi Vazirlar Maxkamasining 2022 yil 30
        sentyabrda tasdiqlangan 552-sonli qarori asosida tartibga solingan.
        Vazirlar Mahkaмаsining 552-sonli qarori asosida universitet axborot
        resurs markazi faoliyatini tartibga soluvchi NIZOM ishlab chiqilgan
        hamda tasdiqlangan(2022 yil 2 dekabrda tasdiqlangan). Axborot resurs
        markazining faoliyati mazkuг Nizomga to‘liq mos keladi. Axborot-resurs
        markazi xodimlarining lavozim yo‘riqnomasi ishlab chiqilgan va shu
        asosida ish tashkil etilgan. Axborot resurs markazining ish rejalari
        mavjud bo‘lib, mazkur rejasi bo‘yicha ishlar tashkil etilgan.{" "}
      </p>
      <p className="m-0">
        Universitet Axborot-resurs markazi (keyingi o‘rinlarda – ARM deyiladi)
        faoliyati O‘zbekiston Respublikasi Vazirlar Maxkamasining 2022 yil 30
        sentyabrda tasdiqlangan 552-sonli qarori asosida tartibga solingan.
        Vazirlar Mahkaмаsining 552-sonli qarori asosida universitet axborot
        resurs markazi faoliyatini tartibga soluvchi NIZOM ishlab chiqilgan
        hamda tasdiqlangan(2022 yil 2 dekabrda tasdiqlangan). Axborot resurs
        markazining faoliyati mazkuг Nizomga to‘liq mos keladi. Axborot-resurs
        markazi xodimlarining lavozim yo‘riqnomasi ishlab chiqilgan va shu
        asosida ish tashkil etilgan. Axborot resurs markazining ish rejalari
        mavjud bo‘lib, mazkur rejasi bo‘yicha ishlar tashkil etilgan.{" "}
      </p>
      <p className="m-0">25851 nomda, 291622 nushada.</p>
      <p className="m-0">Shundan: </p>
      <ul>
        <li>Darslik - 1476 nomda, 47327 nusxa</li>
        <li>Darslik - 1476 nomda, 47327 nusxa</li>
        <li>Ilmiy adabiyotlar – 12827 nomda, 61875 nusxa</li>
        <li>Badiiy adabiyotlar – 6977 nomda, 16034 nusxa</li>
        <li>Boshqa turdagi adabiyotlar -1354 nomda, 98351 nusxa</li>
        <li>Xorijiy adabiyotlar -97 nomda, 257 nusxa</li>
        <li>Xorijiy adabiyotlar -97 nomda, 257 nusxa</li>
        <li>
          Darslik va o‘quv qo‘llanmalarning elektron nusxasi – 25851 nomda
        </li>
        <li>Audio darsliklar — 423 nomda</li>
        <li>Badiiy nashrlarning elektron nusxasi – 721 nomda</li>
        <li>Badiiy nashrlarning audio to‘plamlari — 128 nomda</li>
      </ul>
      <p className="m-0">Badiiy nashrlarning audio to‘plamlari — 128 nomda</p>
    </div>
  );
};

export default Traditional_service;
