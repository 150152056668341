import React, { useEffect, useState } from "react";
// import "./UniversityStructure.css"
import { HiOutlineCheckCircle } from "react-icons/hi"
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"
import { MdOutlineDelete } from "react-icons/md"
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../Super_admin/BaseUrl";
import ReactPaginate from "react-paginate"
import { useDispatch, useSelector } from "react-redux";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CiSquareRemove } from "react-icons/ci" 
import { IlmiyNashAction, ShaharAction } from "../../redux/actions/Action";

const IlmiyNashr = () => {

    // const [fakultet, setFakultet] = useState([])
    const navgate = useNavigate()
    const dispatch = useDispatch()
    const ilmiynashr = useSelector(state =>state.store.ilmiynashr)
    // console.log(fakultet);

    const [text, setText] = useState(false)

    const [nomi, setNomi] = useState("")
    const [anotatsiya, setAnotatsiya] = useState("")
    const [country_id, setCountry_id] = useState("")
    const [city_id, setCity_id] = useState("")
    const [ilmiy_nashr_turi_id, setIlmiy_nashr_turi_id] = useState("")
    const [resurs_tili_id, setResurs_tili_id] = useState("")
    const [fan_sohasi_id, setFan_sohasi_id] = useState("")
    const [yunalishi_id, setYunalishi_id] = useState("")
    const [yili, setYili] = useState("")
    const [sahifa_soni, setSahifa_soni] = useState("")
    const [invertar_raqam, setInvertar_raqam] = useState("")
    const [muqova_jpg, setMuqova_jpg] = useState("")
    const [elektron_pdf, setElektron_pdf] = useState("")
    const [izoh, setIzoh] = useState("")
    const [user_id, setUser_id] = useState("")
    const [mualliflar, setMualliflar] = useState("")
    const [kalitSuzlar, setKalitSuzlar] = useState("")





  
    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === ""){} 
        else if (text === "Yangi Fakultet yaratildi."){
            toast.success(text)
        }
        else if (text === "Fakultet o'zgartirildi."){
            toast.success(text)
        }
        else{
            toast.error(text)
        }
        setText("")
    };

      const  getIlmiyNashr = () => {
        axios.get(BaseUrl + "/api/kutubxonachi/ilmiynashr", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res => {
            console.log(res.data.ilmiyNashrlar);
            // setFakultet(res.data.fakultetlar)
            dispatch(IlmiyNashAction(res.data.ilmiyNashrlar))
            
        }).catch(err => {
            console.log(err);
        })
    }

    const deleteIlmiyNashr = (id) =>{
        axios.delete(BaseUrl+`/api/kutubxonachi/ilmiynashr/${id}`,{
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res.data.message);
            if (res.status == 200) {
                getIlmiyNashr()
                setText(res.data.message)
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(() => {
        getIlmiyNashr()
    }, [])



    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 13;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(Loading items from ${itemOffset} to ${endOffset});
        setCurrentItems(ilmiynashr && ilmiynashr.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(ilmiynashr.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, ilmiynashr]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % ilmiynashr.length;
        setItemOffset(newOffset);
    };

    return (
        <div>
             <ToastContainer/>
            <h2 className="mt-4">Ilmiy nashrlar</h2>
            <hr />
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Link to={sessionStorage.getItem("userId") == 1 ? "/Supermain/ilmiynashr/add" : "/adminMain/ilmiynashr/add"}>
                    <div className="add-kafedra">Qo'shsih</div>
                </Link>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Turi</th>
                        <th scope="col">Nomi</th>
                        <th scope="col">Yili</th>
                        <th scope="col">Faolmi</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems && currentItems.map((item,index)=>(
                        <tr key={index}>
                        <th scope="row">{item.id}</th>
                        <td>{item.ilmiy_nashr_turi.nomi}</td>
                        <td style={{width:"300px",boxSizing:"border-box"}}>{item.nomi}</td>
                        <td>{item.yili}</td>
                        <td>{
                                item.active == 0 ? <div className="chek2"><CiSquareRemove style={{ color: "white" }} /></div>
                                    :
                                    <div className="chek"><HiOutlineCheckCircle style={{ color: "white" }} /></div>
                                }</td>
                        <td>
                            <div className="main_edite-box">
                                <div className="edite_show_box">
                                {/*  */}
                                {/*  */}
                                    <div onClick={() => navgate(`/Supermain/ilmiynashr/show/${item.id}`)} className="show_data"><AiOutlineEye /></div>
                                    {/* <div  className="edite"><AiOutlineEdit /></div> */}
                                    <div onClick={()=>deleteIlmiyNashr(item.id)}    className="delete"><MdOutlineDelete /></div>
                                </div>
                                {/* <div className="allDelete">Ma'lumotlar bazasidan o'chirish</div> */}
                            </div>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>


            <div className="my-pagination">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
        </div>
    )
}

export default IlmiyNashr