import React, { useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "./Super_admin/BaseUrl";
import { useDispatch } from "react-redux";
import {
    BulimAction, DavlatAction, FakultetAction, GroupAction, IlmiyNashTuriAction, KafedraAction, KutubxonaActions, ShaharAction, UquvYiliAction, UserLavozim, UserListAction,
    YunalishAction, Book_entryAction, Book_directionAction, Book_languageAction, Book_typeAction,
    File_typeAction, Science_fieldAction, SumerkaAction, CategoryAction, Text_typeAction, JurnalListAction, MaqolaAction, JurnalSoniListAction, IlmiyNashAction
} from "./redux/actions/Action";




export const AllFunctionActions = () => {
    const dispatch = useDispatch()

    // <<<<<<<<<<<<<<<<<<<< GET ALL FUNCTION COMPONENTS >>>>>>>>>>>>>>>>>>>>>>>>>>

    const GETUQUVYILI = () => {
        axios.get(BaseUrl + "/api/admin/uquvyili", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.uquvYillari);
            // setFakultet(res.data.fakultetlar)
            dispatch(UquvYiliAction(res.data.uquvYillari))
        }).catch(err => {
            // console.log(err.response);
        })
    }

    const getBulim = () => {
        axios.get(BaseUrl + "/api/admin/bulim", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            dispatch(BulimAction(res.data.bulimlar))

        }).catch(err => {
            console.log(err);
        })
    }


    const getFaculyty = () => {
        axios.get(BaseUrl + "/api/admin/fakultet", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.fakultetlar);
            // setFakultet(res.data.fakultetlar)
            dispatch(FakultetAction(res.data.fakultetlar))

        }).catch(err => {
            console.log(err);
        })
    }


    const getGroup = () => {
        axios.get(BaseUrl + "/api/admin/guruh", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            dispatch(GroupAction(res.data.guruhlar))
        }).catch(err => {
            console.log(err);
        })
    }

    const getKafedra = () => {
        axios.get(BaseUrl + "/api/admin/kafedra", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.kafedralar);
            // setFakultet(res.data.fakultetlar)
            dispatch(KafedraAction(res.data.kafedralar))
        }).catch(err => {
            console.log(err);
        })
    }


    const getYunalish = () => {
        axios.get(BaseUrl + "/api/admin/yunalish", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            dispatch(YunalishAction(res.data.yunalishlar))
        }).catch(err => {
            // console.log(err.response);
        })
    }


    const getUserType = () => {
        axios.get(BaseUrl + "/api/admin/lavozim", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res);
            dispatch(UserLavozim(res.data.lavozimlar))
        }).catch(err => {
            console.log(err);
        })
    }

    const getUserList = () => {
        axios.get(BaseUrl + "/api/admin/users", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.users);
            dispatch(UserListAction(res.data.users))
        }).catch(err => {
            console.log(err);
        })
    }

    const getKutubxona = () => {
        axios.get(BaseUrl + "/api/admin/kutubxonabulim", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            dispatch(KutubxonaActions(res.data.kutubxonaBulim))
        }).catch(err => {
            console.log(err.response);
        })
    }
    const getDavlat = () => {
        axios.get(BaseUrl + "/api/admin/country", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.countries);
            // setFakultet(res.data.fakultetlar)
            dispatch(DavlatAction(res.data.countries))

        }).catch(err => {
            console.log(err);
        })
    }

    const getShahar = () => {
        axios.get(BaseUrl + "/api/admin/city", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.cities);
            // setFakultet(res.data.fakultetlar)
            dispatch(ShaharAction(res.data.cities))

        }).catch(err => {
            console.log(err);
        })
    }

    const getIlmiyNashrTuri = () => {
        axios.get(BaseUrl + "/api/kutubxonachi/ilmiynashrturi", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            // setFakultet(res.data.fakultetlar)
            dispatch(IlmiyNashTuriAction(res.data.ilmiyNashrTurlari))

        }).catch(err => {
            console.log(err);
        })
    }

    // BOOKS

    const getBook_direction = () => {
        axios
            .get(BaseUrl + "/api/kutubxonachi/kitobyunalishi", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data);
                dispatch(Book_directionAction(res.data.kitobYunalishlari));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getBook_Entry = () => {
        axios
            .get(BaseUrl + "/api/kutubxonachi/kitobyozuvi", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data.kitobYozuvlari);
                dispatch(Book_entryAction(res.data.kitobYozuvlari));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getBook_language = () => {
        axios
            .get(BaseUrl + "/api/kutubxonachi/kitobtili", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data.kitobTillari);
                dispatch(Book_languageAction(res.data.kitobTillari));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getBook_type = () => {
        axios
            .get(BaseUrl + "/api/kutubxonachi/kitobturi", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data.kitobTuri);

                dispatch(Book_typeAction(res.data.kitobTuri));
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const getCategory = () => {
        axios
            .get(BaseUrl + "/api/kutubxonachi/kimgamuljal", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data.kimgaMuljallanganlar);
                dispatch(CategoryAction(res.data.kimgaMuljallanganlar));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getFile_type = () => {
        axios
            .get(BaseUrl + "/api/kutubxonachi/faylturi", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data.faylTurlari);
                dispatch(File_typeAction(res.data.faylTurlari));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getScience_field = () => {
        axios
            .get(BaseUrl + "/api/kutubxonachi/fansohasi", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data.fanSohalari);
                dispatch(Science_fieldAction(res.data.fanSohalari));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getSumerka = () => {
        axios
            .get(BaseUrl + "/api/kutubxonachi/sumerka", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data.sumerkalar);
                dispatch(SumerkaAction(res.data.sumerkalar));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getTextType = () => {
        axios
            .get(BaseUrl + "/api/kutubxonachi/matnturi", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // console.log(res.data.matnTurlari);
                dispatch(Text_typeAction(res.data.matnTurlari));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getJurnal = () => {
        axios.get(BaseUrl + "/api/kutubxonachi/jurnallar", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.jurnallar);
            dispatch(JurnalListAction(res.data.jurnallar))
        }).catch(err => {
            console.log(err.response);
        })
    }

    const getMaqola = () => {
        axios.get(BaseUrl + "/api/kutubxonachi/maqolalar", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.maqolalar);
            dispatch(MaqolaAction(res.data.maqolalar))
        }).catch(err => {
            console.log(err.response);
        })
    }

    const getJurnalSoni = () => {
        axios.get(BaseUrl + "/api/kutubxonachi/jurnalsonlari", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data);
            dispatch(JurnalSoniListAction(res.data.jurnalSonlari))
        }).catch(err => {
            console.log(err.response);
        })
    }
    const getIlmiyNashr = () => {
        axios.get(BaseUrl + "/api/kutubxonachi/ilmiynashr", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // console.log(res.data.ilmiyNashrlar);
            // setFakultet(res.data.fakultetlar)
            dispatch(IlmiyNashAction(res.data.ilmiyNashrlar))

        }).catch(err => {
            console.log(err);
        })
    }




    useEffect(() => {
        if (localStorage.getItem("token")) {
            GETUQUVYILI()
            getBulim()
            getFaculyty()
            getGroup()
            getKafedra()
            getYunalish()
            getUserType()
            getUserList()
            getKutubxona()
            getDavlat()
            getShahar()
            getIlmiyNashr()
            getIlmiyNashrTuri()
            getBook_direction()
            getBook_Entry()
            getBook_language()
            getBook_type()
            getCategory()
            getFile_type()
            getScience_field()
            getSumerka()
            getTextType()
            getJurnal()
            getMaqola()
            getJurnalSoni()
        }
    }, [])




    // <<<<<<<<<<<<<<<<<<<< GET ALL FUNCTION COMPONENTS >>>>>>>>>>>>>>>>>>>>>>>>>>

    return (
        <></>
    )
}